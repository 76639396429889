const isLocalhost = () => window.location.hostname === 'localhost';

export const isProduction = () => !isLocalhost();

export const pluralize = (count: number, noun: string, suffix = 's') =>
  `${noun}${count > 1 ? suffix : ''}`;

export const filter = <T>(
  items: T[],
  filters: (((items: T[]) => T[]) | undefined | boolean)[]
) => {
  return filters
    .filter((x) => typeof x === 'function')
    .reduce((acc, filter) => filter(acc), items);
};

export function extractLetters(input: string): string {
  // Split the input string into words
  const words = input.split(' ');

  if (words.length >= 2) {
    // If there are at least two words, take the first letter of the first two words
    return words[0]![0]! + words[1]![0];
  } else {
    // If there's only one word, take the first two letters of that word
    // Check if the word has at least two letters
    return words[0]!.length > 1 ? words[0]!.substring(0, 2) : words[0]!;
  }
}
