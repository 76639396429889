import { z } from 'zod';
import { Contract, defaultErrors, POST } from '@cotera/contracts';
import { llmMessageSchema, toolsSchema } from '../../llm';

export const completionParamSchema = z.object({
  model: z.string(),
  messages: llmMessageSchema.array(),
  orgId: z.string(),
});

export const llmToolUseParamSchema = z.object({
  model: z.string(),
  messages: llmMessageSchema.array(),
  tools: toolsSchema,
  orgId: z.string(),
});

export const LlmContract = Contract.new({
  completion: POST({
    params: completionParamSchema,
    output: z.object({
      completion: z.string(),
    }),
    errors: defaultErrors(['LlmError', 'ValidationError']),
    mustBeAbleTo: [['write', 'warehouse']],
  }),
  useTool: POST({
    params: llmToolUseParamSchema,
    output: z.object({
      response: z.string(),
    }),
    errors: defaultErrors(['LlmError', 'ValidationError']),
    mustBeAbleTo: [['write', 'warehouse']],
  }),
  embedding: POST({
    params: z.object({
      messages: z
        .object({
          id: z.string(),
          content: z.string(),
        })
        .array(),
    }),
    output: z.object({
      embeddings: z
        .object({
          id: z.string(),
          embedding: z.number().array(),
        })
        .array(),
    }),
    errors: defaultErrors(['EmbeddingsError']),
    mustBeAbleTo: [['use', 'application']],
  }),
});
