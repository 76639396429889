import _ from 'lodash';
import { AST } from '../../ast';
import { Constant, Eq, From, Or } from '../../builder';
import { Ty } from '../../ty';
import { sqlExprMacro, intersperse } from '../sql-ast';
import { assertConstantString, SqlDialect } from './dialect';
import { PostgresDialect } from './postgres';

const PrimitiveAttributeTypeToSnowflakeType: Record<
  Ty.PrimitiveAttributeType,
  string
> = {
  string: 'text',
  boolean: 'boolean',
  int: 'integer',
  float: 'float',
  day: 'timestamp_tz',
  month: 'timestamp_tz',
  year: 'timestamp_tz',
  timestamp: 'timestamp_tz',
  super: 'variant',
};

const typeMapping = (ty: Ty.AttributeType): string => {
  if (ty.k !== 'primitive') {
    return 'variant';
  }

  return PrimitiveAttributeTypeToSnowflakeType[ty.t];
};

const tryWarehouseTypeToEraType = (
  warehouseType: string
): Ty.AttributeType | null => {
  return null;
};

const getPropertyFromStruct = (
  expr: AST.ExprIR,
  name: string | AST.ExprIR,
  wantedTy: Ty.AttributeType
) => {
  const field =
    typeof name === 'string' ? Constant(name, { ty: 'string' }).ir() : name;
  return sqlExprMacro`CAST(GET(${expr}, ${field}) as ${typeMapping(wantedTy)})`;
};

export const SnowflakeDialect: SqlDialect = {
  tryWarehouseTypeToEraType,
  nullLiteral: () => sqlExprMacro`null`,
  supportsFileSources: false,
  quotingCharacter: '"',
  generateSeries: ({ start, stop }) => {
    const amount = stop - start + 1;
    return sqlExprMacro`select (ROW_NUMBER() over (order by "n")) + ${(
      start - 1
    ).toString()} as "n" from (select 1 as "n" from table (generator(rowcount => ${amount.toString()})))`;
  },
  makeArray: ({ elements }) => {
    return sqlExprMacro`[${intersperse<AST.ExprIR | string>(elements, ', ')}]`;
  },
  makeStruct(fields) {
    return sqlExprMacro`(OBJECT_CONSTRUCT(${intersperse(
      Object.entries(fields).flatMap(([name, expr]) => [`'${name}'`, expr]),
      ', '
    )}))::VARIANT`;
  },
  makeRecord(values) {
    return this.makeStruct(values);
  },
  getPropertyFromStruct,
  functionOverrides: {
    ...PostgresDialect.functionOverrides,
    get_from_record: ([target, name], wanted) =>
      getPropertyFromStruct(target!, name!, wanted),
    is_numeric_string: ([arg0]) => sqlExprMacro`${arg0!} regexp '^\\\\d+$'`,
    array_agg: ([arg0]) => sqlExprMacro`ARRAY_AGG(${arg0!})`,
    string_agg: ([arg0, arg1]) => sqlExprMacro`LISTAGG(${arg0!}, ${arg1!})`,
    '^': ([arg0, arg1]) => sqlExprMacro`pow(${arg0!}, ${arg1!})`,
    gen_random_uuid: () => sqlExprMacro`UUID_STRING()`,
    now: () => sqlExprMacro`CONVERT_TIMEZONE('UTC', CURRENT_TIMESTAMP())`,
    random: () => sqlExprMacro`uniform(0::float, 1::float, random())`,
    date_diff: ([arg0, arg1, arg2]) =>
      sqlExprMacro`DATEDIFF(${assertConstantString(
        arg2!,
        AST.DATE_DIFF_UNITS
      )}, CONVERT_TIMEZONE('UTC', ${arg0!}), CONVERT_TIMEZONE('UTC', ${arg1!}))`,
    date_trunc: ([arg0, arg1]) =>
      sqlExprMacro`DATE_TRUNC('${assertConstantString(
        arg1!,
        AST.DATE_TRUNC_UNITS
      ).toUpperCase()}', CONVERT_TIMEZONE('UTC', ${arg0!}))`,
    date_part: ([arg0, arg1]) =>
      sqlExprMacro`DATE_PART(${assertConstantString(
        arg1!,
        AST.DATE_PART_UNITS
      ).toUpperCase()}, CONVERT_TIMEZONE('UTC', ${arg0!}))`,
    date_add: ([arg0, arg1, arg2]) => {
      const unit = assertConstantString(arg2!, AST.DATE_ADD_UNITS);
      const replacement: string = { days: 'day' }[unit];
      return sqlExprMacro`DATEADD(${replacement}, (${arg1!}), CONVERT_TIMEZONE('UTC', ${arg0!}))`;
    },
    nan: () => sqlExprMacro`'NaN'::float`,
    is_nan: ([arg0]) => sqlExprMacro`(${arg0!}) = 'NaN'::float`,
    log_2: ([arg0]) => sqlExprMacro`log(2, ${arg0!})`,
    log_10: ([arg0]) => sqlExprMacro`log(10, ${arg0!})`,
    cosine_distance: ([arg0, arg1]) => {
      return sqlExprMacro`(1 - VECTOR_COSINE_SIMILARITY((${arg0!}), (${arg1!})))`;
    },
  },
  placeholder: function ({ oneIndexedArgNum }): string {
    return `(:${oneIndexedArgNum})`;
  },
  relation: function (ref): string {
    if (typeof ref === 'string') {
      return `"${ref}"`;
    }
    return ref.schema ? `"${ref.schema}"."${ref.name}"` : `"${ref.name}"`;
  },
  attr(ident): string {
    return `"${ident}"`;
  },
  typeMapping,
  informationSchema: {
    tables: (schemas: readonly string[]) => {
      const base = From({
        schema: 'INFORMATION_SCHEMA',
        name: 'TABLES',
        attributes: {
          TABLE_NAME: 'string',
          TABLE_SCHEMA: 'string',
        },
      }).renameAttributes((t) => t.toLowerCase());

      return base.where((t) =>
        Or(...schemas.map((schema) => Eq(schema, t.attr('table_schema'))))
      ).ast;
    },
    columns: (schemas: readonly string[]) => {
      const base = From({
        schema: 'INFORMATION_SCHEMA',
        name: 'COLUMNS',
        attributes: {
          COLUMN_NAME: 'string',
          TABLE_SCHEMA: 'string',
          TABLE_NAME: 'string',
          IS_NULLABLE: 'string',
          DATA_TYPE: 'string',
        },
      })
        .renameAttributes((t) => t.toLowerCase())
        .select((t) => ({
          ...t.star(),
          is_nullable: t.attr('is_nullable').eq('YES'),
        }));

      return base.where((t) =>
        Or(...schemas.map((schema) => Eq(schema, t.attr('table_schema'))))
      ).ast;
    },
  },
  values({ values, attributes }) {
    const selectExprs = _.sortBy(Object.entries(attributes), ([name]) => name)
      .map(
        ([name, { ty }]) =>
          `cast(value['${name}'] as ${typeMapping(ty)}) as ${this.attr(name)}`
      )
      .join(', ');

    return sqlExprMacro`select ${selectExprs} from table (flatten(input => parse_json(${Constant(
      JSON.stringify(values)
    ).ir()})))`;
  },
  cast: (expr, targetTy) => {
    return sqlExprMacro`cast(${expr} as ${typeMapping(targetTy)})`;
  },
};
