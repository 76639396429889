import { forwardRef } from 'react';
import { ColorScheme } from '../../utils/colors/color-scheme';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';

export const Caption = forwardRef<
  HTMLParagraphElement,
  ChildrenProps & { className?: string }
>(({ children, className }, ref) => {
  return (
    <p
      ref={ref}
      className={classNames(ColorScheme.text['muted'], 'text-xs', className)}
    >
      {children}
    </p>
  );
});
