import { round } from 'lodash';
import { ISO_4217_CURRENCY_CODES, getCurrencySymbol } from '../utils';
import { Format } from '.';
import { format } from '../../app/percentage/utilts';

export const NUMBER_FORMAT_OPTIONS = ['%', 'number'] as const;

export type NumberFormat = (typeof NUMBER_FORMAT_OPTIONS)[number] | string;

const isPercentageUnit = (unit: NumberFormat): unit is '%' => {
  return unit === '%';
};

export const isNumberFormat = (
  props: Format
): props is {
  unit: NumberFormat;
  value: number | string;
} => {
  return (
    !!props.unit &&
    (isCurrencyUnit(props.unit as NumberFormat) ||
      NUMBER_FORMAT_OPTIONS.includes(props.unit as any))
  );
};

const isCurrencyUnit = (
  unit?: NumberFormat
): unit is (typeof ISO_4217_CURRENCY_CODES)[number] => {
  return ISO_4217_CURRENCY_CODES.includes(unit?.toUpperCase() as any);
};

export const numberFormatter = (
  _value: number | string | null | undefined,
  unit?: NumberFormat | null | undefined
): string => {
  if (_value === null || _value === undefined) {
    return 'NULL';
  }

  if (isNaN(Number(_value))) {
    return 'Invalid number';
  }

  const value = Number(_value);

  if (!unit || unit === 'number') {
    return round(value, 2).toLocaleString();
  }

  if (isPercentageUnit(unit)) {
    return `${format(value).toLocaleString()}%`;
  }

  if (isCurrencyUnit(unit)) {
    const currencySymbol = getCurrencySymbol(unit);

    if (currencySymbol.isErr()) {
      return `${unit}${round(value, 2).toLocaleString()}`;
    }

    if (value < 0) {
      return `-${currencySymbol.value}${round(
        Math.abs(value),
        2
      ).toLocaleString()}`;
    }
    return `${currencySymbol.value}${round(value, 2).toLocaleString()}`;
  }

  return `${round(value, 2).toLocaleString()} ${unit}`;
};
