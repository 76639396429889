import React from 'react';
import { classNames } from '@cotera/client/app/components/utils';

export type TextAreaProps = {
  id: string;
  label?: string;
  className?: string;
} & React.HTMLProps<HTMLTextAreaElement>;

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ id, label, className = '', ...textAreaProps }, ref) => {
    return (
      <div className="flex flex-col w-full">
        {label && (
          <label
            htmlFor={id}
            className="block text-sm font-medium text-gray-800"
          >
            Enter one user ID per line
          </label>
        )}
        <textarea
          id={id}
          className={classNames(
            'mt-1 block w-full py-2 px-3 border border-divider bg-white rounded shadow-sm',
            'focus:outline-none focus:ring-indigo-500 focus:border-indigo-500',
            'sm:text-sm',
            className
          )}
          {...textAreaProps}
          ref={ref}
        />
      </div>
    );
  }
);
