import { z } from 'zod';
import { Contract, defaultErrors, GET, POST } from '@cotera/contracts';

const connectorSchema = z.object({
  id: z.string().uuid(),
  destinationId: z.string().uuid(),
  connector: z.string(),
  config: z.any(),
});

const destinationSchema = z.object({
  id: z.string().uuid(),
  warehouseConnectionId: z.string().uuid(),
  groupId: z.string(),
  connectors: connectorSchema.array(),
});

export const EtlContract = Contract.new({
  getDestination: GET({
    params: z.object({ orgId: z.string().uuid() }),
    output: destinationSchema,
    errors: defaultErrors(['NotFound']),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
  createConnector: POST({
    params: z.object({ connector: z.string(), config: z.any() }),
    output: z.object({ id: z.string() }),
    errors: defaultErrors(['FivetranError', 'NotFound']),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
  createConnectCard: POST({
    params: z.object({
      id: z.string().uuid(),
    }),
    output: z.object({ url: z.string().url() }),
    errors: defaultErrors(['FivetranError', 'NotFound']),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
});
