import { Expression } from '../../builder';
import { ExprCtx, ParseResult, T, Token, WithMeta } from '../ast';
import { lex } from '../lex';
import { parseExpr } from './parse-expr';
import { Ty } from '../../ty';
import _ from 'lodash';

type RawSqlExpressionCompiler = (
  rawCode: readonly string[],
  keys: readonly (Ty.Scalar | Expression)[],
  ctx: ExprCtx
) => ParseResult;

export type SqlExpressionCompiler = (
  rawCode: readonly string[],
  ...keys: readonly (Ty.Scalar | Expression)[]
) => Expression;

const RAW_EXPR_OFFSET = 4;

const rawSqlExpressionCompiler: RawSqlExpressionCompiler = (
  rawCode,
  keys,
  opts
) => {
  const tokens: WithMeta<Token>[] = [];

  let offset = 0;
  for (const [code = '', interpolated] of _.zip(rawCode, keys)) {
    for (const [token, meta] of lex(code, { offset })) {
      tokens.push([token, meta]);
      offset = meta.range[1];
    }

    if (interpolated !== undefined) {
      const expr = Expression.wrap(interpolated);
      tokens.push([
        T.rawExpr(expr),
        { range: [offset, offset + RAW_EXPR_OFFSET] },
      ]);
      offset += RAW_EXPR_OFFSET;
    }
  }

  return parseExpr(tokens, opts);
};

export const makeSqlExpressionCompiler = (
  ctx: ExprCtx,
  opts: {
    jsStackPointer?: Function;
  }
): SqlExpressionCompiler => {
  const inner: SqlExpressionCompiler = (rawCode, ...keys) => {
    const { jsStackPointer = inner } = opts;
    const res = rawSqlExpressionCompiler(rawCode, keys, ctx);
    if (res.isErr()) {
      const [e, _meta] = res.error;

      if (e.t === 'type-check-error') {
        throw e.trace.toError({ jsStackPointer });
      }

      const error = new Error(`${e.t}`);

      if ((Error as any).captureStackTrace && jsStackPointer !== undefined) {
        (Error as any).captureStackTrace(error, jsStackPointer);
      }

      throw error;
    }

    return res.value;
  };

  return inner;
};
