/* eslint-disable @typescript-eslint/unbound-method */

import { Token, WithMeta, T, OPERATORS, GROUPING } from './ast';

type Rule = [RegExp, (token: string) => Token];

const sanatizeForRegex = (token: string): string =>
  [...token]
    .map((char) => ('/.*+?|(){}[]^'.includes(char) ? `\\${char}` : char))
    .join('');

const regex = (literals: readonly string[]) =>
  new RegExp(`^(${literals.map((op) => sanatizeForRegex(op)).join('|')})`);

const lexDate = (match: string): Token => {
  const num = Date.parse(`${match.slice(1)}Z`);
  return isNaN(num) ? T.unknown(match) : T.lit(new Date(num));
};

const RULES: Rule[] = [
  // White Space
  [/^\s+/, T.ws],
  // Grouping
  [regex(GROUPING), T.group],
  // Ident
  [/^".*?"/, (t) => T.ident(t.slice(1, -1))],
  // String Literal
  [/^'.*?'/, (t) => T.lit(t.slice(1, -1))],
  // Number literal
  [/^-?\d+(\.\d+)?/, (token) => T.lit(Number(token))],
  // Bool Literal
  [/^(true|false)/, (x) => T.lit(x === 'true')],
  // Day Literal
  [/^@[\d-]+/, lexDate],
  // Operator
  [regex(OPERATORS), T.op],
  // Keyword
  [/^[a-zA-Z_]+/, T.sym],
];

export function* lex(
  sql: string,
  opts?: { offset?: number }
): Generator<WithMeta<Token>> {
  const { offset = 0 } = opts ?? {};
  let cursor = 0;

  outer: while (cursor < sql.length) {
    const unprocessed = sql.slice(cursor);

    for (const [match, process] of RULES) {
      const found = match.exec(unprocessed);

      if (found) {
        const rawToken = found[0];
        const end = cursor + rawToken.length;
        const token = process(rawToken);
        yield [token, { range: [cursor + offset, end + offset] }];
        cursor = end;
        continue outer;
      }
    }

    break;
  }
  if (cursor < sql.length) {
    const token = { t: 'unknown' as const, found: sql.slice(cursor) };
    yield [token, { range: [cursor, sql.length] }];
  }
  return;
}
