import { Relation } from '@cotera/era';
import React, { useState } from 'react';
import _ from 'lodash';
import { TopLevelMarkupWrapper } from '../top-level-markup-wrapper';
import { CompileMarkup } from '../apps/compiler/compiler';
import {
  SUPPORTED_CHARTS,
  SupportedChart,
  useChartBuilder,
} from './hooks/use-chart-builder';
import { RenderDraft } from './query-builder';

export const BuildChart: React.FC<{ rel: Relation }> = ({ rel }) => {
  const [chartType, setChartType] = useState<SupportedChart>('bar-chart');

  const { drafts, chart } = useChartBuilder({ rel, chartType });

  return (
    <div>
      <select
        defaultValue={chartType}
        onChange={(x) => setChartType(x.target.value as SupportedChart)}
      >
        {['sql', ...SUPPORTED_CHARTS].map((x) => (
          <option>{x}</option>
        ))}
      </select>
      <div className="flex flex-col gap-y-4 py-4">
        {drafts.map((draft) => (
          <RenderDraft draft={draft} nameMode="fixed" />
        ))}
      </div>
      {chart && (
        <TopLevelMarkupWrapper>
          <CompileMarkup inBlock={false} section={chart} />
        </TopLevelMarkupWrapper>
      )}
    </div>
  );
};
