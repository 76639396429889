import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppData, useTenantedClient, useWhoami } from '../stores/org';
import { queryKeys } from './query-cache-keys';
import { Assert } from '@cotera/utilities';
import { useSuspenseQuery } from '@tanstack/react-query';
import { TC } from '@cotera/era';

export const useIdeConfig = () => {
  const orgId = useWhoami((s) => s.org.id);
  const client = useTenantedClient();
  return useSuspenseQuery({
    queryKey: queryKeys.ideConfig({ orgId }),
    queryFn: async () => Assert.assertOk(await client.config.config({})),
  });
};

export const useCreateEntity = () => {
  const client = useTenantedClient();
  const queryClient = useQueryClient();
  const orgId = useWhoami((s) => s.org.id);

  return useMutation({
    mutationFn: client.config.createEntityDefinition,
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.ideConfig({ orgId }),
      }),
  });
};

export const useEntity = ({ entityName }: { entityName: string }) => {
  const {
    data: { entities },
  } = useIdeConfig();
  const definitions = useAppData((s) => s.skeleton.definitions);
  const matchedEntity = entities.find((x) => x.name === entityName)!;

  Assert.assert(matchedEntity !== undefined, `Entity ${entityName} not found`);

  return {
    ...matchedEntity,
    definitions: Object.fromEntries(
      Object.entries(definitions).filter(([_, def]) =>
        Object.values(def.attributes).some((attr) =>
          TC.implementsTy({ subject: attr, req: matchedEntity.idType })
        )
      )
    ),
  };
};
