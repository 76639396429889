import {
  DisplayError,
  Loading,
  NotFound,
  ResultBoundary,
} from '@cotera/client/app/components';
import { Layout } from '@cotera/client/app/layout';
import React, { Suspense } from 'react';
import _, { mapValues } from 'lodash';
import { ExploreProvider, useExplore } from './store';
import { Continuous } from './continuous';
import { Categorical } from './categorical';
import { Assert } from '@cotera/utilities';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs } from '@cotera/client/app/components/headless/tabs';
import { Center } from '@cotera/client/app/components/ui';
import { Relation } from '@cotera/era';
import { DataGridForRelation } from '@cotera/client/app/components/data-vis';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ErrorBoundary } from 'react-error-boundary';
import { useDefinition } from '../../etc/data/manifest';
import {
  FilterBuilderForRelation,
  useDetectedQuickFilters,
} from '@cotera/client/app/components/app/filter-builder';
import { useFirstValue } from '../../hooks/use-first-value';

export const ExploreDataSet = () => {
  return (
    <Layout>
      <ErrorBoundary
        fallbackRender={({ error }) => <DisplayError error={error} />}
      >
        <Suspense
          fallback={
            <Center>
              <Loading.Dots />
            </Center>
          }
        >
          <ExploreDataSetView />
        </Suspense>
      </ErrorBoundary>
    </Layout>
  );
};

const ExploreDataSetView = () => {
  const { id } = useParams();
  Assert.assert(id !== undefined);
  const { data: def } = useDefinition({ id });

  return (
    <ResultBoundary
      result={def}
      fallback={() => <NotFound resource={`Definition "${id}"`} />}
    >
      {(def) => <WithDef id={id} def={Relation.wrap(def)} />}
    </ResultBoundary>
  );
};

const WithDef: React.FC<{ id: string; def: Relation }> = ({ id, def }) => {
  const rel = Relation.wrap(def);
  const sample = useDuckDBQuery({ rel });

  return (
    <ExploreProvider
      definitionId={id}
      sample={sample.data}
      definition={def}
      fromArtifactId={sample.data.fromArtifactId ?? null}
    >
      <DefinitionView />
    </ExploreProvider>
  );
};

const DefinitionView: React.FC<{}> = () => {
  const rel = useExplore((s) => s.rel);
  const initalRel = useFirstValue(rel);
  const setRel = useExplore((s) => s.actions.setRel);
  const detectedFilters = useDetectedQuickFilters(initalRel.attributes);

  return (
    <>
      <div className="relative w-full flex flex-col h-full">
        <div className="mb-2 w-full">
          <FilterBuilderForRelation
            rel={initalRel}
            onRun={setRel}
            filterGroup={{
              connective: 'and',
              items: detectedFilters,
            }}
          />
        </div>
        <Tabs.Container default="table" mode="link">
          <div className="relative flex-1 border border-divider rounded bg-white overflow-auto flex flex-col">
            <div className="top-0 z-[2] w-full flex flex-row bg-white items-center justify-between border-b border-divider">
              <Tabs.Panel className="justify-between w-fit" border={false}>
                <Tabs.LinkTab id="table" text="Table" />
                <Tabs.LinkTab id="continuous" text="Continuous" />
                <Tabs.LinkTab id="categorical" text="Categorical" />
              </Tabs.Panel>
            </div>
            <Tabs.Content id="table">
              <SampleTable />
            </Tabs.Content>
            <Tabs.Content id="continuous">
              <Continuous />
            </Tabs.Content>
            <Tabs.Content id="categorical">
              <Categorical />
            </Tabs.Content>
          </div>
        </Tabs.Container>
      </div>
    </>
  );
};

const SampleTable: React.FC<{}> = () => {
  const definitionId = useExplore((state) => state.definitionId);
  const definition = useExplore((s) => s.definition);
  const rel = useExplore((s) => s.rel);
  const attributes = useExplore((state) => state.attributes);
  const fromArtifactId = useExplore((s) => s.fromArtifactId);
  const visibilityState = mapValues(attributes, (x) => !x.hidden);
  const navigate = useNavigate();
  Assert.assert(definition !== undefined);

  return (
    <DataGridForRelation
      fromArtifactId={fromArtifactId}
      name={definitionId}
      rel={rel}
      columnVisibility={visibilityState}
      onColumnVisibilityChange={() => {}}
      menuItems={[
        {
          name: 'Graph',
          onClick: async () => {
            navigate(`/explore/data/${definitionId}/graph`);
          },
          icon: <MagnifyingGlassIcon className="w-5 h-5" />,
        },
      ]}
    />
  );
};
