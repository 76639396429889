import { z } from 'zod';

export const defaultErrors = (errorTypes) =>
  z.object({
    errorType: z.enum(errorTypes),
    message: z.string().optional(),
  });

export const NotFoundErrorSchema = z.object({
  errorType: z.literal('NotFound'),
  message: z.string().optional(),
});

export const ApiErrorSchema = z.object({
  errorType: z.enum([
    'NotImplemented',
    'Forbidden',
    'ServerError',
    'Unauthorized',
    'InvalidInput',
  ]),
  message: z.string().optional(),
});
