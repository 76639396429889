import React, { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Layout } from '../../layout';
import { useAppData } from '../../stores/org';
import mapValues from 'lodash/mapValues';
import uniqBy from 'lodash/uniqBy';
import { Assert } from '@cotera/utilities';
import { useCallback } from 'react';
import dagre from 'dagre';
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  BackgroundVariant,
  ConnectionLineType,
} from 'reactflow';

import 'reactflow/dist/style.css';
import { AstGraph, Node } from './ast-graph';
import { Handle, Position } from 'reactflow';
import { classNames } from '../../components/utils';
import { Text, Divider } from '@cotera/client/app/components/ui';
import { H4 } from '@cotera/client/app/components/ui/typography';
import { NotFound } from '@cotera/client/app/components/app';

export const GraphPage: React.FC = () => {
  const { id: name } = useParams<{ id: string }>();
  Assert.assert(name !== undefined);
  const manifest = useAppData((s) => s.skeleton.definitions);
  const attributes = manifest[name]?.attributes;

  if (!attributes) {
    return (
      <Layout>
        <NotFound resource={`Definition "${name}"`} />
      </Layout>
    );
  }

  return <GraphView key={name} definitionId={name} />;
};

const nodeWidth = 220;
const nodeHeight = 56;

const getLayoutedElements = (
  dagreGraph: dagre.graphlib.Graph<{}>,
  nodes: any,
  edges: any,
  direction = 'TB'
) => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node: any) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

const GraphView: React.FC<{ definitionId: string }> = ({
  definitionId: name,
}) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  const manifest = useAppData((s) => s.skeleton.definitions);
  const graph = new AstGraph(mapValues(manifest, (def) => def));

  const nodeTypes = useMemo(() => ({ custom: CustomNode }), []);

  const root = graph.node({
    name: name,
    rel: manifest[name]!,
  });

  const edgeType = 'smoothstep';

  const initialEdges: {
    id: string;
    source: string;
    target: string;
    type: string;
    node: Node;
  }[] = [];

  const makeEdges = (nodes: Node[], parent: Node) => {
    const siblings = nodes.map((x) => x.name);

    nodes.forEach((x) => {
      initialEdges.push({
        source: parent.name,
        target: x.name,
        id: [parent.name, x.name].join('-'),
        type: edgeType,
        node: x,
      });

      makeEdges(
        x.nodes().filter((x) => !siblings.includes(x.name)),
        x
      );
    });
  };

  makeEdges(root.nodes(), root);

  const initialNodes = uniqBy(
    [root, ...initialEdges.map((x) => x.node)],
    'name'
  ).map((x) => ({
    id: x.name,
    type: 'custom',
    data: { node: x },
  }));

  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    dagreGraph,
    initialNodes,
    uniqBy(initialEdges, 'id'),
    'LR'
  );
  const [nodes, _setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const onConnect = useCallback(
    (params: any) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    [setEdges]
  );

  return (
    <Layout>
      <div style={{ width: '100%', height: 'calc(100% + 15px)' }}>
        <ReactFlow
          key={name}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          connectionLineType={ConnectionLineType.SmoothStep}
          fitView
          nodeTypes={nodeTypes}
        >
          <Controls />
          <MiniMap />
          <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
        </ReactFlow>
      </div>
    </Layout>
  );
};

function CustomNode({ data }: { data: { node: Node } }) {
  return (
    <>
      <Handle type="target" position={Position.Left} />
      <div
        className={classNames(
          'border-2 rounded p-5 flex flex-col bg-white',
          data.node.parents.length === 0
            ? 'border-primary-background'
            : 'border-divider '
        )}
      >
        <Link to={`/explore/data/${data.node.name}/graph`}>
          <Text.Caption className="mb-2 hover:text-primary-text">
            {data.node.name}
          </Text.Caption>
        </Link>
        <Divider className="mb-1" />
        <table>
          <tbody>
            {data.node.identifiers().map((x) => (
              <tr key={x.column}>
                <td className="text-left pr-2">
                  <H4>{x.column}:</H4>
                </td>
                <td className="pr-2">
                  <Text.Caption>{x.attr.name}</Text.Caption>
                </td>
                <td>
                  <Text.Caption>{x.attr.t}</Text.Caption>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Handle type="source" position={Position.Right} id="a" />
    </>
  );
}
