import { ArtifactRequest } from '@cotera/api';
import { Loading, NotFound } from '@cotera/client/app/components';
import { useUseableArtifactCache } from '@cotera/client/app/etc/data/artifacts';
import {
  useIrForSqlHash,
  useSqlInWarehouseDialect,
  useWarehouseConnection,
} from '@cotera/client/app/hooks';
import { ERA_CACHE_POLICY } from '@cotera/client/app/hooks/artifacts';
import { useTenantedClient } from '@cotera/client/app/stores/org';
import { AST, Relation, From, EraCache, SQL, Ty } from '@cotera/era';
import { Assert } from '@cotera/utilities';
import { useSuspenseQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React from 'react';
import { Link } from 'react-router-dom';
import { useEraScopesAwareRelIR } from './macro-expansion/scopes';

const CODE_STYLE_CLASS =
  'max-w-7xl p-2 rounded bg-gray-100 border border-divider font-mono overflow-auto my-2';

export const RenderRelationInfo: React.FC<{
  rel: AST.Rel;
}> = (props) => {
  const ir = useEraScopesAwareRelIR(props.rel);
  const rel = Relation.wrap(ir);

  const source = SQL.originForIR(ir).match(
    (s) => s ?? 'functional',
    ({ msg }) => `Error: ${msg}`
  );

  return (
    <div className="border-solid border-2 m-4 p-4 space-y-4">
      <h1 className="text-2xl">Relation Info</h1>

      <RelTyDisplay attributes={rel.attributes} />

      <div className="border-solid border-2 p-4">
        <details>
          <summary>Cache Strategy</summary>
          <CacheStrategy rel={rel} />
        </details>
      </div>

      <div className="border-solid border-2 p-4">
        <details>
          <summary>Assumptions</summary>
          <Assumptions rel={rel} />
        </details>
      </div>

      <div className="border-solid border-2 p-4">
        <details>
          <summary>Advanced</summary>

          <pre className={CODE_STYLE_CLASS}>
            {JSON.stringify({ source, sqlHash: rel.sqlHash() }, null, 2)}
          </pre>
        </details>
      </div>
    </div>
  );
};

const Assumptions: React.FC<{ rel: Relation }> = ({ rel }) => {
  const assumptions = rel.assumptions();

  return (
    <div className="p-4 space-y-4">
      {assumptions.map((assumption) => (
        <details>
          <summary>{`"${assumption.schema}"."${assumption.name}"`}</summary>
          <RelTyDisplay attributes={assumption.attributes} />
        </details>
      ))}
    </div>
  );
};

const CacheStrategy: React.FC<{ rel: Relation }> = ({ rel }) => {
  const cache = useUseableArtifactCache({});

  const strategy = EraCache.cacheStrategy({
    ir: rel.ir(),
    existingCache: cache.data,
    policy: ERA_CACHE_POLICY,
  });

  let child: React.ReactNode;

  switch (strategy.type) {
    case 'functional':
      child = <FunctionalCacheStrategy strategy={strategy} />;
      break;
    case 'existing':
      child = <ExistingCacheStrategy strategy={strategy} cache={cache.data} />;
      break;
    case 'create':
      child = <CreateCacheStrategy strategy={strategy} />;
      break;
    default:
      return Assert.unreachable(strategy);
  }

  return <div className="p-2">{child}</div>;
};

const FunctionalCacheStrategy: React.FC<{
  strategy: EraCache.FunctionalCacheStrategy;
}> = ({ strategy }) => {
  const { sql, params } = Relation.fromAst(strategy.relation).duckdbwasmSql();

  return (
    <div>
      <h1>
        <span className="font-bold">Functional:</span> (This will always run in
        the browser)
      </h1>
      <details>
        <summary>SQL:</summary>
        <pre className={CODE_STYLE_CLASS}>{sql}</pre>
        <pre className={CODE_STYLE_CLASS}>
          {JSON.stringify(params, null, 2)}
        </pre>
      </details>
    </div>
  );
};

const CreateCacheStrategy: React.FC<{
  strategy: EraCache.CreateCacheStrategy;
}> = ({ strategy }) => {
  const warehouseSql = useSqlInWarehouseDialect({
    rel: Relation.fromAst(strategy.relation),
  });

  const inBrowserSql = Relation.fromAst(
    strategy.serve(
      From({
        uri: `*some-future-value*.parquet`,
        attributes: Relation.fromAst(strategy.relation).attributes,
      }).ir()
    )
  ).duckdbwasmSql();

  return (
    <div className="space-y-2">
      <h1>
        <span className="font-bold">Create:</span> (This will create a new
        artifact but may run more operations in the browser)
      </h1>
      <span className="font-bold">Complete</span>:{' '}
      {strategy.complete
        ? 'This will be a "Complete" artifact meaning other queries are guaranteed be able to use the artifact for downstream queries'
        : 'This *may* be a "Complete artifact meaning we wont know if it is complete until its run"'}
      <details>
        <summary>In Warehouse SQL</summary>
        <pre className={CODE_STYLE_CLASS}>{warehouseSql.data.sql}</pre>
        <pre className={CODE_STYLE_CLASS}>
          {JSON.stringify(warehouseSql.data.params, null, 2)}
        </pre>
      </details>
      <details>
        <summary>In Browser SQL</summary>
        <pre className={CODE_STYLE_CLASS}>{inBrowserSql.sql}</pre>
        <pre className={CODE_STYLE_CLASS}>
          {JSON.stringify(inBrowserSql.params, null, 2)}
        </pre>
      </details>
    </div>
  );
};

const ExistingCacheStrategy: React.FC<{
  cache: EraCache.ExistingCache<{ artifactId: string }, { message: string }>;
  strategy: EraCache.ExistingCacheStrategy;
}> = ({ cache, strategy }) => {
  const cacheHit = cache[strategy.sqlHash];
  Assert.assert(cacheHit !== undefined, 'We would pick a different strategy');
  const client = useTenantedClient();
  const req = useSuspenseQuery({
    queryFn: () =>
      client.artifacts.getRequestById({ id: cacheHit.meta.artifactId }),
    queryKey: ['artifacts.getRequestById', { id: cacheHit.meta.artifactId }],
  });

  if (!req.data.isOk()) {
    return <NotFound />;
  }

  const inBrowserSql = Relation.fromAst(
    strategy.serve(
      From({
        uri: `${cacheHit.meta.artifactId}.parquet`,
        attributes: strategy.signature,
      }).ir()
    )
  ).duckdbwasmSql();

  return (
    <div className="space-y-2">
      <h1>
        <span className="font-bold">Existing:</span>(this will use an existing
        artifact but may do more operations in the browser)
      </h1>
      <p>
        <span className="font-bold">Using Artifact:</span>{' '}
        <Link
          to={`/ide/artifacts/${cacheHit.meta.artifactId}`}
          className="text-indigo-700 hover:text-indigo-900"
        >
          ({cacheHit.meta.artifactId})
        </Link>
      </p>

      <details>
        <summary>Artifact Details</summary>
        <pre className={CODE_STYLE_CLASS}>
          {JSON.stringify(displayReq(req.data.value), null, 2)}
        </pre>
      </details>

      <details>
        <summary>In Warehouse SQL</summary>
        <RenderSqlForSqlHash sqlHash={strategy.sqlHash} />
      </details>

      <details>
        <summary>In Browser SQL</summary>
        <pre className={CODE_STYLE_CLASS}>{inBrowserSql.sql}</pre>
        <pre className={CODE_STYLE_CLASS}>
          {JSON.stringify(inBrowserSql.params, null, 2)}
        </pre>
      </details>
    </div>
  );
};

const RenderSqlForSqlHash: React.FC<{ sqlHash: string }> = ({ sqlHash }) => {
  const ir = useIrForSqlHash({ sqlHash });
  const wc = useWarehouseConnection();

  if (ir.data === undefined || wc.data === undefined) {
    return <Loading.Shimmer />;
  }
  return ir.data !== null ? (
    <pre className={CODE_STYLE_CLASS}>
      {
        Relation.fromAst(ir.data).sqlForDialect(
          wc.data ? wc.data.type : 'DUCKDBWASM',
          {
            replaceSchemas: {
              '@@write-schema': wc.data?.writeSchema ?? '@@write-schema',
            },
          }
        ).sql
      }
    </pre>
  ) : (
    <NotFound />
  );
};

const RelTyDisplay: React.FC<{
  attributes: { [name: string]: Ty.ExtendedAttributeType };
}> = ({ attributes }) => {
  const str = `(\n  ${Object.entries(attributes)
    .map(([name, ty]) => `"${name}" ${Ty.displayTy(ty)}`)
    .join(',\n  ')}\n)`;
  return (
    <pre className="max-w-7xl p-2 rounded bg-gray-100 border border-divider font-mono overflow-auto">
      {str}
    </pre>
  );
};

const minutesAgo = (timestamp: Date): number =>
  Math.round(DateTime.now().diff(DateTime.fromJSDate(timestamp)).as('minutes'));

const displayReq = (req: ArtifactRequest) => {
  const { id, createdAt, fullfillment, failure, requestedByUserId } = req;

  const status =
    fullfillment !== null
      ? {
          t: 'ready',
          fullfillmentCreatedAt: fullfillment.createdAt,
          fullfilledMinutesAgo: minutesAgo(fullfillment.createdAt),
        }
      : failure
      ? {
          t: 'failure',
          reason: failure.errorMessage,
          failedAt: failure.createdAt,
          failedMinutesAgo: minutesAgo(failure.createdAt),
        }
      : {
          t: 'pending',
        };
  return {
    id,
    createdAt,
    status,
    requestedByUserId,
    createdMinutesAgo: minutesAgo(createdAt),
  };
};
