import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { AST, Relation } from '@cotera/era';
import { Suspense } from 'react';
import { z } from 'zod';
import { Loading, Text, Title } from '@cotera/client/app/components/ui';
import { DateTime } from 'luxon';
import {
  Empty,
  Pagination,
  usePagination,
} from '@cotera/client/app/components';
import { useEraScopesAwareRelIR } from '@cotera/client/app/pages/apps/compiler/macro-expansion/scopes';

export const DocumentListForRelation: React.FC<{
  rel: Relation;
}> = ({ rel }) => {
  const chartRelIR = useEraScopesAwareRelIR(rel);

  return (
    <div className="flex flex-col">
      <Title type="section" title="" />
      <Suspense fallback={<LoadingView />}>
        <Dataset chartRelIR={chartRelIR} />
      </Suspense>
    </div>
  );
};

const dataSchema = z.object({
  value: z.string(),
  id: z.string(),
  timestamp: z.coerce.date(),
});

const Dataset: React.FC<{ chartRelIR: AST.RelIR }> = ({ chartRelIR }) => {
  const rel = Relation.wrap(chartRelIR);
  const { data } = useDuckDBQuery({ rel });
  const results = data.data.toArrayOf(dataSchema);

  if (results.length === 0) {
    return (
      <div className="py-8 flex items-center justify-center w-full">
        <Empty
          type="list"
          title="No Results"
          caption="Try adjusting the search criteria"
        />
      </div>
    );
  }
  return (
    <Pagination.Container
      key={rel.sqlHash()}
      totalRows={results.length}
      id={rel.sqlHash()}
      rowsPerPage={20}
    >
      <Results results={results} />
      <Pagination.View>
        <Pagination.Total />
        <Pagination.PageControls />
      </Pagination.View>
    </Pagination.Container>
  );
};

const Results: React.FC<{ results: ItemT[] }> = ({ results }) => {
  const page = usePagination((s) => s.page);
  const rowsPerPage = usePagination((s) => s.rowsPerPage);

  return (
    <ul className="pb-6">
      {results
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
        .map((result, i) => (
          <Item pos={i} item={result} />
        ))}
    </ul>
  );
};

const LoadingItem: React.FC = () => {
  return (
    <li className="flex items-center justify-between px-3 py-3">
      <Loading.Shimmer
        as={'span'}
        className="flex items-center justify-between w-[40px] h-8 mr-4"
      ></Loading.Shimmer>
      <div className="flex-auto">
        <div className="flex items-baseline justify-between gap-x-4 mb-2">
          <Loading.Shimmer as={'time'} className="w-24 h-4" />
          <Loading.Shimmer as={'div'} className="w-[150px] h-4" />
        </div>
        <Loading.Shimmer as={'div'} className="w-[80%] h-4" />
        <Loading.Shimmer as={'div'} className="w-[75%] h-4" />
      </div>
    </li>
  );
};

const LoadingView: React.FC = () => {
  return (
    <ul className="w-full">
      <LoadingItem />
      <LoadingItem />
      <LoadingItem />
    </ul>
  );
};

type ItemT = z.infer<typeof dataSchema>;

export const Item: React.FC<{ pos: number; item: ItemT }> = ({ item }) => {
  const ts = new Date(item.timestamp);
  return (
    <li
      key={item.id}
      className="relative flex items-center justify-between px-3 py-3 [&:not(:last-child)]:border-b border-divider hover:bg-zinc-50 "
    >
      <div className="flex-auto">
        <div className="flex items-baseline justify-between gap-x-4">
          <Title
            type="label"
            title={DateTime.fromJSDate(ts).toUTC().toFormat('yyyy/MM/dd HH:mm')}
          />
          <Text.Caption>{item.id}</Text.Caption>
        </div>
        <Text.P>{item.value}</Text.P>
      </div>
    </li>
  );
};
