import _ from 'lodash';
import { AST } from '../../ast';
import { Ty } from '../../ty';
import { TyStackTrace, TC } from '../../type-checker';
import { ExprVar, Expression } from '../expression';
import { RelVar, Relation } from '../relation';
import { mkMuMacro } from '../macro/mu-macro';
import { Constant } from '../utilities';
import { Markup, MarkupShorthand } from './section';
import { Stat } from './chart';
import { Page as UiPage } from './fluent-page';
export * from './detail-page';

export const Page = UiPage;

export type { MarkupShorthand } from './section';

export const Header = (props: {
  readonly title: string | Expression;
  readonly caption?: string | Expression;
}): Markup => {
  return Markup.fromAst(
    {
      t: 'header',
      title: Expression.wrap(props.title).ast,
      caption: Expression.wrap(
        props.caption ?? Constant(null, { ty: 'string' })
      ).ast,
    },
    { jsStackPointer: Header }
  );
};

export const Img = ({
  uri,
  align = 'left',
  size = 'md',
  description,
}: {
  uri: string;
  align?: 'left' | 'right' | 'center';
  size?: 'lg' | 'md' | 'sm';
  description: string;
}): Markup =>
  Markup.fromAst(
    { t: 'image', uri, align, size, description },
    { jsStackPointer: Img }
  );

export const App = (props: {
  title: string;
  icon: AST.MarkupIcon;
  pages: Record<string, AST._Page | MarkupShorthand>;
  menu?: AST._MenuItem[];
  context?: string | null;
}): AST._App => {
  const pages = _.mapValues(props.pages, (p): AST._Page => {
    if (p === null || typeof p === 'string') {
      return UiPage([], () => p);
    }
    if ('t' in p && p.t === 'page') {
      return p;
    }
    return UiPage([], () => p);
  });

  const checked = TC.MU.checkApp({
    t: 'app',
    pages,
    title: props.title,
    icon: props.icon,
    menu: props.menu ?? [],
    context: props.context ?? null,
  });

  if (checked instanceof TyStackTrace) {
    throw checked.toError({ jsStackPointer: App });
  }

  return checked;
};

export const StartCase = (x: string | Expression): Markup =>
  Markup.fromAst(
    {
      t: 'text',
      text: Expression.wrap(x).ast,
      modifier: 'start-case',
    },
    { jsStackPointer: StartCase }
  );

export const Block = (
  sections: MarkupShorthand[],
  opts?: {
    title?: string | Expression;
    border?: boolean;
    jsStackPointer?: Function;
  }
): Markup => {
  const { border, title, jsStackPointer } = opts ?? {};
  return Markup.fromAst(
    {
      t: 'block',
      sections: sections.map(
        (shorthand) => Markup.fromShorthand(shorthand).ast
      ),
      title: Expression.wrap(title ?? Constant(null, { ty: 'string' })).ast,
      border: border ?? true,
    },
    { jsStackPointer: jsStackPointer ?? Block }
  );
};

export const Grid = (
  {
    sections,
    columns,
  }: {
    sections: MarkupShorthand[];
    columns: number;
  },
  opts?: { jsStackPointer?: Function }
): Markup =>
  Markup.fromAst(
    {
      t: 'sections',
      sections: sections.map((s) => Markup.fromShorthand(s).ast),
      config: { t: 'grid', columns },
    },
    { jsStackPointer: opts?.jsStackPointer ?? Grid }
  );

export const TwoThirds = (
  section: MarkupShorthand,
  opts?: { jsStackPointer?: Function }
): Markup =>
  Markup.fromAst(
    {
      t: 'width',
      section: Markup.fromShorthand(section).ast,
      size: '2/3',
    },
    { jsStackPointer: opts?.jsStackPointer ?? TwoThirds }
  );

export const Third = (
  section: AST.Mu | MarkupShorthand,
  opts?: { jsStackPointer?: Function }
): Markup => {
  return Markup.fromAst(
    {
      t: 'width',
      section: Markup.fromShorthand(section).ast,
      size: '1/3',
    },
    {
      jsStackPointer: opts?.jsStackPointer ?? Third,
    }
  );
};

export const Half = (
  section: MarkupShorthand,
  opts?: { jsStackPointer?: Function }
): Markup => {
  return Markup.fromAst(
    {
      t: 'width',
      section: Markup.fromShorthand(section).ast,
      size: '1/2',
    },
    {
      jsStackPointer: opts?.jsStackPointer ?? Half,
    }
  );
};

export const Divider = (
  text?: string | Expression,
  opts?: { jsStackPointer?: Function }
): Markup => {
  return Markup.fromAst(
    {
      t: 'divider',
      text: Expression.wrap(text ?? Constant(null, { ty: 'string' })).ast,
    },
    { jsStackPointer: opts?.jsStackPointer ?? Divider }
  );
};

export const Row = (
  sections: MarkupShorthand[],
  opts?: { jsStackPointer?: Function }
): Markup => {
  return Markup.fromAst(
    {
      t: 'sections',
      sections: sections.map((sh) => Markup.fromShorthand(sh).ast),
      config: { t: 'row' },
    },
    { jsStackPointer: opts?.jsStackPointer ?? Row }
  );
};

export const TabsV2 = (
  variable: ExprVar,
  inner: MarkupShorthand[]
): Markup[] => {
  return [variable.TabSelector(), Block(inner)];
};

export const Nav = (variable: ExprVar, inner: MarkupShorthand[]): Markup[] => {
  return [
    variable.Nav(),
    Block(inner, {
      border: false,
    }),
  ];
};

export const Tabs = (params: {
  tabs: { title: string; section: MarkupShorthand }[];
  style: 'tabs' | 'select';
}): Markup => {
  const tabs = params.tabs.map((tab) => ({
    ...tab,
    section: Markup.fromShorthand(tab.section).ast,
  }));

  return Markup.fromAst(
    {
      t: 'tabs',
      tabs,
      style: params.style,
    },
    { jsStackPointer: Tabs }
  );
};

export const State = <
  T extends {
    readonly [name: string]: Relation | Expression | NonNullable<Ty.Primitive>;
  }
>(
  vars: T,
  body: (args: {
    [key in keyof T]: T[key] extends Relation ? RelVar : ExprVar;
  }) => MarkupShorthand,
  opts?: {
    path?: (args: {
      [key in keyof T]: { name: string };
    }) => AST._UiState['url']['path'];
    params?: Partial<{ [name in keyof T]: boolean }>;
  }
): Markup => {
  const defaults: Record<string, Expression | Relation> = _.chain(vars)
    .entries()
    .map(([name, val]): [string, Expression | Relation] => {
      if (val instanceof Relation) {
        return [name, val];
      }

      // infer strings to type string and not single item enums
      if (typeof val === 'string') {
        return [name, Constant(val, { ty: Ty.nn('string') })];
      }

      return [name, Expression.wrap(val)];
    })
    .compact()
    .fromPairs()
    .value();

  const m = mkMuMacro(defaults, (args) => body(args as any));

  const path: AST._UiState['url']['path'] = opts?.path
    ? opts.path(_.mapValues(vars, (_, name) => ({ name })))
    : null;

  const params = _.compact(
    Object.keys(vars).map((name) => {
      const inPath = (path ?? []).some(
        (section) => typeof section !== 'string' && section.name === name
      );

      if (inPath) {
        return null;
      }

      return opts?.params?.[name as keyof T] ?? true ? name : null;
    })
  );

  const exprs = _.chain(defaults)
    .entries()
    .map(([name, def]): [string, AST.Expr<'full'>] | null =>
      def instanceof Expression ? [name, def.ast] : null
    )
    .compact()
    .fromPairs()
    .value();

  const rels = _.chain(defaults)
    .entries()
    .map(([name, def]): [string, AST.Rel<'full'>] | null =>
      def instanceof Relation ? [name, def.ast] : null
    )
    .compact()
    .fromPairs()
    .value();

  return Markup.fromAst({
    t: 'ui-state',
    defaults: { exprs, rels },
    url: { params, path },
    body: { scope: m.scope, macro: m.body.ast },
  });
};

export const Stats = (
  stats: Stat[],
  opts?: {
    caption?: string | Expression;
  }
): Markup => {
  const caption = Expression.wrap(
    opts?.caption ?? Constant(null, { ty: 'string' })
  ).ast;

  const sts: AST._Stats = {
    t: 'stats',
    caption,
    stats: stats.map(({ stat }) => ({
      rel: stat.rel,
      config: stat.config,
    })),
  };

  return Markup.fromAst(sts, { jsStackPointer: Stats });
};
