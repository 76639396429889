import {
  Popover as BasePopover,
  PopoverButton,
  PopoverPanel,
} from '@headlessui/react';
import { Props } from '@headlessui/react/dist/types';
import {
  CSSProperties,
  ElementType,
  ForwardedRef,
  forwardRef,
  useRef,
} from 'react';
import { AnchorProps } from '@headlessui/react/dist/internal/floating';
import {
  ChildrenProps,
  Styles,
  classNames,
} from '@cotera/client/app/components/utils';
import { Theme } from '../../types/utils';

type PopoverProps<TTag extends ElementType> = Props<TTag> & {
  panel: {
    view: ChildrenProps['children'];
    style?: CSSProperties;
  };
  anchor: AnchorProps;
  style?: CSSProperties;
  priority?: keyof (typeof Styles)['priority'];
  theme?: Theme;
};

const CSS: Record<Theme, string> = {
  secondary: 'bg-emerald-50 text-emerald-500 border-emerald-400',
  primary: 'bg-indigo-50 border-indigo-400',
  regular: 'bg-white border-zinc-200 ',
  muted: 'bg-white border-zinc-200 ',
  error: 'bg-rose-50 border-rose-400',
  warning: 'bg-orange-50 border-orange-400',
};

function InternalPopover<TTag extends ElementType>(
  { panel, anchor, ...props }: PopoverProps<TTag>,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const popoverRef = useRef<HTMLElement>(null);

  return (
    <BasePopover className="relative">
      <PopoverButton ref={ref} {...props} />
      <PopoverPanel
        ref={popoverRef}
        style={panel.style}
        anchor={anchor}
        transition
        className={classNames(
          'rounded border shadow-md mt-1 focus:outline-none transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0',
          Styles.priority[props.priority ?? 'medium'],
          CSS[props.theme ?? 'regular']
        )}
      >
        {panel.view}
      </PopoverPanel>
    </BasePopover>
  );
}

//this is an annoying hack to pass generics along to forwardRef
export const makePopover = <TTag extends ElementType>(_t: TTag) =>
  forwardRef(
    (props: PopoverProps<TTag>, ref: ForwardedRef<HTMLButtonElement>) =>
      InternalPopover(props, ref)
  );
