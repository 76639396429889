import { parseZodToResult } from '@cotera/utilities';
import { ApiClient, Method } from '@cotera/contracts';
import { err, ok, Result } from 'neverthrow';
import { z } from 'zod';
import {
  TenantedContract,
  UntenantedContract,
  ManifestCompilerError,
  DevServerContract,
} from './http-api';
import { CoteraContract } from './cotera-contract';

export type TenantedClient = ApiClient<
  typeof TenantedContract,
  AdditionalErrors,
  { abort?: AbortController }
>;
export type UntenantedClient = ApiClient<
  typeof UntenantedContract,
  AdditionalErrors,
  { abort?: AbortController }
>;

export type CoteraClient = ApiClient<
  typeof CoteraContract,
  AdditionalErrors,
  { abort?: AbortController }
>;

export type DevServerError =
  | {
      errorType: 'NoDevServerFound' | 'OrgNotFound';
    }
  | ManifestCompilerError;

export type DevServerClient = ApiClient<
  typeof DevServerContract,
  AdditionalErrors | DevServerError,
  { abort?: AbortController }
>;

const parseErr = (err: any) => ({
  errorType: 'ParseError' as const,
  err,
});

type AdditionalErrors = ReturnType<typeof parseErr>;

export const mkDevServer = (
  handler: (
    route: string[],
    method: Method,
    input: any
  ) => Promise<Result<{ status: number; data: any }, DevServerError>>
): DevServerClient =>
  DevServerContract.makeApiClient(
    (
        route,
        method,
        { output: OutputSchema, params: ParamsSchema, errors: ErrorsSchema }
      ) =>
      async (
        input: z.infer<typeof ParamsSchema>
      ): Promise<
        Result<z.infer<typeof OutputSchema>, z.infer<typeof ErrorsSchema>>
      > => {
        const responseRes = await handler(route, method, input);
        if (responseRes.isErr()) {
          return err(responseRes.error);
        } else {
          const response = responseRes.value;
          if (response.status >= 200 && response.status < 300) {
            return parseZodToResult(OutputSchema, response.data).mapErr(
              parseErr
            );
          } else {
            return parseZodToResult(ErrorsSchema, response.data)
              .mapErr((e) => mapError(response.status, e))
              .andThen(err);
          }
        }
      }
  );

export const mkCoteraClient = (
  handler: (
    route: string[],
    method: Method,
    input: any,
    opts?: {
      abort?: AbortController;
    }
  ) => Promise<{ status: number; data: any }>,
  clientOpts?: { skipParse?: boolean }
): CoteraClient =>
  CoteraContract.makeApiClient(
    (
        route,
        method,
        { output: OutputSchema, params: ParamsSchema, errors: ErrorsSchema }
      ) =>
      async (
        input: z.infer<typeof ParamsSchema>,
        opts?: { abort?: AbortController }
      ): Promise<
        Result<z.infer<typeof OutputSchema>, z.infer<typeof ErrorsSchema>>
      > => {
        const response = await handler(route, method, input, opts);
        if (response.status >= 200 && response.status < 300) {
          if (clientOpts?.skipParse) {
            return ok(response.data);
          } else {
            return parseZodToResult(OutputSchema, response.data).mapErr(
              parseErr
            );
          }
        } else {
          return parseZodToResult(ErrorsSchema, response.data)
            .mapErr((e) => mapError(response.status, e))
            .andThen(err);
        }
      }
  );

const mapError = (status: number, error: any) => {
  switch (status) {
    case 401:
      return {
        err: error,
        errorType: 'Unauthorized' as const,
      };
    case 403:
      return {
        err: error,
        errorType: 'Forbidden' as const,
      };
    default:
      return parseErr(err);
  }
};
