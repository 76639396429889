import { createContext, JSXElementConstructor } from 'react';
import React from 'react';
import {
  StateGetter,
  StateSetter,
  makeStoreProvider,
  makeStoreContextHook,
} from '@cotera/client/app/etc';
import { Children } from '../utils';
import { ReactConstructor } from '../../types/utils';
import { ChildrenProps, classNames } from '@cotera/client/app/components/utils';
import { useKeyPress } from '../../../hooks/use-key-press';
import { useForwardedRef } from '@cotera/client/app/hooks/use-forwarded-ref';

export type ListState = {
  selectedIndex: number;
  listItems: React.ReactElement[];
  hasFocus: boolean;
};

const ListContext = createContext<ListState>(undefined as any);

const actions = (
  set: StateSetter<ListState>,
  get: StateGetter<ListState, any>
) => ({
  selectNext: () => {
    if (!get().hasFocus) {
      return;
    }
    const selectedIndex = Math.min(
      get().selectedIndex + 1,
      get().listItems.length - 1
    );
    set(() => ({ selectedIndex }));
  },
  selectPrevious: () => {
    if (!get().hasFocus) {
      return;
    }
    set(() => ({ selectedIndex: Math.max(get().selectedIndex - 1, -1) }));
  },
  select(index: number) {
    if (!get().hasFocus) {
      return;
    }
    set(() => ({ selectedIndex: index }));
  },
});

export const ListProvider = makeStoreProvider<
  ListState,
  ReturnType<typeof actions>
>(ListContext);

export const useList = makeStoreContextHook<
  ListState,
  ReturnType<typeof actions>
>(ListContext);

const Ul: React.FC<
  {
    className?: string;
    childType?: ReactConstructor;
    selectedIndex?: number;
    hasFocus?: boolean;
  } & ChildrenProps
> = ({
  className,
  children,
  childType = Li,
  selectedIndex = -1,
  hasFocus = true,
}) => {
  const listItems = Children.ofType(childType)(children);

  return (
    <ListProvider
      key={listItems.length}
      state={{ selectedIndex, listItems, hasFocus }}
      actions={actions}
    >
      <InternalList
        childType={childType}
        className={className}
        hasFocus={hasFocus}
      >
        {children}
      </InternalList>
    </ListProvider>
  );
};

const InternalList: React.FC<
  {
    className?: string;
    childType: ReactConstructor;
    hasFocus: boolean;
  } & ChildrenProps
> = ({ className, children, childType, hasFocus }) => {
  const selectNext = useList((store) => store.actions.selectNext);
  const selectPrevious = useList((store) => store.actions.selectPrevious);
  const selectedIndex = useList((store) => store.selectedIndex);

  useKeyPress('ArrowUp', (e) => {
    if (hasFocus) {
      e.preventDefault();
      selectPrevious();
    }
  });

  useKeyPress('ArrowDown', (e) => {
    if (hasFocus) {
      e.preventDefault();
      selectNext();
    }
  });

  let index = 0;
  const listChildren = Children.recursiveMap(
    children,
    (child) => {
      if (React.isValidElement(child) && Children.isOfType(childType)(child)) {
        return React.cloneElement<any>(child, {
          'data-focus': index === selectedIndex ? 'true' : undefined,
          index: index++,
        });
      }

      return child;
    },
    (child) => Children.isOfType(Ul)(child)
  );

  return <ul className={classNames(className)}>{listChildren}</ul>;
};

type Slot = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

const Li = React.forwardRef<
  HTMLElement,
  React.ComponentProps<Slot> & { as?: Slot }
>(function Li({ as: As = 'li', index, ...props }, forwardRef) {
  const select = useList((store) => store.actions.select);
  const selected = useList((store) => store.selectedIndex);
  const hasFocus = useList((store) => store.hasFocus);
  const ref = React.useRef<HTMLElement>(null);

  useForwardedRef(forwardRef, ref);

  useKeyPress('Enter', () => {
    if (selected === index && hasFocus && selected !== -1) {
      ref.current?.click();
    }
  });

  return (
    <As
      {...props}
      ref={ref}
      onMouseOver={(e: any) => {
        select(index);
        return props['onMouseOver'] && props['onMouseOver'](e);
      }}
      onMouseOut={(e: any) => {
        select(-1);
        return props['onMouseOut'] && props['onMouseOut'](e);
      }}
    />
  );
});

export const List = {
  Ul,
  Li,
};
