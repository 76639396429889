import * as icons from '@heroicons/react/24/outline';
import * as radixIcons from '@radix-ui/react-icons';

export const ICON_MAPPING = {
  search: icons.MagnifyingGlassIcon,
  close: icons.XMarkIcon,
  add: icons.PlusIcon,
  save: icons.CheckIcon,
  edit: icons.PencilIcon,
  filter: icons.FunnelIcon,
  copy: icons.ClipboardDocumentIcon,
  'bar-chart': icons.ChartBarIcon,
  'pie-chart': icons.ChartPieIcon,
  'chat-bubble': icons.ChatBubbleBottomCenterIcon,
  dollar: icons.CurrencyDollarIcon,
  lightbulb: icons.LightBulbIcon,
  plus: icons.PlusIcon,
  play: icons.PlayIcon,
  'lock-open': icons.LockOpenIcon,
  'lock-closed': icons.LockClosedIcon,
  clock: icons.ClockIcon,
  'academic-cap': icons.AcademicCapIcon,
  'adjustments-horizontal': icons.AdjustmentsHorizontalIcon,
  'adjustments-vertical': icons.AdjustmentsVerticalIcon,
  'archive-box-arrow-down': icons.ArchiveBoxArrowDownIcon,
  'archive-box-x-mark': icons.ArchiveBoxXMarkIcon,
  'archive-box': icons.ArchiveBoxIcon,
  'arrow-down-circle': icons.ArrowDownCircleIcon,
  'arrow-down-left': icons.ArrowDownLeftIcon,
  'arrow-down-on-square-stack': icons.ArrowDownOnSquareStackIcon,
  'arrow-down-on-square': icons.ArrowDownOnSquareIcon,
  'arrow-down': icons.ArrowDownIcon,
  'arrow-left': icons.ArrowLeftIcon,
  'arrow-path': icons.ArrowPathIcon,
  'arrow-right': icons.ArrowRightIcon,
  'arrow-top-right-on-square': icons.ArrowTopRightOnSquareIcon,
  'arrow-trending-down': icons.ArrowTrendingDownIcon,
  'arrow-trending-up': icons.ArrowTrendingUpIcon,
  'arrow-up-circle': icons.ArrowUpCircleIcon,
  'arrow-up-left': icons.ArrowUpLeftIcon,
  'arrow-up-on-square-stack': icons.ArrowUpOnSquareStackIcon,
  'arrow-up-on-square': icons.ArrowUpOnSquareIcon,
  'arrow-up-right': icons.ArrowUpRightIcon,
  'arrow-up-tray': icons.ArrowUpTrayIcon,
  'arrow-up': icons.ArrowUpIcon,
  'arrows-up-down': icons.ArrowsUpDownIcon,
  'at-symbol': icons.AtSymbolIcon,
  backspace: icons.BackspaceIcon,
  backward: icons.BackwardIcon,
  banknotes: icons.BanknotesIcon,
  'bars-2': icons.Bars2Icon,
  'bars-3': icons.Bars3Icon,
  'bars-4': icons.Bars4Icon,
  'bars-arrow-down': icons.BarsArrowDownIcon,
  'bars-arrow-up': icons.BarsArrowUpIcon,
  'battery-0': icons.Battery0Icon,
  'battery-100': icons.Battery100Icon,
  'battery-50': icons.Battery50Icon,
  beaker: icons.BeakerIcon,
  'bell-alert': icons.BellAlertIcon,
  'bell-slash': icons.BellSlashIcon,
  'bell-snooze': icons.BellSnoozeIcon,
  bell: icons.BellIcon,
  'bolt-slash': icons.BoltSlashIcon,
  bolt: icons.BoltIcon,
  'book-open': icons.BookOpenIcon,
  'bookmark-slash': icons.BookmarkSlashIcon,
  'bookmark-square': icons.BookmarkSquareIcon,
  bookmark: icons.BookmarkIcon,
  briefcase: icons.BriefcaseIcon,
  'bug-ant': icons.BugAntIcon,
  'building-library': icons.BuildingLibraryIcon,
  'building-office': icons.BuildingOfficeIcon,
  'building-storefront': icons.BuildingStorefrontIcon,
  cake: icons.CakeIcon,
  calculator: icons.CalculatorIcon,
  'calendar-days': icons.CalendarDaysIcon,
  calendar: icons.CalendarIcon,
  camera: icons.CameraIcon,
  'chart-bar': icons.ChartBarIcon,
  'chart-pie': icons.ChartPieIcon,
  'check-badge': icons.CheckBadgeIcon,
  'check-circle': icons.CheckCircleIcon,
  check: icons.CheckIcon,
  'chevron-double-down': icons.ChevronDoubleDownIcon,
  'chevron-double-left': icons.ChevronDoubleLeftIcon,
  'chevron-double-right': icons.ChevronDoubleRightIcon,
  'chevron-down': icons.ChevronDownIcon,
  'chevron-left': icons.ChevronLeftIcon,
  'chevron-right': icons.ChevronRightIcon,
  'chevron-up-down': icons.ChevronUpDownIcon,
  'chevron-up': icons.ChevronUpIcon,
  'circle-stack': icons.CircleStackIcon,
  'clipboard-document-check': icons.ClipboardDocumentCheckIcon,
  'clipboard-document-list': icons.ClipboardDocumentListIcon,
  'clipboard-document': icons.ClipboardDocumentIcon,
  clipboard: icons.ClipboardIcon,
  'cloud-arrow-down': icons.CloudArrowDownIcon,
  'cloud-arrow-up': icons.CloudArrowUpIcon,
  cloud: icons.CloudIcon,
  'code-bracket-square': icons.CodeBracketSquareIcon,
  'code-bracket': icons.CodeBracketIcon,
  'cog-6-tooth': icons.Cog6ToothIcon,
  'cog-8-tooth': icons.Cog8ToothIcon,
  cog: icons.CogIcon,
  'command-line': icons.CommandLineIcon,
  'computer-desktop': icons.ComputerDesktopIcon,
  'cpu-chip': icons.CpuChipIcon,
  'credit-card': icons.CreditCardIcon,
  'cube-transparent': icons.CubeTransparentIcon,
  cube: icons.CubeIcon,
  'currency-bangladeshi': icons.CurrencyBangladeshiIcon,
  'currency-dollar': icons.CurrencyDollarIcon,
  'currency-euro': icons.CurrencyEuroIcon,
  'currency-pound': icons.CurrencyPoundIcon,
  'currency-rupee': icons.CurrencyRupeeIcon,
  'currency-yen': icons.CurrencyYenIcon,
  'cursor-arrow-rays': icons.CursorArrowRaysIcon,
  'cursor-arrow-ripple': icons.CursorArrowRippleIcon,
  'device-phone-mobile': icons.DevicePhoneMobileIcon,
  'device-tablet': icons.DeviceTabletIcon,
  'document-arrow-down': icons.DocumentArrowDownIcon,
  'document-arrow-up': icons.DocumentArrowUpIcon,
  'document-chart-bar': icons.DocumentChartBarIcon,
  'document-check': icons.DocumentCheckIcon,
  'document-duplicate': icons.DocumentDuplicateIcon,
  'document-magnifying-glass': icons.DocumentMagnifyingGlassIcon,
  'document-minus': icons.DocumentMinusIcon,
  'document-plus': icons.DocumentPlusIcon,
  'document-text': icons.DocumentTextIcon,
  document: icons.DocumentIcon,
  'ellipsis-horizontal-circle': icons.EllipsisHorizontalCircleIcon,
  'ellipsis-horizontal': icons.EllipsisHorizontalIcon,
  'ellipsis-vertical': icons.EllipsisVerticalIcon,
  'envelope-open': icons.EnvelopeOpenIcon,
  envelope: icons.EnvelopeIcon,
  'exclamation-circle': icons.ExclamationCircleIcon,
  'exclamation-triangle': icons.ExclamationTriangleIcon,
  'eye-dropper': icons.EyeDropperIcon,
  'eye-slash': icons.EyeSlashIcon,
  eye: icons.EyeIcon,
  'face-frown': icons.FaceFrownIcon,
  'face-smile': icons.FaceSmileIcon,
  film: icons.FilmIcon,
  'finger-print': icons.FingerPrintIcon,
  fire: icons.FireIcon,
  flag: icons.FlagIcon,
  'folder-arrow-down': icons.FolderArrowDownIcon,
  'folder-minus': icons.FolderMinusIcon,
  'folder-open': icons.FolderOpenIcon,
  'folder-plus': icons.FolderPlusIcon,
  folder: icons.FolderIcon,
  forward: icons.ForwardIcon,
  funnel: icons.FunnelIcon,
  gif: icons.GifIcon,
  'gift-top': icons.GiftTopIcon,
  gift: icons.GiftIcon,
  'globe-alt': icons.GlobeAltIcon,
  'globe-americas': icons.GlobeAmericasIcon,
  'globe-asia-australia': icons.GlobeAsiaAustraliaIcon,
  'globe-europe-africa': icons.GlobeEuropeAfricaIcon,
  'hand-raised': icons.HandRaisedIcon,
  'hand-thumb-down': icons.HandThumbDownIcon,
  'hand-thumb-up': icons.HandThumbUpIcon,
  hashtag: icons.HashtagIcon,
  heart: icons.HeartIcon,
  'home-modern': icons.HomeModernIcon,
  home: icons.HomeIcon,
  identification: icons.IdentificationIcon,
  'inbox-arrow-down': icons.InboxArrowDownIcon,
  'inbox-stack': icons.InboxStackIcon,
  inbox: icons.InboxIcon,
  'information-circle': icons.InformationCircleIcon,
  key: icons.KeyIcon,
  language: icons.LanguageIcon,
  lifebuoy: icons.LifebuoyIcon,
  'light-bulb': icons.LightBulbIcon,
  link: icons.LinkIcon,
  'list-bullet': icons.ListBulletIcon,
  'magnifying-glass-circle': icons.MagnifyingGlassCircleIcon,
  'magnifying-glass-minus': icons.MagnifyingGlassMinusIcon,
  'magnifying-glass-plus': icons.MagnifyingGlassPlusIcon,
  'magnifying-glass': icons.MagnifyingGlassIcon,
  'map-pin': icons.MapPinIcon,
  map: icons.MapIcon,
  megaphone: icons.MegaphoneIcon,
  microphone: icons.MicrophoneIcon,
  'minus-circle': icons.MinusCircleIcon,
  minus: icons.MinusIcon,
  moon: icons.MoonIcon,
  'musical-note': icons.MusicalNoteIcon,
  newspaper: icons.NewspaperIcon,
  'no-symbol': icons.NoSymbolIcon,
  'paint-brush': icons.PaintBrushIcon,
  'paper-airplane': icons.PaperAirplaneIcon,
  'paper-clip': icons.PaperClipIcon,
  'pause-circle': icons.PauseCircleIcon,
  pause: icons.PauseIcon,
  'pencil-square': icons.PencilSquareIcon,
  pencil: icons.PencilIcon,
  'phone-arrow-down-left': icons.PhoneArrowDownLeftIcon,
  'phone-arrow-up-right': icons.PhoneArrowUpRightIcon,
  'phone-x-mark': icons.PhoneXMarkIcon,
  phone: icons.PhoneIcon,
  photo: icons.PhotoIcon,
  'play-circle': icons.PlayCircleIcon,
  'play-pause': icons.PlayPauseIcon,
  'plus-circle': icons.PlusCircleIcon,
  power: icons.PowerIcon,
  'presentation-chart-bar': icons.PresentationChartBarIcon,
  'presentation-chart-line': icons.PresentationChartLineIcon,
  printer: icons.PrinterIcon,
  'puzzle-piece': icons.PuzzlePieceIcon,
  'qr-code': icons.QrCodeIcon,
  'question-mark-circle': icons.QuestionMarkCircleIcon,
  'queue-list': icons.QueueListIcon,
  radio: icons.RadioIcon,
  'receipt-percent': icons.ReceiptPercentIcon,
  'receipt-refund': icons.ReceiptRefundIcon,
  'rectangle-group': icons.RectangleGroupIcon,
  'rectangle-stack': icons.RectangleStackIcon,
  'rocket-launch': icons.RocketLaunchIcon,
  rss: icons.RssIcon,
  scale: icons.ScaleIcon,
  scissors: icons.ScissorsIcon,
  'server-stack': icons.ServerStackIcon,
  server: icons.ServerIcon,
  share: icons.ShareIcon,
  'shield-check': icons.ShieldCheckIcon,
  'shield-exclamation': icons.ShieldExclamationIcon,
  'shopping-bag': icons.ShoppingBagIcon,
  'shopping-cart': icons.ShoppingCartIcon,
  'signal-slash': icons.SignalSlashIcon,
  signal: icons.SignalIcon,
  sparkles: icons.SparklesIcon,
  'speaker-wave': icons.SpeakerWaveIcon,
  'speaker-x-mark': icons.SpeakerXMarkIcon,
  'square-2-stack': icons.Square2StackIcon,
  'squares-plus': icons.SquaresPlusIcon,
  star: icons.StarIcon,
  'stop-circle': icons.StopCircleIcon,
  stop: icons.StopIcon,
  sun: icons.SunIcon,
  swatch: icons.SwatchIcon,
  'table-cells': icons.TableCellsIcon,
  tag: icons.TagIcon,
  ticket: icons.TicketIcon,
  trash: icons.TrashIcon,
  trophy: icons.TrophyIcon,
  truck: icons.TruckIcon,
  tv: icons.TvIcon,
  'user-circle': icons.UserCircleIcon,
  'user-group': icons.UserGroupIcon,
  'user-minus': icons.UserMinusIcon,
  'user-plus': icons.UserPlusIcon,
  user: icons.UserIcon,
  users: icons.UsersIcon,
  variable: icons.VariableIcon,
  'video-camera-slash': icons.VideoCameraSlashIcon,
  'video-camera': icons.VideoCameraIcon,
  'view-columns': icons.ViewColumnsIcon,
  'viewfinder-circle': icons.ViewfinderCircleIcon,
  wallet: icons.WalletIcon,
  wifi: icons.WifiIcon,
  window: icons.WindowIcon,
  'wrench-screwdriver': icons.WrenchScrewdriverIcon,
  wrench: icons.WrenchIcon,
  'x-circle': icons.XCircleIcon,
  'x-mark': icons.XMarkIcon,
  'arrows-pointing-out': icons.ArrowsPointingOutIcon,
  'drag-handle': radixIcons.DragHandleDots2Icon,
};

export type IconName = keyof typeof ICON_MAPPING;
