import { AST, Relation } from '@cotera/era';
import { queryKeys } from '@cotera/client/app/hooks/query-cache-keys';
import { DuckDBQueryResult } from '../duckdb';
import { err, ok, Result } from 'neverthrow';
import { assertOk } from '@cotera/utilities';
import { DevServerError, ManifestSkeleton } from '@cotera/api';
import { useAppData, useWhoami } from '../../stores/org';
import { useApi } from './api';
import { useDevMode } from '@cotera/client/app/stores/dev-mode';
import { DataProvider } from './fn.type';
import {
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import deepEqual from 'fast-deep-equal';

export type QueryData = {
  data: DuckDBQueryResult;
  artifactRel: Relation;
  fromArtifactId?: string;
};

export const useDefinition: DataProvider<
  {
    id: string;
  },
  Result<
    AST.Rel,
    | DevServerError
    | {
        errorType: 'NotFound';
        message: string;
      }
  >
> = ({ id }, opts) => {
  const api = useApi();
  const orgId = useWhoami((x) => x.org.id);
  const skeleton = useAppData((x) => x.skeleton);

  return useSuspenseQuery({
    queryKey: queryKeys.manifest.app({ id, version: skeleton.version, orgId }),
    queryFn: async () => {
      if (skeleton.definitions[id] === undefined) {
        return err({
          errorType: 'NotFound' as const,
          message: `Definition for id: ${id} not found`,
        });
      }
      const res = assertOk(
        await api.t.devServer.manifests.definition(
          { id, version: skeleton.version },
          opts
        )
      );

      if (res === null) {
        return err({
          errorType: 'NotFound' as const,
          message: `Definition for id: ${id} not found`,
        });
      }

      const { ast } = Relation.wrap(res);
      return ok(ast);
    },
  });
};

const DEV_MANIFEST_SKELETON_POLL_INTERVAL_MS = 2000; // 2 seconds

type SkeletonResult = Result<ManifestSkeleton, DevServerError>;

export const useSkeleton = (): UseSuspenseQueryResult<SkeletonResult> => {
  const api = useApi();
  const orgId = useWhoami((x) => x.org.id);
  const source = useDevMode((s) => (s.enabled ? 'dev' : 'commited'));

  return useSuspenseQuery({
    queryKey: queryKeys.manifest.skeleton({ orgId, source }),
    queryFn: async (): Promise<SkeletonResult> => {
      const response = await api.t.devServer.manifests.skeleton({});
      if (response.isOk()) {
        return ok(response.value);
      } else {
        const e = response.error as DevServerError;

        switch (e.errorType) {
          case 'CompileError':
          case 'NoDevServerFound': {
            return err(e);
          }
          default:
            throw new Error(JSON.stringify(response));
        }
      }
    },
    structuralSharing: (oldData, newData) => {
      return deepEqual(oldData, newData) ? oldData : newData;
    },
    refetchInterval:
      source === 'dev' ? DEV_MANIFEST_SKELETON_POLL_INTERVAL_MS : false,
  });
};
