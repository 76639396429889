import { err, ok, Result } from 'neverthrow';
import type { AttributeType, ExtendedAttributeType } from '../../ty/ty';

export type TyParseError = { t: 'unknown'; str: string };
export type TyParseResult = Result<ExtendedAttributeType, TyParseError>;

const TYPE_REGEX =
  /^(?<type>(ARRAY|RANGE|ENUM|RECORD|STRUCT|ID_STRING|ID_INT)\(.*\)|int|string|boolean|float|timestamp|day|month|year|super)(?<notNull> NOT NULL)?( <(?<tags>.*)>)?$/;

export const parseTy = (x: string): TyParseResult => {
  const match = x.match(TYPE_REGEX);
  if (match) {
    const { groups = {} } = match;
    const t = groups['type']!;
    const notNull = groups['notNull'] !== undefined;

    const tags = groups['tags'];
    const parsedTags = tags ? parseTags(tags) : ok([]);

    if (parsedTags.isErr()) {
      return err(parsedTags.error);
    }

    switch (t) {
      case 'int':
      case 'boolean':
      case 'day':
      case 'float':
      case 'month':
      case 'string':
      case 'super':
      case 'timestamp':
      case 'year':
        return ok({
          ty: { k: 'primitive' as const, t },
          tags: parsedTags.value,
          nullable: !notNull,
        });
      default: {
        const compoundRes = parseCompound(t);
        if (compoundRes.isErr()) {
          return err(compoundRes.error);
        }
        return ok({
          ty: compoundRes.value,
          nullable: !notNull,
          tags: parsedTags.value,
        });
      }
    }
  }
  return err({ t: 'unknown', str: x });
};

const COMPOUND_REGEX = /(?<name>[A-Z_]*)\((?<args>.*)\)/;

const parseCompound = (
  compound: string
): Result<AttributeType, TyParseError> => {
  const match = compound.match(COMPOUND_REGEX);
  if (match !== null) {
    const { groups = {} } = match;
    const name = groups['name']!;
    const args = groups['args']!;
    switch (name) {
      case 'RECORD': {
        const child = parseTy(args);
        if (child.isErr()) {
          return err(child.error);
        }
        return ok({ k: 'record', t: child.value });
      }
      case 'ARRAY': {
        const child = parseTy(args);
        if (child.isErr()) {
          return err(child.error);
        }
        return ok({ k: 'array', t: child.value });
      }
      case 'ENUM': {
        const child = parseTags(args);
        if (child.isErr()) {
          return err(child.error);
        }
        return ok({ k: 'enum', t: child.value });
      }
      case 'RANGE': {
        const child = parseRange(args);
        if (child.isErr()) {
          return err(child.error);
        }
        return ok({
          k: 'range',
          t: 'int',
          start: child.value.start,
          end: child.value.end,
        });
      }
      case 'ID_INT':
      case 'ID_STRING': {
        const child = parseId(args);
        if (child.isErr()) {
          return err(child.error);
        }
        return ok({
          k: 'id',
          name: child.value,
          t: name === 'ID_INT' ? 'int' : 'string',
        });
      }
      case 'STRUCT': {
        const child = parseStruct(args);
        if (child.isErr()) {
          return err(child.error);
        }

        return ok({ k: 'struct', fields: child.value });
      }
      default:
        throw new Error(`UNREACHABLE: "${name}"`);
    }
  }

  return err({ t: 'unknown', str: compound });
};

const parseStruct = (
  _args: string
): Result<Record<string, ExtendedAttributeType>, TyParseError> => {
  // const match = args.match(/(?<key>"[^"]*"): '[^']*',?(?<rest>.*)/);
  // if (!match) {
  //   return err({ t: 'unknown', str: args });
  // }

  throw new Error('TODO PARSE STRUCT');
};

const parseId = (args: string): Result<string, TyParseError> => {
  const match = args.match(/^"(?<inner>.*)"$/);
  if (match) {
    const { groups = {} } = match;
    const inner = groups['inner']!;
    return ok(inner);
  }
  return err({ t: 'unknown', str: args });
};

const parseTags = (tags: string): Result<string[], TyParseError> => {
  if (tags === '') {
    return ok([]);
  }
  if (!tags.match(/(?:("([a-zA-Z]+)")(?:, )?)+/)) {
    return err({ t: 'unknown', str: tags });
  }
  return ok(tags.replace(/"/g, '').split(/,\s?/));
};

const parseRange = (
  args: string
): Result<{ start: number; end: number }, TyParseError> => {
  const match = args.match(/\s*(?<start>-?\d+)\s*,\s*(?<end>-?\d+)\s*/);
  if (!match) {
    return err({ t: 'unknown', str: args });
  }
  const { groups = {} } = match;
  return ok({
    start: parseInt(groups['start']!),
    end: parseInt(groups['end']!),
  });
};
