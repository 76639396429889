import { AST } from '../ast';
import { hashLiteral } from './hash-literal';

const HASH_CACHE: WeakMap<AST.Rel, string> = new WeakMap();

export const hashRel = (rel: AST.RelFR): string => {
  const existing = HASH_CACHE.get(rel);

  if (existing !== undefined) {
    return existing;
  }

  const res = hashLiteral(rel, { algorithm: 'md5' });
  HASH_CACHE.set(rel, res);
  return res;
};
