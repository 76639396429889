import { useAppData } from '@cotera/client/app/stores/org';
import { TC } from '@cotera/era';
import { AstGraph } from '../pages/experimental/ast-graph';
import { mapValues } from 'lodash';

export const usePossibleDefinitons = (req: TC.RelInterfaceShorthand) => {
  const definitions = useAppData((x) => x.skeleton.definitions);

  const defs = Object.fromEntries(
    Object.entries(definitions).filter(([_, value]) => {
      return TC.implementsRel({
        subject: value.attributes,
        reqs: req,
      }).isOk();
    })
  );

  return defs;
};

export const useDefinitionsForEntity = (entity: string) => {
  const manifest = useAppData((s) => s.skeleton.definitions);
  const graph = new AstGraph(mapValues(manifest, (def) => def));

  return graph.nodes(entity);
};
