import { ChildrenProps } from './components/utils';
import { Runtime } from '@cotera/sdk/core';
import { useAppData, useWhoami } from './stores/org';
import { ProvideEraScopes } from './pages/apps/compiler/macro-expansion/scopes';
import { useIdeConfig } from './hooks/entities';
import { useSinkCursors } from './pages/ide/events/hooks';
import {
  useInternSignalNodes,
  useKeywordConfig,
} from './pages/ide/entities/hooks';
import { Ty } from '@cotera/era';

export const RuntimeDataProvider: React.FC<ChildrenProps & {}> = ({
  children,
}) => {
  const org = useWhoami((x) => x.org);
  const { data: ide } = useIdeConfig();
  const { data: cursors } = useSinkCursors();
  const { data: keywordConfig } = useKeywordConfig();
  const { data: intentSignalNodes } = useInternSignalNodes();
  const eventStreams = useAppData((x) => x.skeleton.eventStreams);
  const definitions = useAppData((x) => x.skeleton.definitions);

  const data: Runtime.RuntimeDataProvider = {
    '@@cotera-org': { ORG_ID: org.id, ORG_NAME: org.name },
    '@@cotera-entities': {
      DEFINITIONS: ide.entities.map((entity) => ({
        uuid: entity.uuid,
        name: entity.name,
        id_type: Ty.displayTy(entity.idType),
      })),
    },
    '@@cotera-manifest-def-information-schema': {
      INFORMATION: Object.entries(definitions).flatMap(([table_name, def]) =>
        Object.entries(def.attributes).map(([column_name, ty]) => ({
          table_name,
          column_name,
          ty,
        }))
      ),
    },
    '@@cotera-keywords-config': keywordConfig,
    '@@cotera-events': {
      CURSORS: cursors.map((x) => ({
        event_stream_id: x.streamId,
        cotera_stable_id: x.coteraStableId,
        paused_at: x.pausedAt,
        sink_id: x.sinkId,
        entity_id: x.entityId,
        detected_at_time: x.detectedAt,
      })),
    },
    '@@cotera-intent-signal-nodes': intentSignalNodes,
    '@@cotera-manifest-event-stream-sinks': {
      SINKS: eventStreams.flatMap((stream) =>
        Object.entries(stream.sinks).map(([sink_id, { config }]) => ({
          event_stream_id: stream.name,
          entity_id: stream.entityId.name,
          sink_id,
          type: config.t,
        }))
      ),
    },
  };

  const scopes = Runtime.provideWithAsyncResults(data);

  return <ProvideEraScopes scopes={scopes}>{children}</ProvideEraScopes>;
};
