import { Relation, Ty } from '@cotera/era';
import { Registry } from './types';
import { Cell } from './components/cell';
import React from 'react';
import { BaseHeader } from '../data-vis/data-grid/header';
import { Loading } from '@cotera/client/app/components/ui';

type CellView = (
  rel: Relation,
  column: string,
  ty: Ty.ExtendedAttributeType,
  value: unknown
) => React.ReactNode;
type HeaderView = (
  rel: Relation,
  column: string,
  ty: Ty.ExtendedAttributeType
) => React.ReactNode;
type LoadingCellView = (column: string) => React.ReactNode | null;

const DefaultLoadingCell: React.FC<{
  column: string;
}> = ({ column }) => (
  <Cell column={column}>
    <Loading.Shimmer className={'h-full w-full'} />
  </Cell>
);

export class DataGridRegistry implements Registry {
  private readonly _registry: {
    matcher: (column: string, ty: Ty.ExtendedAttributeType) => boolean;
    header?: HeaderView;
    cell?: CellView;
    loadingCell?: LoadingCellView;
  }[] = [];

  getHeader(
    rel: Relation,
    column: string,
    ty: Ty.ExtendedAttributeType
  ): React.ReactNode {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    return (
      view?.header?.(rel, column, ty) ??
      BaseHeader({
        attr: { name: column, ty, detailPages: {} },
        children: null,
      })
    );
  }

  getCell(
    rel: Relation,
    column: string,
    ty: Ty.ExtendedAttributeType,
    value: unknown
  ): React.ReactNode {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    return (
      view?.cell?.(rel, column, ty, value) ??
      Cell({ column, children: value as React.ReactNode })
    );
  }

  getLoadingCell(
    column: string,
    ty: Ty.ExtendedAttributeType
  ): React.ReactNode | null {
    const view = this._registry.find(({ matcher }) => matcher(column, ty));

    return view?.loadingCell?.(column) ?? DefaultLoadingCell({ column });
  }

  add(
    rule: (column: string, ty: Ty.ExtendedAttributeType) => boolean,
    views: {
      cell?: CellView;
      header?: HeaderView;
      loadingCell?: LoadingCellView;
    }
  ) {
    this._registry.push({ matcher: rule, ...views });

    return this;
  }
}
