import {
  Alert,
  Button,
  DisplayError,
  Empty,
  Loading,
  NotFound,
  ResultBoundary,
  Section,
  Title,
  useWorkspace,
  WorkspaceNode,
  WorkspaceProvider,
} from '@cotera/client/app/components';
import { Layout } from '@cotera/client/app/layout';
import React, { Suspense } from 'react';
import { Assert } from '@cotera/utilities';
import { useParams } from 'react-router-dom';
import { Center } from '@cotera/client/app/components/ui';
import { Relation } from '@cotera/era';
import { ErrorBoundary } from 'react-error-boundary';
import { useDefinition } from '../../etc/data/manifest';
import {
  FilterBuilder,
  filterGroupToExpression,
  useDetectedQuickFilters,
  useOptions,
} from '@cotera/client/app/components/app/filter-builder';
import { v4 } from 'uuid';
import {
  BarChartForRelation,
  LineChartForRelation,
  PieChartForRelation,
} from '../../components/data-vis';
import {
  NodeTransform,
  TransformableRelation,
  useNodeRel,
} from '../../components/app/workspace/workspace';
import { NodeCard } from './components/node';
import { classNames } from '../../components/utils/utils';
import { sortBy } from 'lodash';
import { GroupByBuilderNode } from './components/group-by';
import { ComboBox, Inputs } from '../../components/forms';
import { DataGrid } from '../../components/data-grid-2';

export const WorkspacePage = () => {
  return (
    <Layout>
      <ErrorBoundary
        fallbackRender={({ error }) => <DisplayError error={error} />}
      >
        <Suspense
          fallback={
            <Center>
              <Loading.Dots />
            </Center>
          }
        >
          <Section className="w-[calc(100%-2em)]">
            <DataSetView />
          </Section>
        </Suspense>
      </ErrorBoundary>
    </Layout>
  );
};

const DataSetView = () => {
  const { id } = useParams();
  Assert.assert(id !== undefined);
  const { data: def } = useDefinition({ id });

  return (
    <ResultBoundary
      result={def}
      fallback={() => <NotFound resource={`Definition "${id}"`} />}
    >
      {(def) => <WithDef def={Relation.wrap(def)} />}
    </ResultBoundary>
  );
};

const WithDef: React.FC<{ def: Relation }> = ({ def: rel }) => {
  const filterNodeId = v4();
  const autoFilters = useAutoFilterTransforms(rel, filterNodeId);
  const groupById = v4();
  const sampleId = v4();
  const datasetNodeId = v4();
  const { id } = useParams() as { id: string };

  return (
    <WorkspaceProvider
      nodes={{
        [datasetNodeId]: {
          id: datasetNodeId,
          name: id,
          t: 'dataset' as const,
          type: 'sample' as const,
          transforms: [],
          parentId: null,
          rel: new TransformableRelation(rel.ast, rel.typecheck),
          position: { x: 0, y: 0 },
        },
        [filterNodeId]: {
          t: 'filter' as const,
          id: filterNodeId,
          parentId: datasetNodeId,
          transforms: [autoFilters],
          position: { x: 0, y: 0 },
        },
        [groupById]: {
          id: groupById,
          t: 'group-by' as const,
          parentId: filterNodeId,
          transforms: [],
          position: { x: 0, y: 1 },
        },
        [sampleId]: {
          id: sampleId,
          t: 'sample' as const,
          parentId: groupById,
          transforms: [],
          position: { x: 0, y: 2 },
        },
      }}
    >
      <Nodes />
    </WorkspaceProvider>
  );
};

const Nodes: React.FC = () => {
  const nodes = useWorkspace((s) => s.nodes);

  return (
    <div className="relative w-full flex flex-col h-full pb-4">
      {sortBy(Object.values(nodes), (x) => x.position.y).map((node) => (
        <NodeWrapper key={node.id} node={node} />
      ))}
    </div>
  );
};

const NodeWrapper: React.FC<{
  node: WorkspaceNode;
}> = ({ node }) => {
  const [showNodeSelector, setShowNodeSelector] = React.useState(false);
  const addNode = useWorkspace((s) => s.actions.addNode);

  return (
    <>
      <NodeCard key={node.id} name={node.id}>
        <Node key={node.id} node={node} />
      </NodeCard>
      <div
        className={classNames(
          'flex w-full items-center relative justify-center pb-1 -mt-2 transition-opactity duration-300 ease-in-out',
          showNodeSelector ? 'opacity-100' : 'hover:opacity-100 opacity-0'
        )}
      >
        {
          <Button
            className={classNames(
              'absolute left-[50%]',
              showNodeSelector ? 'hidden' : ''
            )}
            icon="add"
            onClick={() => {
              setShowNodeSelector(true);
            }}
            tooltip="top"
            iconOnly
            text="Add Node"
            small
            inline
          />
        }
        {
          <div
            className={classNames(
              showNodeSelector ? 'h-[45px] mt-2' : 'h-[20px]',
              'flex space-x-2 justify-center ease-in-out overflow-hidden transition-height duration-300'
            )}
          >
            {showNodeSelector && (
              <>
                <Button
                  icon="filter"
                  text="Filter"
                  compact
                  onClick={() => {
                    addNode({
                      t: 'filter',
                      parentId: node.id,
                      position: { x: 0, y: node.position.y + 1 },
                      transforms: [],
                    });
                    setShowNodeSelector(false);
                  }}
                />
                <Button
                  icon="link"
                  text="Group By"
                  compact
                  onClick={() => {
                    addNode({
                      t: 'group-by',
                      parentId: node.id,
                      position: { x: 0, y: node.position.y + 1 },
                      transforms: [],
                    });
                    setShowNodeSelector(false);
                  }}
                />
                <Button
                  icon="chart-bar"
                  text="Chart"
                  compact
                  onClick={() => {
                    addNode({
                      t: 'chart' as const,
                      parentId: node.id,
                      position: { x: 0, y: node.position.y + 1 },
                      transforms: [],
                      type: 'bar' as const,
                    } as any);
                    setShowNodeSelector(false);
                  }}
                />
                <Button
                  icon="table-cells"
                  text="Table"
                  compact
                  onClick={() => {
                    addNode({
                      t: 'sample',
                      parentId: node.id,
                      position: { x: 0, y: node.position.y + 1 },
                      transforms: [],
                    });
                    setShowNodeSelector(false);
                  }}
                />
                <Button
                  icon="close"
                  iconOnly
                  text="Close"
                  compact
                  inline
                  tooltip="right"
                  onClick={() => {
                    setShowNodeSelector(false);
                  }}
                />
              </>
            )}
          </div>
        }
      </div>
    </>
  );
};

const Node: React.FC<{ node: WorkspaceNode }> = ({ node }) => {
  switch (node.t) {
    case 'filter':
      return <FilterBuilderNode node={node} />;
    case 'sample':
      return <SampleNode node={node} />;
    case 'group-by':
      return <GroupByBuilderNode node={node} />;
    case 'dataset':
      return <DatasetNode node={node} />;
    case 'chart':
      return <ChartNode node={node} />;
    default:
      return (
        <Alert
          variant="warn"
          //@ts-ignore
          detail={`Unimplemented for type ${node.t}`}
          message={''}
        />
      );
  }
};

const SampleNode: React.FC<{ node: WorkspaceNode }> = ({ node }) => {
  Assert.assert(node.t === 'sample');
  const { rel } = useNodeRel(node, { canUseSample: true });
  const registerArtifact = useWorkspace((s) => s.actions.registerArtifact);

  //   defaultRowCount={20}
  //   rel={rel}
  //   fromArtifactId={null}
  //   onArtifact={(artifact) => {
  //     registerArtifact(node.id, artifact);
  //   }}
  // />
  return (
    <DataGrid.Table rel={rel}>
      {(props) => <DataGrid.AutoLoadData {...props} />}
    </DataGrid.Table>
  );
};

const CHART_MAPPING = {
  bar: BarChartForRelation,
  line: LineChartForRelation,
  pie: PieChartForRelation,
};

const ChartNode: React.FC<{ node: WorkspaceNode }> = ({ node }) => {
  Assert.assert(node.t === 'chart');
  const { rel, baseRel } = useNodeRel(node, { canUseSample: true });
  const [axis, setAxis] = React.useState<{
    x: string | null;
    y: string | null;
  }>({
    x: baseRel.attributes['x'] ? 'x' : null,
    y: baseRel.attributes['y'] ? 'y' : null,
  });
  const transform = useWorkspace((s) => s.actions.node(node.id).transform);
  const Chart = CHART_MAPPING[node.type];

  const hasRequiredAttributes = (axis: {
    x: string | null;
    y: string | null;
  }) => axis.x !== null && axis.y !== null;

  return (
    <div className="flex w-full h-[350px]">
      <div className="flex flex-col w-1/4 border-r border-divider pr-4">
        <ComboBox.Select
          className="mb-4"
          label="X Axis"
          value={{
            display: axis.x ?? '',
            value: axis.x ?? '',
          }}
          options={Object.keys(baseRel.attributes).map((key) => ({
            display: key,
            value: key,
          }))}
          onChange={(value) => {
            setAxis((prev) => {
              const newValues = {
                ...prev,
                x: value?.value ?? null,
              };

              if (hasRequiredAttributes(newValues)) {
                transform((rel) =>
                  rel.select((t) => ({
                    y: t.attr(newValues.y!),
                    x: t.attr(value!.value),
                    category: 'All',
                  }))
                );
              }
              return newValues;
            });
          }}
        />
        <ComboBox.Select
          label="Y Axis"
          value={{
            display: axis.y ?? '',
            value: axis.y ?? '',
          }}
          options={Object.keys(baseRel.attributes).map((key) => ({
            display: key,
            value: key,
          }))}
          onChange={(value) => {
            setAxis((prev) => {
              const newValues = {
                ...prev,
                y: value?.value ?? null,
              };
              if (hasRequiredAttributes(newValues)) {
                transform((rel) =>
                  rel.select((t) => ({
                    x: t.attr(newValues.x!),
                    y: t.attr(value!.value!),
                    category: 'All',
                  }))
                );
              }
              return newValues;
            });
          }}
        />
      </div>
      <div className="flex flex-col w-3/4 justify-center items-center">
        {hasRequiredAttributes(axis) ? (
          <Chart
            rel={rel}
            axis={{
              x: {
                scale: 'point',
              },
              y: {},
            }}
          />
        ) : (
          <Empty type="chart" title="This chart is empty" />
        )}
      </div>
    </div>
  );
};

const DatasetNode: React.FC<{ node: WorkspaceNode }> = ({ node }) => {
  Assert.assert(node.t === 'dataset');
  const updateNode = useWorkspace((s) => s.actions.updateNode);

  return (
    <div className="flex flex-row w-full justify-between items-center">
      <Title title={node.name} type="section" />
      <Inputs.Toggle
        compact
        options={['Sample', 'Full']}
        value={node.type === 'sample' ? 'Sample' : 'Full'}
        onChange={(value) => {
          updateNode(node.id, {
            type: value === 'Sample' ? 'full' : 'sample',
          });
        }}
      />
    </div>
  );
};

const useAutoFilterTransforms = (
  rel: Relation,
  nodeId: string
): NodeTransform => {
  const detectedFilters = useDetectedQuickFilters(rel.attributes);

  return {
    t: 'filter',
    fn: (rel) => {
      return rel.where((t) =>
        filterGroupToExpression(t)({
          connective: 'and',
          items: detectedFilters,
        })
      );
    },
    fromNodeId: nodeId,
  };
};

const FilterBuilderNode: React.FC<{ node: WorkspaceNode }> = ({ node }) => {
  const actions = useWorkspace((s) => s.actions.node(node.id));
  const { baseRel: rel } = useNodeRel(node, { canUseSample: false });
  const autoFilters = useDetectedQuickFilters(rel.attributes);

  const options = useOptions(rel.attributes);

  return (
    <FilterBuilder
      rel={rel}
      filterGroup={{
        connective: 'and',
        items: autoFilters,
      }}
      options={options}
      autoRun={true}
      onRun={({ filterGroup, limit = 50_000 }) => {
        actions.filter((rel) => {
          return rel
            .where((t) => filterGroupToExpression(t)(filterGroup))
            .limit(limit);
        });
      }}
    />
  );
};
