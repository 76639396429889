import {
  ComboBox,
  Inputs,
  useComboBox,
} from '@cotera/client/app/components/forms';
import { OPERATORS } from './constants';
import { Relation, TC, Ty } from '@cotera/era';
import type { Range } from '@cotera/client/app/components/forms/date-picker';
import { Connective } from './types';
import React, { Suspense } from 'react';
import { useDynamicOptions } from './hooks';
import { useFuzzySearch } from '@cotera/client/app/hooks/use-fuzzy-search';
import { makeStatsQuery } from './utils';
import { useArtifactQuery } from '@cotera/client/app/etc/data/duckdb';
import { Categorical, Histogram } from '@cotera/client/app/components/data-vis';
import { z } from 'zod';
import { Divider, Loading } from '@cotera/client/app/components/ui';
import { ChildrenProps } from '@cotera/client/app/components/utils';

type ValueProps = {
  onChange: (value: (string | null)[]) => void;
  operator: (typeof OPERATORS)[number];
  t: Ty.ExtendedAttributeType;
  attr: string;
  rel: Relation;
  options?: string[];
  value?: (string | null)[];
  name?: string;
};

const showOptions = (operator: (typeof OPERATORS)[number]) => {
  if (operator === 'one-of') {
    return true;
  }
  return false;
};

const showSingleSelect = (t: Ty.ExtendedAttributeType) => {
  if (
    TC.implementsTy({
      subject: t,
      req: 'string',
    })
  ) {
    return true;
  }
  return false;
};

export function Value({
  value,
  operator,
  onChange,
  t,
  attr,
  name,
  options,
  rel,
}: ValueProps) {
  if (showOptions(operator)) {
    return (
      <ComboBox.Multi
        label={name}
        nullable
        className="max-w-[250px] min-w-50px"
        compact
        value={
          value?.filter((t) => !!t).map((t) => ({ value: t!, display: t! })) ??
          []
        }
        onChange={(value) => {
          onChange(value.map((k) => k.value));
        }}
        options={({ query }) => (
          <SelectOptions
            options={options}
            query={query}
            attr={attr}
            rel={rel}
            t={t}
          />
        )}
      />
    );
  }

  if (showSingleSelect(t)) {
    return (
      <ComboBox.Single
        label={name}
        nullable
        className="max-w-[250px] min-w-50px"
        compact
        value={
          value?.[0]
            ? {
                value: value[0],
                display: value[0],
              }
            : undefined
        }
        onChange={(v) => {
          onChange([v?.value ?? null]);
        }}
        options={({ query }) => (
          <SelectOptions
            options={options}
            query={query}
            attr={attr}
            rel={rel}
            t={t}
          />
        )}
      />
    );
  }

  if (
    TC.implementsTy({
      subject: t,
      req: 'timestamp',
    })
  ) {
    return (
      <Inputs.Date
        label={name}
        compact
        className="max-w-[250px] min-w-50px"
        value={
          (value?.map((x) => (x ? new Date(x) : null)) as Range) ?? [null, null]
        }
        onChange={(value) => onChange(value.map((x) => x?.toString() ?? null))}
        rangeEnabled={operator === 'between'}
      />
    );
  }

  if (
    TC.implementsTy({
      subject: t,
      req: 'boolean',
    })
  ) {
    return (
      <Inputs.Toggle
        label={name}
        compact
        value={
          value?.[0] !== undefined && value?.[0] !== null
            ? value?.[0] === 'true'
              ? 'true'
              : 'false'
            : undefined
        }
        options={['true', 'false']}
        onChange={(value) => {
          onChange([value === undefined ? null : value]);
        }}
      />
    );
  }

  if (TC.isNumeric(t)) {
    if (operator === 'between') {
      return (
        <NumbericStatsWrapper
          rel={rel}
          attr={attr}
          t={t}
          onClick={([min, max]) => onChange([min, max].map(String))}
        >
          <Inputs.MinMax
            label={name}
            compact
            className="w-[150px]"
            value={[Number(value?.[0] ?? '0'), Number(value?.[1] ?? '0')]}
            onChange={(min, max) => {
              onChange([min, max].map(String));
            }}
          />
        </NumbericStatsWrapper>
      );
    }
    return (
      <NumbericStatsWrapper
        rel={rel}
        attr={attr}
        t={t}
        onClick={([min, max]) => onChange([min, max].map(String))}
      >
        <Inputs.Number
          label={name}
          type="number"
          compact
          value={Number(value?.[0] ?? '0')}
          onChange={(e) => {
            onChange([String(e)]);
          }}
        />
      </NumbericStatsWrapper>
    );
  }

  throw new Error('Invalid type');
}

const NumbericStatsWrapper: React.FC<{
  children: ChildrenProps['children'];
  rel: Relation;
  t: Ty.ExtendedAttributeType;
  attr: string;
  onClick?: (range: [number, number]) => void;
}> = ({ children, rel, attr, t, onClick }) => {
  return (
    <div className="flex items-center">
      {children}
      <div className="pl-2 border border-divider border-l-0 rounded-r h-10 -ml-[2px]">
        <NumericStatsContainer attr={attr} rel={rel} t={t} onClick={onClick} />
      </div>
    </div>
  );
};

const SelectOptions: React.FC<{
  query: string | null;
  attr: string;
  rel: Relation;
  t: Ty.ExtendedAttributeType;
  options?: string[];
}> = ({ attr, rel, t, query, options }) => {
  const shouldGetDynamicOptions = TC.implementsTy({
    subject: t,
    req: Ty.CARD_LT_1000,
  });

  if (!shouldGetDynamicOptions) {
    return (
      <div className="flex flex-col">
        <DiscreteStatsContainer attr={attr} rel={rel} t={t} />
        <ComboBox.StaticOptions
          options={
            options?.map((x) => ({
              value: x,
              display: x,
            })) ?? []
          }
          query={query}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <DiscreteStatsContainer attr={attr} rel={rel} t={t} />
      <Suspense fallback={<ComboBox.LoadingOptions />}>
        <SelectOptionsWithData rel={rel} attr={attr} query={query} />
      </Suspense>
    </div>
  );
};

const SelectOptionsWithData: React.FC<{
  rel: Relation;
  attr: string;
  query: string | null;
}> = ({ rel, attr, query }) => {
  const options = useDynamicOptions(rel, attr).map((o) => ({
    value: o.value,
    display: o.value,
  }));

  const search = useFuzzySearch(options, ['value', 'display']);

  const results = search(query);

  return (
    <ComboBox.Options>
      {results
        .filter((x) => x.value !== null)
        .map((o) => {
          const value = {
            value: o.value as string,
            display: o.display as string,
          };
          return <ComboBox.Option key={o.value} value={value} />;
        })}
    </ComboBox.Options>
  );
};

const DiscreteStatsContainer: React.FC<{
  attr: string;
  rel: Relation;
  t: Ty.ExtendedAttributeType;
}> = ({ rel, attr, t }) => {
  return (
    <Suspense
      fallback={
        <Loading.Shimmer className="w-[calc(100%-2rem)] h-8 mx-2 mb-2" />
      }
    >
      <DiscreteStatsView attr={attr} rel={rel} t={t} />
    </Suspense>
  );
};

const DiscreteStatsView: React.FC<{
  attr: string;
  rel: Relation;
  t: Ty.ExtendedAttributeType;
}> = ({ rel, attr, t }) => {
  const select = useComboBox((store) => store.actions.select);
  const deselect = useComboBox((store) => store.actions.deselect);
  const selectedItems = useComboBox((store) => store.selectedItems);

  const { data } = useArtifactQuery({
    baseRel: rel,
    limit: 50_000,
    rel: (rel) =>
      makeStatsQuery({
        type: t,
        col: attr,
        rel,
      }),
  });

  if (TC.isStringLike(t)) {
    const result = data.data.toArrayOf(
      z.object({ count: z.number(), value: z.string().nullable() })
    );

    if (result.length === 0) {
      return null;
    }

    return (
      <>
        <div className="w-full h-8 px-2 mb-2">
          <Categorical
            active={selectedItems.map((i) => i.value)}
            horizontal
            categories={result}
            numDistinct={0}
            onClick={(c) => {
              if (selectedItems.map((i) => i.value).includes(c)) {
                deselect({
                  value: c,
                  display: c,
                });
              } else {
                select({
                  value: c,
                  display: c,
                });
              }
            }}
          />
        </div>
        <Divider />
      </>
    );
  }

  return null;
};

const NumericStatsContainer: React.FC<{
  attr: string;
  rel: Relation;
  t: Ty.ExtendedAttributeType;
  onClick?: (range: [number, number]) => void;
}> = ({ rel, attr, t, onClick }) => {
  return (
    <Suspense fallback={<Loading.Shimmer className="w-[110px] h-10" />}>
      <NumericStatsView onClick={onClick} attr={attr} rel={rel} t={t} />
    </Suspense>
  );
};

const NumericStatsView: React.FC<{
  attr: string;
  rel: Relation;
  t: Ty.ExtendedAttributeType;
  onClick?: (range: [number, number]) => void;
}> = ({ rel, attr, t, onClick }) => {
  const { data } = useArtifactQuery({
    baseRel: rel,
    limit: 50_000,
    rel: (rel) =>
      makeStatsQuery({
        type: t,
        col: attr,
        rel,
      }),
  });

  if (TC.isNumeric(t)) {
    return (
      <div className="w-[110px] h-10">
        <div className="pr-2 border-divider border-l-0 rounded py-1.5 h-[32px]">
          <Histogram
            direction="horizontal"
            buckets={
              data.data.toArrayOf(
                z.object({
                  count: z.number().nullable(),
                  start: z.number().nullable(),
                  end: z.number().nullable(),
                })
              ) ?? []
            }
            onClick={(min, max) => {
              onClick?.([min, max]);
            }}
          />
        </div>
      </div>
    );
  }

  return null;
};

export const GroupPreview: React.FC<{
  onClick: () => void;
  name: string;
}> = ({ onClick, name }) => {
  return (
    <div
      onClick={onClick}
      className="mr-2 mb-2 flex items-center rounded text-sm cursor-pointer bg-white px-2 text-gray-500 border-gray-200 border h-10"
    >
      {name}
    </div>
  );
};

export const ConnectiveSelect: React.FC<{
  value: Connective;
  onChange: (value: Connective) => void;
  className?: string;
}> = ({ value, onChange, className }) => {
  return (
    <ComboBox.Select
      className={className}
      theme={value === 'and' ? 'secondary' : 'warning'}
      value={{
        display: value,
        value,
      }}
      options={['and', 'or'].map((v) => ({
        value: v,
        display: v,
      }))}
      onChange={(value) => {
        onChange(value!.value as Connective);
      }}
    />
  );
};
