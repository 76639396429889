import { Checkbox } from './checkbox';
import { DatePicker } from './date-picker';
import { NumberInput, TextInput } from './input';
import { MinMaxInput } from './min-max/min-max';
import { ToggleSelect } from './toggle';

export * from './combo-box';

export const Inputs = {
  Text: TextInput,
  Number: NumberInput,
  MinMax: MinMaxInput,
  Date: DatePicker,
  Toggle: ToggleSelect,
  Checkbox: Checkbox,
};
