import { z } from 'zod';

export const WriteToLogJobParamsSchema = z.object({ msg: z.string() });

export type WriteToLogJobParams = z.infer<typeof WriteToLogJobParamsSchema>;

export const FPGrowthModelParamsSchema = z.object({
  orgId: z.string().uuid(),
  outputTableName: z.string(),
  transactionsDefinition: z.string(),
  userItemFrequenciesDefinition: z.string(),
  constrainTransactionSizeTo: z.number().int().gte(2).lte(8),
  useNMostCommonItems: z.number().int().gte(2).lte(1024),
  useNTransactions: z.number().int().lte(2000000),
  nRecommendations: z.number().int(),
});

export const RunWarehouseUpdateParamsSchema = z.object({
  orgId: z.string().uuid(),
  models: z.string().array().optional(),
});

export const VfsRecommendationModelParamsSchema = z.object({
  orgId: z.string().uuid(),
  nProductSimilarityRecs: z.number().int(),
  nCollabFilteringRecs: z.number().int(),
  minInteractions: z.number().int(),
  shouldRunOutputDbt: z.boolean(),
  shouldRunInputDbt: z.boolean(),
});

export const ProductAffinityParamsSchema = z.object({
  orgId: z.string().uuid(),
  shouldRunOutputDbt: z.boolean(),
  shouldRunInputDbt: z.boolean(),
});

export const UpdateWarehouseParamsSchema = z.object({
  orgId: z.string().uuid(),
  models: z.string().array().optional(),
});

export const ArtifactsCacheWarmSchema = z.object({});

export type VfsRecommendationModelParams = z.infer<
  typeof VfsRecommendationModelParamsSchema
>;

export type RunWarehouseUpdateParams = z.infer<
  typeof RunWarehouseUpdateParamsSchema
>;

export const ScheduledJobSchema = z.object({
  schedule: z.string(),
  id: z.string(),
  parameters: z.discriminatedUnion('t', [
    z.object({
      t: z.literal('write-to-log'),
      params: WriteToLogJobParamsSchema,
    }),
    z.object({
      t: z.literal('recommendation-model'),
      params: VfsRecommendationModelParamsSchema,
    }),
    z.object({
      t: z.literal('product-affinity-model'),
      params: ProductAffinityParamsSchema,
    }),
    z.object({
      t: z.literal('fp-growth-model'),
      params: FPGrowthModelParamsSchema,
    }),
    z.object({
      t: z.literal('update-warehouse'),
      params: UpdateWarehouseParamsSchema,
    }),
    z.object({
      t: z.literal('artifacts-cache-warm'),
      params: ArtifactsCacheWarmSchema,
    }),
  ]),
});

export type ScheduledJob = z.infer<typeof ScheduledJobSchema>;
