import { Ty } from '@cotera/era';

type Attributes = Record<string, Ty.Shorthand>;

type NamedRel<
  Rel extends { attributes: Attributes } = { attributes: Attributes }
> = { name: string; rel: Rel };

export class AstGraph<Rel extends { attributes: Attributes }> {
  private readonly relations: NamedRel<Rel>[] = [];
  constructor(defs: Record<string, Rel>) {
    this.relations = Object.entries(defs).map(([name, rel]) => ({
      name,
      rel,
    }));
  }

  node(topic: NamedRel<Rel>): Node<Rel> {
    return new Node<Rel>(topic, this.relations, []);
  }

  nodes(identifier: string): Node<Rel>[] {
    return this.relations
      .filter((rel) => {
        const relIdentifiers = identifiersForRel(rel);

        return relIdentifiers.some((relId) => relId.attr.name === identifier);
      })
      .map((x) => new Node(x, this.relations, []));
  }
}

const identifiersForRel = (
  rel: NamedRel
): {
  column: string;
  attr: Ty.IdType;
}[] => {
  return Object.entries(rel.rel.attributes)
    .filter(([_, attr]) => Ty.isIdentifier(attr))
    .map(([column, attr]) => {
      return {
        column,
        attr: Ty.shorthandToTy(attr).ty as Ty.IdType,
      };
    });
};

export class Node<
  Rel extends { attributes: Attributes } = { attributes: Attributes }
> implements NamedRel
{
  constructor(
    private readonly _rel: NamedRel<Rel>,
    private readonly _relations: NamedRel<Rel>[],
    readonly parents: NamedRel<Rel>[]
  ) {}

  identifiers() {
    return identifiersForRel(this._rel);
  }

  get rel() {
    return this._rel.rel;
  }

  get name() {
    return this._rel.name;
  }

  nodes(): Node<Rel>[] {
    return this._relations
      .filter((rel) => {
        if (
          this.parents.map((x) => x.name).includes(rel.name) ||
          rel.name === this.name
        ) {
          return false;
        }
        const relIdentifiers = identifiersForRel(rel);

        return this.identifiers().some((id) =>
          relIdentifiers.some((relId) => relId.attr.name === id.attr.name)
        );
      })
      .map((x) => new Node(x, this._relations, [...this.parents, this._rel]));
  }
}
