import { err, ok } from 'neverthrow';
import { Ty } from '../../ty';
import { implementsTy } from '../implements';
import type {
  FuncCallSignature,
  FuncCallSignatureShorthand,
  FunctionTypingRule,
} from './function-call';
import { Assert } from '../../utils';
import * as Errs from '../type-check-error';
import _ from 'lodash';

export const satisfiesSignature =
  (input: readonly Ty.Shorthand[], output: Ty.Shorthand): FunctionTypingRule =>
  (name, args) => {
    const error = () =>
      err(
        new Errs.InvalidFunctionCall({
          op: name,
          recieved: args.map((a) => a.ty),
          allowed: [funcCallShorthandToFuncCall([input, output])],
        })
      );

    if (args.length !== input.length) {
      return error();
    }

    for (const [expected, found] of _.zip(
      input.map((i) => Ty.shorthandToTy(i)),
      args.map((a) => Ty.shorthandToTy(a))
    )) {
      Assert.assert(
        expected !== undefined && found !== undefined,
        'We know that the arrays are the same length'
      );

      if (!implementsTy({ req: expected, subject: found })) {
        return error();
      }
    }

    return ok({
      ...Ty.shorthandToTy(output),
      nullable: _.some(args, (a) => a.nullable),
    });
  };

export const satisfiesOneOf =
  (signatures: FuncCallSignatureShorthand[]): FunctionTypingRule =>
  (name, args) => {
    for (const [input, output] of signatures) {
      const check = satisfiesSignature(input, output)(name, args);
      if (check.isOk()) {
        return check;
      }
    }
    return err(
      new Errs.InvalidFunctionCall({
        op: name,
        recieved: args.map((a) => a.ty),
        allowed: signatures.map((s) => funcCallShorthandToFuncCall(s)),
      })
    );
  };

export const funcCallShorthandToFuncCall = ([
  args,
  output,
]: FuncCallSignatureShorthand): FuncCallSignature =>
  [args.map((arg) => Ty.shorthandToTy(arg)), Ty.shorthandToTy(output)] as const;
