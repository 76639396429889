import { useKeyDownState } from '@cotera/client/app/hooks';
import { useDataGridStore } from '../context';
import { useCallback } from 'react';

export const useSelectRow = () => {
  const selectedRows = useDataGridStore((s) => s.selectedRows);
  const setSelectedRows = useDataGridStore((s) => s.actions.setSelectedRows);

  const isCtrlPressed = useKeyDownState((e) => e.metaKey || e.ctrlKey);
  const isShiftPressed = useKeyDownState((e) => e.shiftKey);

  const selectRow = useCallback(
    (index: number) => {
      const isMultiSelecting = isCtrlPressed || isShiftPressed;

      if (!isMultiSelecting && selectedRows.length === 0) {
        return setSelectedRows([index]);
      }

      if (
        !isMultiSelecting &&
        selectedRows.length === 1 &&
        selectedRows[0] !== index
      ) {
        return setSelectedRows([index]);
      }

      if (!isMultiSelecting && selectedRows.length > 1) {
        return setSelectedRows([index]);
      }

      if (selectedRows.includes(index)) {
        return setSelectedRows(selectedRows.filter((x) => x !== index));
      }

      if (isMultiSelecting) {
        return setSelectedRows([...selectedRows, index]);
      }
    },
    [isCtrlPressed, isShiftPressed, selectedRows, setSelectedRows]
  );

  return selectRow;
};
