import { Assert } from '@cotera/utilities';
import { useQuery } from '@tanstack/react-query';
import { useTenantedClient, useWhoami } from '../stores/org';
import { queryKeys } from './query-cache-keys';

export const useFivetranConnectors = () => {
  const client = useTenantedClient();
  const orgId = useWhoami((x) => x.org.id);

  return useQuery({
    queryKey: queryKeys.sources.fivetranConnectors.getDestination({ orgId }),
    queryFn: async () => {
      const res = await client.warehouse.etl.getDestination({ orgId });
      if (res.isErr()) {
        if (res.error.errorType === 'NotFound') {
          return null;
        }
        throw res.error;
      }
      return Assert.assertOk(res);
    },
  });
};
