import { FunctionTypingRule } from './function-call';
import { err, ok } from 'neverthrow';
import { Ty } from '../../ty';
import * as Errs from '../type-check-error';
import { Assert } from '../../utils';
import { parseTy } from '../../era-sql/ty/parse';

export const tagTypingRule: FunctionTypingRule = (_name, args) => {
  const [target, tag] = args;
  Assert.assert(target !== undefined && tag !== undefined);

  const e: Errs.TypeCheckError = new Errs.SingleVariantEnumRequired({
    got: tag,
  });

  if (tag.ty.k !== 'enum') {
    return err(e);
  }

  const [tagName, ...restVariants] = tag.ty.t;

  if (tagName === undefined || restVariants.length > 0) {
    return err(e);
  }

  return ok(Ty.tag(target, [tagName]));
};

export const typeOfTypingRule: FunctionTypingRule = (_name, args) => {
  const [target] = args;
  Assert.assert(target !== undefined);
  return ok(Ty.nn(Ty.e([Ty.displayTy(target)])));
};

export const nullOfTypingRule: FunctionTypingRule = (_name, args) => {
  const [ty] = args;
  Assert.assert(ty !== undefined);

  if (ty.ty.k !== 'enum' || ty.ty.t.length !== 1) {
    return err(
      new Errs.SingleVariantEnumRequired({
        got: ty,
      })
    );
  }

  const tyString = ty.ty.t[0]!;
  const parse = parseTy(tyString);

  if (parse.isErr()) {
    return err(new Errs.CantParseAsTy({ err: parse.error }));
  }

  if (!parse.value.nullable) {
    return err(
      new Errs.CantHaveNullOfNonNullableType({
        attempted: parse.value,
      })
    );
  }

  return ok(parse.value);
};

export const implementsTypingRule: FunctionTypingRule = (_name, args) => {
  const [target, req] = args;
  Assert.assert(target !== undefined && req !== undefined);
  if (req.ty.k !== 'enum' || req.ty.t.length !== 1) {
    return err(
      new Errs.SingleVariantEnumRequired({
        got: req,
      })
    );
  }

  const parsed = parseTy(req.ty.t[0]!);

  if (parsed.isErr()) {
    return err(new Errs.CantParseAsTy({ err: parsed.error }));
  }

  return ok(Ty.nn('boolean'));
};
