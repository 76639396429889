import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import { Main } from './components/ui/main';
import { Login } from './pages/login/login';
import { Fallback as FallbackPage } from './fallback';
import { OrgApp } from './org-app';
import { Loading } from './components';
import { CoteraDuckDB } from './etc';
import { SignupPage } from './pages/signup';
import { WaitlistPage } from './pages/signup/waitlist';
import { makeClient } from './etc/api/client';
import { config } from '@cotera/client/config/config';
import { useQuery } from '@tanstack/react-query';
import { AsyncResult } from '@cotera/sdk/core';

const client = makeClient(config);

export const App: React.FC<{ initedDb: Promise<CoteraDuckDB> }> = ({
  initedDb,
}) => {
  const whoamiQ = useQuery({
    queryFn: () => client.u.whoami({}),
    queryKey: ['whoami'],
  });
  const whoami = AsyncResult.from(whoamiQ);

  if (!whoami.hasData()) {
    return (
      <Main>
        <Loading.Dots />
      </Main>
    );
  }

  const result = whoami.data;

  if (result.isErr()) {
    if (
      result.error.errorType === 'Forbidden' ||
      result.error.errorType === 'Unauthorized'
    ) {
      return <LoggedOutRoutes />;
    }
    return <FallbackPage />;
  }

  return (
    <Routes>
      <Route path="/login" element={<Navigate replace to="/" />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/waitlist" element={<WaitlistPage />} />
      <Route
        path="/*"
        element={<OrgApp whoami={result.value} initedDb={initedDb} />}
      />
    </Routes>
  );
};

const IGNORED_RETURN_TO_PATHS = ['logout', 'errorType'];

const LoggedOutRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/waitlist" element={<WaitlistPage />} />
      {/* Redirect to login if not logged in*/}
      <Route
        path="/*"
        element={
          <Navigate
            replace
            to={`/login?returnTo=${
              IGNORED_RETURN_TO_PATHS.some((path) =>
                window.location.pathname.includes(path)
              )
                ? '/'
                : window.location.pathname
            }`}
          />
        }
      />
    </Routes>
  );
};
