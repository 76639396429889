import { MacroArgs, MacroBody, mkMacroVars } from './macro-base';
import { sha1 } from 'object-hash';
import { TC, TyStackTrace } from '../../type-checker';
import { AST } from '../../ast';
import _ from 'lodash';
import { Relation } from '../relation';
import { Expression } from '../expression';
import { MarkupShorthand, Markup } from '../markup/section';
import { Assert } from '@cotera/utilities';

export type MuMacro<Args extends MacroArgs> = {
  readonly call: MacroBody<Args, Markup>;
  readonly body: Markup;
  readonly scope: string;
};

export const mkMuMacro = <Args extends MacroArgs>(
  args: Args,
  body: (args: Args) => MarkupShorthand,
  opts?: {
    readonly displayName: string;
  }
): MuMacro<Args> => {
  const scope = `${opts?.displayName ? `${opts.displayName}-` : ''}${sha1({
    args,
    body: body.toString(),
    opts: opts ?? {},
  })}`;

  const mBody = Markup.fromShorthand(body(mkMacroVars(scope, args))).ast;

  const check = TC.MU.checkMarkup(mBody);

  if (check instanceof TyStackTrace) {
    throw check.toError({ jsStackPointer: body });
  }

  const callback: MacroBody<Args, Markup> = (params) => {
    const rels: Record<string, AST.Rel> = _.chain(params)
      .entries()
      .filter(([_name, def]) => def instanceof Relation)
      .map(([name, _def]) => {
        const val = params[name];
        Assert.assert(val instanceof Relation);
        return [name, val.ast];
      })
      .fromPairs()
      .value();

    const exprs: Record<string, AST.Expr> = _.chain(params)
      .entries()
      .filter(([_name, def]) => !(def instanceof Relation))
      .map(([name, _def]) => {
        const val = params[name];
        Assert.assert(val !== undefined && !(val instanceof Relation));
        return [name, Expression.wrap(val).ast];
      })
      .fromPairs()
      .value();

    const called: AST._MacroApplyVarsToMarkup = {
      t: 'macro-apply-vars-to-markup',
      scope,
      vars: { rels, exprs, sections: {} },
      section: mBody,
      displayName: opts?.displayName ?? null,
    };

    const checked = TC.MU.checkApplyVarsToMu(called);

    if (checked instanceof TC.TyStackTrace) {
      throw checked.toError({ jsStackPointer: callback });
    }

    return Markup.fromAst(called);
  };

  return { scope, body: Markup.fromAst(mBody), call: callback };
};

export const muIf = (
  condition: boolean | Expression,
  branches: {
    readonly then: MarkupShorthand;
    readonly else?: MarkupShorthand | null;
  }
): Markup =>
  muCase([{ when: condition, then: branches.then }], {
    else: branches.else ?? { t: 'noop' },
  });

export const muCase = (
  cases: readonly {
    readonly when: boolean | Expression;
    readonly then: MarkupShorthand;
  }[],
  opts?: { readonly else?: MarkupShorthand }
): Markup =>
  Markup.wrap(
    {
      t: 'macro-section-case',
      else: Markup.fromShorthand(opts?.else ?? null).ast,
      cases: cases.map(({ when, then }) => ({
        when: Expression.wrap(when).ast,
        then: Markup.fromShorthand(then).ast,
      })),
    },
    { jsStackPointer: muCase }
  );
