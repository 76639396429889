import { Inputs } from '@cotera/client/app/components/forms';
import { Layout } from '../../layout';
import React, { Suspense, useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSuspenseQueries, useSuspenseQuery } from '@tanstack/react-query';
import { useTenantedClient } from '../../stores/org';
import { Assert } from '@cotera/utilities';
import {
  Section,
  Title,
  Badge,
  Loading,
  Divider,
  Text,
  Button,
  Editable,
  toast,
  Modal,
  ProgressBar,
  Searchable,
  Center,
} from '@cotera/client/app/components/ui';
import { ErrorBoundary } from 'react-error-boundary';
import { Card, List } from '@cotera/client/app/components/headless';
import {
  Copyable,
  DisplayError,
  Empty,
  RelationPicker,
} from '@cotera/client/app/components/app';
import { classNames } from '@cotera/client/app/components/utils';
import { useTenantedQueryKey } from '@cotera/client/app/hooks/use-tenanted-query-key';
import { useFuzzySearch } from '@cotera/client/app/hooks/use-fuzzy-search';
import {
  applyMiddleware,
  filter,
  makeStore,
  pluralize,
  StateGetter,
  StateSetter,
  withHistory,
} from '@cotera/client/app/etc';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BarChart } from '@cotera/client/app/components/data-vis';
import { v4 } from 'uuid';
import { FocusableComponentProps } from '@cotera/client/app/components/types/form-component';
import { useKeyPress } from '@cotera/client/app/hooks/use-key-press';
import { useEntity } from '@cotera/client/app/hooks/entities';
import { TenantedClient } from '@cotera/api';
import { queryKeys } from '@cotera/client/app/hooks/query-cache-keys';
import { AddNewVersion, PromoteButton } from './components';
import { uniqBy } from 'lodash';

type Feature = {
  featureId: string;
  content: string;
  documentCount: number;
  type: string;
  topic: {
    id: string | null;
    name: string;
  } | null;
};
type State = {
  focusedFeature: Feature | null;
  topics: {
    id: string;
    name: string;
    description: string | null;
    features: Feature[];
  }[];
  features: Feature[];
  history: State[];
  pop: () => void;
};

const actions = (set: StateSetter<State>, get: StateGetter<State>) => ({
  assign: (topicId: string, featureId: string) => {
    const { topics, features } = get();

    const newFeatures = features.map((feature) =>
      feature.featureId === featureId
        ? {
            ...feature,
            topic: {
              id: topicId,
              name: topics.find((t) => t.id === topicId)!.name,
            },
          }
        : feature
    );

    const newTopics = topics.map((topic) => {
      if (topic.id === topicId) {
        return {
          ...topic,
          features: [
            ...topic.features,
            newFeatures.find((f) => f.featureId === featureId)!,
          ],
        };
      } else {
        return {
          ...topic,
          features: topic.features.filter((f) => f.featureId !== featureId),
        };
      }
    });

    set(() => ({ topics: newTopics, features: newFeatures }));
  },
  remove: (topicId: string, featureId: string) => {
    const { topics, features } = get();

    const newTopics = topics.map((topic) =>
      topic.id === topicId
        ? {
            ...topic,
            features: topic.features.filter((f) => f.featureId !== featureId),
          }
        : topic
    );

    const newFeatures = uniqBy(
      features.map((feature) =>
        feature.featureId === featureId
          ? {
              ...feature,
              topic: null,
            }
          : feature
      ),
      'featureId'
    );

    set(() => ({ topics: newTopics, features: newFeatures }));
  },
  setFocusedFeature: (feature: Feature | null) => {
    set(() => ({ focusedFeature: feature }));
  },
  addTopic: (name: string, description: string | null) => {
    const topics = [...get().topics];
    const newTopic = {
      id: v4(),
      name,
      description,
      features: [],
    };
    topics.push(newTopic);
    set(() => ({ topics }));
  },
  updateTopic: (topicId: string, name: string, description: string | null) => {
    set((state) => ({
      topics: state.topics.map((topic) =>
        topic.id === topicId ? { ...topic, name, description } : topic
      ),
    }));
  },
});

type Actions = ReturnType<typeof actions>;

const { hook: useDrafts, provider: DraftProvider } = makeStore<
  State,
  Actions
>();

type AssignedFilter = 'assigned' | 'unassigned' | 'all';

export const TopicsInbox: React.FC = () => {
  const { entityName } = useParams() as { entityName: string };

  return (
    <Layout>
      <Suspense
        fallback={
          <Center>
            <Loading.Dots />
          </Center>
        }
      >
        <View key={entityName} />
      </Suspense>
    </Layout>
  );
};

const useVersions = (entityId: string) => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey((orgId) =>
    queryKeys.topics.versions({ orgId, entityId })
  );

  return useSuspenseQuery({
    queryFn: async () =>
      Assert.assertOk(
        await client.topics.versions({
          entityId,
        })
      ),
    queryKey,
  });
};

const useData = (props: {
  entityId: string;
  assigned?: string;
  versionId: string;
}) => {
  const client = useTenantedClient();
  const nlpFeaturesKey = useTenantedQueryKey([
    'nlp',
    'features',
    props.entityId,
    props.versionId,
  ]);

  return useSuspenseQueries({
    queries: [
      {
        queryFn: async () =>
          Assert.assertOk(
            await client.topics.detectedFeatures({
              entityId: props.entityId,
              versionId: props.versionId,
              search: {
                assigned:
                  props.assigned === 'all' || props.assigned === undefined
                    ? undefined
                    : props.assigned === 'assigned',
              },
            })
          ),
        queryKey: nlpFeaturesKey,
      },
    ],
  });
};

const withTopicsSync =
  (client: TenantedClient, versionId: string) =>
  (set: StateSetter<State>, get: () => State): StateSetter<State> => {
    return (data: (s: State) => Partial<State>) => {
      const nextState: State = {
        ...get(),
        ...data(get()),
      };
      const { topics } = nextState;
      void client.topics
        .save({
          versionId,
          topics: topics.map((topic) => ({
            id: topic.id,
            name: topic.name,
            description: topic.description,
            features: topic.features.map((feature) => feature.featureId),
          })),
        })
        .catch((e) => {
          toast.error(`Failed to persist changes: ${e.message}`);
          set(() => get());
        });
      set(() => nextState);
    };
  };

const View: React.FC = () => {
  const [showAuditSelector, setShowAuditSelector] = useState(false);
  const { entityName, version: versionName } = useParams() as {
    entityName: string;
    version: string;
  };
  const client = useTenantedClient();
  const [form, setForm] = useState<FormState>({
    search: '',
    assigned: 'unassigned',
  });
  const entity = useEntity({ entityName });
  const { data: topicVersions } = useVersions(entity.uuid);
  const version = topicVersions.find((x) => x.version === versionName);

  Assert.assert(version !== undefined, 'Version not found');

  const [{ data: nlpFeatures }] = useData({
    entityId: entity.uuid,
    assigned: 'all',
    versionId: version.id,
  });

  const { data: topics } = useTopics({
    entityId: entity.uuid,
    versionId: version.id,
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <DraftProvider
        key={version.id}
        state={{
          pop: () => {},
          history: [],
          topics: topics.map((topic) => ({
            id: topic.id,
            name: topic.name,
            description: topic.description,
            features: nlpFeatures.features.filter(
              (feature) => feature.topic?.id === topic.id
            ),
          })),
          features: nlpFeatures.features,
          focusedFeature: null,
        }}
        actions={(set, get) => {
          const enhancedSet = applyMiddleware(
            [withHistory, withTopicsSync(client, version.id)],
            set,
            get
          );
          return actions(enhancedSet, get);
        }}
      >
        <CrtlZ />
        <Modal
          open={showAuditSelector}
          onOpenChange={() => {
            setShowAuditSelector(false);
          }}
        >
          <Title title="Select definition" type="section" className="mb-4" />
          <Divider className="mb-6" />
          <RelationPicker
            definitions={entity.definitions}
            wrapper={({ children, id }) => (
              <Link to={`/explore/data/${id}?topicVersionId=${version.id}`}>
                {children}
              </Link>
            )}
          />
        </Modal>
        <Section direction="vertical" className="w-full" top={false}>
          <div className="flex w-full items-center justify-between  mb-4">
            <div className="flex w-full items-center justify-between">
              <Title
                title={`Manage Topics`}
                type="title"
                className="mr-2"
                subtitle={version.id}
              />
              <div className="flex items-center">
                <Button
                  text="Audit"
                  icon="clipboard-document-list"
                  theme="regular"
                  small
                  className="mr-2"
                  onClick={() => {
                    setShowAuditSelector(true);
                  }}
                />
                {version.published ? (
                  <Badge theme="primary" className="mr-2">
                    Published
                  </Badge>
                ) : (
                  <PromoteButton
                    small
                    versionId={version.id}
                    entityId={entity.uuid}
                  />
                )}
                <AddNewVersion
                  numVersions={topicVersions.length}
                  entityName={entity.name}
                  entityId={entity.uuid}
                  sourceVersionId={version.id}
                />
              </div>
            </div>
          </div>
          <Divider className="mb-4" />
          <div className="flex w-full h-[calc(100%-54px)]">
            <div className="flex flex-col w-2/3 h-full">
              <Form value={form} onChange={setForm} />
              <Suspense fallback={<Loading.Dots />}>
                <Results query={form} />
              </Suspense>
            </div>
            <div className="flex flex-col w-1/2 ml-4">
              <Topics key={version.id} />
            </div>
          </div>
        </Section>
      </DraftProvider>
    </DndProvider>
  );
};

const CrtlZ = () => {
  const popHistory = useDrafts((s) => s.pop);

  useKeyPress('z', (e) => {
    if (e.ctrlKey || e.metaKey) {
      popHistory();
    }
  });

  return null;
};

const useTopics = (props: { entityId: string; versionId: string }) => {
  const client = useTenantedClient();
  const queryKey = useTenantedQueryKey([
    'topics',
    'list',
    props.entityId,
    props.versionId,
  ]);

  return useSuspenseQuery({
    queryFn: async () =>
      Assert.assertOk(
        await client.topics.topics({
          versionId: props.versionId,
        })
      ),
    queryKey,
  });
};

const useFilteredTopics = (
  focusedFeature: string | null,
  searchValue: string
) => {
  const topics = useDrafts((s) => s.topics);
  const topicsForFeature = useFuzzySearch(topics, ['name', 'description'], {
    threshold: 0.9,
  });
  const search = useFuzzySearch(topicsForFeature(focusedFeature ?? ''), [
    'name',
    'description',
  ]);

  return search(searchValue);
};

const AddTopicModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const addTopic = useDrafts((s) => s.actions.addTopic);

  return (
    <Modal
      priority="medium"
      open={open}
      onOpenChange={(v) => {
        if (!v) {
          onClose();
        }
      }}
      contentClassName="w-1/3"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addTopic(name, description);
          onClose();
        }}
      >
        <Title title="Add Topic" type="section" className="mb-3" />
        <Divider className="mb-4" />
        <Inputs.Text
          className="mb-2"
          label="Name"
          value={name}
          onChange={setName}
        />
        <Inputs.Text
          className="mb-4"
          label="Description"
          value={description}
          onChange={setDescription}
        />
        <div className="flex justify-end">
          <Button theme="primary" text="Add" type="submit" icon="save" />
        </div>
      </form>
    </Modal>
  );
};

const Topics: React.FC = () => {
  const focusedFeature = useDrafts((s) => s.focusedFeature);
  const [searchValue, setSearchValue] = useState('');
  const [addTopic, setAddTopic] = useState(false);

  const topics = useFilteredTopics(
    focusedFeature?.content ?? null,
    searchValue
  );
  const features = useDrafts((s) => s.features);

  const sortedTopics = topics
    .map((topic) => ({
      category: topic.name,
      value: topic.features.reduce((acc, x) => acc + x.documentCount, 0),
      style: 'random' as const,
    }))
    .sort((a, b) => b.value - a.value);

  const other = sortedTopics.slice(5);
  const unassined = {
    category: 'Unassigned',
    value: features
      .filter((x) => x.topic === null)
      .reduce((acc, x) => acc + x.documentCount, 0),
    style: 'warning' as const,
  };
  const chartData = [
    ...sortedTopics.slice(0, 5),
    {
      category: 'Other',
      value: other.reduce((acc, x) => acc + x.value, 0),
      style: 'random' as const,
    },
    unassined,
  ];

  return (
    <div className="flex flex-col h-full">
      <Card.Container className="h-[60%]">
        <Card.Content className="h-full">
          <div className="flex items-center justify-between mb-4">
            <Searchable
              className="w-full"
              value={searchValue}
              onChange={setSearchValue}
            >
              <Title title="Topics" type="section" />
            </Searchable>
            <div className="flex items-center">
              <Text.Caption className="mr-2 text-nowrap">
                {topics.length} Results
              </Text.Caption>
              <Button
                icon="plus"
                text="Add Topic"
                iconOnly
                tooltip="left"
                onClick={() => {
                  setAddTopic(true);
                }}
              />
            </div>
          </div>
          <Suspense fallback={<Loading.Dots />}>
            <ErrorBoundary
              fallbackRender={({ error }) => <DisplayError error={error} />}
            >
              <ul
                className="overflow-scroll h-[calc(100%-35px)]"
                key={focusedFeature?.featureId}
              >
                {topics.map((topic) => {
                  return <TopicItem key={topic.id} topic={topic} />;
                })}
              </ul>
            </ErrorBoundary>
          </Suspense>
        </Card.Content>
      </Card.Container>
      <Card.Container className="h-[38%] overflow-scroll">
        <Card.Content className="h-full mb-4">
          <Title type="section">Occurences per Topic</Title>
          <BarChart
            axis={{
              x: {},
              y: {},
            }}
            direction="horizontal"
            data={chartData.map((x) => ({
              ...x,
              y: x.value,
              x: x.category,
            }))}
            loading={false}
            onLegendClick={() => {}}
          />
        </Card.Content>
      </Card.Container>
      <AddTopicModal open={addTopic} onClose={() => setAddTopic(false)} />
    </div>
  );
};

const TopicItem: React.FC<{
  topic: {
    id: string;
    name: string;
    description: string | null;
    features: Feature[];
  };
}> = ({ topic }) => {
  const assignFeature = useDrafts((s) => s.actions.assign);
  const update = useDrafts((s) => s.actions.updateTopic);
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.ITEM,
      drop: (item: { featureId: string }) => {
        assignFeature(topic.id, item.featureId);
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    []
  );

  return (
    <li
      ref={drop}
      className={classNames(
        'border-dashed border  rounded py-3 px-3 mb-2 flex justify-between transition-all duration-150',
        isOver ? 'h-[180px] border-indigo-400' : 'border-indigo-200'
      )}
    >
      <div className="flex flex-col w-full">
        <Editable
          value={topic.name}
          onChange={(v) => {
            update(topic.id, v, topic.description);
          }}
        >
          {(value) => <Title type="label">{value}</Title>}
        </Editable>
        {topic.description && <Text.Caption>{topic.description}</Text.Caption>}
        <div className="mt-2 flex items-center flex-wrap">
          {topic.features.map((feature) => {
            return (
              <TopicFeatureItem key={feature.featureId} feature={feature} />
            );
          })}
        </div>
        {isOver && (
          <div className="w-full flex-grow flex items-center justify-center">
            <Text.Caption>Drop here to assign</Text.Caption>
          </div>
        )}
      </div>
    </li>
  );
};

const TopicFeatureItem: React.FC<{
  feature: Feature;
}> = ({ feature }) => {
  const removeFromTopic = useDrafts((s) => s.actions.remove);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.ITEM,
    item: feature,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      className={classNames(
        isDragging ? 'opacity-50' : '',
        'flex items-center mr-2 mb-2'
      )}
    >
      <Badge theme="regular" className="text-nowrap">
        <Button
          noPadding
          ref={drag}
          inline
          icon="drag-handle"
          className="mr-2"
        />
        {feature.content}
        <Button
          inline
          icon="x-mark"
          onClick={() => removeFromTopic(feature.topic!.id!, feature.featureId)}
        />
      </Badge>
    </div>
  );
};

type FormState = {
  search: string;
  assigned: AssignedFilter;
};

const Form: React.FC<{
  value: FormState;
  onChange?: (value: FormState) => void;
}> = ({ onChange, value }) => {
  const setFormValue = useCallback(
    (newValue: Partial<FormState>) => {
      onChange?.({
        ...value,
        ...newValue,
      });
    },
    [onChange, value]
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="flex w-full mb-4"
    >
      <Inputs.Text
        className="flex-grow mr-2"
        icon="search"
        value={value.search}
        onChange={(v) => setFormValue({ search: v })}
      />
      <Inputs.Toggle
        nullable={false}
        className="mr-2 flex-shrink-0"
        options={['all', 'assigned', 'unassigned']}
        value={value.assigned}
        onChange={(v) => setFormValue({ assigned: v })}
      />
    </form>
  );
};

const Results: React.FC<{
  query?: FormState;
  assigned?: AssignedFilter;
}> = ({ query }) => {
  const features = useDrafts((s) => s.features);
  const data = useDrafts((s) => s.features);
  const search = useFuzzySearch(data, ['content', 'type']);
  const searchedData = search(query?.search ?? null);
  const setFocusedFeature = useDrafts((s) => s.actions.setFocusedFeature);
  const focusedFeature = useDrafts((s) => s.focusedFeature);

  const filteredData = filter(searchedData, [
    query?.assigned === 'assigned' &&
      ((items) => items.filter((item) => !!item.topic)),
    query?.assigned === 'unassigned' &&
      ((items) => items.filter((item) => item.topic === null)),
  ]);

  const numAssigned = features.filter((x) => x.topic !== null).length;

  return (
    <>
      <Card.Container className="h-[calc(100%-110px)]">
        <div className="flex items-center w-full justify-between px-4 pt-4 pb-3">
          <Title type="section" className="w-fit">
            Detected Features
          </Title>
          <Text.Caption>{filteredData.length} Results</Text.Caption>
        </div>
        {filteredData.length > 0 && (
          <List.Ul
            className="pl-2 pr-4 py-2 overflow-scroll h-[calc(100%-45px)]"
            hasFocus={true}
            childType={Item}
          >
            {filteredData.map((item) => {
              const isSelected = focusedFeature?.featureId === item.featureId;
              return (
                <Item
                  onClick={() => {
                    if (!isSelected) {
                      setFocusedFeature(item);
                    } else {
                      setFocusedFeature(null);
                    }
                  }}
                  key={item.featureId}
                  item={item}
                  selected={isSelected}
                />
              );
            })}
          </List.Ul>
        )}
        {filteredData.length === 0 && (
          <Card.Content className="h-full flex items-center justify-center">
            <Empty
              type="list"
              title="No detected features"
              caption="Try adjusting your search query or filters"
            />
          </Card.Content>
        )}
      </Card.Container>
      <ProgressBar
        className="h-10 w-full"
        text="Assigned"
        percent={(numAssigned / features.length) * 100}
      />
    </>
  );
};

const ItemTypes = {
  ITEM: 'item',
  TOPIC: 'topic',
};

const Item: React.FC<
  {
    onClick: () => void;
    selected: boolean;
    item: {
      featureId: string;
      content: string;
      documentCount: number;
      type: string;
      topic: {
        name: string;
      } | null;
    };
  } & FocusableComponentProps
> = ({ item, selected, onClick, ...focusProps }) => {
  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.ITEM,
    item,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <List.Li
      {...focusProps}
      as="li"
      onClick={onClick}
      className={classNames(
        selected ? '!border-indigo-300' : '',
        'data-[focus]:border-indigo-200 w-full justify-between transition-colors rounded px-2 py-2 flex border boder-divder mb-2 items-center',
        isDragging ? 'opacity-50' : ''
      )}
    >
      <Button ref={drag} icon="drag-handle" inline className="mr-2" />
      <div
        className="flex flex-grow flex-col items-start relative"
        ref={preview}
      >
        <Text.Caption className="relative">
          <Copyable offset={{ right: '-right-10', top: '-top-3' }}>
            {item.featureId}
          </Copyable>
        </Text.Caption>
        <p className="mt-1 line-clamp-2 text-sm leading-6 text-standard-text overflow-ellipsis">
          {item.content}
        </p>
      </div>
      <div className="flex items-center mb-1 justify-between">
        <div className="flex">
          <Badge theme={item.topic ? 'secondary' : 'warning'} className="mr-2">
            {item.topic ? item.topic.name : 'Unassigned'}
          </Badge>
          <Badge theme="primary" className="mr-2">
            {item.type}
          </Badge>
          <Badge theme="regular" className="mr-2">
            {item.documentCount} {pluralize(item.documentCount, 'message')}
          </Badge>
        </div>
      </div>
    </List.Li>
  );
};
