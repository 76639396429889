import { AST } from '../ast';
import { Assert } from '../utils';

export type TraversalOrder = 'pre' | 'post';

export function* relsInMu<T>(
  ast: AST.Mu,
  cb: (rel: AST.Rel) => T,
  opts: { order: TraversalOrder }
): Generator<T> {
  const { t } = ast;

  switch (t) {
    case 'noop':
    case 'image':
    case 'text':
    case 'divider':
    case 'header':
    case 'width':
    case 'markup-var':
      return;
    case 'chart':
    case 'insights': {
      yield* relsInRel(ast.rel, cb, opts);
      break;
    }
    case 'sections':
    case 'block': {
      for (const section of ast.sections) {
        yield* relsInMu(section, cb, opts);
      }
      break;
    }
    case 'tabs': {
      for (const { section } of ast.tabs) {
        yield* relsInMu(section, cb, opts);
      }
      break;
    }
    case 'for-each': {
      yield* relsInRel(ast.rel, cb, opts);
      yield* relsInMu(ast.body.macro, cb, opts);
      break;
    }
    case 'stats': {
      for (const stat of ast.stats) {
        yield* relsInRel(stat.rel, cb, opts);
      }
      break;
    }
    case 'callout': {
      for (const rel of ast.rels) {
        yield* relsInRel(rel, cb, opts);
      }
      break;
    }
    case 'expr-controls-v2': {
      const config = ast.config;
      if (config !== null) {
        switch (config.t) {
          case 'picklist':
            break;
          case 'pickfrom':
            yield* relsInRel(config.rel, cb, opts);
            break;
          default:
            return Assert.unreachable(config);
        }
      }
      break;
    }
    case 'page': {
      yield* relsInMu(ast.body.macro, cb, opts);
      break;
    }
    case 'ui-state': {
      yield* relsInMu(ast.body.macro, cb, opts);
      for (const rel of Object.values(ast.defaults.rels)) {
        yield* relsInRel(rel, cb, opts);
      }
      break;
    }
    case 'rel-controls-v2': {
      yield* relsInRel(ast.var, cb, opts);
      break;
    }
    case 'macro-section-case': {
      for (const { then } of ast.cases) {
        yield* relsInMu(then, cb, opts);
      }
      yield* relsInMu(ast.else, cb, opts);
      break;
    }
    case 'macro-apply-vars-to-markup': {
      yield* relsInMu(ast.section, cb, opts);

      for (const mu of Object.values(ast.vars.sections)) {
        yield* relsInMu(mu, cb, opts);
      }

      for (const rel of Object.values(ast.vars.rels)) {
        yield* relsInRel(rel, cb, opts);
      }
      break;
    }
    default:
      return Assert.unreachable(t);
  }
}

export function* relIRsInRelIR<T>(
  ast: AST.RelIR,
  cb: (rel: AST.RelIR) => T
): Generator<T> {
  yield cb(ast);

  const { t } = ast;

  switch (t) {
    case 'table':
    case 'values':
    case 'file':
    case 'information-schema':
    case 'generate-series':
      break;
    case 'aggregate':
    case 'select': {
      yield* relIRsInRelIR(ast.sources.from, cb);
      break;
    }
    case 'join':
    case 'union':
      yield* relIRsInRelIR(ast.sources.left, cb);
      yield* relIRsInRelIR(ast.sources.right, cb);
      break;
  }
}

export function* relsInRel<T>(
  ast: AST.Rel,
  cb: (rel: AST.Rel) => T,
  opts: { order: TraversalOrder }
): Generator<T> {
  const { t } = ast;

  const l2rChildren: AST.Rel[] = [];

  switch (t) {
    case 'values':
    case 'file':
    case 'information-schema':
    case 'generate-series':
    case 'table':
      break;
    case 'aggregate':
    case 'select':
      l2rChildren.push(ast.sources.from);
      break;
    case 'join':
    case 'union':
      l2rChildren.push(ast.sources.left, ast.sources.right);
      break;
    case 'rel-var':
      if (ast.default) {
        l2rChildren.push(ast.default);
      }
      break;
    case 'macro-rel-case': {
      for (const { then } of ast.cases) {
        l2rChildren.push(then);
      }
      l2rChildren.push(ast.else);
      break;
    }
    case 'macro-apply-vars-to-rel': {
      l2rChildren.push(ast.sources.from);
      for (const rel of Object.values(ast.vars.rels)) {
        l2rChildren.push(rel);
      }
      break;
    }
    default:
      return Assert.unreachable(t);
  }

  switch (opts.order) {
    case 'pre': {
      yield cb(ast);
      for (const child of l2rChildren) {
        yield* relsInRel(child, cb, opts);
      }
      break;
    }
    case 'post': {
      for (const child of l2rChildren) {
        yield* relsInRel(child, cb, opts);
      }
      yield cb(ast);
      break;
    }
    default:
      return Assert.unreachable(opts.order);
  }
}
