import { TC, TyStackTrace } from '../../type-checker';
import _ from 'lodash';
import { err } from 'neverthrow';
import { Expression } from '../../builder';
import { Ty } from '../../ty';
import { ParseError, ParseResult } from '../ast';
import { lex } from '../lex';
import { parseExpr } from './parse-expr';
import {
  AggregateFunctionInNonAggregatedContext,
  TypeDoesNotMatchExpectation,
} from '../../type-checker/type-check-error';

export const compileExprSql = (x: {
  sql: string;
  attributes: Record<string, Expression>;
  allowAggregate: boolean;
  typeImplsOneOf?: readonly Ty.Shorthand[];
}): ParseResult => {
  const { attributes, allowAggregate, sql, typeImplsOneOf } = x;

  const lexed = lex(sql);
  const parsed = parseExpr(lexed, { attributes });

  if (parsed.isOk()) {
    const subject = parsed.value.ty;

    const isCorrectTy =
      typeImplsOneOf === undefined ||
      typeImplsOneOf.some((req) => TC.implementsTy({ subject, req }));

    const isIncorrectlyAggregated =
      !allowAggregate && parsed.value.isAggregated;

    if (!isCorrectTy) {
      const e: ParseError = {
        t: 'type-check-error',
        trace: TyStackTrace.fromErr(
          {},
          new TypeDoesNotMatchExpectation({
            found: parsed.value.ty,
            expected: typeImplsOneOf,
          })
        ),
      };
      return err([e, { range: [0, sql.length - 1] }]);
    } else if (isIncorrectlyAggregated) {
      const e: ParseError = {
        t: 'type-check-error',
        trace: TyStackTrace.fromErr(
          {},
          new AggregateFunctionInNonAggregatedContext()
        ),
      };

      return err([e, { range: [0, sql.length - 1] }]);
    }
  }

  return parsed;
};
