import { classNames } from '@cotera/client/app/components/utils';
const THEME = {
  normal: {
    container: 'bg-gray-200',
    inner: 'bg-indigo-400',
    text: 'text-alt-text',
  },
  inverse: {
    container: 'bg-white',
    inner: 'bg-secondary-background',
    text: 'text-standard-text',
  },
};

export const ProgressBar = ({
  percent,
  text,
  className,
  theme = 'normal',
  pulse = false,
}: {
  percent: number;
  text?: string;
  className?: string;
  theme?: keyof typeof THEME;
  pulse?: boolean;
}) => {
  const colorScheme = THEME[theme];
  return (
    <div
      className={classNames(
        'rounded overflow-hidden relative',
        className,
        colorScheme.container
      )}
    >
      <div
        className={classNames(
          'h-full transition-[width] duration-300',
          colorScheme.inner,
          pulse ? 'animate-pulse' : ''
        )}
        style={{ width: `${percent}%` }}
      ></div>
      <span
        className={classNames(
          'absolute text-xs top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-none text-nowrap break-keep whitespace-nowrap',
          colorScheme.text
        )}
      >
        {text}
      </span>
    </div>
  );
};
