import {
  EraHash,
  Asc,
  From,
  InformationSchema,
  Relation,
  AST,
  Driver,
} from '@cotera/era';
import { z } from 'zod';
import type { Schedule } from '@cotera/api';
import { Assert } from '@cotera/utilities';

const MAT_PREFIX = '_mat';

export type Materialization = {
  readonly viewName: string;
  readonly schedule: Schedule;
  readonly source: AST.Rel<'full'>;
};

export class MaterializationConfig {
  readonly underlyingRel: Relation;
  readonly view: Relation;
  readonly config: Omit<Materialization, 'schedule'>;

  static fromConf(conf: {
    viewName: string;
    source: AST.Rel | Relation;
  }): MaterializationConfig {
    return new MaterializationConfig(conf);
  }

  get source(): Relation {
    return Relation.wrap(this.config.source);
  }

  constructor(conf: { viewName: string; source: AST.Rel | Relation }) {
    const rel = Relation.wrap(conf.source);

    this.underlyingRel = rel;
    this.config = { ...conf, source: rel.ast };
    this.view = From({
      schema: '@@write-schema',
      name: conf.viewName,
      attributes: rel.attributes,
    });
  }

  async update(
    driver: Driver.EraDriver,
    opts: { now?: Date; cleanUnused?: boolean; statementTimeoutMs: number }
  ): Promise<{ underlying: AST.TableDescription }> {
    const underlying = await driver.createTableFrom(
      this.#sourceTableNameForTimestamp(opts?.now ?? new Date()),
      this.config.source
    );

    const replaceRes = await driver.createOrReplaceView(
      this.config.viewName,
      From(underlying)
    );

    Assert.assert(replaceRes.isOk());

    if (opts?.cleanUnused) {
      const existing = await this.existingMaterializations(driver);
      const unused = existing.filter((t) => t.table_name !== underlying.name);

      for (const { table_name } of unused) {
        await driver.dropTable({
          name: table_name,
          schema: driver.writeSchema,
        });
      }
    }

    return { underlying };
  }

  async existingMaterializations(
    driver: any
  ): Promise<{ table_name: string }[]> {
    const existing = await InformationSchema({
      schemas: [driver.writeSchema],
      type: 'tables',
    })
      .where((t) => t.attr('table_name').like(`${this.#sourcePrefix()}_%`))
      .orderBy((t) => Asc(t.attr(`table_name`)))
      .execute(driver);

    return z.object({ table_name: z.string() }).array().parse(existing);
  }

  #sourceTableNameForTimestamp(ts: Date): string {
    return `${this.#sourcePrefix()}_${toUnixTimestampSecs(ts)}`;
  }

  #sourcePrefix(): string {
    return `${MAT_PREFIX}_${this.config.viewName}_${this.#sourceHash()}`;
  }

  #sourceHash(): string {
    return EraHash.hashRel(this.config.source).slice(0, 12);
  }
}

const toUnixTimestampSecs = (t: Date) => Math.floor(t.getTime() / 1000);
