import {
  Contract,
  GET,
  LIST,
  POST,
  defaultErrors,
  NotFoundErrorSchema,
} from '@cotera/contracts';
import { z } from 'zod';
import { UserSchema } from '../user';
import { TenantedRoleSchema } from '../../ability';

const SuperAdminUserSchema = UserSchema.merge(
  z.object({
    tenantedRoles: z.array(
      z.object({
        orgId: z.string(),
        org: z.object({
          name: z.string(),
        }),
        role: TenantedRoleSchema,
      })
    ),
  })
);

export type SuperAdminUserDef = z.infer<typeof SuperAdminUserSchema>;

export const SuperAdminUsersContract = Contract.new({
  getByEmail: GET({
    params: z.string().email(),
    output: SuperAdminUserSchema,
    errors: NotFoundErrorSchema,
  }),
  getByEmailDomain: LIST({
    params: z.object({
      domain: z.string(),
    }),
    output: z.array(SuperAdminUserSchema),
  }),
  all: LIST({
    params: z.object({}),
    output: z.array(SuperAdminUserSchema),
  }),
  addToOrg: POST({
    params: z.object({
      userId: z.string(),
      orgId: z.string(),
      role: TenantedRoleSchema,
    }),
    output: z.object({}),
    errors: defaultErrors(['UserNotFound', 'OrgNotFound']),
  }),
  create: POST({
    params: z.object({
      name: z.string().optional(),
      email: z.string().email(),
      tenantedRoles: z
        .array(
          z.object({
            orgId: z.string(),
            role: TenantedRoleSchema,
          })
        )
        .optional(),
    }),
    output: SuperAdminUserSchema,
    errors: defaultErrors(['InvalidEmail', 'OrgNotFound']),
  }),
});
