import { z } from 'zod';
import { llmMessageSchema, toolsSchema } from '../llm';
import { Parser } from '@cotera/era';

// This file is super slow to typecheck, so we special case it to JS to avoid
// editor slow downs

export const SinkConfigSchema = z.discriminatedUnion('t', [
  z.object({ t: z.literal('writeToLog'), config: z.object({}) }),
  z.object({
    t: z.literal('katalystSuitSizing'),
    config: z.object({
      GEN: z.enum(['v3', 'v4']),
      SHEET_ID: z.string(),
      WRITE_TO_SHEET_RANGE: z.string(),
    }),
  }),
  z.object({ t: z.literal('embeddings'), config: z.object({}) }),
  z.object({
    t: z.literal('topicEmbeddings'),
    config: z.object({
      summaryPromptId: z.string(),
      topicExtractionPromptId: z.string(),
      warehouseConnectionId: z.string().optional(),
    }),
  }),
  z.object({
    t: z.literal('topicIntentMatching'),
    config: z.object({
      summaryPromptId: z.string(),
      topicExtractionPromptId: z.string(),
      warehouseConnectionId: z.string().optional(),
      intentSignalId: z.string(),
    }),
  }),
  z.object({
    t: z.literal('topicKeywordMatching'),
    config: z.object({
      summaryPromptId: z.string().uuid(),
      topicExtractionPromptId: z.string().uuid(),
      warehouseConnectionId: z.string().uuid().optional(),
    }),
  }),
  z.object({
    t: z.literal('topicMatching'),
    config: z.object({
      summaryPromptId: z.string().uuid(),
      topicExtractionPromptId: z.string().uuid(),
      warehouseConnectionId: z.string().uuid().optional(),
    }),
  }),
  z.object({
    t: z.literal('nlpFeatureExtraction'),
    config: z.object({
      summaryPromptId: z.string().uuid(),
      topicExtractionPromptId: z.string().uuid(),
      warehouseConnectionId: z.string().uuid().optional(),
    }),
  }),
  z.object({
    t: z.literal('hubspotEvent'),
    config: z.object({
      hubspotConnectionId: z.string(),
      eventName: z.string(),
    }),
  }),
  z.object({
    t: z.literal('hubspotUpdateUserProperties'),
    config: z.object({
      hubspotConnectionId: z.string(),
    }),
  }),
  z.object({
    t: z.literal('klaviyoUpdateUsers'),
    config: z.object({}),
  }),
  z.object({
    t: z.literal('klaviyoSendEvent'),
    config: z.object({ event: z.string() }),
  }),
  z.object({
    t: z.literal('iterableUpdateUser'),
    config: z.object({
      iterableConnectionId: z.string(),
    }),
  }),
  z.object({
    t: z.literal('iterableTrackEvent'),
    config: z.object({
      iterableConnectionId: z.string(),
      eventName: z.string(),
    }),
  }),
  z.object({
    t: z.literal('postscriptUpdateUser'),
    config: z.object({
      postscriptConnectionId: z.string(),
    }),
  }),
  z.object({
    t: z.literal('brazeTrackUser'),
    config: z.object({
      brazeConnectionId: z.string(),
      endpoint: z.string().url(),
    }),
  }),
  z.object({
    t: z.literal('zendeskUpdateTickets'),
    config: z.object({
      zendeskConnectionId: z.string().optional(),
      endpoint: z.string().url(),
      customFields: z.record(z.number()),
    }),
  }),
  z.object({
    t: z.literal('kustomerCreateObjects'),
    config: z.object({
      kustomerConnectionId: z.string().optional(),
      name: z.string(),
      fieldTypes: z.record(z.string(), z.enum(['txt', 'str'])),
    }),
  }),
  z.object({
    t: z.literal('sendGridUpdateContact'),
    config: z.object({
      sendGridConnectionId: z.string(),
    }),
  }),
  z.object({
    t: z.literal('oneSignalUpdateUser'),
    config: z.object({
      oneSignalConnectionId: z.string(),
      idType: z.enum(['onesignal_id', 'external_id']),
    }),
  }),
  z.object({
    t: z.literal('attentiveUpdateUser'),
    config: z.object({}),
  }),
  z.object({
    t: z.literal('llmCompletion'),
    config: z.object({
      model: z.string(),
      column: z.string(),
      messages: llmMessageSchema.array(),
      expectedType: Parser.ExtendedAttributeTypeSchema,
    }),
  }),
  z.object({
    t: z.literal('llmToolUse'),
    config: z.object({
      model: z.string(),
      column: z.string(),
      messages: llmMessageSchema.array(),
      tools: toolsSchema,
      expectedType: Parser.ExtendedAttributeTypeSchema,
    }),
  }),
  z.object({
    t: z.literal('embeddingTag'),
    config: z.object({
      taxonomyConfigId: z.string(),
    }),
  }),
  z.object({
    t: z.literal('coteraSlack'),
    config: z.object({
      channel: z.string(),
    }),
  }),
  z.object({
    t: z.literal('shopifyUpdateUser'),
    config: z.object({
      shopifyUrl: z.string(),
      shopifyConnectionId: z.string().optional(),
    }),
  }),
  z.object({
    t: z.literal('trueNutritionUpdateUser'),
    config: z.object({
      shopifyUrl: z.string(),
      shopifyConnectionId: z.string().optional(),
      klaviyoConnectionId: z.string().optional(),
    }),
  }),
]);
