export const ColorScheme = {
  text: {
    primary: 'text-indigo-400',
    secondary: 'text-emerald-400',
    muted: 'text-zinc-500',
    regular: 'text-zinc-700',
    error: 'text-rose-500',
    warning: 'text-orange-500',
  },
  background: {
    primary: 'bg-indigo-300',
    secondary: 'bg-emerald-300',
    regular: 'bg-zinc-500',
    muted: 'bg-zinc-300',
    error: 'bg-rose-300',
    warning: 'bg-orange-300',
  },
  border: {
    primary: 'border-indigo-500',
    secondary: 'border-emerald-500',
    regular: 'border-zinc-500',
    muted: 'border-zinc-500',
    error: 'border-rose-500',
    warning: 'border-orange-500',
  },
};
