import { AST, Markup, Relation, TC } from '@cotera/era';
import _ from 'lodash';
import {
  EditableSqlExpression,
  useMultiSqlExpressionBuilder,
} from './use-multi-sql-expression-builder';
import { useEffect } from 'react';
import { Assert } from '@cotera/utilities';

export const SUPPORTED_CHARTS = [
  'bar-chart',
  'line-chart',
  'pie-chart',
  'sankey-chart',
  'summary-chart',
] as const;

export type SupportedChart = (typeof SUPPORTED_CHARTS)[number];

export const useChartBuilder = (x: {
  rel: Relation;
  chartType: SupportedChart;
}): { drafts: readonly EditableSqlExpression[]; chart: AST._Chart | null } => {
  const { rel, chartType } = x;
  const config = TC.CHART_REL_REQUIREMENTS[chartType];
  const { drafts, reset, add } = useMultiSqlExpressionBuilder({
    attributes: rel,
    allowAggregate: false,
    typeImplsOneOf: ({ name }) => {
      const allowed = config[name]?.allowed;
      return allowed === undefined || allowed === 'any' ? [] : allowed;
    },
  });

  useEffect(
    () => {
      reset();
      for (const name of Object.keys(config)) {
        add({ name });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config]
  );

  const charted: Record<SupportedChart, AST.Chart._ChartConfig> = {
    'line-chart': {
      t: 'line-chart',
      type: 'normal',
      axis: {
        x: { label: null, scale: null },
        y: { label: null, scale: null },
      },
    },
    'pie-chart': {
      t: 'pie-chart',
      labels: true,
    },
    'bar-chart': {
      t: 'bar-chart',
      axis: {
        x: { label: null, scale: null },
        y: { label: null, scale: null },
      },
      trendline: false,
      type: 'stacked',
      direction: 'vertical',
    },
    'sankey-chart': {
      t: 'sankey-chart',
    },
    'summary-chart': {
      t: 'summary-chart',
    },
  };
  const isValid: boolean = Object.entries(config).every(([name]) =>
    drafts.find((draft) => draft.name === name)?.parse.isOk()
  );

  if (isValid) {
    const selected = rel.select((_t) =>
      Object.fromEntries(
        drafts.map((draft) => [draft.name, draft.parse._unsafeUnwrap()])
      )
    ).ast;
    const c: AST._Chart = {
      t: 'chart',
      rel: selected,
      title: null,
      size: 'lg',
      config: charted[chartType],
      format: {},
    };

    const { ast } = Markup.fromAst(c);
    Assert.assert(ast.t === 'chart');
    return { drafts, chart: ast };
  } else {
    return { drafts, chart: null };
  }
};
