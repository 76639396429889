import { ColorScheme } from '../../utils/colors/color-scheme';
import { Theme } from '../../types/utils';
import { UiComponent } from '../../types/ui-component';
import { classNames } from '@cotera/client/app/components/utils';
import { forwardRef } from 'react';

const SIZE_CLASS = {
  heading: 'text-4xl leading-10',
  title: 'text-xl  leading-6',
  label: 'text-sm leading-6',
  section: 'text-base',
} as const;

const SUB_SIZE_CLASS = {
  heading: 'text-xl opacity-70 ',
  title: 'text-sm opacity-70 mt-1',
  label: 'text-sm opacity-70 ',
  section: 'text-sm opacity-70 ',
} as const;

const PRE_SIZE_CLASS = {
  heading: 'text-base',
  title: 'text-sm',
  label: 'text-xs',
  section: 'text-xs',
} as const;

const THEME_CLASS: Record<Theme, string> = {
  regular: ColorScheme.text.regular,
  primary: ColorScheme.text.primary,
  secondary: ColorScheme.text.secondary,
  muted: ColorScheme.text.secondary,
  error: ColorScheme.text.error,
  warning: ColorScheme.text.warning,
} as const;

type Props = {
  title?: string;
  children?: React.ReactNode;
  pre?: string;
  subtitle?: string;
  type?: 'heading' | 'title' | 'label' | 'section';
  theme?: Theme;
};

export const Title = forwardRef<HTMLDivElement, UiComponent<Props>>(
  (
    {
      className,
      pre,
      subtitle: sub,
      title,
      children,
      type = 'label',
      theme = 'regular',
    },
    ref
  ) => {
    return (
      <div
        className={classNames('flex flex-col font-sans', className)}
        ref={ref}
      >
        {pre && (
          <span
            className={classNames(
              'mb-0.5',
              PRE_SIZE_CLASS[type],
              ColorScheme.text.muted
            )}
          >
            {pre}
          </span>
        )}
        <span
          className={classNames(
            'tracking-tight font-medium',
            THEME_CLASS[theme],
            SIZE_CLASS[type]
          )}
        >
          {title ?? children}
        </span>
        {sub && (
          <span
            className={classNames(
              SUB_SIZE_CLASS[type],
              ColorScheme.text.regular
            )}
          >
            {sub}
          </span>
        )}
      </div>
    );
  }
);
