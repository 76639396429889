import { Contract, defaultErrors, GET } from '@cotera/contracts';
import { z } from 'zod';
import { SuperAdminContract } from './super-admin';
import { DebugContract } from './debug';
import { WhoamiSchema } from './user';

export const UntenantedContract = Contract.new({
  superAdmin: SuperAdminContract,
  debug: DebugContract,
  whoami: GET({
    params: z.object({}),
    errors: defaultErrors(['Unauthorized']),
    output: WhoamiSchema,
  }),
});
