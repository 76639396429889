import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef } from 'react';

type VirtualizeData = {
  attribute: string;
};

export type VirtualizeProps = {
  height: number;
  component: React.FC<{
    data: any;
  }>;
  data: VirtualizeData[];
};

export const Virtualize: React.FC<VirtualizeProps> = ({
  height,
  data,
  component: Component,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => height, // px
    overscan: 1,
  });

  return (
    <div
      ref={parentRef}
      style={{
        height: `100%`,
        width: `100%`,
        overflow: 'auto',
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer
          .getVirtualItems()
          .map((virtualRow: { index: number; start: number; size: number }) => {
            const item = data[virtualRow.index]!;

            return (
              <div
                key={virtualRow.index}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
              >
                <Component key={item.attribute} data={item} />
              </div>
            );
          })}
      </div>
    </div>
  );
};
