import { Column } from '@tanstack/react-table';
import { CSSProperties } from 'react';

export const getCommonPinningStyles = (
  column: Pick<
    Column<unknown>,
    | 'getIsPinned'
    | 'getIsLastColumn'
    | 'getIsFirstColumn'
    | 'getStart'
    | 'getAfter'
    | 'getSize'
  >
): CSSProperties => {
  const isPinned = column.getIsPinned();

  const isLastLeftPinnedColumn =
    isPinned === 'left' && column.getIsLastColumn('left');
  const isFirstRightPinnedColumn =
    isPinned === 'right' && column.getIsFirstColumn('right');

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
      ? '4px 0 4px -4px gray inset'
      : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned !== false ? 0.95 : 1,
    position: isPinned !== false ? 'sticky' : 'relative',
    width: column.getSize(),
    zIndex: isPinned !== false ? 1 : 0,
  };
};

export const makeActionsColumn = (position: 'left' | 'right') => ({
  getIsPinned: () => position,
  getIsFirstColumn: () => position === 'left',
  getIsLastColumn: () => position === 'right',
  getStart: () => 0,
  getAfter: () => 0,
  getSize: () => 30,
});
