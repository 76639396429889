import { AST } from '@cotera/era';

export const SYSTEM_TABLES: Record<
  'STABLE_IDS' | 'ENTITY_EVENTS' | 'USER_DEFINED_DIMENSIONS',
  AST.TableDescription
> = {
  STABLE_IDS: {
    name: 'stable_ids',
    schema: '@@write-schema',
    attributes: {
      identifier: 'string',
      definition_id: 'string',
      cotera_stable_id: 'string',
    },
  },
  ENTITY_EVENTS: {
    name: 'entity_events',
    schema: '@@write-schema',
    attributes: {
      cotera_stable_id: 'string',
      event_stream_id: 'string',
      entity_id: 'string',
      value: { ty: 'string', nullable: true },
      detected_at_time: 'timestamp',
    },
  },
  USER_DEFINED_DIMENSIONS: {
    name: 'user_defined_dimensions',
    schema: '@@write-schema',
    attributes: {
      entity_id: 'string',
      identifier: 'string',
      key: 'string',
      value: { ty: 'super', nullable: true },
      timestamp: 'timestamp',
      actor_id: { ty: 'string', nullable: true },
    },
  },
} as const;

export const SPEC_TABLES: Record<
  'FOO_ENTITY' | 'SOME_INTS',
  AST.TableDescription
> = {
  FOO_ENTITY: {
    name: 'spec_table_foo_entity',
    schema: '@@write-schema',
    attributes: {
      id: 'string',
      bar: 'string',
    },
  },
  SOME_INTS: {
    name: 'spec_table_some_ints',
    schema: '@@write-schema',
    attributes: {
      a: { ty: 'int', nullable: true },
      b: { ty: 'int', nullable: true },
    },
  },
};
