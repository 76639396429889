import { AST } from '../ast';
import { TypeCheckError, TypeCheckErrorLocation } from './type-check-error';

export type Frame = {
  frame?: AST.Mu | AST.Rel | AST.Expr | null;
  location?: TypeCheckErrorLocation;
};

export type WeakFrame = {
  frame?: WeakRef<AST.Mu | AST.Rel | AST.Expr> | null;
  location?: TypeCheckErrorLocation;
};

const extraAstFrameInfo = (
  ast: AST.Mu | AST.Rel | AST.Expr
): [string, Object][] | null => {
  switch (ast.t) {
    case 'macro-apply-vars-to-rel':
      return [
        ['scope', ast.scope],
        ['rel-vars', Object.keys(ast.vars.rels)],
        ['expr-vars', Object.keys(ast.vars.exprs)],
      ];
    default:
      return null;
  }
};

const displayFrame = ({ frame, location }: WeakFrame): string => {
  const frameRef = frame?.deref();
  if (!frameRef) {
    return '(Unkown)';
  }

  const extraInfo = extraAstFrameInfo(frameRef);

  return `${frameRef.t}${
    location !== undefined ? ` - ${JSON.stringify(location)}` : ''
  }${
    extraInfo && extraInfo.length > 0
      ? `(${extraInfo
          .map(([key, val]) => `\n      ${key}: ${JSON.stringify(val)}`)
          .join(',')}\n    )`
      : ''
  }`;
};

export class TyStackTrace {
  private constructor(
    private readonly err: TypeCheckError,
    readonly weakFrames: readonly WeakFrame[]
  ) {}

  get frames() {
    return this.weakFrames.map((frame) => ({
      ...frame,
      frame: frame.frame?.deref() ?? null,
    }));
  }

  static fromErr(frame: Frame, err: TypeCheckError) {
    return new TyStackTrace(err, [
      {
        frame: frame.frame ? new WeakRef(frame.frame) : null,
        location: frame.location,
      },
    ]);
  }

  withFrame(frame: Frame): TyStackTrace {
    return new TyStackTrace(this.err, [
      ...this.weakFrames,
      {
        frame: frame.frame ? new WeakRef(frame.frame) : null,
        location: frame.location,
      },
    ]);
  }

  get headline(): string {
    return this.err.toString();
  }

  get message(): string {
    return `${this.headline}\n\nTraceBack:${this.weakFrames
      .slice(0, 10)
      .map((frame) => `\n -> ${displayFrame(frame)}`)}\n`;
  }

  toError({ jsStackPointer }: { jsStackPointer?: Function }): Error {
    const error = new Error(this.message);

    // If the js engine supports it, point the error at the callsite and not
    // here (makes it waaaaay easier to track down what function call is the
    // issue in application code)
    if ((Error as any).captureStackTrace && jsStackPointer) {
      (Error as any).captureStackTrace(error, jsStackPointer);
    }

    return error;
  }
}
