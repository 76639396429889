import { Navigate, Route, Routes } from 'react-router-dom';
import { NotFoundPage } from './pages/not-found/not-found';
import { SuperAdminView } from './pages/super-admin';
import { Logout } from './logout';
import { CredentialsPage } from './pages/connections/connections';
import React, { Suspense } from 'react';
import { Layout, OrgLayout } from './layout';
import { ExploreSelector } from './pages/explore/explore-selector';
import { ExploreDataSet } from './pages/explore';
import { AppStore, RenderApp } from './pages/apps';
import { ListConnectionsPage } from './pages/connections/list';
import { ListSourcesPage, SourcePage, NewSourcePage } from './pages/sources';
import { ViewArtifact } from './pages/ide/artifacts/view-artifact';
import { Protected, ProtectedRoute } from './components/app';
import { UnauthorizedPage } from './pages/unauthorised';
import { WarehouseSearchPage } from './pages/warehouse/search';
import { RenderDetails } from './pages/details/render-details';
import { ListEmbeddableRelations } from './pages/semantic-search/list';
import { SemanticSearch } from './pages/semantic-search/search';
import { Embeddings } from './pages/semantic-search';
import { GraphPage } from './pages/experimental/graph';
import { DryRunPage } from './pages/ide/events/dry-run';
import { Center, DisplayError, Loading } from './components';
import { ErrorBoundary } from 'react-error-boundary';
import { ChartBuilderPage } from './pages/chart-builder/chart-builder-page';
import { ManageReason } from './pages/reasons/manage';
import { SqlExplorer } from './pages/ide/sql-explorer';
import { ListReasons } from './pages/reasons/list';
import { Artifacts } from './pages/ide/artifacts/list';
import { EventsObserver } from './pages/ide/events/observability';
import { TopicsInbox, ListTopicsPage } from './pages/topics';
import { WorkspacePage } from './pages/workspace/workspace';

export const CoteraRouter: React.FC = () => {
  return (
    <OrgLayout>
      <ErrorBoundary
        fallbackRender={({ error }) => (
          <Layout>
            <DisplayError error={error} />
          </Layout>
        )}
      >
        <Suspense
          fallback={
            <Layout>
              <Center>
                <Loading.Dots />
              </Center>
            </Layout>
          }
        >
          <Routes>
            <Route
              path="/"
              element={
                <Protected
                  scopes={[['use', 'explore']]}
                  fallback={<Navigate replace to="/apps" />}
                >
                  <Navigate replace to="/explore" />
                </Protected>
              }
            />
            <Route path="/details/:page/:id" element={<RenderDetails />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="apps">
              <Route index element={<AppStore />} />
              <Route path=":appId" element={<RenderApp routePath="/apps" />} />
              <Route
                path=":appId/*"
                element={<RenderApp routePath="/apps" />}
              />
            </Route>
            <Route
              path="/explore"
              element={<ProtectedRoute scopes={[['use', 'explore']]} />}
            >
              <Route index element={<ExploreSelector />} />
              <Route path="data" element={<Navigate to={'/explore'} />} />
              <Route path="data/:id" element={<ExploreDataSet />} />
              <Route path="data/:id/graph" element={<GraphPage />} />
              <Route path="data/:id/chart" element={<ChartBuilderPage />} />
              <Route path="warehouse" element={<WarehouseSearchPage />} />
            </Route>
            <Route
              path="/workspace"
              element={<ProtectedRoute scopes={[['use', 'explore']]} />}
            >
              <Route index element={<ExploreSelector />} />
              <Route path="data" element={<Navigate to={'/workspace'} />} />
              <Route path="data/:id" element={<WorkspacePage />} />
            </Route>
            <Route path="/reasons">
              <Route index element={<ListReasons />} />
              <Route path=":id" element={<ManageReason />} />
            </Route>
            <Route path="/entities">
              <Route path=":entityName">
                <Route path="topics">
                  <Route index element={<ListTopicsPage />} />
                  <Route path=":version" element={<TopicsInbox />} />
                </Route>
              </Route>
            </Route>
            <Route
              path="connections"
              element={<ProtectedRoute scopes={[['write', 'integrations']]} />}
            >
              <Route index element={<ListConnectionsPage />} />
              <Route path=":type" element={<CredentialsPage />} />
              <Route path="sources" element={<ListSourcesPage />} />
              <Route path="sources/new" element={<NewSourcePage />} />
              <Route path="sources/:type" element={<SourcePage />} />
            </Route>
            <Route
              path="embeddings"
              element={<Navigate replace to="/semantic-search" />}
            />
            <Route path="semantic-search">
              <Route index element={<ListEmbeddableRelations />} />
              <Route path="demo" element={<Embeddings />} />
              <Route path=":id" element={<SemanticSearch />} />
            </Route>
            <Route
              path="superadmin"
              element={<ProtectedRoute scopes={[['use', 'superadmin']]} />}
            >
              <Route index element={<SuperAdminView />} />
            </Route>
            <Route path="sql-explorer" element={<SqlExplorer />} />
            <Route path="artifacts">
              <Route index element={<Artifacts />} />
              <Route path=":artifactId" element={<ViewArtifact />} />
            </Route>
            <Route path="events">
              <Route index element={<EventsObserver />} />
              <Route path=":eventStreamId">
                <Route path=":sinkId/dry-run" element={<DryRunPage />} />
              </Route>
            </Route>
            <Route path="/unauthorized" element={<UnauthorizedPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </OrgLayout>
  );
};
