import { AST } from '../ast';
import { relsInMu, relsInRel } from './rels-in';
import _ from 'lodash';

export const namedRelsOfRel = (ast: AST.Rel): Record<string, AST.Rel> =>
  Object.fromEntries(
    _.compact([...relsInRel(ast, getNamedRel, { order: 'pre' })])
  );

export const namedRelsOfMu = (ast: AST.Mu): Record<string, AST.Rel> =>
  Object.fromEntries(
    _.compact([...relsInMu(ast, getNamedRel, { order: 'pre' })])
  );

const getNamedRel = (ast: AST.Rel): [name: string, rel: AST.Rel] | null =>
  ast.t === 'select' && ast.meta['name'] !== undefined
    ? [ast.meta['name'], ast]
    : null;
