import { Result } from 'neverthrow';
import React, { ReactNode } from 'react';

interface ResultBoundaryProps<T, E> {
  result: Result<T, E>;
  children: (value: T) => ReactNode;
  fallback: (error: E) => ReactNode;
}

export function ResultBoundary<T, E>({
  result,
  children,
  fallback,
}: ResultBoundaryProps<T, E>) {
  if (result.isOk()) {
    return <>{children(result.value)}</>;
  } else {
    return <>{fallback(result.error)}</>;
  }
}
