import { Assert, Freeze } from '../utils';
import { AST } from '../ast';
import { Ty } from '../ty';
import _ from 'lodash';

const isPrimitive = (x: Ty.Scalar): x is Date | number | string | boolean =>
  x instanceof Date ||
  typeof x === 'number' ||
  typeof x === 'string' ||
  typeof x === 'boolean';

const areEqual = (
  ty: Ty.ExtendedAttributeType,
  l: Ty.Scalar,
  r: Ty.Scalar
): boolean | null => {
  if (l === null || r === null) {
    return null;
  }

  if (ty.ty.k === 'primitive' || ty.ty.k === 'enum') {
    Assert.assert(isPrimitive(l) && isPrimitive(r));
    const lhs = l instanceof Date ? +l : l;
    const rhs = r instanceof Date ? +r : r;
    return lhs === rhs;
  }

  if (ty.ty.k === 'struct') {
    Assert.assert(
      !isPrimitive(l) &&
        !Freeze.isReadonlyArray(l) &&
        !isPrimitive(r) &&
        !Freeze.isReadonlyArray(r)
    );

    return Object.entries(ty.ty.fields)
      .map(([name, ty]) => {
        const lhs = l[name] ?? null;
        const rhs = r[name] ?? null;
        // Inside of structs null behaves like a JSON `null` and not SQL null
        if (lhs === null && rhs === null) {
          return true;
        }
        return areEqual(ty, lhs, rhs) ?? false;
      })
      .reduce((l, r) => l && r, true);
  }

  if (ty.ty.k === 'array') {
    Assert.assert(Freeze.isReadonlyArray(l) && Freeze.isReadonlyArray(r));
    const t = ty.ty.t;
    return _.zip(l, r).every(([lhs, rhs]) => {
      if (lhs === undefined || rhs === undefined) {
        return false;
      }

      return compareLiterals(t, lhs, '=', rhs);
    });
  }

  if (ty.ty.k === 'id' || ty.ty.k === 'range') {
    return l === r;
  }

  if (ty.ty.k === 'record') {
    throw new Error('UNREACHABLE - Records cant be compared for equality');
  }

  return Assert.unreachable(ty.ty);
};

export const compareLiterals = (
  ty: Ty.ExtendedAttributeType,
  l: Ty.Scalar,
  op: AST.Comparison,
  r: Ty.Scalar
): boolean | null => {
  switch (op) {
    case '<':
    case '<=':
    case '>':
    case '>=': {
      if (l === null || r === null) {
        return null;
      }
      // You can't compare structs with GT or LT
      Assert.assert(isPrimitive(l) && isPrimitive(r));

      switch (op) {
        case '>':
          return l > r;
        case '<':
          return l < r;
        case '>=':
          return l >= r;
        case '<=':
          return l <= r;
        default:
          return Assert.unreachable(op);
      }
    }
    case '=':
      return areEqual(ty, l, r);
    case '!=': {
      const x = areEqual(ty, l, r);
      return x === null ? x : !x;
    }
    default:
      return Assert.unreachable(op);
  }
};
