import { Contract } from '@cotera/contracts';
import { AdminContract } from './admin';
import { ArtifactsContract } from './artifacts';
import { ConfigContract } from './config';
import { DevServerContract } from './dev-server';
import { LlmContract } from './llm';
import { SourcesContract } from './sources';
import { WarehouseContract } from './warehouse';
import { ReasonsContract } from './reasons';
import { PromptsContract } from './prompts';
import { TopicsContract } from './topics';
import { UddContract } from './udds';

export const TenantedContract = Contract.new(
  {
    admin: AdminContract,
    config: ConfigContract,
    devServer: DevServerContract,
    warehouse: WarehouseContract,
    reasons: ReasonsContract,
    artifacts: ArtifactsContract,
    sources: SourcesContract,
    llm: LlmContract,
    udds: UddContract,
    prompts: PromptsContract,
    topics: TopicsContract,
  },
  {
    mustBeAbleTo: [['use', 'application']],
  }
);
