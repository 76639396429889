import { ResponsiveSankey } from '@nivo/sankey';
import { ChartLayout, DEFAULT_MENU_ITEMS } from '../layout';
import { uniq } from 'lodash';
import { Loading } from './loading';
import { ChartContext, makeChartContextHook } from '../context';
import { ChartProps } from '../types';
import { EmptyChart } from '../empty';
import { THEME } from '../utils';
import { Tooltip } from '../tooltip';
import { Text } from '@cotera/client/app/components/ui';
import { Formatters, GRAY_200 } from '@cotera/client/app/components/utils';

type Datum = {
  from: string;
  to: string;
  value: number;
};

export type Props = {
  loading?: boolean;
} & ChartProps<Datum>;

const cleanSpecialChars = (x: string): string =>
  x.replaceAll('\\', '').replaceAll('.', '');

export const SankeyChart: React.FC<Props> = ({
  data,
  loading = false,
  ...layoutProps
}) => {
  const cleanedData = data.map(({ from, to, ...rest }) => ({
    ...rest,
    to: cleanSpecialChars(to),
    from: cleanSpecialChars(from),
  }));

  return (
    <ChartContext data={cleanedData} labelKeys={['to', 'from']}>
      <ChartLayout {...layoutProps} menuItems={DEFAULT_MENU_ITEMS}>
        {loading && <Loading />}
        {data.length === 0 && !loading && <EmptyChart />}
        {!loading && data.length > 0 && <Chart />}
      </ChartLayout>
    </ChartContext>
  );
};

const useTypedChartContext = makeChartContextHook<Datum>();

const Chart = () => {
  const labels = useTypedChartContext((s) => s.activeLabels);
  const keys = labels.map((x) => x.label);
  const data = useTypedChartContext((s) => s.data);
  const labelData = uniq([
    ...data.map((x) => `${x.from}.source`),
    ...data.map((x) => `${x.to}.target`),
  ]).map((x) => ({
    id: x,
    label: x,
  }));

  const links = data
    .filter((x) => keys.includes(x.from) && keys.includes(x.to))
    .map((x) => ({
      from: x.from,
      to: x.to,
      source: `${x.from}.source`,
      target: `${x.to}.target`,
      value: x.value,
      startColor: labels.find((y) => y.label === x.from)?.color,
      endColor: labels.find((y) => y.label === x.to)?.color,
    }));

  const total = links.reduce((acc, x) => acc + x.value, 0);

  return (
    <ResponsiveSankey
      theme={THEME}
      data={{
        nodes: labelData.filter((x) => keys.includes(x.id.split('.')[0]!)),
        links,
      }}
      margin={{ top: 30, right: 50, bottom: 30, left: 50 }}
      align="justify"
      colors={(datum) =>
        datum.sourceLinks.at(0)?.startColor ??
        datum.targetLinks.at(0)?.endColor ??
        datum.targetLinks.at(0)?.startColor ??
        datum.sourceLinks.at(0)?.endColor ??
        GRAY_200
      }
      nodeOpacity={1}
      nodeHoverOthersOpacity={0.35}
      nodeThickness={18}
      nodeSpacing={24}
      nodeBorderWidth={0}
      nodeBorderColor={{
        from: 'color',
        modifiers: [['darker', 0.8]],
      }}
      nodeBorderRadius={3}
      linkOpacity={0.5}
      linkHoverOthersOpacity={0.1}
      linkContract={3}
      label={(e) => e.id.split('.')[0]!}
      enableLinkGradient={true}
      labelPosition="inside"
      labelOrientation="horizontal"
      labelPadding={16}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1]],
      }}
      linkTooltip={(props) => (
        <Tooltip.Container>
          <Tooltip.Item>
            {props.link.startColor && (
              <Tooltip.Dot color={props.link.startColor} />
            )}
            <strong>{props.link.source.id}</strong>
            <strong>
              <Text.Caption className="mx-3">{'>'}</Text.Caption>
            </strong>
            {props.link.endColor && <Tooltip.Dot color={props.link.endColor} />}{' '}
            <strong>{props.link.target.id}</strong>:{' '}
            {Formatters.number(props.link.value)} (
            {Formatters.number((props.link.value / total) * 100, '%')})
          </Tooltip.Item>
        </Tooltip.Container>
      )}
    />
  );
};
