import {
  And,
  Asc,
  Avg,
  Case,
  Constant,
  Eq,
  Relation,
  GenerateSeries,
  GroupedRelationRef,
  If,
  LagOver,
  LeadOver,
  Least,
  Max,
  Min,
  Not,
  Now,
  Or,
  StdDevSamp,
  Sum,
  Today,
  Yesterday,
  Expression,
  CountDistinct,
} from '@cotera/era';

export const mkCustomerEvents = ({ Orders }: { Orders: Relation }) => {
  const CustomerEvents = Orders.groupBy((t) =>
    t.pick('__COTERA_CUSTOMER_ID', 'ORDER_DATE', 'ORDER_ID')
  )
    .select((t) => ({
      ...t.group(),
      TOTAL_ITEMS: Sum(t.attr('QUANTITY')).coalesce(0),
      TOTAL_SPEND: Sum(t.attr('PRICE').mul(t.attr('QUANTITY'))).coalesce(0),
    }))
    .select((t) => ({
      ...t.star(),
      PLACED_AT_DAY: t.attr('ORDER_DATE').dateTrunc('day'),
    }))
    .select((t) => ({
      ...t.star(),
      DAYS_SINCE_PREVIOUS_INTERACTION: LagOver(t.attr('PLACED_AT_DAY'), {
        partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
        orderBy: Asc(t.attr('PLACED_AT_DAY')),
      }).dateDiff(t.attr('PLACED_AT_DAY'), 'days'),
    }));

  return CustomerEvents;
};

export const mkCustomers = ({
  CustomerEvents,
}: {
  CustomerEvents: Relation;
}) => {
  return CustomerEvents.groupBy((t) => t.pick('__COTERA_CUSTOMER_ID')).select(
    (t) => ({
      ...t.group(),
      CREATED_AT: Min(t.attr('PLACED_AT_DAY')).dateTrunc('day'),
    })
  );
};

type Aggregate = {
  by: (t: GroupedRelationRef) => Expression;
};

export function mkRfmAnalytics(params: {
  Orders: Relation;
  rfmDays: number;
  analyticsTimePeriod: number[];
  extraAggregates?: { [name: string]: Aggregate };
  timePeriodCompare?: boolean;
  monthAverages?: boolean;
  allTime?: boolean;
}): Relation {
  const { extraAggregates, rfmDays, Orders, analyticsTimePeriod } = params;

  const aggregates: Record<string, Aggregate> = {
    ...extraAggregates,
    SPEND: {
      by: (t) => Sum(t.attr('PRICE').mul(t.attr('QUANTITY'))).coalesce(0),
    },
    ITEMS: {
      by: (t) => Sum(t.attr('QUANTITY')).coalesce(0),
    },
    ORDERS: {
      by: (t) => CountDistinct(t.sql`"ORDER_ID"`).coalesce(0),
    },
  };

  const Customers = Orders.groupBy((t) =>
    t.pick('__COTERA_CUSTOMER_ID')
  ).select((t) => ({
    ...t.group(),
    CREATED_AT: Min(t.attr('ORDER_DATE')).dateTrunc('day'),
  }));

  const CustomerEvents = Orders.groupBy((t) => ({
    ...t.pick('__COTERA_CUSTOMER_ID'),
    PLACED_AT_DAY: t.sql`"ORDER_DATE"`.dateTrunc('day'),
  }))
    .select((t) => ({
      ...t.group(),
      ...Object.fromEntries(
        Object.entries(aggregates).map(([name, { by }]) => [
          `TOTAL_${name}`,
          by(t),
        ])
      ),
    }))
    .select((t) => ({
      ...t.star(),
      DAYS_SINCE_PREVIOUS_INTERACTION: LagOver(t.attr('PLACED_AT_DAY'), {
        partitionBy: t.attr('__COTERA_CUSTOMER_ID'),
        orderBy: Asc(t.attr('PLACED_AT_DAY')),
      }).dateDiff(t.attr('PLACED_AT_DAY'), 'days'),
    }));

  const Days = mkDays({ rfmDays });

  const CustomerDays = Customers.leftJoin(Days, (customer, day) => ({
    on: customer.attr('CREATED_AT').lt(day.attr('DAY_AFTER')),
    select: { ...customer.star(), ...day.star() },
  }));

  const CustomerEventDays = CustomerDays.innerJoin(
    CustomerEvents,
    (customerDays, events) => ({
      on: Eq(
        customerDays.attr('__COTERA_CUSTOMER_ID'),
        events.attr('__COTERA_CUSTOMER_ID')
      ),
      select: {
        ...customerDays.star(),
        ...events.except('__COTERA_CUSTOMER_ID'),
      },
    })
  );

  return CustomerEventDays.groupBy((t) =>
    t.pick('__COTERA_CUSTOMER_ID', 'TODAY', 'CREATED_AT', 'DAY_AFTER')
  )
    .select((t) => {
      const CUSTOMER_AGE_IN_DAYS = t
        .attr('CREATED_AT')
        .dateDiff(t.attr('TODAY'), 'days');

      const totals = Object.fromEntries(
        Object.entries(aggregates).map(([name, _rest]) => [
          `TOTAL_${name}`,
          Sum(
            If(isOrderInPeriod(t), { then: t.attr(`TOTAL_${name}`), else: 0 })
          ),
        ])
      );

      const totalsByTimePeriod = Object.fromEntries(
        Object.entries(aggregates).flatMap(([name]) => {
          return analyticsTimePeriod.map((i) => [
            `TOTAL_${name}_LAST_${i}_DAYS`,
            Sum(
              If(placedAtDayIsInPeriod(t, i), {
                then: t.attr(`TOTAL_${name}`),
                else: 0,
              }).coalesce(0)
            ),
          ]);
        })
      );

      const totalsByPreviousTimePeriod = Object.fromEntries(
        Object.entries(aggregates).flatMap(([name]) => {
          return analyticsTimePeriod.map((i) => [
            `TOTAL_${name}_LAST_${i}_${i * 2}_DAYS`,
            Sum(
              If(placedAtDayIsInPrevPeriod(t, i), {
                then: t.attr(`TOTAL_${name}`),
                else: 0,
              }).coalesce(0)
            ),
          ]);
        })
      );

      const monthAverageByTimePeriod = Object.fromEntries(
        Object.entries(aggregates).flatMap(([name]) => {
          return analyticsTimePeriod.map((i) => [
            `AVERAGE_${name}_PER_MONTH_LAST_${i}_DAYS`,
            Sum(If(placedAtDayIsInPeriod(t, i), { then: 1, else: 0 }))
              .coalesce(0)
              .cast('float')
              .div(
                CUSTOMER_AGE_IN_DAYS.div(30).clamp(
                  1,
                  Constant(i).div(30).floor()
                )
              ),
          ]);
        })
      );

      const allTimeCounts = Object.fromEntries(
        Object.entries(aggregates).map(([name]) => [
          `TOTAL_LIFETIME_${name}`,
          Sum(
            If(isOrderInOrBeforePeriod(t), {
              then: t.attr(`TOTAL_${name}`),
              else: 0,
            })
          ).coalesce(0),
        ])
      );

      return {
        ...t.group(),
        ...totals,
        ...totalsByTimePeriod,
        ...(params.timePeriodCompare ? totalsByPreviousTimePeriod : {}),
        ...(params.monthAverages ? monthAverageByTimePeriod : {}),
        ...(params.allTime ? allTimeCounts : {}),
        CUSTOMER_AGE_IN_DAYS,
        AVG_DAYS_BETWEEN_INTERACTION: Avg(
          If(isOrderInOrBeforePeriod(t), {
            then: t.attr('DAYS_SINCE_PREVIOUS_INTERACTION'),
          })
        ),
        STD_DEV_DAYS_BETWEEN_INTERACTION: StdDevSamp(
          If(isOrderInOrBeforePeriod(t), {
            then: t.attr('DAYS_SINCE_PREVIOUS_INTERACTION'),
          })
        ),
        DAYS_SINCE_LAST_ORDER: Max(
          If(
            And(
              t.attr('TOTAL_ITEMS').coalesce(0).gt(0),
              isOrderInOrBeforePeriod(t)
            ),
            {
              then: t.attr('PLACED_AT_DAY'),
            }
          )
        ).dateDiff(Least(t.attr('TODAY'), Yesterday()), 'days'),
      };
    })
    .select((t) => ({
      ...t.renameWith((name) => {
        // TEMP SHIM
        return name
          .replace('TOTAL_ITEMS', 'ITEM_COUNT')
          .replace('TOTAL_ORDERS', 'ORDER_COUNT')
          .replace('TOTAL_LIFETIME_ITEMS', 'TOTAL_ITEMS_PURCHASED');
      }),
    }));
}

export const mkDays = (params: { rfmDays: number | Expression }) => {
  const rfmDays = Expression.wrap(params.rfmDays);

  const Days = GenerateSeries(1, rfmDays.add(31))
    .where((t) =>
      If(rfmDays.lte(7), { then: t.attr('n').lte(rfmDays), else: true })
    )
    .select((t) => ({
      TODAY: Now().dateSub('days', t.attr('n')).dateTrunc('day'),
    }))
    .where((t) =>
      t
        .attr('TODAY')
        .gte(
          Today().dateSub(
            'days',
            rfmDays.add(Today().datePart('day').cast('int'))
          )
        )
    )
    .where((t) =>
      Or(Not(rfmDays.gt(7)), t.attr('TODAY').datePart('day').oneOf([1, 11, 21]))
    )
    .select((t) => ({
      ...t.star(),
      DAY_AFTER: LeadOver(t.attr('TODAY'), {
        orderBy: Asc(t.attr('TODAY')),
      }).coalesce(
        Case([
          {
            when: rfmDays.gt(7),
            then: Least(
              t.attr('TODAY').dateAdd('days', Constant(7).cast('int')),
              Today()
            ),
          },
          {
            when: rfmDays.lte(7),
            then: Least(
              t.attr('TODAY').dateAdd('days', Constant(1).cast('int')),
              Today()
            ),
          },
        ])
      ),
    }));

  return Days;
};

const isOrderInPeriod = (t: GroupedRelationRef) =>
  And(
    t.attr('PLACED_AT_DAY').gte(t.attr('TODAY')),
    t.attr('PLACED_AT_DAY').lt(t.attr('DAY_AFTER'))
  );

const isOrderInOrBeforePeriod = (t: GroupedRelationRef) =>
  t.attr('DAY_AFTER').gt(t.attr('PLACED_AT_DAY'));

const placedAtDayIsInPeriod = (t: GroupedRelationRef, i: number) =>
  And(
    t
      .attr('PLACED_AT_DAY')
      .gte(t.attr('DAY_AFTER').dateSub('days', Constant(i).cast('int'))),
    t.attr('PLACED_AT_DAY').lt(t.attr('DAY_AFTER'))
  );

const placedAtDayIsInPrevPeriod = (t: GroupedRelationRef, i: number) =>
  And(
    t
      .attr('PLACED_AT_DAY')
      .gte(t.attr('DAY_AFTER').dateSub('days', Constant(i).mul(2).cast('int'))),
    t
      .attr('PLACED_AT_DAY')
      .lt(t.attr('DAY_AFTER').dateSub('days', Constant(i).cast('int')))
  );
