import {
  Button,
  Divider,
  Modal,
  Section,
  Title,
  toast,
} from '@cotera/client/app/components/ui';
import { useTenantedClient, useWhoami } from '@cotera/client/app/stores/org';
import { Assert } from '@cotera/utilities';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@cotera/client/app/hooks/query-cache-keys';
import { Inputs } from '@cotera/client/app/components/forms';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const usePromote = (entityId: string) => {
  const client = useTenantedClient();
  const queryClient = useQueryClient();
  const orgId = useWhoami((s) => s.org.id);

  return useMutation({
    mutationFn: async ({ versionId }: { versionId: string }) =>
      Assert.assertOk(await client.topics.publishVersion({ versionId })),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.topics.versions({ orgId, entityId }),
      });
    },
  });
};

const useAdd = (entityId: string) => {
  const client = useTenantedClient();
  const queryClient = useQueryClient();
  const orgId = useWhoami((s) => s.org.id);

  return useMutation({
    mutationFn: async ({
      name,
      sourceVersionId,
    }: {
      name: string;
      sourceVersionId?: string;
    }) =>
      Assert.assertOk(
        await client.topics.newVersion({
          version: name,
          sourceVersionId,
          entityId,
        })
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.topics.versions({ orgId, entityId }),
      });
    },
  });
};

export const PromoteButton: React.FC<{
  versionId: string;
  entityId: string;
  small?: boolean;
}> = ({ entityId, versionId, small = false }) => {
  const promote = usePromote(entityId);

  return (
    <Button
      icon="rocket-launch"
      text="Promote"
      compact
      iconOnly
      tooltip="left"
      theme="warning"
      small={small}
      className="mr-2"
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
          await promote.mutateAsync({ versionId });
          toast.success('Version published', 'This is now the primary version');
        } catch (e) {
          toast.error('Failed to promote version', String(e));
        }
      }}
    />
  );
};

export const AddNewVersion: React.FC<{
  sourceVersionId?: string;
  entityId: string;
  entityName: string;
  numVersions: number;
  display?: 'full' | 'compact';
  type?: 'add' | 'copy';
  inline?: boolean;
}> = ({
  sourceVersionId,
  entityId,
  entityName,
  display = 'compact',
  type = 'copy',
  numVersions,
  inline,
}) => {
  const [value, setValue] = useState(`v${numVersions + 1}`);
  const [open, setOpen] = useState(false);
  const add = useAdd(entityId);
  const navigate = useNavigate();

  return (
    <>
      <Button
        inline={inline}
        icon={type}
        small={display === 'compact'}
        iconOnly={display === 'compact'}
        text={type === 'add' ? 'Add Version' : 'Duplicate Version'}
        tooltip="left"
        compact={display === 'compact'}
        theme="secondary"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpen(!open);
        }}
      />
      <Modal
        open={open}
        priority="high"
        onOpenChange={setOpen}
        padding={false}
        contentClassName="w-1/3"
      >
        <Section direction="vertical" className="bg-white">
          <Title title="Add Reason" type="section" className="mb-4" />
          <Divider className="mb-6" />
          <form onSubmit={(e) => e.preventDefault()}>
            <Inputs.Text
              label="Name"
              value={value}
              onChange={setValue}
              className="mb-4"
            />
            <div className="flex justify-end">
              <Button
                text="Save"
                theme="secondary"
                disabled={!value}
                onClick={async () => {
                  await add.mutateAsync({
                    name: value,
                    sourceVersionId,
                  });
                  navigate(`/entities/${entityName}/topics/${value}`);
                }}
              />
            </div>
          </form>
        </Section>
      </Modal>
    </>
  );
};
