import React from 'react';
import { getCommonPinningStyles, makeActionsColumn } from '../utils';
import { Cell } from './cell';
import { RowActions } from './row-actions';
import { Button, Divider, Title } from '@cotera/client/app/components/ui';
import { classNames } from '../../utils';
import { useDataGridStore } from '../context';
import { Inputs } from '../../forms';

export const SlideOver: React.FC = () => {
  const show = useDataGridStore((s) => s.showActionsColumn);
  const toggleShow = useDataGridStore((s) => s.actions.toggleActionsColumn);
  const [search, setSearch] = React.useState('');

  return (
    <>
      <div
        onClick={() => {
          toggleShow();
        }}
        className={classNames(
          show ? 'opacity-60 blur-md' : 'hidden opacity-0',
          'blur-md w-full h-full absolute top-0 right-0 h-full border-l border-divider z-10 transition-all duration-300 ease-in-out bg-white'
        )}
      />
      <div
        className={classNames(
          show ? 'w-[250px]' : 'w-0',
          'overflow-hidden absolute top-0 right-0 h-full bg-white border-l border-divider z-50 transition-all duration-300 ease-in-out'
        )}
      >
        <div className="flex flex-col justify-center px-4">
          <div className="flex justify-between items-center mb-2">
            <Title type="section">Manage Data Grid</Title>
            <Button
              inline
              icon="close"
              onClick={() => {
                toggleShow();
              }}
            />
          </div>
          <Inputs.Text
            placeholder="Search for a property"
            icon="search"
            value={search}
            onChange={setSearch}
          />
          <Divider className="mb-4 mt-4" />
          <Title type="label">Shown in table</Title>
          <Title type="label">Hidden in table</Title>
          <Title type="label">Actions</Title>
        </div>
      </div>
    </>
  );
};

const Header: React.FC = () => {
  const toggleActionsColumn = useDataGridStore(
    (s) => s.actions.toggleActionsColumn
  );

  return (
    <>
      <th
        style={{
          ...getCommonPinningStyles(makeActionsColumn('right')),
        }}
        className="bg-white border-b border-l border-divider text-standard-text"
      >
        <div className="flex flex-col justify-center items-center">
          <Button
            inline
            icon="wrench"
            onClick={() => {
              toggleActionsColumn();
            }}
          />
        </div>
      </th>
    </>
  );
};

const Body: React.FC = () => {
  return (
    <Cell
      style={getCommonPinningStyles(makeActionsColumn('right'))}
      column="__actions"
      className={'bg-white border-l'}
    >
      <RowActions />
    </Cell>
  );
};

export const ActionsColumn = {
  Header,
  Body,
};
