import _ from 'lodash';
import { AST, Relation, Page, TC, Ty, Traverse } from '@cotera/era';
import { Definition, ManifestShorthand, ManifestAST } from './ast';
import { ManifestSkeleton, _Sink, Schedule } from '@cotera/api';
import { ScheduledJob } from './scheduled-job';
import { CronExpression } from '../cookbook/cron-expression';
import { Materialization } from '../materialization';
import { EventStreamThrottle } from '../builder';

const shorthandToManifest = (shorthand: ManifestShorthand): ManifestAST => {
  const definitions: Record<string, Definition> = _.mapValues(
    shorthand.definitions,
    (def, id) => {
      if (def instanceof Relation) {
        return { rel: def.ast, displayName: _.startCase(id), hidden: false };
      }

      return {
        rel: def.rel.ast,
        displayName: def.displayName ?? _.startCase(id),
        hidden: def.hidden ?? false,
      };
    }
  );

  const apps: Record<string, AST._App> = _.mapValues(
    shorthand.apps,
    (app, name) => {
      if (
        typeof app === 'object' &&
        app !== null &&
        't' in app &&
        typeof app.t === 'string' &&
        app.t === 'app'
      ) {
        return app;
      }

      const res: AST._App = {
        t: 'app',
        title: name,
        icon: 'command-line',
        pages: {
          '/': Page([], () => app),
        },
        menu: [],
        context: null,
      };

      return res;
    }
  );

  const udds = (shorthand.udds ?? []).map((x) => x.config);

  const materializations = (shorthand.materializations ?? []).map(
    ({ schedule, config }) => ({
      schedule,
      source: config.config.source,
      viewName: config.config.viewName,
    })
  );

  const detailPages: ManifestAST['detailPages'] = _.mapValues(
    shorthand.detailPages ?? {},
    (page) => ({ ty: page.ty, render: page.mu })
  );

  const manifest: ManifestAST = {
    detailPages,
    definitions,
    apps,
    udds,
    materializations,
    scheduledJobs: shorthand.scheduledJobs ?? [],
    schemas: shorthand.schemas ?? { read: [] },
    eventStreams: [
      ...(shorthand.eventStreams ?? []),
      ...(shorthand.alerts ?? []),
    ],
    preloadApps: shorthand.preloadApps ?? true,
  };

  return manifest;
};

export type EventStreamDefinition = {
  readonly entityId: Ty.IdType;
  readonly name: string;
  readonly identifier: AST.Expr;
  readonly rel: AST.Rel;
  readonly when: Schedule;
  readonly onChange: AST.Expr;
  readonly sinks: Record<string, _Sink>;
  readonly batchSize: number;
  readonly debounceByIdForSecs?: number;
  readonly throttle?: EventStreamThrottle;
};

export class Manifest {
  readonly udds: readonly {
    readonly id: Ty.IdType | string;
    readonly attributes: Record<string, Ty.ExtendedAttributeType>;
  }[];
  readonly definitions: {
    readonly [id: string]: {
      readonly hidden: boolean;
      readonly displayName: string;
      readonly rel: Relation;
      readonly id: string;
    };
  };

  readonly detailPages: {
    readonly [page: string]: {
      readonly ty: Ty.ExtendedAttributeType;
      readonly render: AST.Mu;
    };
  };

  readonly eventStreams: EventStreamDefinition[];

  readonly materializations: readonly Materialization[];

  readonly scheduledJobs: readonly ScheduledJob[];

  readonly schemas: {
    readonly read: string[];
  };

  readonly apps: {
    readonly [id: string]: AST._App;
  };

  public readonly preloadApps: boolean;

  static fromShorthand(shorthand: ManifestShorthand) {
    return new this(shorthandToManifest(shorthand));
  }

  informationSchema(): readonly {
    readonly column_name: string;
    readonly table_name: string;
    readonly ty: Ty.ExtendedAttributeType;
  }[] {
    return Object.entries(this.definitions).flatMap(([table_name, def]) =>
      Object.entries(def.rel.attributes).map(([column_name, ty]) => ({
        table_name,
        column_name,
        ty,
      }))
    );
  }

  skeleton(params: { version: string }): ManifestSkeleton {
    const skeleton: ManifestSkeleton = {
      version: params.version,
      udds: this.udds,
      detailPages: _.mapValues(this.detailPages, ({ ty }) => ty),
      definitions: _.mapValues(this.definitions, (def, id) => ({
        id,
        displayName: def.displayName,
        hidden: def.hidden,
        attributes: def.rel.attributes,
      })),
      apps: _.mapValues(this.apps, (app, id) => ({
        id,
        icon: app.icon,
        title: app.title,
        hasHomePage: Object.keys(app.pages).includes('/'),
      })),
      schemas: this.schemas,
      eventStreams: this.eventStreams.map((s) => ({
        name: s.name,
        when: s.when,
        entityId: s.entityId,
        sinks: s.sinks,
        rel: s.rel,
        identifier: s.identifier,
      })),
    };

    return skeleton;
  }

  getEventStream(name: string): EventStreamDefinition | null {
    const resource = this.eventStreams.find((x) => x.name === name);
    return resource ?? null;
  }

  assumptions(): { readonly [name: string]: AST._TableContract[] } {
    return Object.fromEntries(
      Object.entries(this.definitions).map(([name, def]) => {
        return [name, def.rel.assumptions()];
      })
    );
  }

  constructor(manifest: ManifestAST) {
    this.udds = manifest.udds ?? [];
    this.schemas = manifest.schemas;

    const inferredDefs = _.mapValues(
      [
        Object.values(manifest.apps).flatMap((app) =>
          Object.values(app.pages).map((page) => Traverse.namedRelsOfMu(page))
        ),
        Object.values(manifest.definitions).map((def) =>
          Traverse.namedRelsOfRel(def.rel)
        ),
      ]
        .flatMap((t) => t)
        .reduce((l, r) => ({ ...l, ...r }), {}),
      (rel, id) => ({
        id,
        displayName: id,
        hidden: true,
        rel: Relation.fromAst(rel),
      })
    );

    const topLevelDefs = _.mapValues(
      manifest.definitions,
      ({ rel, displayName, hidden }, id) => ({
        id,
        displayName,
        hidden,
        rel: Relation.fromAst(rel),
      })
    );

    this.definitions = { ...inferredDefs, ...topLevelDefs };
    this.materializations = manifest.materializations;
    this.detailPages = manifest.detailPages;
    this.apps = _.mapValues(manifest.apps, (app) => {
      const checked = TC.MU.checkApp(app);

      if (checked instanceof TC.TyStackTrace) {
        throw checked.toError({});
      }

      return checked;
    });
    this.eventStreams = manifest.eventStreams;
    this.preloadApps = manifest.preloadApps;
    this.scheduledJobs = [
      ...manifest.scheduledJobs,
      {
        id: 'warm-artifact-cache',
        schedule: CronExpression.EVERY_DAY_AT_4AM,
        parameters: {
          t: 'artifacts-cache-warm',
          params: {},
        },
      },
    ];
  }
}
