import { EraSQL } from '@cotera/era';
import React, { useState } from 'react';
import { Assert } from '@cotera/utilities';
import { Inputs } from '@cotera/client/app/components/forms';
import { Tooltip } from '@cotera/client/app/components/ui';
import { Layout } from '@cotera/client/app/layout';
import { classNames } from '@cotera/client/app/components/utils';

export const SqlExplorer: React.FC = () => {
  const [sql, setSql] = useState<string>('');
  return (
    <Layout>
      <div className="flex flex-col space-y-4">
        <Inputs.Text value={sql} onChange={(x) => setSql(x)} />
        <RenderLex sql={sql} />
      </div>
    </Layout>
  );
};

const RenderLex: React.FC<{ sql: string }> = ({ sql }) => {
  const lex = [...EraSQL.lex(sql)];
  const tokens = lex.map(([token, _meta], i) => {
    let render: { className: string; text: string };

    switch (token.t) {
      case 'literal': {
        const text =
          token.val instanceof Date
            ? token.val.toISOString()
            : token.val.toString();
        render = {
          className: 'text-green-400',
          text,
        };
        break;
      }
      case 'operator':
        render = {
          className: 'text-blue-400',
          text: token.op,
        };
        break;
      case 'ident':
        render = {
          className: 'text-yellow-600',
          text: token.name,
        };
        break;
      case 'unknown':
        render = {
          className: 'text-red-600',
          text: token.found,
        };
        break;
      case 'white-space':
        render = {
          className: 'text-purple-400',
          text: '_',
        };
        break;
      case 'grouping':
        render = {
          className: 'text-orange-400',
          text: token.op,
        };
        break;
      case 'symbol': {
        render = {
          className: 'text-pink-700',
          text: token.sym,
        };
        break;
      }
      case 'raw-expression':
        render = { className: 'text-green-400', text: '' };
        break;
      default:
        return Assert.unreachable(token);
    }

    return (
      <Tooltip text={token.t} side={'bottom'} key={i}>
        <div
          className={classNames(
            render.className,
            'border border-solid border-2 p-1'
          )}
        >
          {render.text}
        </div>
      </Tooltip>
    );
  });
  return <div className="flex flex-row space-x-1">{tokens}</div>;
};
