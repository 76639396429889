import {
  StateSetter,
  makeStoreProvider,
  makeStoreContextHook,
} from '@cotera/client/app/etc';
import { createContext, useEffect } from 'react';
import { ChildrenProps } from '@cotera/client/app/components/utils';
import { Collapse } from '@cotera/client/app/components/ui';

type AccordionState = {
  expanded: string[];
};

type AccordionActions = (set: (state: Partial<AccordionState>) => void) => {
  expand: (id: string) => void;
  close: (id: string) => void;
};

const AccordionContext = createContext<AccordionState>(undefined as any);

const AccordionProvider = makeStoreProvider<
  AccordionState,
  ReturnType<AccordionActions>
>(AccordionContext);

export const useAccordion = makeStoreContextHook<
  AccordionState,
  ReturnType<AccordionActions>
>(AccordionContext);

const actions = (set: StateSetter<AccordionState>) => ({
  expand: (id: string) =>
    set((state) => ({ expanded: [...state.expanded, id] })),
  close: (id: string) =>
    set((state) => ({
      expanded: state.expanded.filter((i) => i !== id),
    })),
});

const Root = ({
  children,
  expanded,
}: { expanded: string[] } & ChildrenProps) => {
  return (
    <AccordionProvider state={{ expanded }} actions={actions}>
      {children}
    </AccordionProvider>
  );
};

const Item = ({
  id,
  children,
  className,
  open,
}: {
  id: string;
  className?: string;
  open?: boolean;
} & ChildrenProps) => {
  const expanded = useAccordion((s) => s.expanded);
  const expand = useAccordion((s) => s.actions.expand);

  useEffect(() => {
    if (open) {
      expand(id);
    }
  }, [open, expand, id]);

  const isExpanded = expanded.includes(id);

  return (
    <Collapse
      hideOnExit={false}
      in={isExpanded}
      duration={200}
      property="height"
      className={className}
    >
      {children}
    </Collapse>
  );
};

const Trigger = ({
  id,
  children,
  className,
  as: As,
}: {
  id: string;
  className?: string;
  as?: (
    props: { onClick: () => void; className?: string } & ChildrenProps
  ) => JSX.Element;
} & ChildrenProps) => {
  const expanded = useAccordion((s) => s.expanded) ?? [];
  const expand = useAccordion((s) => s.actions.expand);
  const close = useAccordion((s) => s.actions.close);

  const El = As ?? ((props) => <button {...props} />);

  return (
    <El
      className={className}
      onClick={() => {
        if (!expanded.includes(id)) {
          expand(id);
        } else {
          close(id);
        }
      }}
    >
      {children}
    </El>
  );
};

export const Accordion = {
  Root,
  Item,
  Trigger,
};
