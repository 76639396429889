import { UiComponent } from '../../types/ui-component';
import { ColorScheme, classNames } from '@cotera/client/app/components/utils';
import { Text } from '../text';
import { Theme } from '../../types/utils';

type DividerProps = {
  theme?: Theme;
  caption?: string;
} & UiComponent;
export const Divider: React.FC<DividerProps> = ({
  theme = 'muted',
  className,
  caption,
}) => {
  const Hr = () => (
    <hr
      className={classNames(
        `h-[1px] w-full`,
        ColorScheme.background[theme],
        className
      )}
    />
  );

  if (caption) {
    return (
      <div className={classNames('flex flex-row items-center', className)}>
        <Hr />
        <Text.Caption className={classNames('mx-4 shrink-0')}>
          {caption}
        </Text.Caption>
        <Hr />
      </div>
    );
  }
  return <Hr />;
};
