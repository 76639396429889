import { ArrowLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Modal } from '../modal';
import { createContext } from 'react';
import { ChildrenProps } from '@cotera/client/app/components/utils';
import { Card } from '@cotera/client/app/components/headless';
import {
  StateSetter,
  makeStoreProvider,
  makeStoreContextHook,
} from '@cotera/client/app/etc';

type State = {
  history: string[];
};

type Actions = (set: (state: Partial<State>) => void) => {
  next(id: string): void;
  back(): void;
  clear(): void;
  onClose(): void;
};

const WizardContext = createContext<State>(undefined as any);

const WizardProvider = makeStoreProvider<State, ReturnType<Actions>>(
  WizardContext
);

export const useWizard = makeStoreContextHook<State, ReturnType<Actions>>(
  WizardContext
);

const actions = (onClose: () => void) => (set: StateSetter<State>) => ({
  next: (id: string) => {
    set((s) => ({ history: [...s.history, id] }));
  },
  back: () => {
    set((s) => {
      if (s.history.length > 1) {
        return { history: s.history.slice(0, -1) };
      }

      return s;
    });
  },
  clear: () => {
    set((s) => ({ history: s.history.slice(0, 1) }));
  },
  onClose,
});

const Container: React.FC<
  ChildrenProps & {
    open: boolean;
    firstStep: string;
    onClose: () => void;
  }
> = ({ children, open, firstStep, onClose }) => {
  return (
    <WizardProvider state={{ history: [firstStep] }} actions={actions(onClose)}>
      <WizardModal open={open} onClose={onClose}>
        {children}
      </WizardModal>
    </WizardProvider>
  );
};

const WizardModal: React.FC<
  ChildrenProps & {
    open: boolean;
    onClose: () => void;
  }
> = ({ children, open, onClose }) => {
  const clear = useWizard((s) => s.actions.clear);
  return (
    <Modal
      padding={false}
      open={open}
      priority="high"
      onOpenChange={(val) => {
        if (!val) {
          onClose();
          clear();
        }
      }}
      className="w-full "
      contentClassName="w-[60%] transition-all"
    >
      <Card.Container bottomPadding={false}>{children}</Card.Container>
    </Modal>
  );
};

const Step: React.FC<
  ChildrenProps & {
    id: string;
    title?: string;
  }
> = ({ children, title, id }) => {
  const history = useWizard((s) => s.history);
  const back = useWizard((s) => s.actions.back);
  const onClose = useWizard((s) => s.actions.onClose);

  const Icon = history.length > 1 ? ArrowLeftIcon : XMarkIcon;

  if (history.at(-1) !== id) {
    return null;
  }

  return (
    <>
      <Card.Header>
        <div className="flex items-center">
          <Icon
            className="h-4 w-4 text-standard-text hover:text-primary-text"
            onClick={() => {
              if (history.length > 1) {
                back();
              } else {
                onClose();
              }
            }}
          />
          {title && <Card.Title className="ml-4">{title}</Card.Title>}
        </div>
      </Card.Header>
      <Card.Content>{children}</Card.Content>
    </>
  );
};

const Actions: React.FC<ChildrenProps> = ({ children }) => {
  return <Card.Footer>{children}</Card.Footer>;
};

export const Wizard = {
  Step,
  Actions,
  Container,
};
