import {
  AST,
  Constant,
  Expression,
  Macro,
  Relation,
  Ty,
  Values,
  InformationSchema,
} from '@cotera/era';
import _ from 'lodash';
import type {
  RuntimeData,
  AsyncFnRuntimeDataProvider,
  RuntimeDataProvider,
} from './runtime-data';
import { Assert } from '@cotera/utilities';

import {
  ENTITY_DEFINITIONS,
  EVENT_CURSORS,
  INTENT_SIGNAL_NODES,
  KEYWORD_CONFIG,
  MANIFEST_EVENT_STREAM_SINKS,
} from './vars';

export const MANIFEST_INFORMATATION_SCHEMA_NAME = 'MANIFEST';

export const applyToExprAsync = async <T>(
  expr: Expression,
  provider: AsyncFnRuntimeDataProvider<T>,
  args: T
): Promise<Expression> => {
  const neededScopes = Object.keys(expr.freeVars);
  const scopes = await provideAsync(neededScopes, provider, args);

  return scopes.reduce(
    (e, { scope, vars }) => Macro.callExprMacro(e.ast, vars, { scope }),
    expr
  );
};

export const applyToRelAsync = async <T>(
  rel: Relation,
  provider: AsyncFnRuntimeDataProvider<T>,
  args: T
): Promise<Relation> => {
  const neededScopes = Object.keys(rel.freeVars);
  const scope = await provideAsync(neededScopes, provider, args);

  return scope.reduce(
    (r, { scope, vars }) => Macro.callRelMacro(r.ast, vars, { scope }),
    rel
  );
};

const RUNTIME_DATA_TO_SCOPE_VARS: {
  [Scope in keyof RuntimeData]: (
    data: RuntimeData[Scope]
  ) => Record<string, Expression | Relation>;
} = {
  '@@cotera-keywords-config': (data) =>
    _.mapValues(data, (keywords, entityId) =>
      StrictValues(keywords, KEYWORD_CONFIG(entityId).attributes)
    ),
  '@@cotera-intent-signal-nodes': (data) =>
    _.mapValues(data, (keywords, entityId) =>
      StrictValues(keywords, INTENT_SIGNAL_NODES(entityId).attributes)
    ),
  '@@cotera-org': (data) => ({
    ORG_ID: Constant(data.ORG_ID),
    ORG_NAME: Constant(data.ORG_NAME),
  }),
  '@@cotera-entities': (data) => ({
    DEFINITIONS: StrictValues(data.DEFINITIONS, ENTITY_DEFINITIONS.attributes),
  }),
  '@@cotera-events': (data) => ({
    CURSORS: StrictValues(data.CURSORS, EVENT_CURSORS.attributes),
  }),
  '@@cotera-manifest-event-stream-sinks': (data) => ({
    SINKS: StrictValues(data.SINKS, MANIFEST_EVENT_STREAM_SINKS.attributes),
  }),
  '@@cotera-manifest-def-information-schema': (data) => ({
    INFORMATION: StrictValues(
      data.INFORMATION.map((row) => ({
        ...row,
        table_schema: MANIFEST_INFORMATATION_SCHEMA_NAME,
        data_type: Ty.displayTy(row.ty),
        is_nullable: row.ty.nullable,
      })),
      InformationSchema({ type: 'columns', schemas: ['@@dummy'] }).attributes
    ),
  }),
};

const buildScopeVars = (
  vars: Record<string, Expression | Relation>
): AST.MacroVarReplacements =>
  Object.entries(vars).reduce<AST.MacroVarReplacements>(
    (scopeVars, [name, replacement]) => {
      if (replacement instanceof Expression) {
        return {
          ...scopeVars,
          exprs: { ...scopeVars.exprs, [name]: replacement.ast },
        };
      } else if (replacement instanceof Relation) {
        return {
          ...scopeVars,
          rels: { ...scopeVars.rels, [name]: replacement.ast },
        };
      } else {
        return Assert.unreachable(replacement);
      }
    },
    { exprs: {}, rels: {}, sections: {} }
  );

export const provideWithAsyncResults = (provider: RuntimeDataProvider) => {
  return _.mapValues(
    provider,
    (res, name): AST.MacroVarReplacements =>
      buildScopeVars(
        RUNTIME_DATA_TO_SCOPE_VARS[name as keyof RuntimeDataProvider](
          res as any
        )
      )
  );
};

const provideAsync = async <T>(
  neededScopes: string[],
  provider: AsyncFnRuntimeDataProvider<T>,
  args: T
): Promise<{ scope: string; vars: AST.MacroVarReplacements }[]> => {
  const dataPromises: (() => Promise<{
    scope: string;
    vars: AST.MacroVarReplacements;
  }>)[] = [];

  const providedScopes = Object.keys(
    provider
  ) as (keyof AsyncFnRuntimeDataProvider<T>)[];

  for (const scope of providedScopes.filter((scope) =>
    neededScopes.includes(scope)
  )) {
    dataPromises.push(async () => {
      const data = await provider[scope](args);
      // This is safe, TS just doesn't know we're calling the same scope on both providers;
      // thus the `as any`
      const mapped = RUNTIME_DATA_TO_SCOPE_VARS[scope](data as any);
      return { scope, vars: buildScopeVars(mapped) };
    });
  }

  return Promise.all(dataPromises.map((f) => f()));
};

const StrictValues = (
  rows: readonly Ty.Row[],
  attributes: Record<string, Ty.Shorthand>
): Relation =>
  Values(
    rows.map((row) => _.pick(row, Object.keys(attributes))),
    attributes
  );
