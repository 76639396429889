import { ExprVar, RelVar, Ty, InformationSchema } from '@cotera/era';

export const ORG_ID = ExprVar.create({
  scope: '@@cotera-org',
  name: 'ORG_ID',
  ty: Ty.nn('string'),
});

export const ORG_NAME = ExprVar.create({
  scope: '@@cotera-org',
  name: 'ORG_NAME',
  ty: Ty.nn('string'),
});

export const ENTITY_DEFINITIONS = RelVar.create({
  scope: '@@cotera-entities',
  name: 'DEFINITIONS',
  ty: {
    name: Ty.nn('string'),
    uuid: Ty.nn('string'),
    id_type: Ty.nn('string'),
  },
});

export const MANIFEST_DEF_INFORMATION_SCHEMA = RelVar.create({
  scope: '@@cotera-manifest-def-information-schema',
  name: 'INFORMATION',
  ty: InformationSchema({ schemas: ['@@dummy'], type: 'columns' }).attributes,
});

export const EVENT_CURSORS = RelVar.create({
  scope: '@@cotera-events',
  name: 'CURSORS',
  ty: {
    paused_at: 'timestamp',
    cotera_stable_id: Ty.nn('string'),
    entity_id: Ty.nn('string'),
    sink_id: Ty.nn('string'),
    event_stream_id: Ty.nn('string'),
    detected_at_time: Ty.nn('timestamp'),
  },
});

export const MANIFEST_EVENT_STREAM_SINKS = RelVar.create({
  scope: '@@cotera-manifest-event-stream-sinks',
  name: 'SINKS',
  ty: {
    entity_id: Ty.nn('string'),
    sink_id: Ty.nn('string'),
    event_stream_id: Ty.nn('string'),
    type: Ty.nn('string'),
  },
});

export const KEYWORD_CONFIG = (name: string) =>
  RelVar.create({
    name,
    scope: '@@cotera-keywords-config',
    ty: { name: Ty.nn('string'), key: 'string' },
  });

export const INTENT_SIGNAL_NODES = (name: string) =>
  RelVar.create({
    name,
    scope: '@@cotera-intent-signal-nodes',
    ty: {
      signalId: Ty.nn('string'),
      signalName: Ty.nn('string'),
      name: Ty.nn('string'),
      description: Ty.ty('string'),
      parentId: Ty.ty('string'),
      id: Ty.nn('string'),
      createdAt: Ty.nn('timestamp'),
      updatedAt: Ty.nn('timestamp'),
    },
  });
