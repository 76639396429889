import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { classNames } from '../../utils';
import { ChildrenProps } from '@cotera/client/app/components/utils';
import { makeStore, StateSetter } from '@cotera/client/app/etc';

type State = {
  open: boolean;
};
const actions = (set: StateSetter<State>) => ({
  toggle: (open?: boolean) => set((state) => ({ open: open ?? !state.open })),
});
type Actions = ReturnType<typeof actions>;

export const { provider: DropdownProvider, hook: useDropdown } = makeStore<
  State,
  Actions
>();

export const Dropdown: React.FC<{
  children: React.ReactNode;
  open?: boolean;
}> = ({ children, open }) => (
  <DropdownProvider
    state={{
      open: open ?? false,
    }}
    actions={actions}
  >
    <View>{children}</View>
  </DropdownProvider>
);

const View: React.FC<ChildrenProps> = ({ children }) => {
  const open = useDropdown((s) => s.open);
  const toggle = useDropdown((s) => s.actions.toggle);
  return (
    <DropdownMenu.Root onOpenChange={toggle} open={open}>
      {children}
    </DropdownMenu.Root>
  );
};

export const DropdownContent: React.FC<
  ChildrenProps & {
    align?: 'start' | 'end' | 'center';
    side?: 'bottom' | 'top' | 'right' | 'left';
    style?: React.CSSProperties;
  }
> = ({ children, align = 'center', side = 'bottom', style }) => {
  return (
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        align={align}
        side={side}
        sideOffset={5}
        className="flex flex-col justify-center items-center z-10"
        style={style}
      >
        <div className="bg-white rounded px-2 py-3 shadow-lg border-divider border min-w-[150px] space-y-2 text-gray-800">
          {children}
        </div>
        <DropdownMenu.Arrow className="fill-gray-300" />
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  );
};

export const DropdownItem = ({
  children,
  icon,
  active,
  onClick,
}: {
  icon: JSX.Element;
  active?: boolean;
  onClick?: () => void;
} & ChildrenProps) => {
  return (
    <div
      className={classNames(
        'flex flex-row items-center px-2 py-2 text-sm ring-0 hover:ring-0 hover:outline-none rounded',
        active ? 'cursor-default' : 'hover:bg-gray-100'
      )}
      role="button"
      onClick={onClick}
    >
      <span className={classNames(active ? 'text-primary-text' : '', 'mr-3')}>
        {icon}
      </span>
      {children}
    </div>
  );
};

export const DropdownTrigger: React.FC<ChildrenProps> = ({ children }) => {
  return <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>;
};
