import { AST } from '../ast';
import { Expression } from '../builder';
import { Result } from 'neverthrow';
import { TyStackTrace } from '../type-checker';
import { Assert } from '../utils';

export const GROUPING = ['(', ')', '[', ']', ','] as const;

export type Grouping = (typeof GROUPING)[number];

export const isGrouping = (x: string): x is Grouping =>
  GROUPING.includes(x as any);

export const OPERATORS = [
  '??',
  '||',
  'like',
  ...AST.MATH_FUNCTIONS,
  ...AST.CONNECTIVE_FUNCTIONS,
  ...AST.COMPARISON_FUNCTIONS,
] as const;

export type Operator = (typeof OPERATORS)[number];

export const isOperator = (x: string): x is Operator =>
  OPERATORS.includes(x as any);

export type GroupingToken = {
  readonly t: 'grouping';
  readonly op: Grouping;
};

export type SymbolToken = {
  readonly t: 'symbol';
  readonly sym: string;
};

export type OperatorToken = {
  readonly t: 'operator';
  readonly op: Operator;
};

export type IdentToken = {
  readonly t: 'ident';
  readonly name: string;
};

export type LiteralToken = {
  readonly t: 'literal';
  readonly val: string | boolean | number | Date;
};

export type RawExpressionToken = {
  readonly t: 'raw-expression';
  readonly val: Expression;
};

export type WhiteSpaceToken = {
  readonly t: 'white-space';
  readonly space: string;
};

export type UnknownToken = {
  readonly t: 'unknown';
  readonly found: string;
};

export type NongroupingToken =
  | SymbolToken
  | OperatorToken
  | IdentToken
  | LiteralToken
  | RawExpressionToken
  | WhiteSpaceToken
  | UnknownToken;

export type Token = GroupingToken | NongroupingToken;

export type TokenMeta = {
  readonly range: readonly [number, number];
};

const op = (opToken: string): OperatorToken => {
  if (isOperator(opToken)) {
    return { t: 'operator', op: opToken };
  } else {
    const error = new Error(`"${opToken}" is not a valid operator`);
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, op);
    }
    throw error;
  }
};

const group = (opToken: string): GroupingToken => {
  if (isGrouping(opToken)) {
    return { t: 'grouping', op: opToken };
  } else {
    const error = new Error(`"${opToken}" is not a valid grouping`);
    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(error, op);
    }
    throw error;
  }
};

export const T = {
  op,
  group,
  unknown(found: string): UnknownToken {
    return { t: 'unknown', found };
  },
  lit(val: string | number | boolean | Date): LiteralToken {
    return { t: 'literal', val };
  },
  ident(name: string): IdentToken {
    return { t: 'ident', name };
  },
  ws(space: string): WhiteSpaceToken {
    return { t: 'white-space', space };
  },
  rawExpr(val: Expression): RawExpressionToken {
    return { t: 'raw-expression', val };
  },
  sym(sym: string): SymbolToken {
    return { t: 'symbol', sym };
  },
};

export type UnclosedDelimError = {
  readonly t: 'unclosed-deliminator';
  readonly delim: string;
};

export type TypeCheckError = {
  readonly t: 'type-check-error';
  readonly trace: TyStackTrace;
};

export type EmptyExpression = {
  readonly t: 'empty-expression';
};

export type UnexpectedToken = {
  readonly t: 'unexpected-token';
  readonly token: string;
};

export type ParseError =
  | UnclosedDelimError
  | TypeCheckError
  | EmptyExpression
  | UnexpectedToken;

export type WithMeta<T> = readonly [data: T, meta: TokenMeta];

export type ParseResult = Result<Expression, WithMeta<ParseError>>;

export type ExprCtx = {
  readonly attributes: { readonly [name: string]: Expression };
};

export const printTokens = (tokens: Iterable<WithMeta<Token>>): string => {
  const output: string[] = [];

  for (const [token, _meta] of tokens) {
    switch (token.t) {
      case 'literal':
        if (typeof token.val === 'string') {
          output.push(`'${token.val}'`);
        } else {
          output.push(`${token.val}`);
        }
        break;
      case 'raw-expression':
        output.push(`$EXPR`);
        break;
      case 'operator':
      case 'grouping': {
        output.push(token.op);
        break;
      }
      case 'symbol':
        output.push(token.sym);
        break;
      case 'ident':
        output.push(`"${token.name}"`);
        break;
      case 'white-space':
        break;
      case 'unknown':
        throw new Error('TODO: print unknown token');
      default:
        return Assert.unreachable(token);
    }
  }

  return output.join(' ');
};
