import { EraSQL, Relation, Ty } from '@cotera/era';
import { useQueryBuilder } from './hooks/use-query-builder';
import React from 'react';
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  TrashIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import { EditableSqlExpression } from './hooks/use-multi-sql-expression-builder';
import { Button, Tooltip } from '@cotera/client/app/components/ui';
import { H1, H3 } from '@cotera/client/app/components/ui/typography';
import { Inputs } from '@cotera/client/app/components/forms';

export const QueryBuilder: React.FC<{
  source: Relation;
  onCommit: (x: { rel: Relation }) => void;
}> = ({ source, onCommit }) => {
  const { where, grouping, select, commit } = useQueryBuilder({
    source,
    onCommit,
  });

  return (
    <div className="flex flex-col space-y-4">
      <div>
        <div className="flex flex-row items-center gap-x-2">
          <Tooltip text="Add Where Clause" side="top">
            <PlusCircleIcon onClick={() => where.add()} className="size-6" />
          </Tooltip>
          <H1>Where</H1>
        </div>
        <div className="flex flex-col gap-y-4 py-2">
          {where.drafts.map((draft) => (
            <RenderDraft draft={draft} nameMode="hidden" />
          ))}
        </div>
      </div>
      <div>
        <div
          className={`flex flex-row items-center gap-x-2 ${
            grouping.cols === null ? 'opacity-30' : ''
          }`}
        >
          <Tooltip text="Add Grouping Clause" side="top">
            <PlusCircleIcon
              onClick={() => grouping.cols?.add()}
              className="size-6"
            />
          </Tooltip>
          <H1>Grouping</H1>
          <Inputs.Checkbox
            checked={grouping.cols !== null}
            onChange={() => grouping.setAggregate((x) => !x)}
          />
        </div>
        <div className="flex flex-col gap-y-4 py-2">
          {grouping.cols?.drafts.map((draft) => (
            <RenderDraft draft={draft} nameMode="editable" />
          ))}
        </div>
      </div>
      <div>
        <div className="flex flex-row items-center gap-x-2">
          <Tooltip text="Add Column" side="top">
            <PlusCircleIcon
              onClick={() => select.additional.add()}
              className="size-6"
            />
          </Tooltip>
          <H1>Select</H1>
        </div>
        <div className="flex flex-row gap-x-4 py-2 items-center">
          <div className="flex flex-row items-center gap-x-4">
            <Inputs.Checkbox
              checked={select.star}
              onChange={() => select.setStar((x) => !x)}
            />
            <H3>Select Star</H3>
          </div>
          <div className="flex flex-row items-center gap-x-4">
            <Inputs.Checkbox
              checked={select.distinct}
              onChange={() => select.setDistinct((x) => !x)}
            />
            <H3>Distinct</H3>
          </div>
        </div>
        <div className="flex flex-col gap-y-4 py-2">
          {select.additional.drafts.map((draft) => (
            <RenderDraft draft={draft} nameMode="editable" />
          ))}
        </div>
      </div>
      <div>
        <Button
          text="Apply"
          disabled={commit === null}
          onClick={() => commit?.()}
        />
      </div>
    </div>
  );
};

type NameMode = 'hidden' | 'fixed' | 'editable';

export const RenderDraft: React.FC<{
  nameMode: NameMode;
  draft: EditableSqlExpression;
}> = ({ draft, nameMode }) => {
  const name: Record<NameMode, React.ReactNode> = {
    hidden: <></>,
    fixed: <div>{draft.name}</div>,
    editable: (
      <>
        <Inputs.Text
          value={draft.name}
          onChange={(name) => draft.set({ name })}
        />
        <div>=&#62;</div>
      </>
    ),
  };
  return (
    <div key={draft.key} className="flex flex-row gap-x-2 items-center">
      <TrashIcon className="size-4" onClick={draft.remove} />
      <DisplaySqlParse parse={draft.parse} />
      {name[nameMode]}
      <Inputs.Text
        value={draft.sql}
        type="text"
        onChange={(sql) => draft.set({ sql })}
      />
    </div>
  );
};

const DisplaySqlParse: React.FC<{ parse: EraSQL.ParseResult }> = ({
  parse,
}) => {
  if (parse.isOk()) {
    const ty = Ty.displayTy(parse.value.ty);
    return (
      <Tooltip text={ty} side="top">
        <CheckCircleIcon className="size-6 text-green-700" />
      </Tooltip>
    );
  } else {
    let text: string;

    const [
      e,
      {
        range: [start, stop],
      },
    ] = parse.error;

    switch (e.t) {
      case 'unclosed-deliminator':
        text = `Unclosed "${e.delim}"`;
        break;
      case 'type-check-error':
        text = e.trace.headline;
        break;
      case 'unexpected-token':
        text = `Unexpected "${e.token}"`;
        break;
      case 'empty-expression':
        text = 'Expression Expected';
        break;
    }

    return (
      <Tooltip text={`${start}->${stop}: ${text}`} side="top">
        <ExclamationTriangleIcon className="size-6 text-red-500" />
      </Tooltip>
    );
  }
};
