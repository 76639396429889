import {
  BottomNav,
  Breadcrumbs,
  BreadcrumbsProps,
  Header,
  Loading,
  Overlay,
  Protected,
  SideBar,
  useSidebar,
} from './components';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { DevMode } from './components/app/side-bar/dev-mode';
import { useAppData, useWhoami } from './stores/org';
import { OrgMenu } from './components/app/side-bar/org-menu';
import { classNames } from './components/utils';
import { useCommandPallet } from './components/app/cmdk';
import { config } from '@cotera/client/config/config';
import { ChildrenProps } from '@cotera/client/app/components/utils';
import {
  Dropdown,
  DropdownContent,
  DropdownTrigger,
} from '@cotera/client/app/components/headless';
import { Button, Icon } from '@cotera/client/app/components/ui';

export interface LayoutProps {
  actions?: ReactNode[];
  children: ReactNode | ReactNode[];
  mainClassName?: string;
  loading?: boolean;
  bottomNav?: () => ChildrenProps['children'];
  scroll?: boolean;
}

export const OrgLayout = ({ children }: Pick<LayoutProps, 'children'>) => {
  const skeleton = useAppData((x) => x.skeleton);
  const sideBarCollapsed = useSidebar((s) => s.collapsed);
  const collapseSidebar = useSidebar((s) => s.setCollapsed);

  return (
    <div className="w-full h-screen">
      <SideBar.Container>
        <SideBar.Main>
          <Protected scopes={[['use', 'explore']]}>
            <SideBar.Link
              to="/explore"
              name="Explore"
              icon="magnifying-glass"
              activeFn={(location) =>
                location.startsWith('/explore/data') || location === '/explore'
              }
            />
            <SideBar.Link
              to="/explore/warehouse"
              name="Explore Warehouse"
              icon="circle-stack"
            />
          </Protected>
          <Protected scopes={[['write', 'integrations']]}>
            <SideBar.Link name="Connections" to="/connections" icon="link" />
          </Protected>
          <SideBar.Separator />
          <SideBar.Link
            to="/apps"
            name="Apps"
            icon="squares-plus"
            activeOnExact
          />
          {...Object.entries(skeleton.apps)
            .filter(([_, mu]) => mu.hasHomePage)
            .map(([id, mu]) => (
              <SideBar.Link
                key={id}
                name={mu.title ?? id}
                to={`/apps/${id}`}
                icon={mu.icon ?? 'variable'}
              />
            ))}
        </SideBar.Main>
        <SideBar.End>
          <Protected scopes={[['use', 'superadmin']]}>
            <Dropdown>
              <DropdownTrigger>
                <div>
                  <SideBar.NavItem
                    name="Admin Tools"
                    isActive={false}
                    icon="wrench-screwdriver"
                  />
                </div>
              </DropdownTrigger>
              <DropdownContent align="end" side="right">
                <SideBar.Link
                  to="/entities"
                  name="Entities"
                  icon="tag"
                  expand
                />
                <SideBar.Link
                  to="/sql-explorer"
                  name="Sql Explorer"
                  icon="scissors"
                  expand
                />
                <SideBar.Link
                  to="/artifacts"
                  name="Artifacts"
                  icon="code-bracket"
                  expand
                />
                <SideBar.Link
                  to="/superadmin"
                  name="Super Admin"
                  icon="wrench-screwdriver"
                  expand
                />
                <SideBar.Link
                  to="/events"
                  name="Events"
                  icon="identification"
                  expand
                />
              </DropdownContent>
            </Dropdown>
          </Protected>
        </SideBar.End>
      </SideBar.Container>
      <div className="relative h-full w-full md:ml-[52px] md:w-[calc(100%-52px)]">
        {!sideBarCollapsed && (
          <Overlay z="z-[5]" onClick={() => collapseSidebar(true)} />
        )}
        {children}
      </div>
    </div>
  );
};

export const Layout: React.FC<
  LayoutProps & {
    header?: Pick<BreadcrumbsProps, 'breadcrumbs'>;
  } & React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >
> = ({
  children,
  className,
  mainClassName,
  loading,
  header = {},
  scroll = true,
  bottomNav,
  ...rest
}) => {
  return (
    <div
      className={classNames(
        'flex-grow bg-white overflow-hidden h-screen',
        className
      )}
      {...rest}
    >
      <TopNav header={header} />
      {loading && (
        <Loading.Shimmer className="w-full">
          <div className="h-2 bg-secondary-background -mt-5" />
        </Loading.Shimmer>
      )}
      {bottomNav && <BottomNav.Container>{bottomNav()}</BottomNav.Container>}

      <main
        role="main"
        className={classNames(
          'main flex relative flex-grow px-3',
          mainClassName,
          scroll ? 'overflow-auto' : '',
          !loading ? 'pt-4' : '',
          bottomNav
            ? 'h-[calc(100%-(var(--header-height)*2-1px))]'
            : 'h-[calc(100%-var(--header-height))]'
        )}
      >
        {children}
      </main>
    </div>
  );
};

const TopNav: React.FC<{
  header: Pick<BreadcrumbsProps, 'breadcrumbs'>;
}> = ({ header }) => {
  const whoami = useWhoami((s) => s.whoami);
  const abilities = useWhoami((s) => s.abilities);
  const setOrgId = useWhoami((s) => s.actions.changeOrg);
  const navigate = useNavigate();

  return (
    <Header.Container {...header}>
      <div className="hidden items-center flex-grow md:flex">
        <Breadcrumbs breadcrumbs={header.breadcrumbs} />
      </div>
      <div className="flex items-center flex-grow md:ml-0 ml-[20px]">
        <Search />
      </div>
      <div className="ml-auto flex items-center md:flex-grow justify-end">
        {config.demo && (
          <Button
            link={{
              to: '/signup',
            }}
            theme="secondary"
            className="mr-4"
            text="Sign Up"
          />
        )}
        {abilities.can(['use', 'devserver']) && (
          <div className="hidden md:flex items-center">
            <Header.Item>
              <DevMode />
            </Header.Item>
          </div>
        )}
        <Header.Item>
          <OrgMenu
            whoami={whoami}
            switchOrg={(id) => {
              navigate('/');
              setOrgId(id);
            }}
            signOut={() => navigate('/logout')}
          />
        </Header.Item>
      </div>
    </Header.Container>
  );
};

const Search = () => {
  const setOpen = useCommandPallet((state) => state.actions.setOpen);

  return (
    <div
      className="text-sm text-standard-text flex flex-1 items-center justify-between rounded px-3 py-2 my-2 max-w-[350px] border border-divider hover:border-gray-400 cursor-pointer"
      onClick={() => setOpen(true)}
    >
      <span className="flex items-center">
        <Icon icon="search" theme="regular" className="mr-2" />
        <span className="hidden md:inline-block	word-break-keep whitespace-nowrap">
          Search or jump to...
        </span>
      </span>
      <span className="justify-self-end">⌘K</span>
    </div>
  );
};
