import {
  Badge,
  Button,
  Icon,
  ProgressBar,
  Text,
} from '@cotera/client/app/components/ui';
import { useDataGridStore } from '../context';
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '../../headless/dropdown/index';
import { jsonToCsv } from '@cotera/client/app/components/utils';

export const Footer: React.FC = () => {
  const dataLength = useDataGridStore((s) => s.dataLength);
  const inViewRows = useDataGridStore((s) => s.inViewRows);
  const totalRows = useDataGridStore((s) => s.totalRows);
  const maxInViewIndex =
    inViewRows.length === 0 ? Math.min(50, totalRows) : Math.max(...inViewRows);
  const selectedRows = useDataGridStore((s) => s.selectedRows);

  return (
    <div className="flex flex-col w-[calc(100%-1em)] border-t">
      {totalRows > 0 && (
        <ProgressBar
          theme="inverse"
          percent={
            dataLength === 0
              ? 0
              : (Math.min(maxInViewIndex, totalRows) / totalRows) * 100
          }
          className="w-full h-1"
        />
      )}

      <div className="w-full flex sticky bottom-0 bg-white pt-2 left-0 items-center justify-between">
        <div className="flex items-center">
          {totalRows > 0 && (
            <Text.Caption className="text-center">
              Loaded {dataLength} of {totalRows} rows
            </Text.Caption>
          )}
        </div>
        <div className="flex items-center">
          {selectedRows.length > 0 && (
            <>
              <Badge theme="secondary">
                {selectedRows.length} rows selected
              </Badge>
              <FooterMenu />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const FooterMenu: React.FC = () => {
  const data = useDataGridStore((s) => s.data);
  const selectedItems = useDataGridStore((s) => s.selectedRows);

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button inline icon="ellipsis-vertical" />
      </DropdownTrigger>
      <DropdownContent>
        <DropdownItem
          icon={<Icon icon="clipboard-document" />}
          active={false}
          onClick={async () => {
            const json = JSON.stringify(
              selectedItems.map((x) => data?.row(x).toArray()).flat()
            );

            await navigator.clipboard.writeText(json);
          }}
        >
          Copy as JSON
        </DropdownItem>
        <DropdownItem
          icon={<Icon icon="clipboard-document-list" />}
          active={false}
          onClick={async () => {
            const csv = await jsonToCsv(
              selectedItems.map((x) => data?.row(x).toArray()).flat()
            );

            await navigator.clipboard.writeText(csv);
          }}
        >
          Copy as CSV
        </DropdownItem>
      </DropdownContent>
    </Dropdown>
  );
};
