import { Relation, Ty } from '@cotera/era';
import { createContext } from 'react';
import {
  makeStoreProvider,
  makeStoreContextHook,
  StateSetter,
} from '@cotera/client/app/etc';
import { QueryData } from '../../etc/duckdb';

export type ExploreAttribute = Ty.ExtendedAttributeType & {
  hidden?: boolean;
  name: string;
};

export type ExploreState = {
  fromArtifactId: string | null;
  definitionId: string;
  definition: Relation;
  rel: Relation;
  attributes: Record<string, ExploreAttribute>;
};

export type ExploreActionsType = {
  setRel: (rel: Relation) => void;
};

const ExploreContext = createContext<ExploreState>(undefined as any);

const InternalExploreProvider = makeStoreProvider<
  ExploreState,
  ExploreActionsType
>(ExploreContext);

export const useExplore = makeStoreContextHook<
  ExploreState,
  ExploreActionsType
>(ExploreContext);

const actions = (set: StateSetter<ExploreState>): ExploreActionsType => {
  return {
    setRel: (rel) => {
      set(() => ({ rel }));
    },
  };
};

export const ExploreProvider: React.FC<{
  children: React.ReactNode;
  definitionId: string;
  definition: Relation;
  sample: QueryData;
  fromArtifactId: string | null;
}> = ({ children, definition, definitionId, sample, fromArtifactId }) => {
  const { artifactRel: rel } = sample;

  return (
    <InternalExploreProvider
      state={{
        fromArtifactId,
        attributes: Object.fromEntries(
          Object.entries(sample.artifactRel.attributes).map(([name, attr]) => [
            name,
            { ...attr, name },
          ])
        ),
        rel,
        definition,
        definitionId,
      }}
      actions={actions}
    >
      {children}
    </InternalExploreProvider>
  );
};
