import { Relation, Expression, And, Grouping, RelationRef } from '@cotera/era';
import { useState } from 'react';
import {
  DraftColumns,
  useMultiSqlExpressionBuilder,
} from './use-multi-sql-expression-builder';

type Dispatch<T> = (x: T | ((prev: T) => T)) => void;

type DraftSqlQuery = {
  where: DraftColumns;
  grouping: {
    setAggregate: Dispatch<boolean>;
    cols: DraftColumns | null;
  };
  select: {
    star: boolean;
    setStar: Dispatch<boolean>;
    distinct: boolean;
    setDistinct: Dispatch<boolean>;
    additional: DraftColumns;
  };
  commit: null | (() => void);
};

export const useQueryBuilder = (x: {
  source: Relation;
  onCommit: (x: { rel: Relation }) => void;
}): DraftSqlQuery => {
  const { source, onCommit } = x;

  const [star, setStar] = useState<boolean>(true);
  const [distinct, setDistinct] = useState(false);
  const [aggregate, setAggregate] = useState<boolean>(false);

  const where = useMultiSqlExpressionBuilder({
    attributes: source.ref('from').star(),
    allowAggregate: false,
    typeImplsOneOf: () => ['boolean'],
  });

  const validWheres = where.drafts
    .map((draft) => draft.parse.unwrapOr(null))
    .filter((x) => x !== null);

  const filtered =
    validWheres.length === 0
      ? source
      : source.where((_t) => And(...validWheres));

  const grouping = useMultiSqlExpressionBuilder({
    attributes: filtered.ref('from').star(),
    allowAggregate: false,
    typeImplsOneOf: () => ['boolean', 'timestamp', 'string', 'float', 'int'],
  });

  const validGrouping = grouping.drafts
    .map((draft) =>
      draft.parse.map((expr) => [draft.name, expr] as const).unwrapOr(null)
    )
    .filter((x) => x !== null);

  const grouped = aggregate
    ? filtered.groupBy((_) => Object.fromEntries(validGrouping))
    : filtered;

  const postGroupingAttributes: Record<string, Expression> = {
    ...source.ref('from').star(),
    ...(grouped instanceof Grouping ? grouped.ref().group() : {}),
  };

  const additional = useMultiSqlExpressionBuilder({
    allowAggregate: aggregate,
    attributes: postGroupingAttributes,
  });

  const validAdditional = additional.drafts
    .map((draft) =>
      draft.parse.map((expr) => [draft.name, expr] as const).unwrapOr(null)
    )
    .filter((x) => x !== null);

  const selected = grouped.select(
    (t): Record<string, Expression> => {
      const base = star
        ? t instanceof RelationRef
          ? t.star()
          : t.group()
        : {};

      return { ...base, ...Object.fromEntries(validAdditional) };
    },
    { distinct }
  );

  const isValid =
    where.drafts.every((draft) => draft.parse.isOk()) &&
    additional.drafts.every((draft) => draft.parse.isOk()) &&
    (!aggregate || grouping.drafts.every((draft) => draft.parse.isOk()));

  const commit = () => {
    onCommit({ rel: selected });
  };

  return {
    where,
    grouping: {
      setAggregate,
      cols: aggregate ? grouping : null,
    },
    select: { additional, star, setStar, distinct, setDistinct },
    commit: isValid ? commit : null,
  };
};
