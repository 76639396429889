import { z } from 'zod';
import { Assert } from '@cotera/utilities';
import type { Scope } from '@cotera/contracts';

export const TENANTED_ROLES = [
  'ADMIN',
  'ARTIFACTUSER',
  'DEVELOPER',
  'VIEWER',
  'DEMO',
] as const;
export const UNTENANTED_ROLES = ['SUPERADMIN', 'SYSTEM'] as const;
export const ROLES = [...TENANTED_ROLES, ...UNTENANTED_ROLES];

export const AVAILABLE_SCOPES = {
  integrations: ['write'],
  warehouse: ['manage', 'write', 'read'],
  application: ['use'],
  superadmin: ['use'],
  explore: ['use'],
  apps: ['read'],
  devserver: ['use'],
  'magic-columns': ['write', 'read'],
  _test: ['foo', 'bar', 'baz'],
} as const;

type Subject = keyof typeof AVAILABLE_SCOPES;

export type Role = TenantedRole | UntenantedRole;

type Allowed = Map<string, Set<string>>;

class ProtectedObjectBuilder<T extends Record<string, any> = {}> {
  private object: T;

  constructor(
    private readonly abilities: CoteraAbilities,
    object: T = {} as T
  ) {
    this.object = object;
  }

  obj<V extends Record<string, any>>(
    [action, subject]: Scope,
    value: V
  ): ProtectedObjectBuilder<T & V> {
    if (this.abilities.can([action, subject])) {
      const newObj = { ...this.object, ...value };
      return new ProtectedObjectBuilder<T & V>(this.abilities, newObj);
    }

    return this as unknown as ProtectedObjectBuilder<T & V>;
  }

  allowedProperties(): T {
    return this.object;
  }
}

class ProtectedArrayBuilder<T> {
  constructor(
    private readonly abilities: CoteraAbilities,
    private readonly _array: T[] = []
  ) {}

  array([action, subject]: Scope, value: T[]): ProtectedArrayBuilder<T> {
    if (this.abilities.can([action, subject])) {
      const newArray = [...this._array, ...value];
      return new ProtectedArrayBuilder<T>(this.abilities, newArray);
    }

    return this as ProtectedArrayBuilder<T>;
  }

  allowedItems(): T[] {
    return this._array;
  }
}

export class CoteraAbilities {
  constructor(private readonly allowed: Allowed) {}

  obj<V extends Record<string, any>>(
    [action, subject]: Scope,
    value: V
  ): ProtectedObjectBuilder<V> {
    if (this.can([action, subject])) {
      return new ProtectedObjectBuilder<V>(this, value);
    }

    return new ProtectedObjectBuilder<V>(this);
  }

  array<T>([action, subject]: Scope, value: T[]): ProtectedArrayBuilder<T> {
    if (this.can([action, subject])) {
      return new ProtectedArrayBuilder<T>(this, value);
    }

    return new ProtectedArrayBuilder<T>(this);
  }

  can([action, subject]: Scope): boolean {
    return this.allowed.get(subject)?.has(action) ?? false;
  }

  static forRoles(roles: Role[]): CoteraAbilities {
    const allowed: Allowed = new Map();

    const can = (action: string, subject: Subject) => {
      const forSubject = allowed.get(subject) ?? new Set();
      forSubject.add(action);
      allowed.set(subject, forSubject);
    };

    for (const role of roles) {
      switch (role) {
        case 'SYSTEM':
          can('use', 'superadmin');
          continue;
        case 'SUPERADMIN':
          can('use', 'superadmin');
          can('use', 'devserver');
          continue;
        case 'ADMIN':
          can('use', 'application');
          can('manage', 'warehouse');
          can('read', 'warehouse');
          can('write', 'warehouse');
          can('write', 'integrations');
          can('use', 'explore');
          can('read', 'apps');
          can('write', 'magic-columns');
          can('read', 'magic-columns');
          continue;
        case 'VIEWER':
          can('read', 'apps');
          can('use', 'application');
          can('read', 'magic-columns');
          continue;
        case 'DEMO':
          can('read', 'apps');
          can('use', 'application');
          can('use', 'devserver');
          can('read', 'magic-columns');
          continue;
        case 'ARTIFACTUSER':
          can('use', 'application');
          can('read', 'warehouse');
          can('write', 'warehouse');
          continue;
        case 'DEVELOPER':
          can('use', 'devserver');
          can('read', 'apps');
          can('read', 'magic-columns');
          continue;
        default:
          return Assert.unreachable(role);
      }
    }

    return new this(allowed);
  }
}

export const TenantedRoleSchema = z.enum(TENANTED_ROLES);
export const UntenantedRoleSchema = z.enum(UNTENANTED_ROLES);

export type UntenantedRole = z.infer<typeof UntenantedRoleSchema>;
export type TenantedRole = z.infer<typeof TenantedRoleSchema>;
