import { AST, Ty, Relation, Constant, Expression } from '@cotera/era';
import { Row } from './row';
import React from 'react';
import { classNames } from '@cotera/client/app/components/utils';
import {
  Chip,
  Text,
  EnhancedPercentage,
  Loading,
  TEXT_STYLE,
  Style,
} from '@cotera/client/app/components';
import { Formatters, ChildrenProps } from '@cotera/client/app/components/utils';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { Column } from './column';
import { z } from 'zod';
import { mapValues } from 'lodash';
import {
  useEraScopesAwareRelIR,
  useTypedExpressionValue,
} from '../compiler/macro-expansion/scopes';
import { H2 } from '@cotera/client/app/components/ui/typography';

const StatisticSchema = z.object({
  value: z.number().nullable(),
  title: z.string().nullish(),
  from: z.number().nullish(),
  style: z.enum(['positive', 'negative', 'warning', 'neutral']).nullish(),
  unit: z.string().nullish(),
});

export type Statistic = z.infer<typeof StatisticSchema>;

export const RenderStats: React.FC<{
  section: AST._Stats;
}> = ({ section }) => {
  const caption = useTypedExpressionValue(
    section.caption ?? Constant(null, { ty: 'string' }),
    z.string().nullable()
  );

  return (
    <Column>
      <Row className="flex-wrap items-stretch mb-2">
        {section.stats.map((stat, i) => (
          <RenderStat key={i} stat={stat} />
        ))}
      </Row>

      <Row className="w-full mt-4 px-4 pt-2">
        <Text.Caption className="w-full border-t border-divider pt-4">
          {caption}
        </Text.Caption>
      </Row>
    </Column>
  );
};

export const RenderStateSkeleton: React.FC<{
  section: AST._Stats;
}> = ({ section }) => {
  return section.stats.map((stat, i) => (
    <Container key={i}>
      <Column>
        <StatSkeleton config={stat.config} />
      </Column>
    </Container>
  ));
};

const RenderStat: React.FC<{ stat: AST._Stat }> = ({ stat }) => {
  const chartRelIR = useEraScopesAwareRelIR(stat.rel);

  const result = useDuckDBQuery({ rel: Relation.wrap(chartRelIR) });

  // TODO: Make sure these can read scopes...
  const configValues = mapValues(stat.config, (expr) =>
    Expression.fromAst(expr ?? Constant(null, { ty: 'string' }).ast).evaluate()
  );

  const items: Statistic[] = result.data.data
    .toArrayOf(StatisticSchema)
    .map((row) => {
      const combined: Record<string, Ty.Scalar | undefined> = {
        ...mapValues(
          configValues,
          (val, key) => row[key as keyof Statistic] ?? val
        ),
        from: row.from,
        value: row.value,
      };

      return StatisticSchema.parse(combined);
    });

  return (
    <Container>
      <Row className="flex-wrap items-stretch">
        {items.map((item, i) => (
          <BigNumber key={i} {...item} />
        ))}
      </Row>
    </Container>
  );
};

const StatSkeleton: React.FC<{
  config: AST._Stat['config'];
}> = ({ config: _config }) => {
  // TODO use config to render static parts
  return (
    <>
      <Loading.Shimmer className="w-[100px] h-[25px] mb-4" />
      <Loading.Shimmer className="w-[200px] h-[30px]" />
    </>
  );
};

const BigNumber: React.FC<Statistic> = ({
  title,
  value,
  from,
  unit,
  style = 'neutral',
}) => {
  return (
    <Container>
      <Text.P className="text-xl mb-1">{title}</Text.P>
      <div className="flex flex-row w-full items-end justify-between flex-wrap  mt-2">
        <div className="flex flex-row items-end flex-wrap mr-2">
          <Value value={value} unit={unit} from={from} style={style} />
        </div>
        {from !== null &&
          from !== undefined &&
          value !== null &&
          value !== undefined && (
            <Change value={Number(value)} from={Number(from)} style={style} />
          )}
      </div>
    </Container>
  );
};

const Change = ({
  value,
  from,
  style,
}: {
  value: number;
  from: number;
  style?: string | null;
}) => {
  const change = ((value - from) / from) * 100;

  let chipType: Style;

  if (
    style === 'positive' ||
    style === 'negative' ||
    style === 'warning' ||
    style === 'neutral'
  ) {
    chipType = style;
  } else {
    const isBad = change < 0;
    chipType = isBad ? 'negative' : 'positive';
  }

  return (
    <Chip className="mt-2" type={chipType}>
      <EnhancedPercentage
        value={change}
        className="mx-1 font-semibold"
        style={chipType}
      />
    </Chip>
  );
};

const Container: React.FC<ChildrenProps> = ({ children }) => (
  <div
    className={classNames(
      `
        border-r
        min-w-[150px]
        grow-1
        flex-1
        mt-2
        [&:not(:last-child)]:mr-4
        last:border-r-0
        px-4
      `
    )}
  >
    {children}
  </div>
);

const Value: React.FC<Omit<Statistic, 'title'>> = ({
  unit,
  value,
  style: color,
  from,
}) => {
  return (
    <>
      <H2 className={'mr-2'} textColor={TEXT_STYLE[color ?? 'neutral']}>
        {Formatters.number(value, unit ?? 'number')}
      </H2>

      {!!from && (
        <Text.Caption className="text-sm mb-1">
          from {Formatters.number(from, unit)}
        </Text.Caption>
      )}
    </>
  );
};
