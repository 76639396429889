import React from 'react';
import { StateSetter } from '@cotera/client/app/etc';
import { create } from 'zustand';
import Fuse from 'fuse.js';
import { useAppData } from '../../../stores/org';
import { AST } from '@cotera/era';
import {
  DocumentTextIcon,
  MagnifyingGlassPlusIcon,
  SquaresPlusIcon,
} from '@heroicons/react/24/outline';
import { classNames } from '../../utils';
import _ from 'lodash';
import { ManifestSkeleton } from '@cotera/api';
import { useKeyPress } from '../../../hooks/use-key-press';
import { Link } from 'react-router-dom';
import { Divider, ICON_MAPPING, Modal } from '@cotera/client/app/components/ui';
import { H3 } from '@cotera/client/app/components/ui/typography';
import { Inputs } from '@cotera/client/app/components/forms';
import { List } from '@cotera/client/app/components/headless';
import { CopilotIcon } from '../icons/copilot';

export type CommandPalletState = {
  readonly isOpen: boolean;
};

type Actions = (set: (state: Partial<CommandPalletState>) => void) => {
  readonly setOpen: (open: boolean) => void;
};

const actions = (set: StateSetter<CommandPalletState>) => ({
  setOpen: (isOpen: boolean) => set(() => ({ isOpen })),
});

export const useCommandPallet = create<
  CommandPalletState & {
    actions: ReturnType<Actions>;
  }
>((set: StateSetter<CommandPalletState>) => ({
  isOpen: false,
  actions: actions(set),
}));

const fuseOptions = {
  shouldSort: true,
  keys: ['resource', 'name', 'key'],
};

type Resource = {
  readonly resource: 'app' | 'definition' | 'page';
  readonly baseLink?: string;
  readonly name: string;
  readonly icon?: AST.MarkupIcon;
  readonly key: string;
};

const makeResources = (skeleton: ManifestSkeleton): Resource[] => {
  const pages = ['explore', 'apps'].map((key) => {
    return {
      resource: 'page' as const,
      name: _.capitalize(key),
      key: key,
      baseLink: ``,
    };
  });
  const apps = Object.entries(skeleton.apps ?? {}).map(([key, value]) => {
    return {
      resource: 'app' as const,
      name: value.title,
      icon: value.icon,
      key: key,
      baseLink: '/apps',
    };
  });

  const definitions = Object.entries(skeleton.definitions ?? {}).map(
    ([key]) => {
      return {
        resource: 'definition' as const,
        name: key,
        key: key,
        baseLink: '/explore/data',
      };
    }
  );

  return [...apps, ...definitions, ...pages];
};

const makeResourceTitle = (resource: string) => {
  switch (resource) {
    case 'app':
      return 'App';
    case 'definition':
      return 'Explore';
    default:
      return 'Page';
  }
};

const makeResourceIcon = (resource: string, icon?: AST.MarkupIcon) => {
  switch (resource) {
    case 'app':
      return icon
        ? ICON_MAPPING[icon ?? 'identification']
        : ({ className }: { className?: string }) => (
            <SquaresPlusIcon className={classNames(className)} />
          );
    case 'definition':
      return ({ className }: { className?: string }) => (
        <MagnifyingGlassPlusIcon className={classNames(className)} />
      );
    case 'page':
      return ({ className }: { className?: string }) => (
        <DocumentTextIcon className={classNames(className)} />
      );
    default:
      return () => null;
  }
};

export const CommandPallet: React.FC<{}> = () => {
  const skeleton = useAppData((state) => state.skeleton);
  const resources = makeResources(skeleton);
  const [search, setSearch] = React.useState('');
  const fuse = new Fuse(resources, fuseOptions);
  const setOpen = useCommandPallet((state) => state.actions.setOpen);
  const isOpen = useCommandPallet((state) => state.isOpen);

  const [results, setResults] = React.useState<Resource[]>([]);

  useKeyPress(
    (e) => e.key === 'k' && (e.ctrlKey || e.metaKey),
    (e) => {
      e.preventDefault();
      setOpen(true);
    }
  );

  return (
    <Modal
      open={isOpen}
      onOpenChange={setOpen}
      center={false}
      priority="highest"
    >
      <div className="w-full md:w-[600px]">
        <div className="flex flex-row items-center foo justify-between">
          <H3 className="flex items-center">
            <CopilotIcon theme="dark" /> <span className="ml-4">Search...</span>
          </H3>
          <div className="flex flex-row items-center space-x-2">
            <div className="text-sm text-standard-text">Esc</div>
          </div>
        </div>
        <div className="mt-4 w-full">
          <Inputs.Text
            value={search}
            autoFocus
            autoComplete="off"
            placeholder="Search..."
            onChange={(value) => {
              setSearch(value);
              const results = fuse.search(value, { limit: 10 });
              setResults(results.map((result) => result.item));
            }}
          />
        </div>
        {results.length > 0 && (
          <List.Ul
            className="flex flex-col w-full items-center w-full [&:not(:last-child)]:mb-4 mt-4"
            hasFocus
          >
            <Divider className="mb-4 mt-4 w-full" />
            {results.map((result, i) => {
              const Icon = makeResourceIcon(result.resource, result.icon);

              return (
                <List.Li
                  as={Link}
                  index={i}
                  key={i}
                  className="text-sm text-standard-text w-full focus:bg-zinc-100 rounded hover:bg-zinc-100 active:bg-zinc-100 cursor-pointer data-[focus]:bg-zinc-100"
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      setOpen(false);
                    }
                  }}
                  onClick={() => {
                    setOpen(false);
                  }}
                  to={
                    ['app', 'definition', 'page'].includes(result.resource)
                      ? `${result.baseLink}/${result.key}`
                      : undefined
                  }
                >
                  <div
                    className={classNames(
                      'flex items-center justify-between py-4 px-4'
                    )}
                  >
                    <span className="flex items-center">
                      <Icon className="mr-4 h-4 w-4" />
                      {result.name}
                    </span>
                    <span className="text-xs text-standard-text">
                      {makeResourceTitle(result.resource)}
                    </span>
                  </div>
                </List.Li>
              );
            })}
          </List.Ul>
        )}
      </div>
    </Modal>
  );
};
