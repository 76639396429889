import {
  Avg,
  Constant,
  Max,
  Min,
  PercentileCont,
  StdDevPop,
  UnionAll,
} from '@cotera/era';
import { Histogram as HistogramRel } from '@cotera/era';

import { startCase } from 'lodash';
import { useExplore } from './store';
import { Assert } from '@cotera/utilities';
import { Virtualize } from './virtualize';
import React from 'react';
import { SummaryForRealtion } from '../../components/data-vis/charts/summary';
import { HistogramChartForRelation } from '@cotera/client/app/components/data-vis';

type HistogramData = {
  count: number;
  min: number;
  max: number;
  median: number;
  pctl_25: number;
  pctl_75: number;
  values: number[];
};

type HistogramDataComparison = {
  attribute: string;
  test: HistogramData;
  normal: HistogramData;
  diff: number;
};

export const Continuous: React.FC = () => {
  const exploring = useExplore((state) => state.rel);
  Assert.assert(
    exploring !== null,
    'There should be guarantees elsewhere that this component is not rendered unless `exploring` is set.'
  );

  return <ContinuousExplore />;
};

const ContinuousExplore: React.FC = () => {
  const attributes = useExplore((state) => state.attributes);

  const validAttributes = Object.entries(attributes)
    .filter(([_, x]) => !x.hidden)
    .filter(
      ([_, x]) => x.ty.k === 'primitive' && ['int', 'float'].includes(x.ty.t)
    )
    .map(([name, _]) => name);

  return (
    <Virtualize
      height={600}
      component={HistogramExplore}
      data={validAttributes.map((attribute) => ({ attribute }))}
    />
  );
};

const HistogramExplore: React.FC<{ data: { attribute: string } }> = ({
  data,
}) => {
  const subSample = useExplore((state) => state.rel);
  return (
    <div className="w-full flex flex-col items-center px-8 py-4">
      <span className="text-xl text-standard-text font-semibold mb-4">
        {startCase(data.attribute)}
      </span>
      <div className="w-full pt-6">
        <SummaryForRealtion
          rel={subSample.summary((t) => ({
            category: Constant(data.attribute),
            min: Min(t.attr(data.attribute)),
            max: Max(t.attr(data.attribute)),
            average: Avg(t.attr(data.attribute)),
            '25th': PercentileCont(t.attr(data.attribute), 0.25),
            '50th': PercentileCont(t.attr(data.attribute), 0.5),
            '75th': PercentileCont(t.attr(data.attribute), 0.75),
            'Standard Deviation': StdDevPop(t.attr(data.attribute)),
          }))}
        />
      </div>
      <div className="h-[450px] w-full border-b pb-4 border-divider">
        <HistogramChartForRelation
          rel={HistogramRel(
            subSample,
            (r) => ({
              target: r.attr(data.attribute),
              group: Constant(data.attribute),
            }),
            {
              numBuckets: 20,
            }
          ).select((t) => ({
            ...t.star(),
            category: t.attr('group'),
          }))}
          axis={{
            x: {},
            y: {
              label: 'Count',
            },
          }}
        />
      </div>
    </div>
  );
};
