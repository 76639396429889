import {
  Contract,
  GET,
  LIST,
  POST,
  defaultErrors,
  NotFoundErrorSchema,
} from '@cotera/contracts';
import { z } from 'zod';
import { FeatureFlagSchema, FeatureFlagsSchema } from '../feature-flags';
import { OrgSchema } from '../user';
import { DomainRoleRulesContract } from './orgs/domain-role-rules.contract';

export const SuperAdminOrgsContract = Contract.new({
  domainRoleRules: DomainRoleRulesContract,
  all: LIST({
    params: z.object({}),
    output: z.array(OrgSchema),
  }),
  setFeatures: POST({
    params: z.object({
      orgId: z.string(),
      features: FeatureFlagsSchema,
    }),
    errors: z.never(),
    output: FeatureFlagsSchema,
  }),
  getByName: GET({
    params: z.string(),
    output: OrgSchema,
    errors: NotFoundErrorSchema,
  }),
  create: POST({
    params: z.object({
      name: z.string(),
      featureFlags: z.array(FeatureFlagSchema).optional(),
    }),
    output: OrgSchema,
    errors: defaultErrors(['NameTaken']),
  }),
  setupHostedSnowflakeOrg: POST({
    params: z.object({
      name: z.string(),
      reportViewerDomains: z.array(z.string()).optional(),
      users: z
        .object({
          name: z.string(),
          email: z.string().email(),
        })
        .array(),
    }),
    errors: z.never(),
    output: z.object({ warehouseConnectionId: z.string() }),
  }),
  setupHostedShopifyOnSnowflakeOrg: POST({
    params: z.object({
      name: z.string(),
      reportViewerDomains: z.array(z.string()).optional(),
      users: z
        .object({
          name: z.string(),
          email: z.string().email(),
        })
        .array(),
      shopName: z.string(),
    }),
    errors: z.never(),
    output: z.object({ connectorId: z.string() }),
  }),
});
