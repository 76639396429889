import { TBody } from './tbody';
import { Row } from './row';
import React from 'react';
import { flexRender } from '@tanstack/react-table';
import { useDataGridStore } from '../context';
import { times } from 'lodash';
import { ROW_HEIGHT } from '../constants';

export const LoadingView: React.FC<{
  visibleColumns: { index: number; start: number }[];
}> = ({ visibleColumns }) => {
  const rel = useDataGridStore((s) => s.rel);
  const registry = useDataGridStore((s) => s.registry);
  const columns = Object.keys(rel.attributes);

  return (
    <TBody height={250}>
      {times(12)
        .map((x) => ({
          start: x,
          index: x,
        }))
        .map((virtualRow) => {
          return (
            <Row index={virtualRow.index} start={virtualRow.index * ROW_HEIGHT}>
              {visibleColumns.map((vc) => {
                const column = columns[vc.index]!;

                return (
                  <React.Fragment key={`${vc.index}-${virtualRow.index}`}>
                    {flexRender(
                      () =>
                        registry.getLoadingCell(
                          column,
                          rel.attributes[column]!
                        ),
                      {}
                    )}
                  </React.Fragment>
                );
              })}
            </Row>
          );
        })}
    </TBody>
  );
};
