import { Relation, Ty } from '@cotera/era';
import { Assert } from '@cotera/utilities';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { QueryBuilder } from './query-builder';
import { BuildChart } from './build-chart';
import { useDefinition } from '@cotera/client/app/etc/data/manifest';
import { NotFound } from '@cotera/client/app/components/app';
import { Layout } from '@cotera/client/app/layout';
import { ResultBoundary } from '@cotera/client/app/components/boundaries';
import { H1 } from '@cotera/client/app/components/ui/typography';

export const ChartBuilderPage: React.FC = () => {
  const { id } = useParams();
  Assert.assert(id !== undefined);
  const { data: result } = useDefinition({ id });

  if (result.isErr()) {
    return (
      <Layout>
        <NotFound resource={`Definition "${id}"`} />
      </Layout>
    );
  }

  return (
    <Layout>
      <ResultBoundary
        result={result}
        fallback={() => <NotFound resource={`Definition "${id}"`} />}
      >
        {(def) => <ChartBuilder rel={Relation.wrap(def)} key={id} id={id} />}
      </ResultBoundary>
    </Layout>
  );
};

export const ChartBuilder: React.FC<{ id: string; rel: Relation }> = ({
  id,
  rel: original,
}) => {
  const [query, setQuery] = useState<Relation | null>(null);

  return (
    <div className="flex flex-col space-y-8 w-full p-8" key={id}>
      <div>
        <H1>{id}</H1>
      </div>
      <details>
        <summary>Attributes</summary>
        <br />
        <pre>
          {Object.entries(original.attributes)
            .map(([name, ty]) => `"${name}": ${Ty.displayTy(ty)}`)
            .join(',\n')}
        </pre>
      </details>
      <QueryBuilder source={original} onCommit={({ rel }) => setQuery(rel)} />
      {query && <BuildChart rel={query} />}
    </div>
  );
};
