import { AST } from '../ast';
import { MacroVarReplacements } from '../ast/base';
import { Expression, Relation, Markup } from '../builder';

export const callRelMacro = (
  macro: AST.Rel,
  vars: Partial<MacroVarReplacements>,
  opts: { scope: string }
): Relation => {
  const called: AST._MacroApplyVarsToRel = {
    t: 'macro-apply-vars-to-rel',
    vars: {
      rels: vars.rels ?? {},
      exprs: vars.exprs ?? {},
    },
    scope: opts.scope,
    sources: { from: macro },
    displayName: null,
  };

  return Relation.fromAst(called, { jsStackPointer: callRelMacro });
};

export const callExprMacro = (
  macro: AST.Expr,
  vars: Partial<MacroVarReplacements>,
  opts: { readonly scope: string }
): Expression => {
  const called: AST._MacroApplyVarsToExpr = {
    t: 'macro-apply-vars-to-expr',
    vars: {
      exprs: vars.exprs ?? {},
    },
    scope: opts.scope,
    sources: {
      from: macro,
    },
    displayName: null,
  };

  return Expression.fromAst(called, { jsStackPointer: callExprMacro });
};

export const callMuMacro = (
  macro: AST.Mu,
  vars: Partial<MacroVarReplacements>,
  opts: { scope: string }
): Markup => {
  const called: AST._MacroApplyVarsToMarkup = {
    t: 'macro-apply-vars-to-markup',
    vars: {
      rels: vars.rels ?? {},
      exprs: vars.exprs ?? {},
      sections: vars.sections ?? {},
    },
    scope: opts.scope,
    section: macro,
    displayName: null,
  };

  return Markup.fromAst(called, { jsStackPointer: callMuMacro });
};
