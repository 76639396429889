import { Ty } from '../ty';
import { ExtendedAttributeType, Scalar } from '../ty/ty';
import { AstMode, MacroVarReplacements } from './base';
import { FunctionIdentifier } from './func-identifier';
import { OrderBy } from './rel';

export type _Scalar = {
  readonly t: 'scalar';
  readonly ty: ExtendedAttributeType;
  readonly val: Scalar;
};

export type _Attribute = {
  readonly t: 'attr';
  readonly name: string;
  readonly ty: ExtendedAttributeType;
  readonly source: 'left' | 'right' | 'from';
};

export const WINDOW_OPS = [
  'rank',
  'min',
  'max',
  'row_number',
  'dense_rank',
  'percent_rank',
  'corr',
  'lead',
  'lag',
  'sum',
  'avg',
  'count',
  'first_value',
  'last_value',
  'ntile',
  'string_agg',
] as const;

export type WindowOp = (typeof WINDOW_OPS)[number];

export type WindowFrame = {
  readonly preceding: number | 'unbounded';
  readonly following: number | 'unbounded';
};

export type _Window<Mode extends AstMode = 'full' | 'ir'> = {
  readonly t: 'window';
  readonly op: WindowOp;
  readonly args: readonly Expr<Mode>[];
  readonly over: Over<Mode>;
  readonly frame: null | WindowFrame;
};

export type Over<Mode extends AstMode> = {
  readonly partitionBy: readonly Expr<Mode>[];
  readonly orderBy: readonly OrderBy<Mode>[];
};

export type _ExprVar = {
  readonly t: 'expr-var';
  readonly name: string;
  readonly ty: Ty.ExtendedAttributeType;
  readonly scope: string;
  readonly default: Expr | null;
};

export type _Invariants<Mode extends AstMode = 'full' | 'ir'> = {
  readonly t: 'invariants';
  readonly expr: Expr<Mode>;
  readonly invariants: {
    readonly [name: string]: Expr<Mode>;
  };
};

export type _FunctionCall<Mode extends AstMode = 'full' | 'ir'> = {
  readonly t: 'function-call';
  readonly op: FunctionIdentifier;
  readonly args: readonly Expr<Mode>[];
};

export type _Cast<Mode extends AstMode = 'full' | 'ir'> = {
  readonly t: 'cast';
  readonly targetTy: Ty.AttributeType;
  readonly expr: Expr<Mode>;
};

export type _GetField<Mode extends AstMode = 'full' | 'ir'> = {
  readonly t: 'get-field';
  readonly name: string;
  readonly expr: Expr<Mode>;
};

export type _Case<Mode extends AstMode = 'full' | 'ir'> = {
  readonly t: 'case';
  readonly cases: readonly {
    readonly when: Expr<Mode>;
    readonly then: Expr<Mode>;
  }[];
  readonly else?: Expr<Mode>;
};

export type _Match<Mode extends AstMode = 'full' | 'ir'> = {
  readonly t: 'match';
  readonly expr: Expr<Mode>;
  readonly cases: Record<string, Expr<Mode>>;
};

export type _MakeStruct<Mode extends AstMode = 'full' | 'ir'> = {
  readonly t: 'make-struct';
  readonly fields: {
    readonly [name: string]: Expr<Mode>;
  };
};

export type _MakeArray<Mode extends AstMode = 'full' | 'ir'> = {
  readonly t: 'make-array';
  readonly elements: readonly Expr<Mode>[];
};

export type _MacroExprCase = {
  readonly t: 'macro-expr-case';
  readonly cases: readonly {
    readonly when: Expr<'full'>;
    readonly then: Expr<'full'>;
  }[];
  readonly else: Expr<'full'>;
};

export type _MacroApplyVarsToExpr = {
  readonly t: 'macro-apply-vars-to-expr';
  readonly vars: Pick<MacroVarReplacements, 'exprs'>;
  readonly scope: string;
  readonly sources: { readonly from: Expr<'full'> };
  readonly displayName: string | null;
};

export type AggOp = 'sum' | 'count' | 'avg' | 'min' | 'max';

export type Expr<Mode extends AstMode = 'full' | 'ir'> =
  | (
      | _Scalar
      | _Attribute
      | _MakeStruct<Mode>
      | _MakeArray<Mode>
      | _GetField<Mode>
      | _Cast<Mode>
      | _Match<Mode>
      | _Invariants<Mode>
      | _FunctionCall<Mode>
      | _Window<Mode>
      | _Case<Mode>
    )
  | (Mode extends 'full'
      ? _ExprVar | _MacroExprCase | _MacroApplyVarsToExpr
      : never);

export type ExprIR = Expr<'ir'>;
export type ExprFR = Expr<'full'>;
