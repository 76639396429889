import { queryKeys } from '@cotera/client/app/hooks/query-cache-keys';
import { useTenantedClient, useWhoami } from '@cotera/client/app/stores/org';
import { Assert } from '@cotera/utilities';
import { useSuspenseQuery } from '@tanstack/react-query';

export const useKeywordConfig = () => {
  const org = useWhoami((s) => s.org);
  const client = useTenantedClient();

  return useSuspenseQuery({
    queryKey: queryKeys.warehouse.keywordConfig({ orgId: org.id }),
    queryFn: async () =>
      Assert.assertOk(await client.warehouse.keywordConfig({})),
  });
};

export const useInternSignalNodes = () => {
  const org = useWhoami((s) => s.org);
  const client = useTenantedClient();

  return useSuspenseQuery({
    queryKey: queryKeys.warehouse.intentSignalNodes({ orgId: org.id }),
    queryFn: async () =>
      Assert.assertOk(await client.warehouse.intentSignalNodes({})),
  });
};
