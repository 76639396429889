import { TC } from '@cotera/era';
import { DataGridRegistry } from './registry-impl';
import {
  AsyncHeaderWrapper,
  BaseHeader,
  CategoricalHeader,
  ContinuousHeader,
  TimestampHeader,
} from './components/spark';

export const defaultRegistry = new DataGridRegistry()
  .add((_column, ty) => TC.isNumeric(ty), {
    header: (rel, column, ty) => (
      <BaseHeader
        attr={{
          name: column,
          ty,
        }}
      >
        <AsyncHeaderWrapper>
          <ContinuousHeader rel={rel} attr={{ name: column, ty }} />
        </AsyncHeaderWrapper>
      </BaseHeader>
    ),
  })
  .add((_column, ty) => TC.isStringLike(ty), {
    header: (rel, column, ty) => (
      <BaseHeader
        attr={{
          name: column,
          ty,
        }}
      >
        <AsyncHeaderWrapper>
          <CategoricalHeader rel={rel} attr={{ name: column, ty }} />
        </AsyncHeaderWrapper>
      </BaseHeader>
    ),
  })
  .add((_column, ty) => TC.isTimestamp(ty), {
    header: (rel, column, ty) => (
      <BaseHeader
        attr={{
          name: column,
          ty,
        }}
      >
        <AsyncHeaderWrapper>
          <TimestampHeader rel={rel} attr={{ name: column, ty }} />
        </AsyncHeaderWrapper>
      </BaseHeader>
    ),
  });
