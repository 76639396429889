import { z } from 'zod';
import { TenantedRoleSchema } from '../../../ability';
import {
  Contract,
  DELETE_BY_ID,
  LIST,
  POST,
  defaultErrors,
} from '@cotera/contracts';

export const DomainRoleRuleSchema = z.object({
  id: z.string().uuid(),
  role: TenantedRoleSchema,
  domain: z.string(),
});

export type DomainRoleRule = z.infer<typeof DomainRoleRuleSchema>;

export const DomainRoleRulesContract = Contract.new({
  all: LIST({
    params: z.object({ orgId: z.string().uuid().optional() }),
    output: z.array(DomainRoleRuleSchema),
  }),
  create: POST({
    params: z.object({
      role: TenantedRoleSchema,
      orgId: z.string().uuid(),
      domain: z.string(),
    }),
    errors: defaultErrors(['OrgNotFound']),
    output: DomainRoleRuleSchema,
  }),
  deleteById: DELETE_BY_ID({
    output: z.object({}),
  }),
});
