import { AST } from '../ast';
import { relsInMu, relsInRel } from './rels-in';
import _ from 'lodash';

export const assumptionsOfRel = (rel: AST.Rel): AST._TableContract[] =>
  _.compact([...relsInRel(rel, returnTables, { order: 'pre' })]);

export const assumptionsOfMarkup = (mu: AST.Mu): AST._TableContract[] =>
  _.compact([...relsInMu(mu, returnTables, { order: 'pre' })]);

export const filesOfRel = (rel: AST.Rel): AST._FileContract[] =>
  _.compact([...relsInRel(rel, returnFiles, { order: 'pre' })]);

export const filesOfMarkup = (rel: AST.Mu): AST._FileContract[] =>
  _.compact([...relsInMu(rel, returnFiles, { order: 'pre' })]);

const returnTables = (x: AST.Rel): AST._TableContract | null =>
  x.t === 'table' ? x : null;

const returnFiles = (x: AST.Rel): AST._FileContract | null =>
  x.t === 'file' ? x : null;
