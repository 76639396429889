import { Desc, Relation, If, RowNumberOver, Sum } from '@cotera/era';
import _, { chain, startCase } from 'lodash';
import { ExploreAttribute, useExplore } from './store';
import { Table } from 'apache-arrow';
import { Assert } from '@cotera/utilities';
import { DisplayError, Loading } from '../../components';
import { isCategorical } from '../../etc/duckdb/utils';
import { useState } from 'react';
import { classNames } from '../../components/utils';
import React from 'react';
import { useAppData } from '../../stores/org';
import { useQuery } from '@tanstack/react-query';
import { AsyncResult } from '@cotera/sdk/core';
import { Center } from '@cotera/client/app/components/ui';
import { Inputs } from '@cotera/client/app/components/forms';
import { PieChart } from '@cotera/client/app/components/data-vis';

const QUALIFIES_FOR_CATEGORICAL_COMPARISON_THRESHOLD = 0.9; // 90%

type PieData = { values: number[]; labels: string[] };

type PieDataExplore = {
  attribute: string;
  data: PieData;
};

export const Categorical: React.FC<{}> = () => {
  // Parent must guarantee exploring is set.
  const exploring = useExplore((state) => state.rel);
  Assert.assert(exploring !== null, 'exploring is undefined');

  return <CategricalExplore rel={exploring} />;
};

const CategricalExplore: React.FC<{
  rel: Relation;
}> = ({ rel }) => {
  const attributes = useExplore((state) => state.attributes);
  const initedDb = useAppData((x) => x.initedDb);
  const pieDataQ = useQuery({
    queryFn: async () => {
      return await createCategoricalExplore({
        query: async (rel) => (await initedDb).query(rel),
        subSample: rel,
        attributes: Object.values(attributes).filter((x) => !x.hidden),
      });
    },
    queryKey: [rel.sqlHash(), JSON.stringify(attributes)],
  });

  const pieData = AsyncResult.from(pieDataQ);

  const [search, setSearch] = useState<string>('');

  if (pieData.isError()) {
    return (
      <Center>
        <DisplayError error={pieData.error} />
      </Center>
    );
  }

  if (!pieData.hasData()) {
    return (
      <Center>
        <Loading.Dots />
      </Center>
    );
  }

  return (
    <div className="flex flex-col">
      <Inputs.Text
        placeholder="Search Attributes"
        value={search}
        onChange={setSearch}
        className="w-[200px]"
      />
      <div className="pt-4 flex flex-row flex-wrap justify-between">
        {pieData.data?.map((d) => (
          <div
            key={d.attribute}
            className={classNames(
              'lg:w-[45%] sm:w-full py-8',
              search &&
                !d.attribute.toLowerCase().includes(search.toLowerCase())
                ? 'hidden'
                : ''
            )}
          >
            <ExplorePie data={d} />
          </div>
        ))}
      </div>
    </div>
  );
};

const humanReadableName = (attribute: string): string => startCase(attribute);

const ExplorePie: React.FC<{ data: PieDataExplore }> = ({ data }) => {
  const { attribute, data: stats } = data;

  return (
    <PieChart
      titlePosition="center"
      chartHeight={450}
      name={humanReadableName(attribute)}
      data={makeChartData(stats).map((d) => ({
        category: d.label,
        value: d.value,
      }))}
    />
  );
};

const makeChartData = (data: PieData) => {
  return chain(
    data.labels.map((label, i) => ({
      label,
      id: label,
      value: data.values[i],
    }))
  )
    .groupBy('label')
    .map((values, label) => ({
      label,
      id: label,
      value: values.reduce((acc, curr) => acc + (curr.value ?? 0), 0),
    }))
    .value();
};

const createCategoricalExplore = async ({
  query,
  subSample,
  attributes,
}: {
  query: (rel: Relation) => Promise<Table>;
  subSample: Relation;
  attributes: ExploreAttribute[];
}): Promise<PieDataExplore[]> => {
  const categoricalCols = attributes.filter((value) => isCategorical(value));

  const results = await Promise.all(
    categoricalCols.map((c) =>
      explorePieDataForColumn({ query, subSample, col: c.name })
    )
  );

  return results.filter((x) => {
    const otherValue = x.data.values
      .filter((_, i) => x.data.labels[i] === 'Other')
      .reduce((a, b) => a + b, 0);
    const total = x.data.values.reduce((a, b) => a + b, 0);

    return (
      otherValue === undefined ||
      otherValue / total < QUALIFIES_FOR_CATEGORICAL_COMPARISON_THRESHOLD
    );
  });
};

const OTHER_LABEL = 'Other';

const explorePieDataForColumn = async ({
  query,
  subSample,
  col,
}: {
  query: (rel: Relation) => Promise<Table>;
  subSample: Relation;
  col: string;
}): Promise<PieDataExplore> => {
  const q = countsByDistinctForCol(subSample, col);
  const data = await query(q);
  const labels = getCol<string>(data, 'attr');
  const values = getCol<number>(data, 'count');

  return {
    attribute: col,
    data: {
      labels,
      values,
    },
  };
};
const getCol = function <T>(table: Table, col: string): T[] {
  return table.getChild(col)?.toArray() as T[];
};

const countsByDistinctForCol = (rel: Relation, col: string): Relation => {
  const countCol = 'c';
  return rel
    .countBy((t) => ({
      [countCol]: t.attr(col),
    }))
    .select((t) => ({
      attr: t.attr(countCol).cast('string'),
      count: t.attr('COUNT'),
    }))
    .groupBy((t) => ({
      ...t.pick('attr'),
    }))
    .select((t) => ({
      ...t.pick('attr'),
      count: Sum(t.attr('count')),
    }))
    .orderBy((t) => Desc(t.attr('count')))
    .select((t) => {
      const rn = RowNumberOver({ orderBy: Desc(t.attr('count')) });
      return {
        attr: If(rn.gt(12), {
          then: OTHER_LABEL,
          else: t.attr('attr'),
        }),
        count: t.attr('count'),
      };
    });
};
