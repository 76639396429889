import { z } from 'zod';
import { Layout } from '@cotera/client/app/layout';
import { Text, NotFound } from '@cotera/client/app/components';
import { useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { toast } from '../../components/ui/toaster';
import { useTenantedClient } from '../../stores/org';
import { CredentialsMap, credentialTypes } from './utils';
import { Parser } from '@cotera/era';
import { ZodForm } from '@cotera/client/app/components/forms/zod-form/form';
import { Button } from '@cotera/client/app/components/ui';
import { H1 } from '@cotera/client/app/components/ui/typography';

export const CredentialsPage = () => {
  const params = useParams();
  if (!(credentialTypes as readonly string[]).includes(params['type'] ?? '')) {
    return (
      <Layout>
        <NotFound resource={`Credentials Type ${params['type']}`} />
      </Layout>
    );
  }
  const { type } = z
    .object({
      type: z.enum(credentialTypes),
    })
    .parse(params);

  return <Credentials type={type} />;
};

const Credentials: React.FC<{ type: (typeof credentialTypes)[number] }> = ({
  type,
}) => {
  const [saving, setSaving] = useState(false);
  const client = useTenantedClient();
  const credentialConfig = CredentialsMap[type];
  const { icon: Icon } = credentialConfig;

  const onSubmit = useCallback(
    async ({
      displayName,
      credentials,
    }: {
      displayName: string;
      credentials: z.infer<typeof credentialConfig.schema>;
    }) => {
      let api;
      switch (type) {
        case 'hubspot':
        case 'iterable':
        case 'klaviyo':
        case 'braze':
        case 'oneSignal':
        case 'sendGrid':
        case 'shopify':
        case 'postscript':
        case 'zendesk':
        case 'kustomer':
        case 'attentive':
          api = (
            credentialValues: z.infer<typeof credentialConfig.schema>,
            displayName: string
          ) =>
            client.admin.connections[type].create({
              credentials: credentialValues,
              displayName,
            });
          break;
        default:
          api = (
            credentialValues: Parser.Credentials.WarehouseCredentials['credentials'],
            displayName: string
          ) =>
            client.admin.warehouseConnection.create({
              skipSetup: true,
              displayName,
              credentials: {
                credentials: credentialValues,
                type: CredentialsMap[type].warehouseType,
              } as Parser.Credentials.WarehouseCredentials,
            });
          break;
      }

      setSaving(true);

      await api(credentials as any, displayName);

      setSaving(false);

      toast.success(
        `${credentialConfig.displayName} connection added successfully`
      );
    },
    [type, client, credentialConfig]
  );

  const schema = z
    .object({
      displayName: z.string(),
    })
    .extend({ credentials: credentialConfig.schema });

  return (
    <Layout>
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="w-[400px]">
          <div className="mb-8 flex flex-row items-center">
            <div className="w-[200px] h-[35px]">
              <Icon height={35} />
            </div>
          </div>
          <H1 className="mb-2">Add Connection</H1>
          <Text.Caption className="mb-8">
            Enter your {credentialConfig.displayName} credentials below
          </Text.Caption>

          <ZodForm
            onSubmit={onSubmit}
            schema={schema}
            submitButton={
              <Button
                text="Save"
                icon={saving ? 'information-circle' : undefined}
                disabled={saving}
                className="w-full justify-center mt-4"
              />
            }
          />
        </div>
      </div>
    </Layout>
  );
};
