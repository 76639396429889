import { Virtualizer } from '@tanstack/react-virtual';
import { Registry } from './types';
import { Relation } from '@cotera/era';
import { makeStore, StateSetter } from '@cotera/client/app/etc';
import { DuckDBQueryResult } from '../../etc/duckdb';

export type TableVirtualizer = {
  controller: Virtualizer<HTMLDivElement, Element>;
  paddingStart: number | undefined;
  paddingEnd: number | undefined;
};

type StoreState = {
  dataLength: number;
  totalRows: number;
  showActionsColumn: boolean;
  rel: Relation;
  registry: Registry;
  selectedRows: number[];
  inViewRows: number[];
  data?: DuckDBQueryResult;
};

export const actions = (set: StateSetter<StoreState>) => ({
  setDataLength: (dataLength: number) =>
    set(() => ({
      dataLength,
    })),
  setTotalRows: (totalRows: number) =>
    set(() => ({
      totalRows,
    })),
  toggleActionsColumn: () =>
    set((s) => ({
      showActionsColumn: !s.showActionsColumn,
    })),
  setSelectedRows: (selectedRows: number[]) =>
    set(() => ({
      selectedRows,
    })),
  setInViewRows: (rows: number[]) =>
    set(() => ({
      inViewRows: rows,
    })),
  setData(data: DuckDBQueryResult) {
    set(() => ({ data }));
  },
});

type Actions = ReturnType<typeof actions>;

export const { hook: useDataGridStore, provider: DataGridProvider } = makeStore<
  StoreState,
  Actions
>();
