import { Section } from '@cotera/client/app/components';
import {
  ChildrenProps,
  classNames,
  ColorScheme,
} from '@cotera/client/app/components/utils';
import { useKeyPress } from '@cotera/client/app/hooks/use-key-press';
import React from 'react';

export const NodeCard: React.FC<
  ChildrenProps & {
    name: string;
  }
> = ({ children, name }) => {
  const [value, setValue] = React.useState(name);
  const [hasFocus, setHasFocus] = React.useState(false);
  const ref = React.useRef<HTMLSpanElement>(null);
  useKeyPress('Enter', (e) => {
    if (hasFocus) {
      e.preventDefault();
      setHasFocus(false);
      ref.current?.blur();
    }
  });

  return (
    <div className={classNames('flex flex-col border rounded mb-4 w-full')}>
      <>
        <span
          ref={ref}
          onFocus={() => setHasFocus(true)}
          onBlur={() => setHasFocus(false)}
          onChange={(e) => setValue(e.currentTarget.innerHTML)}
          contentEditable="true"
          className={classNames(
            '-mt-2.5 ml-4 text-xs bg-white w-fit px-2 outline-none rounded border',
            hasFocus
              ? `${ColorScheme.border['primary']}`
              : 'border-transparent',
            ColorScheme.text['muted']
          )}
        >
          {value}
        </span>
        <Section className="w-[calc(100%-2em)]">{children}</Section>
      </>
    </div>
  );
};
