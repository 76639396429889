import {
  useVirtualizer as useBaseVirtualizer,
  Virtualizer as BaseVirtualizer,
  VirtualizerOptions,
} from '@tanstack/react-virtual';
import React, { useMemo } from 'react';

type VirtualizerProps = {
  containerRef: React.RefObject<HTMLDivElement>;
  defaultCount: number;
  estimatedSize: (index: number) => number;
  horizontal?: boolean;
  overscan?: number;
};

export type Virtualizer = {
  controller: BaseVirtualizer<HTMLDivElement, Element>;
  setCount: (count: number) => void;
};

export const useVirtualizer = ({
  containerRef,
  estimatedSize,
  defaultCount,
  horizontal,
  overscan = 5,
}: VirtualizerProps): Virtualizer => {
  const [count, setCount] = React.useState(defaultCount);

  const options: Pick<
    VirtualizerOptions<HTMLDivElement, any>,
    | 'count'
    | 'estimateSize'
    | 'getScrollElement'
    | 'measureElement'
    | 'overscan'
    | 'horizontal'
  > = useMemo(
    () => ({
      count,
      estimateSize: estimatedSize,
      getScrollElement: () => containerRef.current,
      measureElement:
        typeof window !== 'undefined' &&
        navigator.userAgent.indexOf('Firefox') === -1
          ? (element) => element?.getBoundingClientRect().height
          : undefined,
      overscan,
      horizontal,
    }),
    [count, estimatedSize, overscan, containerRef, horizontal]
  );

  return {
    controller: useBaseVirtualizer(options),
    setCount,
  };
};
