import { ChildrenProps } from '../../utils';

type BodyProps = {
  height: number;
  onMouseOut?: () => void;
} & ChildrenProps;
export const TBody: React.FC<BodyProps> = ({
  height,
  children,
  onMouseOut,
}) => {
  return (
    <tbody
      onMouseOut={onMouseOut}
      style={{
        display: 'grid',
        height: `${height}px`, //tells scrollbar how big the table is
        position: 'relative', //needed for absolute positioning of rows
      }}
    >
      {children}
    </tbody>
  );
};
