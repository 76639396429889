import { z } from 'zod';
import { Contract, POST, defaultErrors } from '@cotera/contracts';

export const MagicLinkCreateInputSchema = z.object({
  email: z.string().email(),
});

export type MagicLinkCreateInput = z.infer<typeof MagicLinkCreateInputSchema>;

export const SuperAdminEmailsContract = Contract.new({
  signInOrSignUp: POST({
    params: MagicLinkCreateInputSchema,
    output: z.object({}),
    errors: defaultErrors(['InvalidEmail', 'UserWouldNotBePartOfAnOrg']),
  }),
  sendWelcomeEmail: POST({
    params: MagicLinkCreateInputSchema,
    output: z.object({}),
    errors: defaultErrors([
      'UserAlreadyExists',
      'InvalidEmail',
      'UserWouldNotBePartOfAnOrg',
    ]),
  }),
  sendMagicLink: POST({
    params: MagicLinkCreateInputSchema,
    output: z.object({}),
    errors: defaultErrors(['EmailNotFound']),
  }),
});
