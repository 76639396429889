import { Contract, GET, GET_BY_ID, LIST, POST } from '@cotera/contracts';
import { z } from 'zod';

export const DebugContract = Contract.new({
  some: Contract.new({
    deeply: Contract.new({
      nested: Contract.new({
        route: GET({
          params: z.object({}),
          output: z.literal(42),
          errors: z.never(),
        }),
      }),
    }),
  }),
  paginated: LIST({
    params: z.object({ multiplier: z.coerce.number() }),
    output: z.array(z.number()),
  }),
  requiresTestScope: GET({
    params: z.object({}),
    output: z.never(),
    errors: z.never(),
    mustBeAbleTo: [['foo', '_test']],
  }),
  customError: GET({
    params: z.object({}),
    output: z.never(),
    errors: z.object({
      errorType: z.literal('SomeCustomError'),
      someData: z.number(),
    }),
  }),
  alwaysForbidden: GET({
    params: z.object({}),
    output: z.never(),
    errors: z.never(),
  }),
  alwaysThrows: GET({
    params: z.object({}),
    output: z.never(),
    errors: z.never(),
  }),
  alwaysNotImplemented: GET({
    params: z.object({}),
    output: z.never(),
    errors: z.never(),
  }),
  alwaysNotFound: Contract.new({
    getById: GET_BY_ID({ output: z.never() }),
  }),
  ping: GET({
    params: z.object({ request: z.literal('PING').optional() }),
    output: z.object({ response: z.literal('PONG') }),
    errors: z.never(),
  }),
  math: POST({
    params: z.object({
      lhs: z.coerce.number().finite(),
      rhs: z.coerce.number().finite(),
      op: z.enum(['+', '-', '*', '/']).optional().default('+'),
    }),
    output: z.number(),
    errors: z.never(),
  }),
});
