import { z } from 'zod';
import { NotFoundErrorSchema } from './error';
import { paginateSchema } from './paginate.js';

const ByIdParamsSchema = z.object({ id: z.string().uuid() });

export const GET = (opts) => ({ ...opts, method: 'GET' });
export const POST = (opts) => ({ ...opts, method: 'POST' });

export const GET_BY_ID = (def) => ({
  ...def,
  params: ByIdParamsSchema,
  errors: NotFoundErrorSchema,
  method: 'GET',
});

export const DELETE_BY_ID = (def) => ({
  ...def,
  params: ByIdParamsSchema,
  errors: NotFoundErrorSchema,
  method: 'DELETE',
});

export const LIST = (opts) => ({
  ...opts,
  params: paginateSchema(opts.params),
  errors: z.never(),
  method: 'GET',
});
