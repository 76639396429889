import { Relation } from '@cotera/era';
import { flexRender, Table as ReactTable } from '@tanstack/react-table';
import React, { Suspense } from 'react';
import { DuckDBQueryResult } from '../../etc/duckdb';
import { useDuckDBQuery } from '../../etc/data/duckdb';
import { Row } from './components/row';
import { TBody } from './components/tbody';
import { Registry } from './types';
import { LoadingView } from './components/loading';
import { actions, DataGridProvider, useDataGridStore } from './context';
import { useMemo } from 'react';
import { defaultRegistry } from './registry';
import { HorizontalPadding } from './components/horizontal-padding';
import { useEffect } from 'react';
import { useVirtualizer, Virtualizer } from './hooks/use-virtualizers';
import { useOnPropChange } from './hooks/use-on-props-change';
import { ROW_HEIGHT } from './constants';
import { ActionsColumn, SlideOver } from './components/actions-column';
import { Footer } from './components/footer';
import { useDataGridSetup } from './hooks/use-data-grid-setup';
import { useSelectRow } from './hooks/use-select-row';

type DataGridProps = {
  className?: string;
  rel: Relation;
  registry?: Registry;
  children: (props: {
    table: ReactTable<unknown>;
    containerRef: React.RefObject<HTMLDivElement>;
    columnVirtualizer: Virtualizer;
  }) => React.ReactNode;
  minHeight?: number;
  onFilter?: (
    column: string,
    filter:
      | {
          t: 'array';
          value: string[];
        }
      | {
          t: 'value';
          value: string;
        }
  ) => void;
  onColumnVisibilityChange?: (column: string, visible: boolean) => void;
};
const Container: React.FC<DataGridProps> = (props) => {
  return (
    <DataGridProvider
      state={{
        dataLength: 50,
        totalRows: 0,
        showActionsColumn: false,
        rel: props.rel,
        registry: props.registry ?? defaultRegistry,
        selectedRows: [],
        inViewRows: [],
      }}
      actions={actions}
    >
      <WrapperView {...props} />
    </DataGridProvider>
  );
};

const WrapperView: React.FC<DataGridProps> = ({
  className,
  children,
  rel,
  registry = defaultRegistry,
  minHeight,
}) => {
  const { containerRef, controller } = useDataGridSetup({
    rel,
    registry,
  });

  return (
    <div className="flex flex-col w-full relative">
      <div
        className="h-full w-full flex flex-col overflow-auto relative max-h-[500px]"
        style={{
          minHeight,
        }}
        ref={containerRef}
      >
        <TableView
          className={className}
          tableContainerRef={containerRef}
          table={controller}
          registry={registry}
          rel={rel}
          children={children}
        />
      </div>
      <Footer />
      <SlideOver />
    </div>
  );
};

const TableView: React.FC<{
  className?: string;
  table: ReactTable<unknown>;
  children: DataGridProps['children'];
  registry: Registry;
  rel: Relation;
  tableContainerRef: React.RefObject<HTMLDivElement>;
}> = ({ className, table, children, registry, rel, tableContainerRef }) => {
  const visibleColumns = table.getVisibleLeafColumns();
  const columnVirtualizer = useVirtualizer({
    containerRef: tableContainerRef,
    estimatedSize: (index) => visibleColumns[index]!.getSize(), //estimate width of each column for accurate scrollbar dragging
    horizontal: true,
    overscan: 3,
    defaultCount: visibleColumns.length,
  });
  const virtualColumns = columnVirtualizer.controller.getVirtualItems();
  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  let virtualPaddingLeft: number | undefined;
  let virtualPaddingRight: number | undefined;

  if (virtualColumns?.length) {
    virtualPaddingLeft = virtualColumns[0]?.start ?? 0;
    virtualPaddingRight =
      columnVirtualizer.controller.getTotalSize() -
      (virtualColumns[virtualColumns.length - 1]?.end ?? 0);
  }

  const rowCssVars = {
    '--row-padding-size-start': virtualPaddingLeft ?? 0,
    '--row-padding-size-end': virtualPaddingRight ?? 0,
  };
  return (
    <table
      className={className}
      style={{
        ...columnSizeVars,
        ...rowCssVars,
        display: 'grid',
      }}
    >
      <thead className="sticky top-0 bg-white z-[1]">
        <tr className="flex flex-row w-full relative">
          <HorizontalPadding.Heading position="start" />
          {table.getHeaderGroups().map((headerGroup) => {
            return virtualColumns.map((virtualColumn) => {
              const header = headerGroup.headers[virtualColumn.index]!;

              return (
                <th
                  key={header.id}
                  style={{
                    width: `calc(var(--header-${header?.id}-size) * 1px)`,
                  }}
                  className="relative flex flex-row border-r border-divider border-b shadow-sm font-normal text-left"
                >
                  {flexRender(
                    registry.getHeader(
                      rel,
                      header.id,
                      rel.attributes[header.id]!
                    ),
                    header.getContext()
                  )}

                  <>
                    {/* <ColumnMenu
                column={header.column}
                table={table}
                type={columnTypes[header.id]}
              /> */}
                    <div
                      onMouseDown={header.getResizeHandler()}
                      onTouchStart={header.getResizeHandler()}
                      className="w-[4px] h-full hover:bg-secondary-background rounded -mr-[2px] cursor-ew-resize"
                    />
                  </>
                </th>
              );
            });
          })}
          <ActionsColumn.Header />
          <HorizontalPadding.Heading position="end" />
        </tr>
      </thead>
      <Suspense fallback={<LoadingView visibleColumns={virtualColumns} />}>
        {children({
          table,
          containerRef: tableContainerRef,
          columnVirtualizer,
        })}
      </Suspense>
    </table>
  );
};

const DataLoader: React.FC<Parameters<DataGridProps['children']>[0]> = (
  props
) => {
  const rel = useDataGridStore((s) => s.rel);
  const { data } = useDuckDBQuery({
    rel,
  });

  return <DataWrapper {...props} data={data.data} />;
};

const DataWrapper: React.FC<
  Parameters<DataGridProps['children']>[0] & { data: DuckDBQueryResult }
> = ({ table, containerRef, columnVirtualizer, data }) => {
  return (
    <>
      {table.getState().columnSizingInfo.isResizingColumn !== false ? (
        <MemoizedDataView
          data={data}
          containerRef={containerRef}
          columnVirtualizer={columnVirtualizer}
        />
      ) : (
        <DataView
          data={data}
          containerRef={containerRef}
          columnVirtualizer={columnVirtualizer}
        />
      )}
    </>
  );
};

const DataView: React.FC<{
  data: DuckDBQueryResult;
  containerRef: React.RefObject<HTMLDivElement>;
  columnVirtualizer: Virtualizer;
}> = ({ data, containerRef, columnVirtualizer }) => {
  const dataLength = useDataGridStore((s) => s.dataLength);
  const setTotalRows = useDataGridStore((s) => s.actions.setTotalRows);
  const setData = useDataGridStore((s) => s.actions.setData);
  const rel = useDataGridStore((s) => s.rel);

  useOnPropChange(data.length, () => {
    setTotalRows(data.length);
  });

  useOnPropChange(rel.sqlHash(), () => {
    setData(data);
  });

  const registry = useDataGridStore((s) => s.registry);
  const columns = Object.keys(rel.attributes);
  const setInViewItems = useDataGridStore((s) => s.actions.setInViewRows);
  const rowVirtualizer = useVirtualizer({
    containerRef,
    estimatedSize: () => ROW_HEIGHT,
    defaultCount: dataLength,
    horizontal: false,
    overscan: 10,
  });

  useOnPropChange(rowVirtualizer.controller.getVirtualItems(), (value) => {
    setInViewItems(value.map((x) => x.index));
  });

  const visibleColumns = columnVirtualizer.controller.getVirtualItems();

  useEffect(() => {
    if (rowVirtualizer.controller.options.count < dataLength) {
      rowVirtualizer.setCount(dataLength);
    }
  }, [dataLength, rowVirtualizer]);

  const virtualRows = rowVirtualizer.controller.getVirtualItems();

  const selectRow = useSelectRow();

  return (
    <TBody
      height={Math.min(
        rowVirtualizer.controller.getTotalSize(),
        data.length * ROW_HEIGHT
      )}
    >
      {virtualRows.map((virtualRow) => {
        if (virtualRow.index >= data.length) {
          return null;
        }
        return (
          <Row
            index={virtualRow.index}
            ref={(node) => rowVirtualizer.controller.measureElement(node)}
            start={virtualRow.start}
            onClick={() => {
              selectRow(virtualRow.index);
            }}
          >
            {visibleColumns.map((vc) => {
              const column = columns[vc.index]!;
              const columnData = data.column(column);
              const rowValue = columnData.valueAt(virtualRow.index);

              return (
                <React.Fragment key={`${column}-${virtualRow.index}`}>
                  {flexRender(
                    registry.getCell(
                      rel,
                      column,
                      rel.attributes[column]!,
                      rowValue
                    ),
                    {}
                  )}
                </React.Fragment>
              );
            })}
          </Row>
        );
      })}
    </TBody>
  );
};

const MemoizedDataView = React.memo(DataView, (prev, next) => {
  return prev.data === next.data;
});

export const DataGrid = {
  AutoLoadData: DataLoader,
  Data: DataWrapper,
  Table: Container,
};
