import { Relation, TC } from '@cotera/era';
import { HeaderContext } from '@tanstack/react-table';
import React, { Suspense, useMemo, useState } from 'react';
import { DataGrid, DatagridMenuItem } from './data-grid';
import { Header, createColumn } from './header';
import { Props as DataGridProps } from './data-grid';
import { DetailPageLinks, NamedAttr } from './types';
import { useDeepMemo } from '@cotera/client/app/hooks/deep-memo';
import {
  ArrowUpOnSquareIcon,
  CodeBracketIcon,
  FilmIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';
import { useAppData, useTenantedClient } from '@cotera/client/app/stores/org';
import { toast } from '@cotera/client/app/components/ui/toaster';
import { compact } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { useInvalidateArtifactById } from '@cotera/client/app/hooks/artifacts';
import { Center, Loading } from '@cotera/client/app/components/ui';
import { DisplayError, ViewSql } from '@cotera/client/app/components/app';
import { DuckDBQueryResult } from '@cotera/client/app/etc/duckdb';
import { useAsyncFn } from '@cotera/client/app/hooks/use-query-data';
import { useFilterContext } from '../../contexts/filters';
import { makeStore, StateSetter } from '@cotera/client/app/etc';

type Props = {
  fromArtifactId: string | null;
  onArtifact?: (props: {
    id: string | null;
    sourceHash: string;
    rel: Relation;
  }) => void;
} & Omit<DataGridProps, 'data' | 'columns' | 'totalRows' | 'columnTypes'>;

type State = {
  rel: Relation;
};

const actions = (set: StateSetter<State>) => ({
  setRel: (rel: Relation) => set(() => ({ rel })),
});
type Actions = ReturnType<typeof actions>;

export const { hook: useDataGrid, provider: RelProvider } = makeStore<
  State,
  Actions
>();

export const DataGridForRelation: React.FC<
  Props & {
    rel: Relation;
  }
> = ({ rel, ...rest }) => {
  const sqlHash = rel.sqlHash();
  const memodRel = useMemo(
    () => rel,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sqlHash]
  );

  return (
    <Suspense
      fallback={
        <Center>
          <Loading.Dots />
        </Center>
      }
    >
      <RelProvider key={sqlHash} state={{ rel: memodRel }} actions={actions}>
        <BaseDataGridForRelation {...rest} />
      </RelProvider>
    </Suspense>
  );
};

const randomHash = () => (+new Date()).toString(36);

function BaseDataGridForRelation({
  className,
  fromArtifactId,
  ...rest
}: Props) {
  const rel = useDataGrid((s) => s.rel);
  const result = useDuckDBQuery({ rel, onArtifact: rest.onArtifact });

  const artifactId = fromArtifactId ?? result.data.fromArtifactId ?? null;

  const client = useTenantedClient();
  const invalidateArtifact = useInvalidateArtifactById({
    id: artifactId,
  });

  const detailPageTys = useAppData((s) => s.skeleton.detailPages);
  const navigate = useNavigate();
  const [viewSql, setViewSql] = useState(false);

  const fields: NamedAttr[] = Object.entries(rel.attributes).map(
    ([name, ty]) => {
      const detailPages: DetailPageLinks = Object.fromEntries(
        Object.entries(detailPageTys ?? {})
          .filter(([_name, detailTy]) =>
            TC.implementsTy({ subject: ty, req: detailTy })
          )
          .map(([name]) => [name, (val) => navigate(`/details/${name}/${val}`)])
      );

      return { ty, name, detailPages };
    }
  );

  const devModeMenuItems: DatagridMenuItem[] = compact([
    artifactId
      ? {
          name: 'View Source Artifact',
          onClick: () => {
            navigate(`/artifacts/${artifactId}`);
          },
          icon: <FilmIcon className="w-5 h-5" />,
        }
      : null,

    artifactId
      ? {
          name: 'Refresh',
          onClick: async () => {
            await invalidateArtifact.mutateAsync();
          },
          icon: invalidateArtifact.isPending ? (
            <Loading.Spinner />
          ) : (
            <ArrowPathIcon className="w-5 h-5" />
          ),
        }
      : null,
    {
      name: 'Create view in warehouse',
      onClick: async () => {
        const result = await client.warehouse.createDevView({
          suffix: rest.name ?? randomHash(),
          ast: rel.ast,
        });
        if (result.isOk()) {
          toast.success(`View ${result.value.name} created!`);
        } else {
          const { errorType } = result.error;
          toast.error(
            `Error: ${errorType}${
              'message' in result.error ? `- ${result.error.message}` : ''
            }`
          );
        }
      },
      icon: <ArrowUpOnSquareIcon className="w-5 h-5" />,
    },
    {
      name: 'View SQL',
      onClick: async () => {
        setViewSql(true);
      },
      icon: <CodeBracketIcon className="w-5 h-5" />,
    },
  ]);

  return (
    <>
      <WithSummary
        rel={result.data.artifactRel}
        data={result.data.data}
        className={className}
        fields={fields}
        {...rest}
        menuItems={[...(rest.menuItems ?? []), ...devModeMenuItems]}
        columnTypes={rel.attributes}
      />
      <ViewSql rel={rel} open={viewSql} onChange={setViewSql} />
    </>
  );
}

type WithDataProps = {
  fields: NamedAttr[];
  rel: Relation;
  data: DuckDBQueryResult;
  className?: string;
  summaries?: {
    min: string | null;
    max: string | null;
    column: string;
    unique: number | null;
  }[];
} & Omit<DataGridProps, 'data' | 'columns' | 'totalRows'>;

const WithSummary = (props: Omit<WithDataProps, 'summaries'>) => {
  const db = useAppData((x) => x.initedDb);

  const result = useAsyncFn(async () => {
    return await (await db).summarize(props.rel);
  });

  if (result.isError()) {
    return (
      <Center>
        <DisplayError error={result.error} />
      </Center>
    );
  }

  if (!result.hasData()) {
    return (
      <Center>
        <Loading.Dots />
      </Center>
    );
  }

  return (
    <WithData
      {...props}
      summaries={result.data.map((x) => ({
        min: x.min,
        max: x.max,
        column: x.column_name,
        unique: x.approx_unique,
      }))}
    />
  );
};

const WithData: React.FC<WithDataProps> = ({
  fields,
  rel,
  data,
  className,
  ...rest
}) => {
  const updateFilter = useFilterContext((s) => s.actions.update);
  const cachedFields = useDeepMemo(() => fields, [fields]);

  const columns = useMemo(
    () =>
      cachedFields.map((f) => {
        const col = createColumn({
          data: f,
          header: (props: HeaderContext<unknown, unknown>) => (
            <Header
              summary={rest.summaries?.find((x) => x.column === f.name)}
              rel={rel}
              attr={f}
              column={props.column}
              filterValue={props.column.getFilterValue() as string}
              onClick={(c) => {
                if (TC.isNumeric(f.ty)) {
                  updateFilter(f.name, {
                    t: f.ty.ty,
                    values: [c.min, c.max],
                  });
                } else {
                  updateFilter(f.name, {
                    t: f.ty.ty,
                    values: [c],
                  });
                }
              }}
            />
          ),
        });
        return col;
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cachedFields, rel.sqlHash()]
  );

  return (
    <DataGrid
      {...rest}
      totalRows={data.numRows}
      columns={columns}
      data={data.toArray()}
      className={className}
    />
  );
};
