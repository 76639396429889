import React from 'react';
import { ComboBox, Inputs } from '@cotera/client/app/components/forms';
import {
  ChildrenProps,
  classNames,
  joinElements,
} from '@cotera/client/app/components/utils';
import {
  Badge,
  Button,
  ButtonOption,
  ButtonOptions,
  Modal,
} from '@cotera/client/app/components/ui';
import { Operator } from './constants';
import { ConnectiveSelect, GroupPreview, Value } from './components';
import {
  FilterGroup,
  FilterGroupManager,
  FilterItem,
  FilterItemManager,
  Option,
} from './types';
import { Assert } from '@cotera/utilities';
import { makeOperatorsForType, useFilterBuilder } from './hooks';
import { useFilterContext } from '../../contexts/filters';
import { Relation, TC, Ty } from '@cotera/era';
import { v4 } from 'uuid';
import { List } from '@cotera/client/app/components/headless';
import { debounce } from 'lodash';

type ContextComponent = (props: {
  onClick: (value: any) => void;
}) => React.ReactNode;

export type FilterBuilderProps = {
  filterGroup?: FilterGroup;
  options: Option[];
  onRun: (props: { filterGroup: FilterGroup; limit?: number }) => void;
  runEnabled?: boolean;
  onChange?: (props: { filterGroup: FilterGroup; limit?: number }) => void;
  context?: Record<string, ContextComponent>;
  rel: Relation;
  autoRun?: boolean;
  onAutoRunChange?: (autoRun: boolean) => void;
};

const makeDefaultFilterGroup = (): FilterGroup => {
  return {
    connective: 'and',
    items: [],
  };
};

const useGlobalFilters = (options: Option[]): FilterItem[] => {
  const globalFilters = useFilterContext((s) => s.filters);

  return Object.entries(globalFilters)
    .filter(([_key, value]) => {
      return TC.isPrimitive(value.t);
    })
    .map(([key, value]) => {
      Assert.assert(
        value.t.k === 'primitive' ||
          value.t.k === 'enum' ||
          value.t.k === 'range' ||
          value.t.k === 'id',
        'Expected primitive, enum, id, or range'
      );
      const filter = {
        id: v4(),
        value: {
          key,
          t: value.t.t,
          value: value.values,
          operator: makeOperatorsForType(Ty.ty(value.t))[0],
        },
      };

      return filter;
    })
    .filter((f) => options.some((x) => x.attr === f.value.key));
};

export const FilterBuilder: React.FC<FilterBuilderProps> = ({
  filterGroup = makeDefaultFilterGroup(),
  ...props
}) => {
  const globalFilterItems = useGlobalFilters(props.options);

  const items = [];
  for (const item of filterGroup.items) {
    const globalFilter = globalFilterItems.find(
      (x) => x.value?.key === item.value?.key
    );
    if (globalFilter) {
      items.push(globalFilter);
    } else {
      items.push(item);
    }
  }
  items.push(
    ...globalFilterItems.filter(
      (x) => !filterGroup.items.some((y) => y.value?.key === x.value?.key)
    )
  );

  return (
    <InnerFilterBuilder
      key={JSON.stringify(globalFilterItems)}
      {...props}
      filterGroup={{
        ...filterGroup,
        items,
      }}
    />
  );
};

const InnerFilterBuilder: React.FC<FilterBuilderProps> = ({
  filterGroup = makeDefaultFilterGroup(),
  options,
  onRun,
  runEnabled,
  onChange,
  context,
  rel,
  autoRun,
  onAutoRunChange,
}) => {
  const [limit, setLimit] = React.useState<number | undefined>(undefined);
  const [state] = useFilterBuilder({
    filterGroup,
    options,
    onChange: (state) => {
      onChange?.({
        filterGroup: state,
        limit,
      });
      if (autoRun) {
        onRun({ filterGroup: state, limit });
      }
    },
  });
  const topLevelFilters = state.items;
  const [showAdvanced, setShowAdvanced] = React.useState(false);

  const filters = topLevelFilters.map((f, i) => {
    if (f.value) {
      return (
        <FilterView
          rel={rel}
          key={`${f.value.key} + ${i}`}
          display="compact"
          item={f}
          options={options}
          context={(context ?? {})[f.value.key]}
        />
      );
    }
    Assert.assert(f.group !== undefined, 'Expected group');

    return (
      <GroupPreview
        key={f.id}
        name={f.group.name ?? 'Group'}
        onClick={() => {
          setShowAdvanced(true);
        }}
      />
    );
  });

  return (
    <form
      className="flex flex-row justify-between w-full"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="flex flex-row items-center flex-wrap">
        {joinElements(filters, (i) => (
          <ConnectiveSelect
            key={i}
            className="mx-2 mb-2"
            value={state.connective}
            onChange={(value) => state.use(value)}
          />
        ))}
      </div>
      <div className="flex flex-row items-start flex-shrink-0 space-x-2 pl-4 mb-2 h-full">
        {limit !== undefined && (
          <Inputs.Number
            label="Limit"
            icon="scissors"
            compact
            value={limit ?? 0}
            onChange={(v) => {
              setLimit(v);
              onChange?.({
                filterGroup: state,
                limit: v,
              });
              if (autoRun) {
                debounce(() => onRun({ filterGroup: state, limit: v }), 500);
              }
            }}
          />
        )}
        <Button
          icon="plus"
          iconOnly
          text="Add Filter"
          onClick={() => {
            state.add();
          }}
          options={
            <ButtonOptions>
              <ButtonOption
                as={Button}
                icon={'scissors'}
                text="Limit"
                inline
                onClick={() => {
                  setLimit(50_000);
                }}
              />
            </ButtonOptions>
          }
        />
        <Button
          icon="academic-cap"
          iconOnly
          text="Advanced"
          onClick={() => {
            setShowAdvanced(!showAdvanced);
          }}
        />
        <Button
          icon={autoRun ? 'arrow-path' : 'play'}
          theme="secondary"
          disabled={!runEnabled && !autoRun}
          iconOnly={!runEnabled && !autoRun}
          text={!autoRun ? 'Run' : 'Auto Run'}
          type="submit"
          onClick={() => {
            onRun({
              filterGroup: state,
              limit,
            });
          }}
          options={
            <List.Ul>
              <List.Li
                as={Button}
                inline
                theme="secondary"
                icon={autoRun ? 'x-mark' : 'check'}
                onClick={() => onAutoRunChange?.(!autoRun)}
                text={autoRun ? 'Disable auto run' : 'Enable auto run'}
              />
            </List.Ul>
          }
        />
      </div>
      <Modal
        contentClassName="w-1/2"
        priority="medium"
        open={showAdvanced}
        onOpenChange={(v) => setShowAdvanced(v)}
      >
        <AdvancedBuilder
          rel={rel}
          filterGroup={state}
          options={options}
          onRun={() => {
            onRun({
              filterGroup: state,
              limit,
            });
          }}
        />
      </Modal>
    </form>
  );
};

const FilterViewRow: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <div className="space-x-2 mb-2 flex flex-row items-center">{children}</div>
  );
};

const Context: React.FC<{
  context?: ContextComponent;
  ty: Ty.ExtendedAttributeType;
  f: FilterItemManager;
}> = ({ context, ty, f }) =>
  context
    ? context({
        onClick: (value) => {
          if (TC.isNumeric(ty)) {
            f.update({
              value: value,
              operator: 'between',
            });
          }
          if (TC.isStringLike(ty)) {
            f.update({
              value: value,
              operator: 'one-of',
            });
          }
        },
      })
    : null;

const FilterView: React.FC<{
  item: FilterItemManager;
  options: Option[];
  display?: 'compact' | 'full';
  context?: ContextComponent;
  rel: Relation;
}> = ({ item: f, options, display = 'full', context, rel }) => {
  const getOptionsForAttr = (attr: string) => {
    return options.find((o) => o.attr === attr);
  };

  const getDefaultOperatorForAttr = (attr: string) => {
    return makeOperatorsForType(getOptionsForAttr(attr)!.t)[0];
  };
  Assert.assert(f.value !== undefined, 'Expected value');
  const tyForAttr = options.find((o) => o.attr === f.value?.key)!.t;
  const optionsForAttr =
    options.find((o) => o.attr === f.value?.key)?.values ?? [];

  if (f.value?.compact) {
    return (
      <FilterViewRow>
        <Value
          name={f.value.key}
          attr={f.value.key}
          rel={rel}
          value={f.value.value}
          operator={f.value.operator}
          onChange={(value) => {
            f.update({
              value,
            });
          }}
          t={tyForAttr}
          options={optionsForAttr}
        />
        {context && <Context context={context} ty={tyForAttr} f={f} />}
      </FilterViewRow>
    );
  }

  return (
    <FilterViewRow>
      <ComboBox.Single
        compact
        onChange={(value) => {
          const optionsForAttr =
            options.find((o) => o.attr === value!.value)?.values ?? [];

          f.update({
            key: value!.value,
            operator: getDefaultOperatorForAttr(value!.value),
            value: [optionsForAttr[0] ?? null],
          });
        }}
        value={{
          display: f.value.key,
          value: f.value.key,
        }}
        options={(props) => (
          <ComboBox.StaticOptions
            {...props}
            dynamicEnabled={false}
            options={options.map((o) => ({ value: o.attr, display: o.attr }))}
          />
        )}
      />
      <ComboBox.Select
        compact
        key={f.value.key}
        onChange={(value) => {
          f.update({
            operator: value!.value as Operator,
          });
        }}
        value={{
          display: f.value.operator,
          value: f.value.operator,
        }}
        options={makeOperatorsForType(tyForAttr).map((x) => ({
          value: x,
          display: x,
        }))}
      />
      <div
        className={classNames(
          display === 'compact' ? 'w-fit flex-wrap' : 'w-auto'
        )}
      >
        <Value
          rel={rel}
          attr={f.value.key}
          value={f.value.value}
          operator={f.value.operator}
          onChange={(value) => {
            f.update({
              value,
            });
          }}
          t={tyForAttr}
          options={optionsForAttr}
        />
      </div>
      {context && <Context context={context} ty={tyForAttr} f={f} />}
      <Button
        icon="trash"
        iconOnly
        text="Remove Filter"
        compact
        inline
        theme="error"
        onClick={() => {
          f.remove();
        }}
      />
    </FilterViewRow>
  );
};

const GroupView = ({
  group,
  depth = 0,
  options,
  rel,
}: {
  group: FilterGroupManager;
  depth?: number;
  options: Option[];
  rel: Relation;
}) => {
  if (depth > 5) {
    return null;
  }
  const items = group.items.map((item, i) => {
    if (item.value) {
      return <FilterView rel={rel} key={i} item={item} options={options} />;
    }
    Assert.assert(item.group !== undefined, 'Expected group');
    return (
      <GroupView
        rel={rel}
        depth={depth + 1}
        key={i}
        group={item.group}
        options={options}
      />
    );
  });
  return (
    <div
      className={classNames(
        'flex flex-col border-l-4 px-2 mb-2 rounded',
        depth % 2 === 0 ? 'border-emerald-300' : 'border-indigo-300'
      )}
    >
      {joinElements(items, (i) => (
        <ConnectiveSelect
          key={i}
          className="mb-2"
          value={group.connective}
          onChange={(value) => group.use(value)}
        />
      ))}
      <div className="flex flex-row items-start flex-shrink-0 space-x-2">
        <Button
          icon="plus"
          iconOnly
          text="Add Filter"
          onClick={() => {
            group.add();
          }}
        />
        <Button
          icon="folder-plus"
          iconOnly
          text="Add Group"
          onClick={() => {
            group.addGroup();
          }}
        />
      </div>
    </div>
  );
};

const AdvancedBuilder: React.FC<{
  filterGroup: FilterGroupManager;
  options: Option[];
  onRun: () => void;
  rel: Relation;
}> = ({ options, filterGroup, onRun, rel }) => {
  return (
    <div className="flex flex-col">
      <Badge className="mb-4" theme="primary">
        WHERE
      </Badge>
      <GroupView rel={rel} options={options} group={filterGroup} />
      <div className="flex flex-row items-center justify-end mt-4">
        <Button
          icon="play"
          theme="secondary"
          compact
          text="Run"
          className="w-fit"
          onClick={onRun}
        />
      </div>
    </div>
  );
};
