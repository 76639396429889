import _ from 'lodash';
import { Ty } from '../ty';
import { AST } from '../ast';
import { FuncCallSignature } from './expr/function-call';
import type { RelTypeCheck } from './check-rel';
import { displayTy } from '../ty/ty';
import { Frame, TyStackTrace } from './ty-stack-trace';
import { TyParseError } from '../era-sql/ty/parse';

export type TypeCheckErrorLocation =
  | 'default'
  | 'from'
  | 'on'
  | 'left'
  | 'right'
  | 'source'
  | 'condition'
  | 'then'
  | 'when'
  | 'subject'
  | 'else'
  | 'named-parameter'
  | 'namespace'
  | 'unreplacedTables'
  | 'target'
  | 'identifier'
  | 'expr'
  | 'sink'
  | 'entity'
  | 'frame'
  | 'start'
  | 'stop'
  | readonly ['field', string]
  | readonly ['event-stream', string]
  | readonly ['sink', string]
  | readonly ['invariant', string]
  | readonly ['var', string]
  | readonly ['var', string, 'attribute', string]
  | readonly ['attribute', string]
  | readonly ['position', number]
  | readonly ['over', 'partition by' | 'order by', 'position', number];

// Used to make sure we don't accidently call toString on an object
const msg = (
  strings: readonly string[],
  ...keys: readonly (string | number | Ty.Shorthand)[]
): string =>
  _.zip(strings, keys)
    .flatMap(([l = '', r = '']) => [
      typeof l === 'string' ? l : Ty.displayTy(l),
      typeof r === 'string'
        ? r
        : typeof r === 'number'
        ? r.toString()
        : Ty.displayTy(r),
    ])
    .join('');

export abstract class TypeCheckError {
  protected abstract message(): string;

  static msg(
    strings: readonly string[],
    ...keys: readonly (string | number | Ty.Shorthand)[]
  ): string {
    return msg(strings, ...keys);
  }

  toString() {
    return `${this.constructor.name} - ${this.message()}`;
  }

  toStackTrace(frame: Frame): TyStackTrace {
    return TyStackTrace.fromErr(frame, this);
  }
}

export class NoSuchAttribute extends TypeCheckError {
  constructor(
    private readonly params: {
      attributeName: string;
      relation: Pick<RelTypeCheck, 'attributes'> | undefined;
      struct?: boolean;
    }
  ) {
    super();
  }

  message() {
    return msg`"${this.params.attributeName}" does not exist in \n${
      this.params.struct ? 'STRUCT' : ''
    }(\n  ${Object.entries(this.params.relation?.attributes ?? {})
      .map(([name, ty]) => `"${name}" ${Ty.displayTy(ty)}`)
      .join(',\n  ')}\n)`;
  }
}

export class IllegalScalar extends TypeCheckError {
  constructor(
    private readonly params: {
      ty: Ty.Shorthand;
      val: Ty.Scalar;
    }
  ) {
    super();
  }

  message() {
    return msg`Unable to create a constant of type "${
      this.params.ty
    }" from provided value (${
      this.params.val === null ? 'NULL' : this.params.val.toString()
    })`;
  }
}

export class IllegalCast extends TypeCheckError {
  constructor(
    private readonly params: {
      from: Ty.Shorthand;
      to: Ty.Shorthand;
    }
  ) {
    super();
  }

  message() {
    return msg`Cannot convert from '${this.params.from}' to '${this.params.to}'`;
  }
}

export class TypeDoesNotMatchExpectation extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly found: Ty.Shorthand;
      readonly expected: Ty.Shorthand | readonly Ty.Shorthand[];
    }
  ) {
    super();
  }
  message() {
    const wanted = Array.isArray(this.params.expected)
      ? this.params.expected
      : [this.params.expected];
    return msg`Found type '${this.params.found}', expected [${wanted
      .map((x) => msg`'${x}'`)
      .join(', ')}]`;
  }
}

export class MissingSource extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly wanted: string;
      readonly sources: string[];
    }
  ) {
    super();
  }

  message() {
    return msg`wanted "${this.params.wanted}" but only ${this.params.sources
      .map((x) => `"${x}"`)
      .join(', ')} exist`;
  }
}

export class FunctionDoesNotExist extends TypeCheckError {
  constructor(private readonly params: { readonly name: string }) {
    super();
  }

  protected override message(): string {
    return msg`function "${this.params.name}" does not exist`;
  }
}

export class InvalidFunctionCall extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly op: string;
      readonly recieved: readonly Ty.Shorthand[];
      readonly allowed: readonly FuncCallSignature[];
    }
  ) {
    super();
  }

  message() {
    return msg`received signature (${this.params.recieved
      .map((arg) => Ty.displayTy(arg))
      .join(', ')}) for function "${
      this.params.op
    }", allowed signatures [${this.params.allowed
      .map(([args]) => `(${args.map((arg) => Ty.displayTy(arg)).join(', ')})`)
      .join(', ')}]`;
  }
}

export class InvalidValuesLiteral extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly attrName: string;
      readonly found: Ty.Scalar;
      readonly expected: Ty.ExtendedAttributeType;
    }
  ) {
    super();
  }

  message() {
    return msg`InvalidValuesLiteral - at "${
      this.params.attrName
    }" recieved value "${(
      this.params.found ?? 'null'
    ).toString()}" wanted type "${this.params.expected}"`;
  }
}

export class UnionColumnsMustMatch extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly name: string;
      readonly left: Ty.ExtendedAttributeType | undefined;
      readonly right: Ty.ExtendedAttributeType | undefined;
    }
  ) {
    super();
  }
  message() {
    return msg`"${this.params.name}": left: ${
      this.params.left ? Ty.displayTy(this.params.left) : '"(Not Found)"'
    } right: ${
      this.params.right ? Ty.displayTy(this.params.right.ty) : '"(Not Found)"'
    }`;
  }
}

export class NestedAggregateFunctions extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly function: AST.FunctionIdentifier;
      readonly argPosition: number;
    }
  ) {
    super();
  }

  message() {
    return msg`"${
      this.params.function
    }" can not be called because argument ${this.params.argPosition.toString()} has an aggregate function`;
  }
}

export class TryingToSelectUnaggregatedAttributes extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly attrName: string;
    }
  ) {
    super();
  }

  message() {
    return msg`Attribute "${this.params.attrName}" must appear in the GROUP BY clause or be used in an aggregate function`;
  }
}

export class CantOrderByStructArrayOrSuper extends TypeCheckError {
  constructor(
    private readonly params: { attempted: Ty.ExtendedAttributeType }
  ) {
    super();
  }

  protected override message(): string {
    return msg`Cant order by type '${this.params.attempted}'`;
  }
}

export class AggregateFunctionInNonAggregatedContext extends TypeCheckError {
  constructor() {
    super();
  }

  message() {
    return msg`cant use an aggregated expression in a non aggregated context`;
  }
}

export class AggregeateFunctionsCantContainWindowedFunctions extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly argPosition: number;
    }
  ) {
    super();
  }

  message() {
    return msg`arg ${this.params.argPosition.toString()} is an aggregate function that contains a window function`;
  }
}

export class MissingRequiredKey extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly requiredKeys: string[];
      readonly foundKeys: string[];
    }
  ) {
    super();
  }

  message() {
    return msg`MissingRequiredKey - required keys: ${this.params.requiredKeys.join(
      ', '
    )} found keys: ${this.params.foundKeys.join(', ')}`;
  }
}

export class NamespaceDoesNotExist extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly name: string;
    }
  ) {
    super();
  }

  message() {
    return msg`"${this.params.name}" does not exist`;
  }
}

export class DuplicateNamespace extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly name: string;
    }
  ) {
    super();
  }

  message() {
    return msg`duplicate namespace "${this.params.name}"`;
  }
}

export class AmbiguousNamespace extends TypeCheckError {
  constructor(
    private readonly params: {
      readonly name: string;
    }
  ) {
    super();
  }

  message() {
    return msg`This action causes namespace "${this.params.name}" to be ambiguous`;
  }
}

export class MismatchedCaseOutputTypes extends TypeCheckError {
  constructor(
    private readonly params: {
      received: Ty.Shorthand[];
    }
  ) {
    super();
  }

  message() {
    return msg`case statement had incompatible output types found [${this.params.received
      .map((ty) => Ty.displayTy(ty))
      .join(', ')}]`;
  }
}

export class NonBooleanCaseStatementCondition extends TypeCheckError {
  constructor(private readonly params: { received: Ty.Shorthand }) {
    super();
  }
  protected message() {
    return msg`Case conditions must be of type boolean, but received ${Ty.displayTy(
      this.params.received
    )}`;
  }
}

export class CantReplaceNonExistantTable extends TypeCheckError {
  constructor(
    private readonly params: {
      attempted: { name: string; schema: string };
      available: { name: string; schema: string }[];
    }
  ) {
    super();
  }
  protected message() {
    const prefix = msg`Attempted to replace table "${this.params.attempted.schema}"."${this.params.attempted.name}", but `;
    const suffix =
      this.params.available.length > 0
        ? msg`only the following tables are available\n${this.params.available
            .map(({ name, schema }) => ` * "${schema}"."${name}"`)
            .join(',\n')}`
        : msg`no tables are available`;

    return msg`${prefix}${suffix}`;
  }
}

export class InvalidRelationReplacement extends TypeCheckError {
  constructor(
    private readonly params: {
      name: string;
      existingTy: Ty.Shorthand;
      replacementType?: Ty.Shorthand;
    }
  ) {
    super();
  }

  protected message() {
    return msg`Unable to replace attribute "${this.params.name}" of type "${
      this.params.existingTy
    }" with type ${this.params.replacementType ?? '(Unknown)'}`;
  }
}

export class ConflictingUnreplacedTables extends TypeCheckError {
  constructor(
    private readonly params: {
      schema: string;
      name: string;
    }
  ) {
    super();
  }

  protected message() {
    return msg`Table "${this.params.schema}"."${this.params.name}" has different attributes in different branches of the query`;
  }
}

export class CantReplaceExistingNamespaceAttribute extends TypeCheckError {
  constructor(
    private readonly params: {
      namespace: string;
      name: string;
    }
  ) {
    super();
  }

  protected message() {
    return msg`Cant create attribute "${this.params.name}" in namespace "${this.params.namespace}" since it already exists`;
  }
}

export class NamespaceAlreadyReplaced extends TypeCheckError {
  constructor(
    private readonly params: {
      name: string;
    }
  ) {
    super();
  }
  protected message() {
    return msg`Namespace "${this.params.name}" has already been replaced`;
  }
}

export class InvalidTypeForAttribute extends TypeCheckError {
  constructor(
    private readonly params: {
      name: string;
      actual: Ty.Shorthand | null;
      wanted: Ty.Shorthand[] | 'any';
    }
  ) {
    super();
  }
  protected message() {
    return msg`Attribute "${this.params.name}" has type '${
      this.params.actual ?? '*Not Found*'
    }' but one of types [${
      this.params.wanted === 'any'
        ? `'any'`
        : this.params.wanted
            .map(Ty.displayTy)
            .map((ty) => `'${ty}'`)
            .join(', ')
    }] is required`;
  }
}

export class InvariantsMustBeOfTypeBoolean extends TypeCheckError {
  constructor(
    private readonly params: {
      name: string;
      actualType: Ty.Shorthand;
    }
  ) {
    super();
  }
  protected message() {
    return msg`Invariant "${this.params.name}" has type "${this.params.actualType}" but should be of type "boolean"`;
  }
}

export class ConstExprRequired extends TypeCheckError {
  constructor(private readonly params: { name: string }) {
    super();
  }
  protected message() {
    return msg`Expr "${this.params.name}" is required to be const`;
  }
}

export class NonNullableTypeRequired extends TypeCheckError {
  constructor(
    private readonly params: {
      got: Ty.Shorthand;
    }
  ) {
    super();
  }

  protected message(): string {
    return msg`Wanted a non nullable type but got ${this.params.got}`;
  }
}

export class ConstParamRequired extends TypeCheckError {
  constructor(
    private readonly params: {
      op: AST.FunctionIdentifier;
      argNum: number;
    }
  ) {
    super();
  }
  protected message() {
    return msg`Function "${
      this.params.op
    }" has a const param in position ${this.params.argNum.toString()}, but a dynamic value was passed`;
  }
}
export class InvalidIdentifierType extends TypeCheckError {
  constructor(
    private readonly params: {
      attempted: Ty.Shorthand;
    }
  ) {
    super();
  }

  protected message() {
    return msg`identifiers must be of type "string", but recieved "${this.params.attempted}"`;
  }
}

export class InvalidSinkCondition extends TypeCheckError {
  constructor(
    private readonly params: {
      attempted: Ty.Shorthand;
    }
  ) {
    super();
  }

  protected message() {
    return msg`Sink conditions must be of type "boolean" but found type "${this.params.attempted}"`;
  }
}

export class CantUseAggregateInWindowSpecification extends TypeCheckError {
  constructor() {
    super();
  }
  protected message() {
    return msg`Cant use an aggragate in a window specification`;
  }
}

export class CantGetFieldOfANonStruct extends TypeCheckError {
  constructor(
    private readonly params: {
      fieldName: string;
      attempted: Ty.Shorthand;
    }
  ) {
    super();
  }

  protected message() {
    return msg`Cant get field "${this.params.fieldName}" from type "${this.params.attempted}"`;
  }
}

export class BadInformationSchemaSchemaCount extends TypeCheckError {
  protected override message(): string {
    return msg`Number of schemas must be > 0`;
  }
}

export class ArityMismatch extends TypeCheckError {
  constructor(
    private readonly params: {
      name: string;
      expected: number;
      attempted: number;
    }
  ) {
    super();
  }
  protected message(): string {
    return msg`Attempted to call function ${this.params.name} with ${this.params.attempted} arguments but it has an arity of ${this.params.expected}.`;
  }
}

export class TypesNotComparable extends TypeCheckError {
  constructor(
    private readonly params: {
      op: string;
      left: Ty.ExtendedAttributeType;
      right: Ty.ExtendedAttributeType;
    }
  ) {
    super();
  }

  protected message(): string {
    return msg`Tried to compare the following types but they are not comparable: '${this.params.left}' vs '${this.params.right}'.`;
  }
}

export class MacroIfBranchesMustHaveTheSameType extends TypeCheckError {
  constructor(
    private readonly params: {
      attr: string;
      lhs: Ty.Shorthand | '*Not Found*';
      rhs: Ty.Shorthand | '*Not Found*';
    }
  ) {
    super();
  }

  protected message(): string {
    return msg`Attr "${this.params.attr}" has type "${this.params.lhs}" in one branch, but has type "${this.params.rhs}" in another branch`;
  }
}

export class UnreplacedVariable extends TypeCheckError {
  constructor(
    readonly params: { namespace: 'rel' | 'expr' | 'section'; varName: string }
  ) {
    super();
  }

  protected message(): string {
    return msg`${_.startCase(this.params.namespace)} variable "${
      this.params.varName
    }" is required to be replaced`;
  }
}

export class VariableDoesNotExist extends TypeCheckError {
  constructor(readonly params: { namespace: 'rel' | 'expr'; varName: string }) {
    super();
  }

  protected message(): string {
    return msg`${_.startCase(this.params.namespace)} variable "${
      this.params.varName
    }" does not exist`;
  }
}

export class ReplacementMissingAttribute extends TypeCheckError {
  constructor(
    readonly params: {
      attrName: string;
      relName: string;
      ty: Ty.Shorthand;
    }
  ) {
    super();
  }

  protected message(): string {
    return msg`Relation Var "${this.params.relName}" is missing attribute ${this.params.attrName} of type "${this.params.ty}"`;
  }
}

export class RelDoesNotExist extends TypeCheckError {
  constructor(
    readonly params: {
      name: string;
      availableRels: string[];
    }
  ) {
    super();
  }

  protected message(): string {
    return msg`rel "${
      this.params.name
    }" not found in available rels ${JSON.stringify(
      this.params.availableRels
    )}`;
  }
}

export class CantUseNullAsANonNullableScalar extends TypeCheckError {
  protected message(): string {
    return msg`"null" was passed as a non nullable scalar`;
  }
}

export class DynamicAttributeMayNotBeConst extends TypeCheckError {
  constructor(
    readonly params: {
      name: string;
      source:
        | { t: 'file'; uri: string }
        | { t: 'table'; name: string; schema: string };
    }
  ) {
    super();
  }

  protected message(): string {
    return msg`Attribute "${this.params.name}" in ${
      this.params.source.t === 'file'
        ? `"${this.params.source.uri}"`
        : `"${this.params.source.schema}"."${this.params.source.name}"`
    } is specified as const, but is not allowed to be const`;
  }
}

export class AttributesCantBeTheSameLettersInDifferentCase extends TypeCheckError {
  constructor(readonly params: { lhs: string; rhs: string }) {
    super();
  }

  protected message(): string {
    return msg`Attributes "${this.params.lhs}" and "${this.params.rhs}" have the same lowercase representation and warehouse cant handle that`;
  }
}

export class WindowFunctionRequiresAFrameClause extends TypeCheckError {
  constructor(readonly params: { op: AST.WindowOp }) {
    super();
  }

  protected message(): string {
    return msg`Window function ${this.params.op} requires a frame clause, but none was provided`;
  }
}

export class WindowFunctionFrameBoundsCanNotBeNegative extends TypeCheckError {
  constructor(readonly params: { op: AST.WindowOp; frame: AST.WindowFrame }) {
    super();
  }

  protected message(): string {
    return msg`Window function ${
      this.params.op
    } was provied with frame ${JSON.stringify(
      this.params.frame
    )} which containes a negative bound`;
  }
}

export class WindowFunctionCanNotHaveAFrame extends TypeCheckError {
  constructor(readonly params: { op: AST.WindowOp; frame: AST.WindowFrame }) {
    super();
  }

  protected message(): string {
    return msg`Window function ${
      this.params.op
    } was provied with frame ${JSON.stringify(
      this.params.frame
    )} but can not have one`;
  }
}

export class WindowFunctionsWithAFrameRequireAnOrderBy extends TypeCheckError {
  constructor(readonly params: { op: AST.WindowOp }) {
    super();
  }

  protected message(): string {
    return msg`Window function ${this.params.op} is allowed to have a frame clause, but requires an "ORDER BY" to use it`;
  }
}

export class CantUseWindowInAWhereClause extends TypeCheckError {
  protected message(): string {
    return msg`Window function are not allowed in where clauses / filters`;
  }
}

export class DuplicateStatsKey extends TypeCheckError {
  constructor(readonly params: { keyName: string }) {
    super();
  }

  protected override message(): string {
    return msg`Both the relation and the config of this stat has the key "${this.params.keyName}"`;
  }
}

export class NoNullInsideArrayLiterals extends TypeCheckError {
  protected override message(): string {
    return msg`Arrays literals cannot contain null (hint: use ".coalesce" to provide a fallback)`;
  }
}

export class ArraysMustBeTheSameType extends TypeCheckError {
  constructor(readonly params: { incompatible: Ty.ExtendedAttributeType[] }) {
    super();
  }
  protected override message(): string {
    return msg`Arrays elements must all be the same type, got types [${this.params.incompatible
      .map((ty) => displayTy(ty))
      .map((x) => `"${x}"`)
      .join(', ')}]`;
  }
}

export class ArrayCreationMustHaveALeastOneElement extends TypeCheckError {
  protected override message(): string {
    return msg`Arrays must have at least one element`;
  }
}

export class ExprVarDefaultsCantBeWindowedOrAggregated extends TypeCheckError {
  constructor(
    readonly params: {
      windowed: boolean;
      aggregated: boolean;
      name: string;
      scope: string;
    }
  ) {
    super();
  }

  protected override message(): string {
    return msg`expression macro variable "${this.params.scope}"."${
      this.params.name
    }" is currently ${_.compact([
      this.params.windowed ? 'windowed' : null,
      this.params.aggregated ? 'aggregated' : null,
    ]).join(' and ')} but cant be`;
  }
}

export class ExprVarsDefaultsCantContainAttributes extends TypeCheckError {
  constructor(
    readonly params: {
      foundAttrs: readonly (readonly [string, string])[];
      name: string;
      scope: string;
    }
  ) {
    super();
  }

  protected override message(): string {
    return msg`expression macro variable "${this.params.scope}"."${
      this.params.name
    }" default cannot contain attributes [${this.params.foundAttrs
      .map(([source, name]) => `"${source}"."${name}"`)
      .join(', ')}]`;
  }
}

export class CantUseAttributsInExprVarReplacements extends TypeCheckError {
  constructor(
    readonly params: {
      readonly varName: string;
      readonly attributeName: string;
    }
  ) {
    super();
  }

  protected override message(): string {
    return msg`Expr Var "${this.params.varName}" contains attribute "${this.params.attributeName}" but var replacements cant have attributes`;
  }
}

export class InvalidUiControlsConfig extends TypeCheckError {
  constructor(
    readonly params: {
      readonly configType: string;
      readonly got: Ty.ExtendedAttributeType;
    }
  ) {
    super();
  }

  protected override message(): string {
    return msg`Unable to have "${this.params.configType}" controls for a variable of type "${this.params.got}"`;
  }
}

export class DynamiclySizedMarkupElementsCantConfigUrl extends TypeCheckError {
  constructor(
    readonly params: {
      readonly names: string[];
    }
  ) {
    super();
  }

  protected override message(): string {
    return msg`Params [${this.params.names
      .map((name) => `"${name}"`)
      .join(', ')}] cant be registered in a dynamicly sized element`;
  }
}

export class CantDoAMatchOnNonEnums extends TypeCheckError {
  constructor(
    readonly params: {
      readonly type: Ty.Shorthand;
    }
  ) {
    super();
  }

  protected override message(): string {
    return msg`Cant match on type "${Ty.displayTy(this.params.type)}"`;
  }
}

export class MissingMatchVariants extends TypeCheckError {
  constructor(
    readonly params: {
      readonly missingVariants: string[];
    }
  ) {
    super();
  }

  protected override message(): string {
    return msg`Missing match branches for variants [${this.params.missingVariants
      .map((variant) => `"${variant}"`)
      .join(', ')}]`;
  }
}

export class TypeCantBeDynamicallyAccessed extends TypeCheckError {
  constructor(
    readonly params: {
      ty: Ty.ExtendedAttributeType;
    }
  ) {
    super();
  }

  protected override message(): string {
    return msg`Type '${Ty.displayTy(
      this.params.ty
    )}' cant be dynamically accessed`;
  }
}

export class SingleVariantEnumRequired extends TypeCheckError {
  constructor(readonly params: { got: Ty.ExtendedAttributeType }) {
    super();
  }

  protected override message(): string {
    return msg`Wanted a single variant enum (like a string literal), but got type '${Ty.displayTy(
      this.params.got
    )}'`;
  }
}

export class CantHaveNullOfNonNullableType extends TypeCheckError {
  constructor(readonly params: { attempted: Ty.Shorthand }) {
    super();
  }

  protected override message(): string {
    throw new Error(
      `Cant have 'null' in type '${displayTy(this.params.attempted)}'`
    );
  }
}

export class CantParseAsTy extends TypeCheckError {
  constructor(readonly params: { err: TyParseError }) {
    super();
  }

  protected override message(): string {
    throw new Error(
      `Attempted to parse '${this.params.err.str}' as a type, but could not`
    );
  }
}
