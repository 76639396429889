import { ICON_MAPPING, IconName } from './mapping';
import { classNames, ColorScheme } from '@cotera/client/app/components/utils';
import { Theme } from '../../types/utils';

export const Icon: React.FC<{
  theme?: Theme;
  icon: IconName;
  className?: string;
}> = ({ theme, icon, className }) => {
  const IconComponent = ICON_MAPPING[icon];
  return (
    <IconComponent
      className={classNames(
        theme ? ColorScheme.text[theme] : '',
        'h-4 w-4',
        className
      )}
    />
  );
};
