import { f } from '@cotera/era';
import { Alert, CronExpression, ManifestShorthand } from '@cotera/sdk/core';
//import { Rfm } from '../apps';
//import { Discount } from '../apps/discount/app';
//import { ItemOverview } from '../apps/item-overview/app';
//import { ItemDetailPerformance } from '../apps/item-performance/app';
//import { Replenishment } from '../apps/replenishment/app';
//import { DISCOUNTS } from '../apps/shopify-assumptions';
//import { Soma } from '../apps/soma/app';
//import { AmazonReviews } from './apps/amazon-reviews/app';
import {
  NotShadowBannedComments,
  ShadowBannedComments,
  UsersFlagged,
} from './apps/telegraph_analysis/telegraph_analytics';
import {
  Reviews,
  ReviewsTagStream,
  ReviewsUdd,
} from './apps/amazon-reviews/llm';
//import { OrdersOverview } from '../apps/orders-overview/app';
//import { CustomerInsights } from './apps/customer-insights/app';
import { Sentiments } from './apps/customer-insights/models';
//import { DemoEngagementRfm } from './apps/platform-lifecycle/app';
//import {
//  PlatformRfmLifecycleSummary,
//  UserCategoryTotals,
//  customerSegments,
//} from './apps/platform-lifecycle/inputs';
import * as assumptions from './assumptions';
import * as tables from './base';
import {
  //HistoricalCustomerRfm,
  RfmLifectyleMaterialization,
  //RfmLifecycleSummary,
  //TodaysCustomerRFM,
} from './rfm';
//import { OrderAnalysisAdjusted, OrderDataPerWeek } from './shopify-analysis';
import { UserLifecycleMetrics } from './user-features';
//import { B2CCompetitorApp } from './b2c-competitor-demo';
//import { B2BApp } from './b2b-competitor-demo';
//import { TaxonomyApp } from './apps/taxonomy/app';
import { LinkedinGrowthHacks } from './linkedin-growth-hacks';
//import {
//  AllCompanyReviews,
//  CompanyList,
//  CompanyReviewOverview,
//} from './apps/company-product-reviews/company-list';
//import { CompanyDetail } from './apps/company-product-reviews/company';
import { TaxonomyAppV2 } from './apps/taxonomy-v2/app';

export const CoteraManifest: ManifestShorthand = {
  definitions: {
    ...assumptions,
    ...tables,
    UserLifecycleMetrics,
    Sentiments,
    Reviews,
    NotShadowBannedComments,
    ShadowBannedComments,
    UsersFlagged,
  },
  schemas: {
    read: ['COTERA_DATA', 'DEMO', 'GROWTH_HACKS'],
  },
  udds: [ReviewsUdd],
  eventStreams: [ReviewsTagStream],
  apps: {
    //'b-2-b': B2BApp,
    //'competitor-analysis': B2CCompetitorApp,
    //['review-analysis']: AmazonReviews,
    //metrics: Soma({ OrderDataPerWeek }),
    //discounts: Discount({
    //  Orders: tables.OrdersAdjusted,
    //  Discounts: From(DISCOUNTS()),
    //}),
    //feedback: CustomerInsights,
    //replenishment: Replenishment({
    //  OrderAnalysis: OrderAnalysisAdjusted,
    //  Orders: tables.OrdersAdjusted,
    //}),
    //['platform-engagement-lifecycle']: DemoEngagementRfm({
    //  RfmLifecycleSummary: PlatformRfmLifecycleSummary,
    //  customerSegments,
    //  UserCategoryTotals,
    //}),
    //lifecycle: Rfm({
    //  RfmLifecycleSummary,
    //  HistoricalCustomerRfm,
    //  categoryMapping: {
    //    'Top Customers': 'active',
    //    'Repeat Customers': 'active',
    //    'At Risk Customers': 'active',
    //    'Top New Customers': 'new',
    //    'New Customers': 'new',
    //    'Lapsed Customers': 'inactive',
    //    'Hibernating Customers': 'inactive',
    //    'Lost Customers': 'inactive',
    //  },
    //  customerIdColumn: (t: RelationRef) => t.attr('__COTERA_CUSTOMER_ID'),
    //  boundaryCustomerCategory: 'At Risk Customers',
    //  activity: {
    //    revenue: {
    //      total: 'total_spend',
    //      avg: 'avg_spend',
    //      unit: 'usd',
    //    },
    //    orders: {
    //      total: 'total_order_count',
    //      avg: 'avg_order_count',
    //    },
    //    items: {
    //      total: 'total_item_count',
    //      avg: 'avg_items',
    //    },
    //  },
    //}),
    //'item-performance': ItemDetailPerformance({
    //  Orders: tables.OrdersAdjusted,
    //  ActiveItems: tables.ActiveItems,
    //  ItemDetail: tables.ItemDetail,
    //  TodaysCustomerRfm: TodaysCustomerRFM,
    //}),
    //'item-overview': ItemOverview({
    //  ActiveItems: tables.ActiveItems,
    //  ItemDetail: tables.ItemDetail,
    //}),
    //'orders-overview': OrdersOverview({
    //  Orders: tables.OrdersAdjusted,
    //  ItemDetail: tables.ItemDetail,
    //}),
    //'taxonomy-demo': TaxonomyApp,
    'taxonomy-v2': TaxonomyAppV2,
    //'semantic-test': [
    //  UI.State(
    //    {
    //      rel: From({
    //        schema: 'GROWTH_HACKS',
    //        name: 'CHIPOTLE',
    //        attributes: {
    //          ID: 'string',
    //          SUMMARY: 'string',
    //          TIMESTAMP: 'timestamp',
    //          EMBEDDING: Ty.EMBEDDING,
    //          CATEGORY: Ty.CARD_LT_1000,
    //          SUB_CATEGORY: Ty.CARD_LT_1000,
    //        },
    //      })
    //        .select((t) => ({
    //          ...t.star(),
    //          VALUE: t.attr('SUMMARY'),
    //          SIMILARITY: Constant(0, {
    //            ty: Ty.ty('float', { nullable: true }),
    //          }),
    //        }))
    //        .renameAttributes((n) => n.toLowerCase()),
    //    },
    //    ({ rel }) => [
    //      rel.SemanticSearch(),
    //      rel.chart.SemanticSearchResults((t) =>
    //        t.pick('id', 'value', 'timestamp', 'similarity')
    //      ),
    //    ]
    //  ),
    //],
    //'company-overview': CompanyReviewOverview({
    //  CompanyReviews: CompanyList,
    //}),
    //'company-detail': CompanyDetail({ CompanyReviews: AllCompanyReviews }),
  },
  materializations: [
    { schedule: 'daily', config: RfmLifectyleMaterialization },
  ],
  alerts: [
    Alert({
      id: 'linkedin-growth-hacks',
      rel: LinkedinGrowthHacks.select((t) => ({
        key: t.attr('URN'),
        type: 'info',
        title: f`LinkedIn Growth Post`,
        subtitle: f`*Post URL:* <${t.attr(
          'POSTURL'
        )}|Post URL>\n\n*Author:* ${t.attr('AUTHOR_FIRSTNAME')} ${t.attr(
          'AUTHOR_LASTNAME'
        )} | *Author URL:*  <${t.attr(
          'AUTHOR_URL'
        )}|Author's LinkedIn> \n\n *URN:* ${t.attr('URN')}\n\n`,
        description: f`${t.attr('TEXT')} \n\n *Total Reactions:* ${t.attr(
          'TOTALREACTIONCOUNT'
        )} | *Comments:* ${t.attr('COMMENTSCOUNT')} | *Reposts:* ${t.attr(
          'REPOSTSCOUNT'
        )}`,
      })),
      when: {
        cron: CronExpression.EVERY_DAY_AT_2PM,
      },
      config: {
        channel: 'C07FWPL234N',
      },
    }),
  ],
};
