import { Contract, defaultErrors, GET, POST } from '@cotera/contracts';
import z from 'zod';

export const TopicsContract = Contract.new({
  detectedFeatures: GET({
    params: z.object({
      entityId: z.string(),
      versionId: z.string(),
      search: z.object({
        topicId: z.string().optional(),
        assigned: z.boolean().optional(),
      }),
    }),
    output: z.object({
      features: z
        .object({
          featureId: z.string(),
          content: z.string(),
          type: z.string(),
          createdAt: z.date(),
          sentiment: z.number().nullable(),
          documentCount: z.number(),
          topic: z
            .object({
              name: z.string(),
              id: z.string().nullable(),
              exampleId: z.string().nullable(),
            })
            .nullable(),
        })
        .array(),
      total: z.number(),
    }),
    errors: z.never(),
  }),
  versions: GET({
    params: z.object({
      entityId: z.string(),
    }),
    output: z
      .object({
        version: z.string(),
        id: z.string(),
        published: z.boolean(),
        topics: z
          .object({
            id: z.string(),
            name: z.string(),
          })
          .array(),
      })
      .array(),
    errors: z.never(),
  }),
  topics: GET({
    params: z.object({
      versionId: z.string(),
    }),
    output: z
      .object({
        name: z.string(),
        id: z.string(),
        description: z.string().nullable(),
      })
      .array(),
    errors: z.never(),
  }),
  save: POST({
    params: z.object({
      versionId: z.string(),
      topics: z
        .object({
          id: z.string(),
          action: z.enum(['delete', 'update', 'create']).optional(),
          name: z.string(),
          description: z.string().nullable(),
          features: z.array(z.string()),
        })
        .array(),
    }),
    output: z.object({}),
    errors: z.never(),
  }),
  newVersion: POST({
    params: z.object({
      entityId: z.string(),
      sourceVersionId: z.string().optional(),
      version: z.string(),
    }),
    output: z.object({
      versionId: z.string(),
    }),
    errors: defaultErrors(['NotFound']),
  }),
  publishVersion: POST({
    params: z.object({
      versionId: z.string(),
    }),
    output: z.object({}),
    errors: z.never(),
  }),
  match: POST({
    params: z.object({
      entityId: z.string(),
      summaryPromptId: z.string(),
      topicExtractionPromptId: z.string(),
      versionId: z.string(),
      messages: z
        .object({
          content: z.string(),
          id: z.string(),
          coteraStableId: z.string(),
        })
        .array(),
    }),
    output: z.object({
      matches: z
        .object({
          topicId: z.string(),
          sentiment: z.number(),
          coteraStableId: z.string(),
        })
        .array(),
    }),
    errors: defaultErrors([
      'NotFound',
      'LlmError',
      'EmbeddingsError',
      'LlmValidationError',
      'NoMatch',
    ]),
  }),
});
