import { z } from 'zod';
import { Contract, defaultErrors, GET, POST } from '@cotera/contracts';

export const fivetranShopifySourceSchema = z.object({
  id: z.string().uuid(),
  shopName: z.string(),
  connectorId: z.string(),
});

export type FivetranShopifySource = z.infer<typeof fivetranShopifySourceSchema>;

export const FivetranShopifyContract = Contract.new({
  getSourceForOrg: GET({
    params: z.object({}),
    output: fivetranShopifySourceSchema,
    errors: defaultErrors(['NotFound']),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
  createConnectCard: POST({
    params: z.object({
      id: z.string().uuid(),
    }),
    output: z.object({
      url: z.string().url(),
    }),
    errors: defaultErrors(['FivetranError', 'NotFound']),
    mustBeAbleTo: [['manage', 'warehouse']],
  }),
});

export const SOURCES = ['shopify'] as const;

export const SourcesContract = Contract.new({
  fivetranShopify: FivetranShopifyContract,
});
