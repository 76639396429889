import { Constant, Relation, Ty, Histogram as HistogramRel } from '@cotera/era';
import { CategoricalAttributes } from '@cotera/sdk/core';
import React, { Suspense } from 'react';
import { z } from 'zod';
import { ChildrenProps } from '@cotera/client/app/components/utils';
import { Loading, Text, Tooltip } from '@cotera/client/app/components/ui';
import { Categorical, Histogram } from '@cotera/client/app/components/data-vis';
import { useArtifactQuery } from '@cotera/client/app/etc/data/duckdb';
import { METADATA_CONFIG } from '@cotera/client/app/etc/duckdb/constants';
import { DateDisplay } from '../../ui/typography';
import { ErrorBoundary } from 'react-error-boundary';

type NamedAttr = {
  name: string;
  ty: Ty.ExtendedAttributeType;
};

type HeaderProps = {
  rel: Relation;
  attr: NamedAttr;
  filterValue?: string;
  summary?: {
    min: string | null;
    max: string | null;
    unique: number | null;
  };
  onClick?: (value: any) => void;
};

export const AsyncHeaderWrapper: React.FC<ChildrenProps> = ({ children }) => {
  return (
    <ErrorBoundary fallbackRender={({ error }) => <ErrorStats error={error} />}>
      <Suspense fallback={<LoadingStats />}>{children}</Suspense>
    </ErrorBoundary>
  );
};

const mapType = (t: NamedAttr['ty']): React.ReactNode => {
  const ty = Ty.shorthandToTy(t);

  // if (isAction(t)) {
  //   return FFI_ACTIONS[getActionName(t)].displayType;
  // }

  // if (isLink(t)) {
  //   return 'link';
  // }

  const full = Ty.displayTy(ty);

  const inner = ty.ty.k === 'primitive' ? ty.ty.t : ty.ty.k;

  if (ty.ty.k === 'primitive') {
    return <span>{inner}</span>;
  } else {
    return (
      <Tooltip side="top" text={full}>
        <span>{inner}</span>
      </Tooltip>
    );
  }
};

export const BaseHeader = ({
  children,
  attr,
}: {
  attr: NamedAttr;
  children?: ChildrenProps['children'];
}) => {
  return (
    <div className="flex relative flex-col px-3 w-full pb-2 bg-white">
      <div className="whitespace-nowrap font-semibold inline-block w-[calc(100%-8px)] overflow-hidden text-ellipsis">
        {attr.name}
      </div>
      <Text.Caption className="mb-2 whitespace-nowrap text-ellipsis verflow-hidden">
        {mapType(attr.ty)}
      </Text.Caption>
      {children}
    </div>
  );
};

export const ContinuousHeader: React.FC<HeaderProps> = ({
  rel,
  attr,
  onClick,
}) => {
  const { data } = useArtifactQuery({
    baseRel: rel,
    limit: 50_000,
    rel: (rel) =>
      HistogramRel(
        rel,
        (r) => ({ target: r.attr(attr.name), group: Constant('all') }),
        { numBuckets: 10 }
      ),
  });

  return (
    <div className="w-full h-[45px]">
      <Histogram
        onClick={(min, max) => {
          onClick?.({ min, max });
        }}
        buckets={data.data.toArrayOf(
          z.object({
            count: z.number().default(0),
            start: z.number().nullable(),
            end: z.number().nullable(),
          })
        )}
      />
    </div>
  );
};

export const CategoricalHeader: React.FC<HeaderProps> = ({
  rel,
  attr,
  summary,
  onClick,
}) => {
  const result = useArtifactQuery({
    baseRel: rel,
    limit: 50_000,
    rel: (rel) =>
      CategoricalAttributes(rel, (r) => r.attr(attr.name), {
        discreteThreshold: METADATA_CONFIG.MAX_CATEGORIES,
      }),
  });

  return (
    <div className="w-full h-[45px]">
      <Categorical
        categories={result.data.data.toArrayOf(
          z.object({ count: z.number(), value: z.string().nullable() })
        )}
        numDistinct={summary?.unique ?? 0}
        onClick={(c) => {
          onClick?.(c);
        }}
      />
    </div>
  );
};

export const TimestampHeader = ({ summary }: HeaderProps) => {
  return (
    <div className="flex flex-col w-full">
      {summary?.min && (
        <Text.Caption className="text-xs mb-1">
          Min: <DateDisplay date={new Date(summary.min)} />
        </Text.Caption>
      )}
      {summary?.max && (
        <Text.Caption className="text-xs">
          Max: <DateDisplay date={new Date(summary.max)} />
        </Text.Caption>
      )}
    </div>
  );
};

const LoadingStats = () => (
  <div className="h-[45px] flex flex-col">
    <Loading.Shimmer className="h-[25px] w-full mb-2" />
    <Loading.Shimmer className="h-[8px] w-1/2 " />
  </div>
);

const ErrorStats: React.FC<{ error?: any }> = ({ error }) => {
  return (
    <Tooltip text={String(error)} side="bottom">
      <div className="h-[45px] flex flex-col">
        <div className="h-[25px] w-full text-red-500 font-semibold text-xs">
          Unable to load data
        </div>
      </div>
    </Tooltip>
  );
};
