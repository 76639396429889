/* eslint-disable @typescript-eslint/unbound-method */
import { err, ok, Result } from 'neverthrow';
import {
  Constant,
  Neq,
  Eq,
  f,
  IsNull,
  Not,
  NullIf,
  Case,
  Or,
  And,
  Match,
  Desc,
  Asc,
  CosineDistance,
} from './utilities';
import _ from 'lodash';
import type { AST } from '../ast';
import { Ty } from '../ty';
import { TC, TyStackTrace } from '../type-checker';
import { CountOver } from './window';
import { expandExpr } from '../macros/expand-expr';
import { Macro } from '../macros';
import { evalExprIR } from '../interpreter/eval-expr-ir';
import type { ExprTypeCheck } from '../type-checker/expr/check-expr';
import { GLOBAL_SCOPE } from './macro/macro-base';
import { Markup } from './markup';
import { Relation } from './relation';
import { RelationRef } from './relation-ref';
import { dateFmt } from './date-formatting';
import {
  CantDoAMatchOnNonEnums,
  InvalidFunctionCall,
  MissingMatchVariants,
} from '../type-checker/type-check-error';
import { MarkupShorthand } from './markup/section';
import { M } from '.';
import type { EraVar } from './era-var';

const EXPRESSION_CACHE: WeakMap<AST.Expr, WeakRef<Expression>> = new WeakMap();

export class Expression {
  protected constructor(
    readonly ast: AST.Expr,
    private readonly typecheck: ExprTypeCheck
  ) {}

  toString() {
    const e = Error(
      `Trying to convert an Expression to a string, did you mean to use the "${f.name}" helper on a template string?`
    );

    if ((Error as any).captureStackTrace) {
      (Error as any).captureStackTrace(e, this.toString);
    }

    throw e;
  }

  static wrap(
    expr: Ty.Scalar | null | Expression,
    opts?: { ty?: Ty.Shorthand }
  ): Expression {
    return expr instanceof Expression ? expr : Constant(expr, opts);
  }

  static fromAst(
    ast: AST.Expr,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    const checked = this.tryfromAst(ast);

    if (checked.isOk()) {
      return checked.value;
    }

    throw checked.error.toError({
      jsStackPointer: opts?.jsStackPointer ?? this.fromAst,
    });
  }

  static tryfromAst(ast: AST.Expr): Result<Expression, TyStackTrace> {
    const existing = EXPRESSION_CACHE.get(ast)?.deref();

    if (existing) {
      return ok(existing);
    }

    const checked = TC.checkExpr(ast);

    if (checked instanceof TyStackTrace) {
      return err(checked);
    }

    const expr = new Expression(ast, checked);
    Object.freeze(expr);
    EXPRESSION_CACHE.set(ast, new WeakRef(expr));
    return ok(expr);
  }

  tag(tag: string): Expression {
    return this.functionCall('tag', [tag], {
      jsStackPointer: this.tag,
    });
  }

  andThen(cb: (expr: Expression) => Expression): Expression {
    return cb(this);
  }

  get freeVars(): { readonly [scope: string]: AST.MacroArgsType } {
    return this.typecheck.vars;
  }

  get ty(): Ty.ExtendedAttributeType {
    return this.typecheck.ty;
  }

  get isAggregated(): boolean {
    return this.typecheck.aggregated;
  }

  get isWindowed(): boolean {
    return this.typecheck.windowed;
  }

  implements(req: Ty.Shorthand): boolean {
    return TC.implementsTy({ subject: this.ty, req });
  }

  get(name: null | string | Expression): Expression {
    const otherArg = name === null ? Constant(null, { ty: 'string' }) : name;
    return this.functionCall('get_from_record', [otherArg], {
      jsStackPointer: this.get,
    });
  }

  getField(first: string, ...path: string[]): Expression {
    return path.reduce(
      (expr, part) => expr.getField(part),
      Expression.fromAst(
        { t: 'get-field', name: first, expr: this.ast },
        {
          jsStackPointer: this.getField,
        }
      )
    );
  }

  isNull(): Expression {
    return IsNull(this);
  }

  isNotNull(): Expression {
    return Not(IsNull(this));
  }

  coalesce(
    expr: Ty.Scalar | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    return this.functionCall('??', [expr], {
      jsStackPointer: opts?.jsStackPointer ?? this.coalesce,
    });
  }

  oneOf(
    options: readonly (
      | boolean
      | string
      | number
      | Date
      | Record<string, Ty.Scalar>
      | Expression
    )[]
  ): Expression {
    return options.length === 0
      ? Constant(false)
      : options.map((opt) => this.eq(opt)).reduce((l, r) => l.or(r));
  }

  between(min: Ty.Scalar | Expression, max: Ty.Scalar | Expression) {
    const opts = {
      jsStackPointer: this.between,
    };
    return this.gte(min, opts).and(this.lte(max, opts));
  }

  cast(targetTy: Ty.Shorthand): Expression {
    return Expression.fromAst(
      {
        t: 'cast',
        targetTy: Ty.shorthandToTy(targetTy).ty,
        expr: this.ast,
      },
      {
        jsStackPointer: this.cast,
      }
    );
  }

  concat(other: string | Expression, opts?: { jsStackPointer: Function }) {
    return this.functionCall('||', [other], {
      jsStackPointer: opts?.jsStackPointer ?? this.concat,
    });
  }

  like(
    other: string | Expression,
    opts?: { jsStackPointer: Function }
  ): Expression {
    return this.functionCall('like', [other], {
      jsStackPointer: opts?.jsStackPointer ?? this.like,
    });
  }

  eq(other: Ty.Scalar | Expression): Expression {
    return Eq(this, other, {
      jsStackPointer: this.eq,
    });
  }

  neq(other: Ty.Scalar | Expression): Expression {
    return Neq(this, other, { jsStackPointer: this.neq });
  }

  isDistinctFrom(other: Ty.Scalar | Expression): Expression {
    const r = other instanceof Expression ? other : Constant(other);
    return Or(
      And(this.isNull(), r.isNotNull()),
      And(this.isNotNull(), r.isNull()),
      And(And(this.isNotNull(), r.isNotNull()), Neq(this, r))
    );
  }

  lower(opts?: { jsStackPointer: Function }): Expression {
    return this.functionCall('lower', [], {
      jsStackPointer: opts?.jsStackPointer ?? this.lower,
    });
  }

  upper(opts?: { jsStackPointer: Function }): Expression {
    return this.functionCall('upper', [], {
      jsStackPointer: opts?.jsStackPointer ?? this.upper,
    });
  }

  replace(
    oldChars: string | Expression,
    newChars: string | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    return this.functionCall('replace', [oldChars, newChars], {
      jsStackPointer: opts?.jsStackPointer ?? this.replace,
    });
  }

  length(opts?: { jsStackPointer: Function }): Expression {
    return this.functionCall('length', [], {
      jsStackPointer: opts?.jsStackPointer ?? this.length,
    });
  }

  functionCall(
    op: AST.FunctionIdentifier,
    args: (Ty.Scalar | Expression)[],
    opts?: { jsStackPointer?: Function }
  ): Expression {
    const argExprs = [
      // Use `this` as the first argument
      this,
      ...args.map((arg) => (arg instanceof Expression ? arg : Constant(arg))),
    ];

    const expr: AST._FunctionCall = {
      t: 'function-call',
      op,
      args: argExprs.map((arg) => arg.ast),
    };

    return Expression.fromAst(expr, {
      jsStackPointer: opts?.jsStackPointer ?? this.functionCall,
    });
  }

  and(
    expr: boolean | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    return this.functionCall('and', [expr], {
      jsStackPointer: opts?.jsStackPointer ?? this.and,
    });
  }

  or(
    expr: boolean | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    return this.functionCall('or', [expr], {
      jsStackPointer: opts?.jsStackPointer ?? this.or,
    });
  }

  gt(
    expr: Ty.Scalar | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    return this.functionCall('>', [expr], {
      jsStackPointer: opts?.jsStackPointer ?? this.gt,
    });
  }

  gte(
    expr: Ty.Scalar | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    return this.functionCall('>=', [expr], {
      jsStackPointer: opts?.jsStackPointer ?? this.gte,
    });
  }

  lt(
    expr: Ty.Scalar | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    return this.functionCall('<', [expr], {
      jsStackPointer: opts?.jsStackPointer ?? this.lt,
    });
  }

  lte(
    expr: Ty.Scalar | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    return this.functionCall('<=', [expr], {
      jsStackPointer: opts?.jsStackPointer ?? this.lte,
    });
  }

  add(expr: number | Expression): Expression {
    return this.functionCall('+', [expr], { jsStackPointer: this.add });
  }

  sub(expr: number | Expression): Expression {
    return this.functionCall('-', [expr], { jsStackPointer: this.sub });
  }

  div(expr: number | Expression): Expression {
    return this.functionCall('/', [expr], { jsStackPointer: this.div });
  }

  floor(opts?: { jsStackPointer?: Function }): Expression {
    return this.functionCall('floor', [], {
      jsStackPointer: opts?.jsStackPointer ?? this.floor,
    });
  }

  ceil(opts?: { jsStackPointer?: Function }): Expression {
    return this.functionCall('ceil', [], {
      jsStackPointer: opts?.jsStackPointer ?? this.floor,
    });
  }

  toThePowerOf(expr: number | Expression): Expression {
    return this.functionCall('^', [expr], {
      jsStackPointer: this.toThePowerOf,
    });
  }

  safeDiv(expr: Ty.Scalar | Expression): Expression {
    return this.functionCall('/', [NullIf(expr, 0)], {
      jsStackPointer: this.div,
    });
  }

  mul(
    expr: Ty.Scalar | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    return this.functionCall('*', [expr], {
      jsStackPointer: opts?.jsStackPointer ?? this.mul,
    });
  }

  not(opts?: { jsStackPointer?: Function }): Expression {
    return this.functionCall('not', [], {
      jsStackPointer: opts?.jsStackPointer ?? this.not,
    });
  }

  match(
    cases: Record<string, Ty.Scalar | Expression>,
    opts?: { jsStackPointer?: Function }
  ) {
    return Match(this, cases, {
      jsStackPointer: opts?.jsStackPointer ?? this.match,
    });
  }

  abs(opts?: { jsStackPointer?: Function }): Expression {
    return this.functionCall('abs', [], {
      jsStackPointer: opts?.jsStackPointer ?? this.abs,
    });
  }

  round(
    amount: number | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    return this.functionCall('round', [amount], {
      jsStackPointer: opts?.jsStackPointer ?? this.round,
    });
  }

  clamp(
    lowerBound: Ty.Scalar | Expression,
    upperBound: Ty.Scalar | Expression
  ): Expression {
    const opts = { jsStackPointer: this.clamp };

    return Case(
      [
        { when: this.lt(lowerBound, opts), then: lowerBound },
        { when: this.gt(upperBound, opts), then: upperBound },
      ],
      { else: this, ...opts }
    );
  }

  dateDiff(
    end: Date | Expression,
    timeframe: 'days' | 'years' | 'seconds',

    opts?: { jsStackPointer?: Function }
  ) {
    return this.functionCall('date_diff', [end, timeframe], {
      jsStackPointer: opts?.jsStackPointer ?? this.dateDiff,
    });
  }

  dateAdd(
    timeframe: 'days' | 'weeks',
    amount: number | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    const newOpts = { jsStackPointer: opts?.jsStackPointer ?? this.dateAdd };
    const rhs = amount instanceof Expression ? amount : Constant(amount);
    switch (timeframe) {
      case 'days':
        return this.functionCall('date_add', [rhs, 'days'], newOpts);
      case 'weeks':
        return this.functionCall('date_add', [rhs.mul(7), 'days'], newOpts);
    }
  }

  dateSub(
    timeframe: 'days' | 'weeks',
    amount: number | Expression,
    opts?: { jsStackPointer?: Function }
  ): Expression {
    const { jsStackPointer = this.dateSub } = opts ?? {};
    const amt = amount instanceof Expression ? amount : Constant(amount);
    return this.dateAdd(timeframe, amt.mul(-1, { jsStackPointer }), {
      jsStackPointer,
    });
  }

  datePart(
    timeframe:
      | 'seconds'
      | 'minute'
      | 'hour'
      | 'day'
      | 'dow'
      | 'week'
      | 'month'
      | 'quarter'
      | 'year'
  ): Expression {
    return this.functionCall('date_part', [timeframe]);
  }

  dateFmt(fmt: string): Expression {
    if (!TC.implementsTy({ subject: this.ty, req: 'timestamp' })) {
      // This isn't a real function, but it can pretend to be!
      throw new InvalidFunctionCall({
        op: 'date_fmt',
        recieved: [this.ty],
        allowed: [[[Ty.ty('timestamp')], Ty.ty('string')]],
      })
        .toStackTrace({})
        .toError({ jsStackPointer: this.dateFmt });
    }
    const res = dateFmt(this, fmt);

    if (res.isErr()) {
      const e = new Error(res.error.msg);
      if ((Error as any).captureStackTrace) {
        (Error as any).captureStackTrace(e, this.dateFmt);
      }
      throw e;
    }

    return res.value;
  }

  dateTrunc(
    timeframe: 'minute' | 'hour' | 'day' | 'week' | 'month' | 'quarter' | 'year'
  ): Expression {
    return this.functionCall('date_trunc', [timeframe]);
  }

  desc() {
    return Desc(this);
  }

  asc() {
    return Asc(this);
  }

  assertDistinct(params?: { key?: string }): Expression {
    return this.invariants({
      [`${params?.key ? `${params.key} ` : ''}Is Unique`]: CountOver(true, {
        partitionBy: [this],
      }).eq(1),
    });
  }

  assertNotNull(params?: { key?: string }): Expression {
    return this.invariants({
      [`${params?.key ? `${params.key} ` : ''}Is Not Null`]: Not(IsNull(this)),
    });
  }

  tap(callback: (expr: Expression) => void): Expression {
    callback(this);
    return this;
  }

  ir(): AST.ExprIR {
    return expandExpr(this.ast, {});
  }

  isNumericString() {
    return this.functionCall('is_numeric_string', []);
  }

  invariants(invariants: {
    readonly [name: string]: boolean | Expression;
  }): Expression {
    const invs = _.mapValues(invariants, (expr) => Expression.wrap(expr));

    return Expression.fromAst({
      t: 'invariants',
      expr: this.ast,
      invariants: _.mapValues(invs, ({ ast: expr }) => expr),
    });
  }

  impure(): Expression {
    return this.functionCall('impure', []);
  }

  evaluate(mocks?: {
    now?: Date;
    sources?: Partial<Record<'from' | 'left' | 'right', Ty.Row>>;
  }): Ty.Scalar {
    const inner = mocks?.now
      ? Macro.callExprMacro(
          this.ast,
          { exprs: { now: Constant(mocks.now).ast } },
          { scope: GLOBAL_SCOPE }
        ).ir()
      : this.ir();

    return evalExprIR(inner, { sources: mocks?.sources ?? {} });
  }

  cosineDistance(other: number[] | Expression): Expression {
    return CosineDistance(this, other);
  }
}

export class ExprVar extends Expression implements EraVar {
  protected constructor(
    readonly exprVar: AST._ExprVar,
    typecheck: ExprTypeCheck
  ) {
    super(exprVar, typecheck);
    Object.freeze(this);
  }

  variableId(): { readonly name: string; readonly scope: string } {
    return { name: this.exprVar.name, scope: this.exprVar.scope };
  }

  muMatch(cases: Record<string, MarkupShorthand>): Markup {
    const problem = this.#checkMatch(cases, { jsStackPointer: this.muMatch });

    if (problem !== null) {
      throw problem;
    }

    return M.muCase(
      Object.entries(cases).map(([val, then]) => ({ when: this.eq(val), then }))
    );
  }

  withNewDefault(newDefault?: Ty.Scalar | Expression) {
    return ExprVar.create({
      name: this.exprVar.name,
      scope: this.exprVar.scope,
      ty: this.exprVar.ty,
      default: newDefault,
    });
  }

  Controls(opts?: { label?: boolean }) {
    return Markup.fromAst({
      t: 'expr-controls-v2',
      var: this.exprVar,
      config: null,
      label: opts?.label ?? true,
    });
  }

  TabSelector(
    options?: readonly string[],
    opts?: { jsStackPointer?: Function }
  ) {
    return this.PickList(options, {
      jsStackPointer: opts?.jsStackPointer ?? this.TabSelector,
      display: 'tab-selector',
      label: false,
    });
  }

  Nav(options?: readonly string[], opts?: { jsStackPointer?: Function }) {
    return this.PickList(options, {
      jsStackPointer: opts?.jsStackPointer ?? this.Nav,
      display: 'nav',
      label: false,
    });
  }

  Toggle(
    options?: readonly string[],
    opts?: { jsStackPointer?: Function }
  ): Markup {
    return this.PickList(options, {
      ...opts,
      jsStackPointer: opts?.jsStackPointer ?? this.Toggle,
      display: 'toggle',
    });
  }

  PickFrom(
    rel: Relation,
    option: (t: RelationRef) => Expression,
    opts?: {
      jsStackPointer?: Function;
      display?: 'picklist' | 'toggle' | 'tab-selector';
      label?: boolean;
    }
  ): Markup {
    return Markup.fromAst(
      {
        t: 'expr-controls-v2',
        var: this.exprVar,
        label: opts?.label ?? true,
        config: {
          t: 'pickfrom',
          rel: rel.select((t) => ({ option: option(t) }), { distinct: true })
            .ast,
          display: opts?.display ?? 'picklist',
        },
      },
      { jsStackPointer: opts?.jsStackPointer ?? this.PickList }
    );
  }

  PickList(
    options?: readonly string[],
    opts?: {
      jsStackPointer?: Function;
      display?: 'picklist' | 'toggle' | 'tab-selector' | 'nav';
      label?: boolean;
    }
  ): Markup {
    let inferedOpts: readonly string[] | null = null;

    if (options) {
      inferedOpts = options;
    } else {
      if (this.ty.ty.k === 'enum') {
        inferedOpts = this.ty.ty.t;
      } else {
        throw new Error(
          `Cant infer options for type "${Ty.displayTy(
            this.ty
          )}", please provide options`
        );
      }
    }

    return Markup.fromAst(
      {
        t: 'expr-controls-v2',
        var: this.exprVar,
        label: opts?.label ?? true,
        config: {
          t: 'picklist',
          options: inferedOpts,
          display: opts?.display ?? 'picklist',
        },
      },
      { jsStackPointer: opts?.jsStackPointer ?? this.PickList }
    );
  }

  static create(params: {
    name: string;
    scope: string;
    ty: Ty.Shorthand;
    default?: Expression | Ty.Scalar;
  }): ExprVar {
    const ast: AST._ExprVar = {
      t: 'expr-var',
      name: params.name,
      scope: params.scope,
      ty: Ty.shorthandToTy(params.ty),
      default:
        params.default !== undefined
          ? Expression.wrap(params.default).ast
          : null,
    };

    const checked = TC.checkExpr(ast);

    if (checked instanceof TyStackTrace) {
      throw checked.toError({ jsStackPointer: this.create });
    }

    return new this(ast, checked);
  }

  isVariableIn(target: Expression | Relation | Markup): boolean {
    return (
      target.freeVars[this.exprVar.scope]?.exprs[this.exprVar.name] !==
      undefined
    );
  }

  #checkMatch(
    cases: Record<string, unknown>,
    opts: { jsStackPointer: Function }
  ): Error | null {
    if (!(this.ty.ty.k === 'enum')) {
      return new CantDoAMatchOnNonEnums({ type: this.ty })
        .toStackTrace({})
        .toError(opts);
    }

    const missingVariants = this.ty.ty.t.filter(
      (variant) => cases[variant] === undefined
    );

    if (missingVariants.length > 0) {
      return new MissingMatchVariants({ missingVariants })
        .toStackTrace({})
        .toError(opts);
    }

    return null;
  }
}
