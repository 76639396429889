import { Relation } from '@cotera/era';
import { pick } from 'lodash';

export const queryKeys = {
  artifacts: {
    usableCache(params: { orgId: string }) {
      return [params.orgId, 'usableCache'];
    },
    requestById(params: { orgId: string; reqId: string }) {
      return [params.orgId, 'artifactRequests', params.reqId];
    },
    requests(params: { orgId: string; page: number; sqlHash?: string }) {
      return [
        params.orgId,
        'artifactRequests',
        ...(params.sqlHash ? [params.sqlHash] : ['all']),
        { page: params.page },
      ];
    },
    forRel(params: { orgId: string; rel: Relation }) {
      return [params.orgId, 'relation', params.rel.sqlHash()];
    },
    data(params: { orgId: string; sqlHash: string }) {
      return [params.orgId, 'data', params.sqlHash];
    },
  },
  magicColumns: {
    list(params: { orgId: string }) {
      return [params.orgId, 'magicColumns', 'list'];
    },
  },
  user: {
    whoami() {
      return 'whoami' as const;
    },
  },
  superAdmin: {
    orgs: {
      all() {
        return 'superAdminAllOrgs' as const;
      },
    },
  },
  warehouse: {
    keywordConfig(params: { orgId: string }) {
      return [params.orgId, 'keyword-config'];
    },
    intentSignalNodes(params: { orgId: string }) {
      return [params.orgId, 'intent-signal-nodes'];
    },
    sqlInWarehouseDialect(params: { orgId: string; rel: Relation }) {
      return [params.orgId, 'sql-in-warehouse-dialect', params.rel.eraHash()];
    },
    irForSqlHash(params: { orgId: string; sqlHash: string }) {
      return [params.orgId, 'ir', params.sqlHash];
    },
    getPrimary(params: { orgId: string }) {
      return [params.orgId, 'warehouse', 'primaryConnection'] as const;
    },
    sinkCursors(params: { orgId: string }) {
      return [params.orgId, 'warehouse', 'sinkCursors'] as const;
    },
  },
  sample: {
    base(params: { orgId: string }) {
      return [params.orgId, 'definitions'] as const;
    },
    get(params: { orgId: string; rel: Relation }) {
      return [...this.base(params), 'sample', params.rel.sqlHash()] as const;
    },
  },
  workbook: {
    base(params: { orgId: string }) {
      return [params.orgId, 'workbooks'] as const;
    },
    all(params: { orgId: string }) {
      return [...this.base(params), 'all'] as const;
    },
    published(params: { id: string }) {
      return [params.id, 'workbooks', 'published'] as const;
    },
    publishedById(params: { id: string }) {
      return [params.id, 'workbooks', 'published', 'byId'] as const;
    },
    byId(params: { orgId: string; id: string }) {
      return [...this.base(params), 'workbook', params.id] as const;
    },
  },
  jobs: {
    base(params: { orgId: string }) {
      return [params.orgId, 'jobs'] as const;
    },
    runs(params: { orgId: string; names: string[] }) {
      return [...this.base(params), ...params.names, 'runs'] as const;
    },
  },
  sinkCursors: {
    base(params: { orgId: string }) {
      return [params.orgId, 'sinkCursors'];
    },
  },
  expressions: {
    base(params: { orgId: string }) {
      return [params.orgId, 'expressions'] as const;
    },
    forDefinition(params: { orgId: string; definitionId: string }) {
      return [
        ...this.base(params),
        'forDefinition',
        params.definitionId,
      ] as const;
    },
  },
  topics: {
    versions(params: { orgId: string; entityId: string }) {
      return [params.orgId, 'topics', 'versions', params.entityId] as const;
    },
    topics(params: { versionId: string; entityId: string; orgId: string }) {
      return [
        params.versionId,
        'topics',
        params.entityId,
        params.orgId,
      ] as const;
    },
  },
  manifest: {
    detailPage(params: { orgId: string; page: string; version: string }) {
      return [params.orgId, 'detailPage', params.version, params.page];
    },
    skeleton(params: { orgId: string; source: 'commited' | 'dev' }) {
      return [params.orgId, 'manifestSkeleton', params.source];
    },
    def(params: { orgId: string; id: string; version: string }) {
      return [
        params.orgId,
        'manifest',
        params.version,
        'definition',
        pick(params, ['id', 'version']),
      ];
    },
    app(params: { orgId: string; id: string; version: string }) {
      return [
        params.orgId,
        'manifest',
        params.version,
        'app',
        pick(params, ['id', 'version']),
      ];
    },
    local(params: { orgId: string; enabled: boolean }) {
      return [params.orgId, 'manifest', 'local', params.enabled] as const;
    },
    commited(params: { orgId: string }) {
      return [params.orgId, 'manifest', 'commited'] as const;
    },
  },
  ideConfig(params: { orgId: string }) {
    return [params.orgId, 'ideConfig'];
  },
  sources: {
    fivetranConnectors: {
      getDestination(params: { orgId: string }) {
        return [params.orgId, 'sources', 'fivetranConnectors'] as const;
      },
    },
  },
  integrations(params: { orgId: string }) {
    return [params.orgId, 'integrations'];
  },
};
