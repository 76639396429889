import React, { HTMLProps } from 'react';
import { classNames } from '../../utils';

export function Checkbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={classNames(
        className,
        'cursor-pointer focus:outline-0 outline-0 rounded focus:ring-0'
      )}
      {...rest}
    />
  );
}
