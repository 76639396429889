import React from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@cotera/client/app/components/headless';
import {
  ClipboardDocumentIcon,
  ClipboardDocumentListIcon,
} from '@heroicons/react/24/outline';
import { toast } from '@cotera/client/app/components/ui';

export type RowAction = {
  label: string;
  onClick: (row: any) => void;
  icon: JSX.Element;
};

export const RowActions = () => {
  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <EllipsisVerticalIcon className="h-5 w-5 text-gray-800 inline-block -ml-2 mr-2" />
        </DropdownTrigger>
        <DropdownContent>
          <DropdownItem
            icon={<ClipboardDocumentIcon className="h-4 w-4" />}
            active={false}
            onClick={async () => {
              // await navigator.clipboard.writeText(JSON.stringify(row.original));
              toast.success('Copied as JSON');
            }}
          >
            Copy as JSON
          </DropdownItem>
          <DropdownItem
            icon={<ClipboardDocumentListIcon className="h-4 w-4" />}
            active={false}
            onClick={async () => {
              // await navigator.clipboard.writeText(
              //   // await jsonToCsv([row.original])
              // );
              toast.success('Copied as CSV');
            }}
          >
            Copy as CSV
          </DropdownItem>
          {/* {actions.map((action, i) => (
            <DropdownItem
              key={i}
              icon={action.icon}
              active={false}
              onClick={() => action.onClick(row.original)}
            >
              {action.label}
            </DropdownItem>
          ))} */}
        </DropdownContent>
      </Dropdown>
    </>
  );
};
