import { Operator } from '../ast';

export const OPERATOR_PRECENDENCE: Record<Operator, number> = {
  '??': 6,
  '^': 5,
  '*': 4,
  '/': 4,
  '+': 3,
  '-': 3,
  '||': 3,
  '=': 2,
  '!=': 2,
  '>': 2,
  '>=': 2,
  '<': 2,
  '<=': 2,
  like: 1,
  and: 1,
  or: 1,
};
