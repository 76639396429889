import { Relation } from '@cotera/era';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import React from 'react';
import { chartTheme } from '../context';
import { classNames, Formatters } from '@cotera/client/app/components/utils';
import { z } from 'zod';

const Dot: React.FC<{ color: string }> = ({ color }) => {
  return (
    <span
      className="rounded-full	mr-3 min-w-[10px] h-[10px] inline-block "
      style={{ background: color }}
    ></span>
  );
};

export const SummaryForRealtion: React.FC<{ rel: Relation }> = ({ rel }) => {
  const result = useDuckDBQuery({ rel });
  const data = result.data.data.toArray();
  const columns = Object.keys(rel.attributes);

  return (
    <table className="text-sm text-standard-text border-collapse">
      <thead>
        <tr className="">
          {columns.map((col, idx) => {
            return (
              <th
                key={idx}
                className="border border-divider font-medium text-left capitalize px-3 py-2"
              >
                {col}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data.map((row, idx) => {
          return (
            <tr key={idx}>
              {columns.map((col, idx) => {
                const isCategoryCol = col.toLowerCase() === 'category';
                return (
                  <td
                    key={idx}
                    className={classNames(
                      'text-xs border border-divider px-3 py-2',
                      isCategoryCol ? 'text-left' : 'text-right'
                    )}
                  >
                    {isCategoryCol ? (
                      <Dot
                        color={
                          chartTheme
                            .theme('random')
                            .forLabel(z.string().parse(row[col])).color
                        }
                      />
                    ) : null}
                    {isCategoryCol
                      ? (row[col] as React.ReactNode)
                      : Formatters.number(row[col] as string | number)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
