import { Relation, TC, Ty } from '@cotera/era';
import { FilterGroup, FilterGroupManager, FilterItem, Option } from './types';
import {
  BOOLEAN_OPERATORS,
  DATE_OPERATORS,
  NUMERIC_OPERATORS,
  STRING_OPERATORS,
} from './constants';
import React from 'react';
import { v4 } from 'uuid';
import { useDuckDBQuery } from '@cotera/client/app/etc/data/duckdb';
import { z } from 'zod';

export const makeGroupManager = (
  group: FilterGroup,
  setState: (state: FilterGroup) => void,
  options: Option[]
): FilterGroupManager => {
  if (options.length === 0) {
    throw new Error('No options provided');
  }

  const getDefaultAttr = () => {
    return options[0]!.attr;
  };

  const makeDefaultItem = () => {
    return {
      id: v4(),
      value: {
        key: getDefaultAttr(),
        operator: makeOperatorsForType(options[0]!.t)[0],
        value: [null],
      },
    };
  };

  const add = () => {
    setState({
      ...group,
      items: [...group.items, makeDefaultItem()],
    });
  };

  const addGroup = () => {
    setState({
      ...group,
      items: [
        ...group.items,
        {
          id: v4(),
          group: {
            connective: 'and',
            items: [makeDefaultItem()],
          },
        },
      ],
    });
  };

  const items = group.items.map((item, i) => {
    const update = (filter: Partial<NonNullable<FilterItem['value']>>) => {
      const newItems = [...group.items];
      newItems[i] = {
        ...item,
        value: {
          ...item.value!,
          ...filter,
        },
      };
      setState({
        ...group,
        items: newItems,
      });
    };

    const remove = () => {
      const newItems = group.items.filter((_, index) => index !== i);
      setState({
        ...group,
        items: newItems,
      });
    };

    return {
      ...item,
      group: item.group
        ? makeGroupManager(
            item.group,
            (newGroup) => {
              const newItems = [...group.items];
              newItems[i] = {
                ...item,
                group: newGroup,
              };
              setState({
                ...group,
                items: newItems,
              });
            },
            options
          )
        : undefined,
      update,
      remove,
    };
  });

  const use = (connective: 'and' | 'or') => {
    setState({
      ...group,
      connective,
    });
  };

  return {
    ...group,
    use,
    items,
    add,
    addGroup,
  };
};

export const useFilterBuilder = ({
  filterGroup,
  options,
  onChange = () => {},
}: {
  filterGroup: FilterGroup;
  options: Option[];
  onChange?: (filterGroup: FilterGroup) => void;
}): [FilterGroupManager, React.Dispatch<React.SetStateAction<FilterGroup>>] => {
  const [state, setState] = React.useState(filterGroup);

  return [
    makeGroupManager(
      state,
      (state) => {
        setState(state);
        onChange(state);
      },
      options
    ),
    setState,
  ];
};

export const makeOperatorsForType = (t: Ty.ExtendedAttributeType) => {
  if (TC.isNumeric(t)) {
    return NUMERIC_OPERATORS;
  }

  if (
    TC.implementsTy({
      subject: t,
      req: 'boolean',
    })
  ) {
    return BOOLEAN_OPERATORS;
  }

  if (
    TC.implementsTy({
      subject: t,
      req: 'timestamp',
    })
  ) {
    return DATE_OPERATORS;
  }

  if (
    TC.implementsTy({
      subject: t,
      req: 'string',
    })
  ) {
    return STRING_OPERATORS;
  }

  throw new Error('Unsupported type');
};

export const useDynamicOptions = (
  rel: Relation,
  attr: string
): { value: string | null; count: number }[] => {
  const { data } = useDuckDBQuery({
    rel: rel
      .groupBy((t) => t.pick(attr))
      .select((t) => ({
        value: t.attr(attr),
        count: t.count()['COUNT'],
      }))
      .orderBy((t) => t.attr('count').desc())
      .limit(500),
  });

  const schema = z.object({
    value: z.string().nullable(),
    count: z.number(),
  });

  return data?.data.toArrayOf(schema) ?? [];
};
